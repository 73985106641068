import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DeliveriesMultiSelectFilter from '~/client/src/desktop/components/DeliveriesMultiSelectFilter'
import FilterLabel from '~/client/src/desktop/components/FilterLabel'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { NotificationFilters } from '~/client/src/shared/stores/InitialState'
import NotificationsStore from '~/client/src/shared/stores/domain/Notifications.store'

import ParametersBar from '../../../components/ParametersBar/ParametersBar'
import DesktopInitialState from '../../../stores/DesktopInitialState'
import DesktopCommonStore from '../../../stores/ui/DesktopCommon.store'
import NotificationsFilter from './NotificationsFilter'

export enum collapsableSectionType {
  tracker = 'tracker',
  deliveries = 'deliveries',
}

// translated

interface IProps {
  eventsStore?: DesktopEventStore
  state?: DesktopInitialState
  notificationsStore: NotificationsStore
  common?: DesktopCommonStore
}

@inject('eventsStore', 'state', 'common')
@observer
export default class NotificationsSideBar extends React.Component<IProps> {
  @observable private isTrackerExpaned = true
  @observable private isDeliveriesExpaned = true
  @observable private isPermitExpaned = true

  public render() {
    const { notificationsStore, state } = this.props
    const {
      isCompaniesBarExpanded,
      selectedCompanies,
      setCompanyFilterValue,
      companies,
      toggleCompaniesBar,
      getCompanyNameById,
      onTimeWindow,
      setOnTimeWindow,
    } = notificationsStore
    const {
      isTrackerDisabled,
      isDeliveriesDisabled,
      isFormsDisabled,
      notificationsStatusFilters,
    } = state
    const options = companies.map(company => ({
      value: company,
      label: (
        <FilterLabel
          name={getCompanyNameById(company)}
          hideCountLabel={true}
          className="text-ellipsis"
        />
      ),
    }))

    return (
      <div className="notifications-sideBar">
        <NotificationsSideBarSection
          title={Localization.translator.inbox}
          isFilterActive={notificationsStatusFilters.includes(
            NotificationFilters.INBOX,
          )}
          onClick={this.showAllNotifications}
        >
          {this.renderFilter(NotificationFilters.UNREAD)}
          {this.renderFilter(NotificationFilters.READ)}
          {this.renderFilter(NotificationFilters.ARCHIVED)}
        </NotificationsSideBarSection>
        <NotificationsSideBarSection title={Localization.translator.filters}>
          {this.renderFilter(NotificationFilters.FOLLOWED)}
          {this.renderFilter(NotificationFilters.FORMS)}
          {!isDeliveriesDisabled && (
            <NotificationsCollabsableSection
              title={Localization.translator.deliveries}
              isExpanded={this.isDeliveriesExpaned}
              toggleSection={this.toggleDeliveriesSection}
            >
              {this.renderFilter(
                NotificationFilters.DELIVERIES_REQUESTED,
                'light-blue',
              )}
              {this.renderFilter(
                NotificationFilters.DELIVERIES_CHANGED,
                'yellow',
              )}
              {this.renderFilter(
                NotificationFilters.DELIVERIES_CANCELED,
                'gray',
              )}
              {this.renderFilter(
                NotificationFilters.DELIVERIES_SCHEDULED,
                'blue',
              )}
              {this.renderFilter(
                NotificationFilters.DELIVERIES_DELIVERING,
                'orange',
              )}
              {this.renderFilter(
                NotificationFilters.DELIVERIES_PAUSED,
                'yellow',
              )}
              {this.renderFilter(
                NotificationFilters.DELIVERIES_ON_HOLD,
                'orange',
              )}
              {this.renderFilter(
                NotificationFilters.DELIVERIES_ON_SITE,
                'orange',
              )}
              {this.renderFilter(
                NotificationFilters.DELIVERIES_PASSED_INSPECTION,
                'green',
              )}
              {this.renderFilter(
                NotificationFilters.DELIVERIES_FAILED_INSPECTION,
                'red',
              )}
              {this.renderFilter(NotificationFilters.DELIVERIES_DONE, 'gray')}
              {this.renderFilter(NotificationFilters.DELIVERIES_DENIED, 'red')}
            </NotificationsCollabsableSection>
          )}
          {!isFormsDisabled && (
            <NotificationsCollabsableSection
              title={Localization.translator.forms}
              isExpanded={this.isPermitExpaned}
              toggleSection={this.togglePermitSection}
            >
              {this.renderFilter(
                NotificationFilters.FORM_SUBMITTED,
                'light-blue',
              )}
              {this.renderFilter(
                NotificationFilters.FORM_CHANGED,
                'light-blue',
              )}
              {this.renderFilter(NotificationFilters.FORM_DENIED, 'red')}
              {this.renderFilter(NotificationFilters.FORM_REVIEWED, 'blue')}
              {this.renderFilter(NotificationFilters.FORM_ACCEPTED, 'blue')}
              {this.renderFilter(NotificationFilters.FORM_ACTIVATED, 'orange')}
              {this.renderFilter(NotificationFilters.FORM_ON_SITE, 'orange')}
              {this.renderFilter(NotificationFilters.FORM_TO_INSPECT, 'yellow')}
              {this.renderFilter(NotificationFilters.FORM_FAILED, 'red')}
              {this.renderFilter(NotificationFilters.FORM_PASSED, 'yellow')}
              {this.renderFilter(
                NotificationFilters.FORM_BIC_INSPECTED,
                'green',
              )}
              {this.renderFilter(NotificationFilters.FORM_FINISHED, 'gray')}
              {this.renderFilter(NotificationFilters.FORM_CLOSED, 'gray')}
              {this.renderFilter(NotificationFilters.FORM_DELETED, 'red')}
            </NotificationsCollabsableSection>
          )}
          {!isTrackerDisabled && (
            <NotificationsCollabsableSection
              title={Localization.translator.tracker}
              isExpanded={this.isTrackerExpaned}
              toggleSection={this.toggleTrackerSection}
            >
              {this.renderFilter(NotificationFilters.FLAGS)}
              {this.renderFilter(NotificationFilters.RFIS)}
              {this.renderFilter(NotificationFilters.SCHEDULE_COMMENTS)}
              {this.renderFilter(NotificationFilters.ACTIVITY_CHANGED)}
            </NotificationsCollabsableSection>
          )}
          {!isDeliveriesDisabled && (
            <NotificationsSideBarSection
              title={Localization.translator.performance}
            >
              <div className="pl10">
                {this.renderFilter(NotificationFilters.DELIVERIES_ON_TIME)}
                {this.renderFilter(NotificationFilters.DELIVERIES_LATE)}
              </div>
              <ParametersBar
                additionalContentStyle="text large"
                shouldHideBorderUnderTitle={true}
                shouldHideTitle={true}
                onTimeWindow={onTimeWindow}
                setOnTimeWindow={setOnTimeWindow}
              />
            </NotificationsSideBarSection>
          )}
        </NotificationsSideBarSection>
        <div
          className={classList({
            'companies-bar overflow-hidden py15': true,
            expanded: isCompaniesBarExpanded,
          })}
        >
          <div className="companies-bar-container scrollable">
            <DeliveriesMultiSelectFilter
              additionalTitleStyle="bb-light-grey pb3"
              title={Localization.translator.companies}
              options={options}
              values={selectedCompanies.slice()}
              onChange={setCompanyFilterValue}
            />
          </div>
          <div
            className="h20 text primary-blue center bb-light-grey pb3"
            onClick={toggleCompaniesBar}
          >
            {isCompaniesBarExpanded
              ? Localization.translator.showLess
              : Localization.translator.showMore}
          </div>
        </div>
      </div>
    )
  }

  private renderFilter(filter: NotificationFilters, color?: string) {
    return (
      <NotificationsFilter
        color={color}
        filter={filter}
        notificationsStore={this.props.notificationsStore}
      />
    )
  }

  private showAllNotifications = () => {
    this.props.state.notificationsStatusFilters = [NotificationFilters.INBOX]
    this.props.notificationsStore.notificationsStatusFilters = [
      NotificationFilters.INBOX,
    ]
    this.props.common.displayNotifications()
  }

  private togglePermitSection = () => {
    this.isPermitExpaned = !this.isPermitExpaned
  }

  private toggleTrackerSection = () => {
    this.isTrackerExpaned = !this.isTrackerExpaned
  }

  private toggleDeliveriesSection = () => {
    this.isDeliveriesExpaned = !this.isDeliveriesExpaned
  }
}

function NotificationsCollabsableSection({
  children,
  title,
  isExpanded,
  toggleSection,
}: {
  title?: string
  isExpanded?: boolean
  toggleSection?
  children?: any
}) {
  const icon = isExpanded ? IconNames.CHEVRON_DOWN : IconNames.CHEVRON_UP
  return (
    <div className="notifications-sidebar-section">
      <div className="row pt10 pb5">
        <div className="no-grow" onClick={toggleSection}>
          <Icon icon={icon} />
        </div>
        {title && (
          <div className="bold">
            <div className="text large bold light">{title}</div>
          </div>
        )}
      </div>
      {isExpanded && <div className="pl15">{children}</div>}
    </div>
  )
}

function NotificationsSideBarSection({
  children,
  title,
  onClick,
  isFilterActive,
}: {
  title?: string
  isFilterActive?: boolean
  children?: any
  onClick?: (e: React.SyntheticEvent<HTMLDivElement>) => any
}) {
  return (
    <div className="notifications-sidebar-section">
      {title && (
        <div
          className={classList({
            'notifications-sidebar-section-title pointer': true,
            active: isFilterActive,
          })}
          onClick={onClick}
        >
          <div className="text small-header bold">{title}</div>
        </div>
      )}
      {children}
    </div>
  )
}
