import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { ILevelsClosure } from '~/client/graph'
import LevelsList from '~/client/src/desktop/components/LevelsList'
import OpeningIndicatorLabel from '~/client/src/shared/components/OpeningIndicatorLabel/OpeningIndicatorLabel'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import VerticalObject from '~/client/src/shared/models/LocationObjects/VerticalObject'
import { isAfter } from '~/client/src/shared/stores/ui/ProjectDate.store'

import ObjectAccessibleLevelsProperties from '../../../../../../../../AppsSitemap/components/PropertiesPanel/ObjectAccessibleLevelsProperties'
import {
  FIXED_CELL_WIDTH,
  ObjectCellBase,
  objectCellInjects,
} from '../ObjectCell'
import CellWrapper from './CellWrapper/CellWrapper'
import LevelsClosureModal from './LevelsClosureModal'

const accessibleLevels = 'Accessible levels'
const addNewClosure = 'Add new closure'
const edit = 'Edit'

const LEVEL_SEPARATOR = ', '

@inject(...objectCellInjects)
@observer
export default class VerticalObjectCell extends ObjectCellBase {
  protected renderNameCellContent() {
    const levelsCount = this.accessibleLevels.length
    return (
      <>
        {super.renderNameCellContent()}
        <div className="pl12 pt12">
          <div className="text grey-light uppercase bold pb5">
            {accessibleLevels}
            <span className="text grey-lighter pl5">({levelsCount})</span>
          </div>
          <LevelsList levels={this.accessibleLevels} />
          <div className="pt5 text blue-highlight bold large">{edit}</div>
        </div>
      </>
    )
  }

  private get accessibleLevels() {
    const { levelsStore } = this.props

    if (!this.verticalObject.hasParent) {
      return []
    }
    return levelsStore.list.filter(
      l =>
        l.hasCommonParent(this.verticalObject) &&
        this.verticalObject.accessibleLevels.includes(l.id),
    )
  }

  private get verticalObject(): VerticalObject {
    return this.props.obj as unknown as VerticalObject
  }

  protected renderAdditionalEditControls() {
    const { saveChanges } = this.store
    return (
      <ObjectAccessibleLevelsProperties
        dataObject={this.verticalObject}
        onChange={saveChanges}
      />
    )
  }

  protected renderLevelsToCloseCell() {
    const {
      shouldShowLevelsClosureModal,
      toggleLevelsClosureModal,
      saveChanges,

      hideLevelsClosureModal,
    } = this.store
    const { levelsClosures } = this.verticalObject
    return (
      <CellWrapper
        paddingY={this.props.paddingY}
        style={{ maxWidth: FIXED_CELL_WIDTH, minWidth: FIXED_CELL_WIDTH }}
      >
        <div className="col">
          {levelsClosures.map((closure, idx) => {
            const levels = this.getLevelsByIds(closure.levels)
            const title = levels.map(l => l.name).join(LEVEL_SEPARATOR)

            return (
              <div
                key={idx}
                title={title}
                className="row interval-wrapper y-center pl6"
              >
                <LevelsList levels={levels} />
              </div>
            )
          })}
        </div>
        {!!this.accessibleLevels.length &&
          this.renderAddControl(addNewClosure, toggleLevelsClosureModal)}

        {shouldShowLevelsClosureModal && (
          <LevelsClosureModal
            datePickerStore={this.props.datePickerStore}
            verticalObject={this.verticalObject}
            saveChanges={saveChanges}
            onClose={hideLevelsClosureModal}
          />
        )}
      </CellWrapper>
    )
  }

  protected renderAdditionalStatusCell(): JSX.Element[] {
    const { levelsClosures } = this.verticalObject
    const { projectDateStore } = this.props

    return levelsClosures.map((closure, idx) => {
      const isOpen = this.verticalObject.isOpenForInterval(
        projectDateStore,
        closure.interval,
      )
      return (
        <div key={idx} className="row interval-wrapper y-center">
          <OpeningIndicatorLabel
            closeLabel={Localization.translator.closed}
            openLabel={Localization.translator.opened}
            isOpen={isOpen}
          />
        </div>
      )
    })
  }

  protected renderUpToDateAdditionalIntervals = (): JSX.Element[] => {
    const oldIntervals = this.getUpToDateClosures(
      this.verticalObject.levelsClosures,
    )
    return this.renderIntervals(oldIntervals)
  }

  protected renderOldAdditionalIntervals = (): JSX.Element[] => {
    const oldIntervals = this.getOldClosures(this.verticalObject.levelsClosures)
    return this.renderIntervals(oldIntervals)
  }

  private renderIntervals = (closures: ILevelsClosure[]): JSX.Element[] => {
    const { formatLevelsClosureInterval, isClosureIntervalActive } = this.store

    return closures.map((closure, idx) => {
      const formattedInterval = formatLevelsClosureInterval(closure.interval)
      return (
        <div
          key={idx}
          title={formattedInterval}
          className={classList({
            'row interval-wrapper': true,
            active: isClosureIntervalActive(closure.interval),
          })}
        >
          <div className="text monospace large w-fit-content">
            {formattedInterval}
            <Icon
              className="pointer"
              icon={IconNames.SMALL_CROSS}
              onClick={this.onRemoveClosure.bind(this, closure)}
            />
          </div>
        </div>
      )
    })
  }

  private onRemoveClosure(closure: ILevelsClosure) {
    const { levelsClosures } = this.verticalObject
    this.verticalObject.levelsClosures = levelsClosures.filter(
      c => c !== closure,
    )
    this.store.saveChanges()
  }

  private getLevelsByIds(ids: string[]) {
    const { byId } = this.props.levelsStore
    return ids.map(id => byId.get(id)).filter(l => l)
  }

  private getOldClosures(closures: ILevelsClosure[]): ILevelsClosure[] {
    const now = Date.now()
    return closures.filter(
      ({ interval: { startDate, endDate } }) =>
        isAfter(now, endDate) && isAfter(now, startDate),
    )
  }

  private getUpToDateClosures(closures: ILevelsClosure[]): ILevelsClosure[] {
    const now = Date.now()
    return closures.filter(
      ({ interval: { startDate, endDate } }) =>
        !isAfter(now, endDate) || !isAfter(now, startDate),
    )
  }
}
