import * as React from 'react'

import SelectWrapper from '~/client/src/desktop/components/SelectWrapper/SelectWrapper'
import IBasicProps from '~/client/src/shared/interfaces/IBasicProps'

interface IProps extends IBasicProps {
  selectedValue: string | number
  options: any[]
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

export default function TypeSelector({
  selectedValue,
  options,
  onChange,
}: IProps) {
  return (
    <SelectWrapper className="no-grow">
      <select
        className="bg-palette-brand-lightest brada4 ba-none pl12 pr24 py6 pointer"
        value={selectedValue}
        onChange={onChange}
      >
        {options.map(({ value, name }) => (
          <option key={value} value={value} className="text large capitalize">
            {name}
          </option>
        ))}
      </select>
    </SelectWrapper>
  )
}
