import * as React from 'react'

import {
  Icon,
  Popover,
  PopoverInteractionKind,
  PopoverPosition,
  Switch,
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList, toggleClass } from 'react-classlist-helper'

import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import activityFieldIdToFilterTypeMap from '~/client/src/desktop/constants/activityFieldIdToFilterTypeMap'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopCommonStore from '~/client/src/desktop/stores/ui/DesktopCommon.store'
import ActionBarBottomMenu from '~/client/src/shared/components/ActionBarBottomMenu/ActivityBarBottomMenu'
import ActivityAssociationStatus from '~/client/src/shared/components/ActivityAssociationStatus/ActivityAssociationStatus'
import ActivityDetailsStore from '~/client/src/shared/components/ActivityDetails/ActivityDetails.store'
import {
  ActivityDetailsTab,
  IActivityField,
} from '~/client/src/shared/components/ActivityDetails/ActivityDetailsInterfaces'
import ActivityFormStore, {
  ActivityFieldType,
} from '~/client/src/shared/components/ActivityDetails/components/ActivityForm.store'
import ActivityInfoSection from '~/client/src/shared/components/ActivityDetails/components/ActivityInfoSection/ActivityInfoSection'
import ActivityLog from '~/client/src/shared/components/ActivityDetails/components/ActivityLog/ActivityLog'
import SlackBar from '~/client/src/shared/components/ActivityDetails/components/SlackBar/SlackBar'
import CompactCompaniesDirectory from '~/client/src/shared/components/CompactCompaniesDirectory/CompactCompaniesDirectory'
import ActivityCompactHeaderBar from '~/client/src/shared/components/CompactHeaderBar/components/ActivityCompactHeaderBar'
import ConfirmDialog from '~/client/src/shared/components/ConfirmDialog/ConfirmDialog'
import * as Icons from '~/client/src/shared/components/Icons'
import ImagePreview from '~/client/src/shared/components/ImagePreview/ImagePreview'
import {
  Content,
  Footer,
  Header,
  View,
} from '~/client/src/shared/components/Layout'
import { Loader } from '~/client/src/shared/components/Loader'
import LocationPicker from '~/client/src/shared/components/LocationPicker/LocationPicker'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import StruxhubAttributeSelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubAttributeSelector'
import StruxhubCompanySelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubCompanySelector'
import StruxhubUserSelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubUserSelector'
import Tabs, { ITabObject } from '~/client/src/shared/components/Tabs/Tabs'
import UsersDirectory from '~/client/src/shared/components/UsersDirectory/UsersDirectory'
import WorkflowsBottomMenu from '~/client/src/shared/components/WorkflowsBottomMenu/WorkflowsBottomMenu'
import activityFieldIdToLinkingSettingNameMap from '~/client/src/shared/constants/activityFieldIdToLinkingSettingNameMap'
import ActivityDataFieldId from '~/client/src/shared/enums/ActivityDataFieldId'
import ActivityLinkingSettings from '~/client/src/shared/enums/ActivityLinkingSettings'
import { TagType } from '~/client/src/shared/enums/TagType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Activity from '~/client/src/shared/models/Activity'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ActivityCodeLocationRelationshipsStore from '~/client/src/shared/stores/domain/ActivityCodeLocationRelationships.store'
import ActivityCompanyRelationshipsStore from '~/client/src/shared/stores/domain/ActivityCompanyRelationships.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import ConstraintsStore from '~/client/src/shared/stores/domain/Constraints.store'
import GraphExecutorStore from '~/client/src/shared/stores/domain/GraphExecutor.store'
import locationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import ThreadsStore from '~/client/src/shared/stores/domain/ThreadsStore/Threads.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import SlackBarStore from '~/client/src/shared/stores/ui/SlackBar.store'
import { NOOP } from '~/client/src/shared/utils/noop'

import CompanyObjectsSelector from '../../views/ProjectSetUp/components/ActivityFilters/components/CompanyObjectsSelector'
import LocationObjectsSelector from '../../views/ProjectSetUp/components/ActivityFilters/components/LocationObjectsSelector'

import '~/client/src/shared/components/ActivityDetails/ActivityDetails.scss'

interface IProps {
  match?: any
  activityId?: string
  shouldPreventScrollToBottom?: boolean
  hideEditButton?: boolean
  backClicked?: () => void

  state?: DesktopInitialState
  activityDetailsStore?: ActivityDetailsStore
  graphExecutorStore?: GraphExecutorStore
  projectDateStore?: ProjectDateStore
  tagsStore?: TagsStore
  constraintsStore?: ConstraintsStore
  locationAttributesStore?: locationAttributesStore
  activitiesStore?: ActivitiesStore
  activityCodeLocationRelationshipsStore?: ActivityCodeLocationRelationshipsStore
  activityCompanyRelationshipsStore?: ActivityCompanyRelationshipsStore
  activityFiltersStore?: ActivityFiltersStore
  slackBarStore?: SlackBarStore
  common?: DesktopCommonStore
  messagesStore?: MessagesStore
  threadsStore?: ThreadsStore
}

const userModalSearchTagTypes = [TagType.User, TagType.Company]
const LOADER_SIZE = 50
const notUniqueActivityCodeMessage = (code: string) =>
  Localization.translator.notUniqueActivityCodeMessage(code)

@inject(
  'state',
  'activityDetailsStore',
  'graphExecutorStore',
  'projectDateStore',
  'tagsStore',
  'constraintsStore',
  'locationAttributesStore',
  'activitiesStore',
  'activityCodeLocationRelationshipsStore',
  'activityCompanyRelationshipsStore',
  'activityFiltersStore',
  'slackBarStore',
  'messagesStore',
  'threadsStore',
  'common',
)
@observer
export default class ActivityDetails extends React.Component<IProps> {
  private containerRef: HTMLDivElement

  private get store(): ActivityDetailsStore {
    return this.props.activityDetailsStore
  }

  private get activityFormStore(): ActivityFormStore {
    return this.store.activityFormStore
  }

  private get selectedActivity(): Activity {
    return this.props.activitiesStore.selectedActivity
  }

  private get sequenceNumber(): number {
    if (!this.selectedActivity?.code) {
      return 0
    }

    return (
      this.props.activitiesStore.listItemIdToNumberMap?.[
        this.selectedActivity.code
      ] || 0
    )
  }

  public componentDidMount() {
    const { loadConstraints, listenToConstraints } = this.props.constraintsStore
    const code = this.props.activityId || this.props.match?.params.objectId
    this.props.activitiesStore.select(code)
    const threads = this.props.threadsStore.list.filter(
      thread => thread.activityObjectId === code,
    )
    const { user } = this.props.state

    threads.forEach(thread => {
      const unreadMessages = this.props.messagesStore.list.filter(message => {
        if (message.threadId !== thread.id) {
          return false
        }
        return !message.readBy.some(
          readByUser => readByUser.userId === user.id && readByUser.isRead,
        )
      })
      unreadMessages.forEach(message => {
        message.markAsReadByUser(user)
        this.props.messagesStore.save(message)
      })
    })

    loadConstraints()
    listenToConstraints()

    this.store.prepareUI()
  }

  public componentDidUpdate(prevProps: IProps) {
    const { activityId } = this.props

    if (prevProps.activityId !== activityId) {
      this.store.prepareUI()
    }

    // force re-listen if project has been switched
    if (
      prevProps.state.activeProject.id !== this.props.state.activeProject.id
    ) {
      const { loadConstraints, listenToConstraints } =
        this.props.constraintsStore
      loadConstraints()
      listenToConstraints()
    }
  }

  public componentWillUnmount() {
    if (
      this.activityFormStore.newActivityData &&
      this.store.isCreateModeActive
    ) {
      this.props.activitiesStore.setDraftNewActivity(
        this.activityFormStore.newActivityData,
      )
    }
    this.store.setLogVisibility(false)
  }

  public render() {
    const { isSaving, activeDetailsTab, isViewModeActive } = this.store
    const { slackBarStore } = this.props
    const { shouldShowPreview, previewImage } = slackBarStore

    return (
      <div className="activity-details col overflow-hidden">
        <View className={toggleClass('unclickable-element', isSaving)}>
          <Header className="relative">
            <ActivityCompactHeaderBar
              activity={this.selectedActivity}
              sequenceNumber={this.sequenceNumber}
              onCloseClick={this.goBack}
            />
          </Header>
          {this.renderLoader()}
          {this.renderFieldPickers()}
          {shouldShowPreview && (
            <ImagePreview>
              <img
                alt={Localization.translator.image}
                src={previewImage}
                className="center"
              />
            </ImagePreview>
          )}
          <Content className="relative y-scroll-hidden">
            <div
              className={classList({
                'activity-details-content full-height': true,
              })}
            >
              <Tabs
                tabObjects={this.activityDetailTabs}
                activeTabId={activeDetailsTab}
                onTabClick={this.switchActiveTab}
                isFlexible={true}
                className="activity-details-tabs y-end bg-palette-brand-lightest"
                tabClassName="tab relative bg-palette-brand-lightest bb-palette-brand-lighter"
                tabTextClassName="tab-text text light large bold lp015 center"
              />

              {activeDetailsTab === ActivityDetailsTab.Form
                ? this.renderActivityDetailsTab()
                : this.renderActivityLogTab()}
            </div>
          </Content>
          {activeDetailsTab === ActivityDetailsTab.Log || isViewModeActive
            ? this.renderActivityInfoFooter()
            : this.renderActivityFormFooter()}
        </View>
      </div>
    )
  }

  private renderLoader(): JSX.Element {
    return (
      this.store.isSaving && (
        <div className="loader-holder row x-center y-center absolute full-height full-width">
          <Loader size={LOADER_SIZE} />
        </div>
      )
    )
  }

  private renderFieldPickers(): JSX.Element {
    const {
      selectedField,
      unsetSelectedField,
      selectedLocationFieldValue,
      isLocationFieldSelected,
      isCompanyFieldSelected,
      isUserFieldSelected,
      restrictedLocationTypes,
    } = this.activityFormStore

    return (
      <>
        {isLocationFieldSelected && (
          <LocationPicker
            className="absolute full-width full-height bg-white"
            title={`${
              Localization.translator.select
            } ${selectedField.getCaption()}`}
            applyButtonTitle={Localization.translator.select}
            isSingleSelectionMode={true}
            selectedIds={selectedLocationFieldValue}
            restrictedObjectTypes={restrictedLocationTypes}
            allowedObjectIds={this.store.allowedLocationIds}
            onSingleSelect={this.changeFieldValue}
            onChange={NOOP}
            onApplyChanges={unsetSelectedField}
            onClose={unsetSelectedField}
          />
        )}
        {isCompanyFieldSelected && (
          <CompactCompaniesDirectory
            className="absolute full-width full-height bg-white"
            isPhoneHidden={true}
            onCompanyRowClick={this.changeFieldValue}
            onHeaderCloseClick={unsetSelectedField}
          />
        )}
        {isUserFieldSelected && (
          <UsersDirectory
            className="absolute full-width full-height bg-white"
            shouldSelectOnlyUsers={true}
            shouldHideFilterBar={true}
            shouldUseAllProjectMembers={true}
            onUserRowClick={this.changeFieldValue}
            onHeaderCloseClick={unsetSelectedField}
            searchTypes={userModalSearchTagTypes}
          />
        )}

        <ConfirmDialog
          isOpen={this.store.isConfirmationDialogOpened}
          doneTitle={Localization.translator.ok}
          onDoneClicked={this.store.doneDialogHandler}
          onCancelClicked={this.store.cancelDialogHandler}
          children={
            <div className="row">
              <Icon
                className="warning-icon mr10"
                icon={IconNames.WARNING_SIGN}
                iconSize={30}
              />
              <div className="confirm-dialog-question">
                {this.store.dialogQuestion}
              </div>
            </div>
          }
        />
      </>
    )
  }

  public renderActivityDetailsTab() {
    return this.store.isViewModeActive
      ? this.renderActivityInfo()
      : this.renderActivityForm()
  }

  public renderActivityInfo() {
    const { isPresentationUser, isActivityUpdateAvailable } =
      this.props.state.userActiveProjectSettings

    return (
      <Content
        className="relative scrollable full-height"
        setRef={this.setContainerRef}
      >
        <div className="row py20 px20">
          <div className="row">
            <div className="nowrap no-grow">
              <div className="text bold uppercase large">
                {Localization.translator.activityDetails}
              </div>
            </div>
            {isActivityUpdateAvailable && !this.props.hideEditButton && (
              <div className="no-grow row pointer" onClick={this.setEditMode}>
                <Icons.Edit className="no-grow option ml10" />
              </div>
            )}
          </div>
          <ActivityAssociationStatus
            activityId={this.selectedActivity.code}
            className={classList({
              'x-end': true,
              'inactive-element': isPresentationUser,
            })}
            shouldHintShow={true}
          />
        </div>
        <ActivityInfoSection selectedActivity={this.selectedActivity} />
      </Content>
    )
  }

  public renderActivityForm() {
    const { fields, hiddenFieldsInEditMode, hiddenFieldsInCreateMode } =
      this.activityFormStore
    const { isEditModeActive } = this.store

    const displayedFields = isEditModeActive
      ? fields.filter(field => !hiddenFieldsInEditMode.includes(field.fieldId))
      : fields.filter(
          field => !hiddenFieldsInCreateMode.includes(field.fieldId),
        )

    return (
      <Content
        className="relative scrollable full-height px16"
        setRef={this.setContainerRef}
      >
        {isEditModeActive && this.renderBackArrow()}
        {displayedFields.map(field => (
          <div key={field.fieldId} className="col">
            {this.renderInput(field)}
          </div>
        ))}
      </Content>
    )
  }

  public renderBackArrow() {
    return (
      <div className="row py20 bb-light-grey">
        <Icons.BackArrow
          className="row no-grow pointer"
          onClick={this.setViewMode}
        />
        <div className="text bold ellipsis extra-large pl10">
          {Localization.translator.edit_verb}
        </div>
      </div>
    )
  }

  private renderLinkingSettings(field: IActivityField) {
    const isCompanyField = field.type === ActivityFieldType.CompanySelect
    const linkingSetting = activityFieldIdToLinkingSettingNameMap[field.fieldId]
    const isFieldLinkingEnabled =
      this.activityFormStore.newActivityData.activityLinkingSettings[
        linkingSetting
      ]
    const externalData = this.selectedActivity?.externalData[field.fieldId]
    const filterType = activityFieldIdToFilterTypeMap[field.fieldId]

    return (
      <div className="data-linking-state-wrapper">
        <Popover
          position={PopoverPosition.TOP_LEFT}
          usePortal={true}
          canEscapeKeyClose={true}
          interactionKind={PopoverInteractionKind.HOVER}
          targetClassName="full-width"
          popoverClassName="multi-select-field beautiful-shadow"
          content={
            <div className="multi-select-content bg-white mw500 scrollable">
              <div className="linking-settings-header pa12">
                {isFieldLinkingEnabled
                  ? Localization.translator.activityDataLinking.linkingIsEnabled
                  : Localization.translator.activityDataLinking
                      .linkingIsDisabled}
                <Switch
                  checked={isFieldLinkingEnabled}
                  onChange={() => {
                    this.activityFormStore.changeFieldLinkingSetting(
                      field.fieldId,
                      !isFieldLinkingEnabled,
                    )
                  }}
                />
              </div>
              <div className="row y-center bb-brand-dark">
                <div className="py8 px12 text bold brand-dark uppercase">
                  {Localization.translator.scheduleData}
                </div>
                <div className="row y-center x-end py2 px12 text bold brand-dark uppercase nowrap">
                  {Localization.translator.struxhubObject}
                </div>
              </div>
              <div key={externalData.id} className="row y-start">
                <div className="row nowrap h48 pr20 pl12 text extra-large brand-dark">
                  {externalData.shortName || externalData.name}
                </div>
                <div>
                  {isCompanyField ? (
                    <CompanyObjectsSelector resource={externalData} />
                  ) : (
                    <LocationObjectsSelector
                      code={externalData}
                      filterType={filterType}
                    />
                  )}
                </div>
              </div>
              <div className="row text large light pa12">
                <Icon
                  icon="warning-sign"
                  iconSize={16}
                  className="warning-icon mr5"
                />
                {Localization.translator.activityDataLinking.editWarning}
              </div>
            </div>
          }
        >
          {isFieldLinkingEnabled ? (
            <Icons.Link className="pointer linking-icon enabled" />
          ) : (
            <Icons.Unlink className="pointer linking-icon disabled" />
          )}
        </Popover>
      </div>
    )
  }

  private renderInput = (field: IActivityField): JSX.Element => {
    const value = this.activityFormStore.newActivityData[field.fieldId]
    const isDateInput = field.type === ActivityFieldType.Date
    let valueToDisplay = value || ''

    if (isDateInput && value) {
      valueToDisplay = this.props.projectDateStore.getDashedFormattedDate(value)
    }

    if (field.type === ActivityFieldType.CompanySelect) {
      const isFieldCanBeLinked =
        this.selectedActivity?.externalData[field.fieldId]
      const isFieldLinkingEnabled =
        this.activityFormStore.newActivityData.activityLinkingSettings[
          ActivityLinkingSettings.isCompanyLinkingActive
        ]
      const data = isFieldLinkingEnabled
        ? this.selectedActivity?.linkedData[field.fieldId]
        : this.activityFormStore.newActivityData[field.fieldId]

      return (
        <div className="linked-field-wrapper">
          <StruxhubCompanySelector
            label={field.getCaption()}
            isRequired={field.required}
            onClick={this.onSelectClick(field)}
            value={data}
          />
          {isFieldCanBeLinked && this.renderLinkingSettings(field)}
        </div>
      )
    }

    if (field.type === ActivityFieldType.UserSelect) {
      return (
        <StruxhubUserSelector
          label={field.getCaption()}
          isRequired={field.required}
          onClick={this.onSelectClick(field)}
          value={value}
        />
      )
    }

    if (field.type === ActivityFieldType.LocationSelect) {
      const linkingSetting =
        activityFieldIdToLinkingSettingNameMap[field.fieldId]
      const isFieldCanBeLinked =
        this.props.activitiesStore.checkIsFieldCanBeLinked(
          field.fieldId,
          this.selectedActivity,
        )
      const isFieldLinkingEnabled =
        this.activityFormStore.newActivityData.activityLinkingSettings[
          linkingSetting
        ]
      const data = isFieldLinkingEnabled
        ? this.selectedActivity?.linkedData[field.fieldId]
        : this.activityFormStore.newActivityData[field.fieldId]

      return (
        <div className="linked-field-wrapper">
          <StruxhubAttributeSelector
            label={field.getCaption()}
            isRequired={field.required}
            onClick={this.onSelectClick(field)}
            value={data}
          />
          {isFieldCanBeLinked && this.renderLinkingSettings(field)}
        </div>
      )
    }

    return (
      <StruxhubInput
        label={field.getCaption()}
        isRequired={field.required}
        isValid={field.isValid?.()}
        type={field.type}
        min={field.min}
        max={field.max}
        isDisabled={
          this.selectedActivity && field.fieldId === ActivityDataFieldId.CODE
        }
        value={valueToDisplay || ''}
        validationMessage={field.validationMessage}
        onChange={this.onInputFieldChanged.bind(this, field)}
        onValueReset={this.onFieldReset.bind(this, field)}
      />
    )
  }

  public renderActivityLogTab() {
    return (
      <Content
        className="activity-log-section relative scrollable full-height"
        setRef={this.setContainerRef}
      >
        <ActivityLog
          FileInputType={DesktopFileInput}
          shouldPreventScrollToBottom={this.props.shouldPreventScrollToBottom}
        />
      </Content>
    )
  }

  public renderActivityInfoFooter() {
    const { userActiveProjectSettings, activityList } = this.props.state
    const { isMenuPopupShown } = this.props.slackBarStore

    return (
      <Footer
        className={toggleClass(
          'inactive-element',
          userActiveProjectSettings?.isPresentationUser,
        )}
      >
        {isMenuPopupShown && (
          <ActionBarBottomMenu isOpen={isMenuPopupShown}>
            <WorkflowsBottomMenu
              store={this.store}
              hideEditButton={this.props.hideEditButton}
            />
          </ActionBarBottomMenu>
        )}
        <SlackBar
          activity={this.selectedActivity}
          selectedCompany={activityList.selectedCompany}
          userActiveProjectSettings={userActiveProjectSettings}
        />
      </Footer>
    )
  }

  public renderActivityFormFooter() {
    const { areFieldsValid, areFieldsChanged } = this.activityFormStore
    const { isCreateModeActive } = this.store

    return (
      <Footer className="row bt-light-grey px10 py15">
        <div
          className={classList({
            'row y-center x-center h40 brada4 text white large pointer mx10 bg-color-blue-brand':
              true,
            'inactive-element':
              !areFieldsValid || (!isCreateModeActive && !areFieldsChanged),
          })}
          onClick={isCreateModeActive ? this.createActivity : this.editActivity}
        >
          {Localization.translator.submit_verb}
        </div>
      </Footer>
    )
  }

  private get activityDetailTabs(): Array<ITabObject<ActivityDetailsTab>> {
    const { isCreateModeActive } = this.store

    return [
      {
        title: Localization.translator.form,
        page: ActivityDetailsTab.Form,
      },
      {
        title: Localization.translator.log,
        page: ActivityDetailsTab.Log,
        className: isCreateModeActive && 'inactive-element',
      },
    ]
  }

  private setEditMode = (event: React.MouseEvent) => {
    event.stopPropagation()
    this.store.setEditActivityMode()
  }

  private setViewMode = (event: React.MouseEvent) => {
    event.stopPropagation()
    this.store.setViewActivityMode()
  }

  private onSelectClick = (field: IActivityField) => () => {
    this.activityFormStore.setSelectedField(field)
  }

  private onInputFieldChanged(
    field: IActivityField,
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    const { changeFieldValue } = this.activityFormStore

    if (field.type === ActivityFieldType.Date) {
      const dateValue = this.props.projectDateStore
        .combineISODateTime(event.target.value)
        .getTime()

      return changeFieldValue(field.fieldId, dateValue)
    }
    if (field.type === ActivityFieldType.Number) {
      return changeFieldValue(field.fieldId, event.target.valueAsNumber || 0)
    }

    changeFieldValue(field.fieldId, event.target.value)
  }

  private openLinkDataDialog(newValue: any) {
    const { selectedField } = this.activityFormStore
    const externalData =
      this.selectedActivity?.externalData[selectedField.fieldId]
    const isCompanyField =
      this.activityFormStore.selectedField.type ===
      ActivityFieldType.CompanySelect

    this.store.setConfirmDialogQuestion(
      Localization.translator.activityDataLinking.enableLinkingQuestion(
        newValue.name,
        externalData.name,
      ),
    )

    this.store.setConfirmationDialogHandler(() => {
      isCompanyField
        ? this.props.activityCompanyRelationshipsStore.createRelationship(
            newValue.id,
            externalData.id,
          )
        : this.props.activityCodeLocationRelationshipsStore.createRelationship(
            externalData.id,
            newValue.id,
            selectedField.locationType,
          )

      this.activityFormStore.changeFieldLinkingSetting(
        selectedField.fieldId,
        true,
      )
      this.activityFormStore.unsetSelectedField()
      this.store.setConfirmDialogVisibility(false)
    })

    this.store.setCancelDialogHandler(() => {
      this.store.changeSelectedFieldValue(newValue.id)
      this.activityFormStore.changeFieldLinkingSetting(
        selectedField.fieldId,
        false,
      )
      this.activityFormStore.unsetSelectedField()
      this.store.setConfirmDialogVisibility(false)
    })

    this.store.setConfirmDialogVisibility(true)
  }

  private openDisableLinkingDialog(newValue: any) {
    const { selectedField } = this.activityFormStore
    const externalData =
      this.selectedActivity?.externalData[selectedField.fieldId]

    this.store.setConfirmDialogQuestion(
      Localization.translator.activityDataLinking.disableLinkingQuestion(
        externalData.name,
      ),
    )

    this.store.setConfirmationDialogHandler(() => {
      this.store.changeSelectedFieldValue(newValue.id)
      this.activityFormStore.changeFieldLinkingSetting(
        selectedField.fieldId,
        false,
      )
      this.activityFormStore.unsetSelectedField()
      this.store.setConfirmDialogVisibility(false)
    })

    this.store.setCancelDialogHandler(() => {
      this.activityFormStore.unsetSelectedField()
      this.store.setConfirmDialogVisibility(false)
    })

    this.store.setConfirmDialogVisibility(true)
  }

  private changeFieldValue = (newValue: any) => {
    const { selectedField, newActivityData } = this.activityFormStore

    const linkingSetting =
      activityFieldIdToLinkingSettingNameMap[selectedField.fieldId]
    const isFieldLinkingEnabled =
      newActivityData.activityLinkingSettings[linkingSetting]
    const isFieldCanBeLinked =
      this.props.activitiesStore.checkIsFieldCanBeLinked(
        selectedField.fieldId,
        this.selectedActivity,
      )
    const linkedData = this.selectedActivity?.linkedData[selectedField.fieldId]

    if (!isFieldCanBeLinked) {
      this.store.changeSelectedFieldValue(newValue.id)
      return
    }

    if (isFieldLinkingEnabled) {
      if (linkedData) {
        this.openDisableLinkingDialog(newValue)
      } else {
        this.openLinkDataDialog(newValue)
      }
    } else {
      if (!linkedData && newValue?.id) {
        this.openLinkDataDialog(newValue)
      } else {
        this.store.changeSelectedFieldValue(newValue.id)
      }
    }
  }

  private onFieldReset(field: IActivityField) {
    const resetValue = field.type === ActivityFieldType.Number ? 0 : ''
    this.activityFormStore.changeFieldValue(field.fieldId, resetValue)
  }

  private createActivity = async () => {
    const { checkActivityCodeUniqueness, saveActivity, isSaving } = this.store
    const { initDraftData, resetActivityCode, areFieldsValid, activityCode } =
      this.activityFormStore

    if (isSaving) {
      return
    }

    try {
      // eslint-disable-next-line no-var
      var isUnique = await checkActivityCodeUniqueness(activityCode)
    } catch (e) {
      return alert(
        Localization.translator.somethingWentWrongDuringAPIInteraction,
      )
    }

    if (isUnique && areFieldsValid) {
      await saveActivity()
      initDraftData()
      this.goBack()
    } else {
      alert(notUniqueActivityCodeMessage(activityCode))
      resetActivityCode()
    }
  }

  private editActivity = async () => {
    if (!this.store.isSaving && this.activityFormStore.areFieldsValid) {
      await this.store.saveActivity(true)
      this.goBack()
    }
  }

  private switchActiveTab = (newActiveTab: ActivityDetailsTab) => {
    this.store.switchActiveTab(newActiveTab)
    this.setScroll(newActiveTab === ActivityDetailsTab.Log)
  }

  private setScroll = (shouldScrollDown: boolean) => {
    if (!this.containerRef) {
      return
    }
    this.store.shouldScrollToBottom = false

    if (shouldScrollDown) {
      this.containerRef.scrollTop =
        this.containerRef.scrollHeight - this.containerRef.clientHeight
    } else {
      this.containerRef.scrollTop = 0
    }
  }

  @action.bound
  private setContainerRef(ref: HTMLDivElement) {
    this.containerRef = ref
  }

  @action.bound
  private goBack() {
    const { slackBarStore, common, backClicked } = this.props

    backClicked?.()

    return slackBarStore.shouldShowPreview
      ? slackBarStore.hidePreview()
      : common.activityDetailsBackClicked()
  }
}
