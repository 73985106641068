import React from 'react'

import {
  Classes,
  Colors,
  Icon,
  Popover,
  PopperModifiers,
  Position,
  Switch,
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { BlockTypeEnum } from '~/client/graph'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import ProjectSetUpPageStore from '~/client/src/desktop/views/ProjectSetUp/ProjectSetUpPage.store'
import IDeliverySettingsSelect from '~/client/src/desktop/views/ProjectSetUp/components/ProjectWorkflowsSetUp/components/DeliveryRequestSetUp/components/DeliveryDetailsConfigurations/components/IDeliverySettingSelect'
import ButtonToggle from '~/client/src/shared/components/DeliveryDetails/components/DeliveryFormFieldWrapper/components/ButtonToggle'
import * as Icons from '~/client/src/shared/components/Icons'
import StruxhubSelect from '~/client/src/shared/components/StruxhubInputs/StruxhubSelect'
import DeliveryControlTypes from '~/client/src/shared/enums/DeliveryControlTypes'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import {
  NO_DEADLINE_OPTION,
  getDeadlineOptionText,
} from '~/client/src/shared/utils/DeadlineOptions'
import { NOOP } from '~/client/src/shared/utils/noop'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import DeliveryRequestSetUpStore from '../../../DeliveryRequestSetUp.store'
import {
  IDeliverySection,
  deliverySelectTypes,
} from '../DeliveryDetailsConfigurations'
import CancelationReasonsListConfig from './CancelationReasonsListConfig'
import CustomActionConfiguration from './CustomActionConfiguration'
import DeliveryAttributeEditor, {
  IDeliveryAttributeEditorProps,
} from './DeliveryAttributeEditor'
import DeliveryInspectorsEditor from './DeliveryInspectorsEditor'
import EditableField from './EditableField'
import IDeliverySettingDto, {
  ConfigurationType,
  DeliverySettingType,
  IDeliveryPulldownSettings,
} from './IDeliverySetting'
import MaterialCategoryEditor from './MaterialCategoryEditor'

import './DeliveryDetailsConfigurationsSection.scss'

interface IProps {
  deliverySettings: IDeliverySection[]
  deliveryDuration: number
  deadlineInterval: number
  eventsStore?: DesktopEventStore
  projectSetUpPageStore: ProjectSetUpPageStore
  deliveryRequestSetUpStore: DeliveryRequestSetUpStore
}

const { CARET_DOWN, CARET_RIGHT } = IconNames
const iconProps = { size: 20, className: 'mr12 section-icon no-grow' }
const popoverPopperModifiers: PopperModifiers = {
  preventOverflow: {
    enabled: false,
  },
  hide: { enabled: false },
  computeStyle: { gpuAcceleration: false },
  arrow: { enabled: false },
}

const mandatory = 'Mandatory'
const optional = 'Optional'
const hide = 'Hide'
const hidden = 'Hidden'

@inject('eventsStore')
@observer
export default class DeliveryDetailsConfigurationsSection extends React.Component<IProps> {
  private sectionsToggleState = observable(new Map<string, boolean>())
  private blocksToggleState = observable(new Map<string, boolean>())

  public render() {
    const { deliverySettings } = this.props

    return deliverySettings.map(
      (
        {
          sectionFieldName,
          sectionTitle,
          sectionDescription,
          deliverySectionSettings,
          sectionSettings,
        },
        index,
      ) => {
        const isSectionHidden = this.isSectionHidden(
          sectionFieldName,
          sectionSettings,
        )
        const isSectionCollapsed = this.sectionsToggleState.get(sectionTitle)

        return (
          <div className="delivery-details-configuration mb10" key={index}>
            {this.renderSectionTitleRow(
              sectionFieldName,
              sectionTitle,
              sectionSettings,
              isSectionHidden,
              index !== 0,
            )}
            {!isSectionCollapsed && (
              <>
                {sectionDescription && (
                  <div className="text light large pb24">
                    {sectionDescription}
                  </div>
                )}
                {deliverySectionSettings.map((setting, settingIndex) => {
                  return (
                    <div className="pb24" key={settingIndex}>
                      {this.renderSettingBlock(setting, isSectionHidden)}
                    </div>
                  )
                })}
              </>
            )}
          </div>
        )
      },
    )
  }

  private renderSectionTitleRow(
    sectionFieldName: string,
    titleText: string,
    sectionSettings: IDeliveryPulldownSettings,
    isSectionHidden: boolean,
    shouldShowBorder?: boolean,
  ) {
    const iconName = this.sectionsToggleState.get(titleText)
      ? CARET_RIGHT
      : CARET_DOWN

    return (
      <div
        className={classList({
          'section-title-row pb24 pt10 row': true,
          'bt-light-input-border': shouldShowBorder,
        })}
      >
        <div className="row">
          <Icon
            icon={iconName}
            color={Colors.BLACK}
            className="pointer no-grow"
            onClick={this.toggleSection.bind(this, titleText)}
          />
          <div
            className="text huge bold nowrap pointer no-grow"
            onClick={this.toggleSection.bind(this, titleText)}
          >
            {titleText}
          </div>
        </div>
        <div className="no-grow mr15">
          {this.renderSettingsPullDown(
            sectionFieldName,
            sectionSettings,
            isSectionHidden,
          )}
        </div>
      </div>
    )
  }

  private renderSettingBlock(
    block: IDeliverySettingDto,
    isSectionHidden: boolean,
  ) {
    const {
      select,
      type,
      description,
      title,
      isInspector,
      deliveryAttribute,
      fieldName,
      deliveryPulldownSettings,
      hasOptionsPreview = false,
      isEditable,
      getIcon,
      onSwitchChange,
      isKeySection,
      isMaterialCategory,
      isCollapsible,
      relatedHideableFieldName,
      isDisabled,
    } = block

    const isCustomActionButton = fieldName === FieldIds.CUSTOM_ACTION_BUTTON

    const isBlockHidden = this.isSectionHidden(
      fieldName,
      deliveryPulldownSettings,
    )
    const isBlockDisabled = this.isSectionHidden(
      relatedHideableFieldName,
      deliveryPulldownSettings,
    )
    const isBlockCollapsed =
      isCollapsible && this.blocksToggleState.get(block.title)
    const shouldRenderContent =
      !isBlockCollapsed && !isBlockHidden && !isSectionHidden

    return (
      <>
        {this.renderBlockTitle(
          title,
          fieldName,
          isKeySection,
          isEditable,
          deliveryPulldownSettings,
          isBlockHidden,
          isSectionHidden,
          getIcon,
          isCollapsible,
          isBlockDisabled,
        )}
        {shouldRenderContent && (
          <>
            {description && this.renderBlockContentText(description)}
            {type === DeliverySettingType.SELECT &&
              this.renderDeliverySelect(select)}
            {type === DeliverySettingType.SWITCH &&
              this.renderDeliverySwitch(fieldName, onSwitchChange, isDisabled)}
            {isInspector && this.renderInspectorsEditor()}
            {isMaterialCategory && (
              <MaterialCategoryEditor
                pullDownRenderer={this.renderSettingsPullDown}
              />
            )}
            {isCustomActionButton && this.renderCustomActionButton()}

            {deliveryAttribute &&
              this.renderDeliveryAttributesEditors(
                deliveryAttribute,
                isKeySection,
              )}

            {hasOptionsPreview && (
              <div className="section-wrapper">
                <ButtonToggle
                  className="mw300"
                  isReadOnly={true}
                  hints={['']}
                  value={this.isInspectionRequired}
                  id={null}
                  type={DeliveryControlTypes.TOGGLE}
                />
              </div>
            )}
            {fieldName === FieldIds.CANCELATION_REASON && (
              <CancelationReasonsListConfig />
            )}
          </>
        )}
      </>
    )
  }

  private isSwitchHidden(fieldName: string): boolean {
    const { hiddenFields } = this.props.eventsStore.appState.delivery
    return hiddenFields[fieldName as FieldIds]
  }

  private get isInspectionRequired(): string {
    const { mandatoryFields } = this.props.eventsStore.appState.delivery
    return mandatoryFields[FieldIds.IS_INSPECTION_REQUIRED] ? '1' : '0'
  }

  private renderBlockTitle = (
    title: string,
    fieldName: string,
    isKeySection: boolean,
    isEditable: boolean,
    deliveryPulldownSettings: IDeliveryPulldownSettings,
    isBlockHidden: boolean,
    isSectionHidden: boolean,
    getIcon?: (props?: any) => JSX.Element,
    isCollapsible?: boolean,
    isBlockDisabled?: boolean,
  ): JSX.Element => {
    const { appState } = this.props.eventsStore

    const blockTitleText = isEditable
      ? appState.getDeliveryFieldName(fieldName)
      : title

    const iconName = this.blocksToggleState.get(title)
      ? CARET_RIGHT
      : CARET_DOWN
    const shouldRenderCollapseIcon =
      !isSectionHidden && isCollapsible && !isBlockHidden

    return (
      <div
        className={classList({
          'mb10 px12 section-settings-block row': true,
          'bb-light-input-border key-section bg-palette-brand-lightest':
            isKeySection,
          'section-wrapper': !isKeySection,
          'inactive-element': isSectionHidden || isBlockDisabled,
        })}
      >
        {shouldRenderCollapseIcon && (
          <Icon
            className="pointer no-grow"
            icon={iconName}
            color={Colors.BLACK}
            onClick={this.toggleBlock.bind(this, title)}
          />
        )}
        {getIcon && getIcon(iconProps)}
        <EditableField
          isEditable={isEditable}
          fieldName={fieldName}
          text={blockTitleText}
          isKeySection={isKeySection}
          update={this.updateFieldName}
        />
        {!isSectionHidden &&
          this.renderSettingsPullDown(
            fieldName,
            deliveryPulldownSettings,
            isBlockHidden,
          )}
      </div>
    )
  }

  private renderSettingsPullDown = (
    fieldName: string,
    deliveryPulldownSettings: IDeliveryPulldownSettings,
    isBlockHidden: boolean,
    shouldBlockMandatorySelection?: boolean,
  ): JSX.Element => {
    if (!deliveryPulldownSettings) {
      return null
    }

    const isMandatorySelected = this.isSettingsBlockMandatory(
      fieldName,
      deliveryPulldownSettings,
    )

    const { customModalText } = deliveryPulldownSettings

    const text = isBlockHidden
      ? customModalText?.hide || hidden
      : isMandatorySelected
      ? customModalText?.mandatory || mandatory
      : customModalText?.optional || optional

    return (
      <Popover
        className="vertical-align-middle no-grow"
        autoFocus={false}
        enforceFocus={false}
        usePortal={false}
        minimal={true}
        position={Position.BOTTOM_RIGHT}
        modifiers={popoverPopperModifiers}
        content={this.renderSectionSettingsDialog(
          fieldName,
          isBlockHidden,
          isMandatorySelected,
          deliveryPulldownSettings,
          shouldBlockMandatorySelection,
        )}
      >
        <div className="row pointer section-block-toggle nowrap">
          <span
            className={classList({
              'text large grey-light': true,
              'blue-highlight': isBlockHidden || isMandatorySelected,
            })}
          >
            {text}
          </span>
          <Icon className="ml5 text grey-light" icon={CARET_DOWN} />
        </div>
      </Popover>
    )
  }

  private renderSectionSettingsDialog = (
    fieldName: string,
    isBlockHidden: boolean,
    isMandatory: boolean,
    deliveryPulldownSettings: IDeliveryPulldownSettings,
    shouldBlockMandatorySelection: boolean,
  ) => {
    const { onMandatoryChange, onHide, customModalText } =
      deliveryPulldownSettings

    const isMandatorySelected = !isBlockHidden && isMandatory
    const isOptionalSelected = !isBlockHidden && !isMandatory

    const shouldShowHideButton = !!onHide

    const onMandatoryClick = onMandatoryChange?.bind(this, fieldName, true)
    const onOptionalClick = onMandatoryChange?.bind(this, fieldName, false)
    const onHideClick = onHide?.bind(this, fieldName, true)

    return (
      <div className="col px12 py6 nowrap">
        {!shouldBlockMandatorySelection &&
          this.renderDialogItem(
            isMandatorySelected,
            customModalText?.mandatory || mandatory,
            onMandatoryClick,
          )}
        {this.renderDialogItem(
          isOptionalSelected,
          customModalText?.optional || optional,
          onOptionalClick,
          shouldShowHideButton && 'mb5',
        )}
        {shouldShowHideButton && (
          <>
            <div className="bt-light-input-border" />
            {this.renderDialogItem(
              isBlockHidden,
              customModalText?.hide || hide,
              onHideClick,
              'mt5',
            )}
          </>
        )}
      </div>
    )
  }

  private renderDialogItem = (
    isSelected: boolean,
    text: string,
    onItemClick: () => void,
    className?: string,
  ): JSX.Element => {
    const onClick = isSelected ? NOOP : onItemClick

    return (
      <div
        className={classList({
          'dialog-toggle-item row x-between': true,
          [Classes.POPOVER_DISMISS]: true,
          'unclickable-element': isSelected,
          pointer: !isSelected,
          [className]: !!className,
        })}
        onClick={onClick}
      >
        <span
          className={classList({
            'text large grey-light mr15': true,
            'blue-highlight': isSelected,
          })}
        >
          {text}
        </span>
        {isSelected && (
          <Icons.CheckPaletteBlue className="section-check-icon no-flex" />
        )}
      </div>
    )
  }

  private updateFieldName = (fieldName: string, newText: string) => {
    const { eventsStore } = this.props
    const {
      delivery,
      getActivityFilterByDeliveryFieldId,
      getFormattedFieldName,
    } = eventsStore.appState

    const { customFieldNames } = delivery
    customFieldNames[getFormattedFieldName(fieldName)] = newText
    eventsStore.dispatch(e.SAVE_DELIVERY_FIELDS_CONFIGURATIONS)

    const activityFilter = getActivityFilterByDeliveryFieldId(fieldName)
    if (activityFilter) {
      activityFilter.caption =
        activityFilter.getKnownCaption() === newText ? '' : newText
      eventsStore.dispatch(e.UPDATE_ACTIVITY_FILTERS_SETTINGS)
    }
  }

  private renderBlockContentText(contentText: string) {
    return (
      <div className="text large light pb5 section-wrapper">
        {contentText.split('\n').map(text => {
          return (
            <p key={text} style={{ maxWidth: '680px' }}>
              {text}
            </p>
          )
        })}
      </div>
    )
  }

  private renderInspectorsEditor() {
    return (
      <div className="section-wrapper">
        <DeliveryInspectorsEditor
          store={this.props.deliveryRequestSetUpStore}
        />
      </div>
    )
  }

  private renderDeliveryAttributesEditors(
    deliveryAttribute: IDeliveryAttributeEditorProps,
    isKeySection?: boolean,
  ) {
    return (
      <div className="section-wrapper">
        <DeliveryAttributeEditor
          {...deliveryAttribute}
          projectSetUpPageStore={this.props.projectSetUpPageStore}
          key={deliveryAttribute.title}
          isKeySection={isKeySection}
        />
      </div>
    )
  }

  private renderCustomActionButton() {
    const { customActionUrl, customActionText, customActionButtonText } =
      this.props.eventsStore.appState.delivery.configurations
    return (
      <CustomActionConfiguration
        customActionUrl={customActionUrl || EMPTY_STRING}
        customActionText={customActionText || EMPTY_STRING}
        customActionButtonText={customActionButtonText || EMPTY_STRING}
      />
    )
  }

  private renderDeliverySelect(select: IDeliverySettingsSelect) {
    const { name, options, onSelectChange, hint } = select
    const value = this.deliverySelectValue(name)

    return (
      <div className="row pb10 section-wrapper">
        <div className="delivery-attribute-editor-input">
          <StruxhubSelect
            label={hint}
            isRequired={true}
            isRequiredTextHidden={true}
            value={String(value)}
            onChange={onSelectChange}
          >
            {options &&
              options.map((option, index) => {
                return (
                  <option key={index} value={option}>
                    {this.deliveryOptionsText(option, name)}
                  </option>
                )
              })}
          </StruxhubSelect>
        </div>
      </div>
    )
  }

  private renderDeliverySwitch(
    fieldName: string,
    onSwitchChange: (fieldName: string, value: boolean) => void,
    isDisabled?: boolean,
  ) {
    return (
      <div className="row pb10 section-wrapper">
        <div className="delivery-attribute-editor-input">
          <Switch
            className="no-grow primary-blue-switch bp3-align-right no-outline-container"
            checked={!this.isSwitchHidden(fieldName)}
            onChange={() =>
              onSwitchChange(fieldName, !this.isSwitchHidden(fieldName))
            }
            disabled={isDisabled}
          />
        </div>
      </div>
    )
  }

  private deliveryOptionsText = (option: number, name: string) => {
    switch (name) {
      case deliverySelectTypes.duration:
        return option + ' minutes'
      case deliverySelectTypes.deadline:
        return getDeadlineOptionText(option)
    }
  }

  private deliverySelectValue = name => {
    const {
      deliveryDuration: defaultDuration,
      deadlineInterval: defaultDeadlineInterval,
    } = this.props.eventsStore.appState.delivery.configurations
    const { deliveryDuration, deadlineInterval } = this.props

    switch (name) {
      case deliverySelectTypes.duration:
        return deliveryDuration || defaultDuration
      case deliverySelectTypes.deadline:
        return deadlineInterval || defaultDeadlineInterval
    }
  }

  private isSettingsBlockMandatory = (
    fieldName: string,
    deliveryPulldownSettings: IDeliveryPulldownSettings,
  ): boolean => {
    if (!deliveryPulldownSettings) {
      return false
    }

    const { configurationType } = deliveryPulldownSettings
    const {
      delivery: { mandatoryFields, configurations, hiddenFields },
      projectMaterialOptions,
    } = this.props.eventsStore.appState

    switch (configurationType) {
      case ConfigurationType.BOOKING_DEADLINE:
        return configurations.shouldBlockLateRequesting

      case ConfigurationType.NON_WORK_TIMES_BLOCK:
        return configurations.nonWorkTimesBlockType === BlockTypeEnum.Block

      case ConfigurationType.EXTENSIBILITY:
        return !configurations.unextendableFields.includes(fieldName)

      case ConfigurationType.MANDATORITY:
        return fieldName
          ? mandatoryFields[fieldName]
          : mandatoryFields[FieldIds.IS_INSPECTION_REQUIRED]

      case ConfigurationType.CONFIGURATION:
        return configurations[fieldName]

      case ConfigurationType.PROJECT_MATERIAL_OPTION:
        return !projectMaterialOptions[fieldName]

      case ConfigurationType.MATERIAL_SECTION:
        return !hiddenFields[fieldName]

      case ConfigurationType.INSPECTION_SECTION:
        return mandatoryFields[FieldIds.IS_INSPECTION_REQUIRED]

      default:
        return false
    }
  }

  private isSectionHidden = (
    fieldName: string,
    deliveryPulldownSettings: IDeliveryPulldownSettings,
  ): boolean => {
    if (!fieldName || !deliveryPulldownSettings) {
      return false
    }

    const { configurations, hiddenFields } =
      this.props.eventsStore.appState.delivery

    const { configurationType, onHide } = deliveryPulldownSettings

    switch (configurationType) {
      case ConfigurationType.NON_WORK_TIMES_BLOCK:
        return configurations.nonWorkTimesBlockType === BlockTypeEnum.Hide

      case ConfigurationType.BOOKING_DEADLINE:
        return configurations.deadlineInterval === NO_DEADLINE_OPTION

      case ConfigurationType.MATERIAL_SECTION:
      case ConfigurationType.EXTENSIBILITY:
        return hiddenFields[fieldName]

      default:
        return !!onHide && hiddenFields[fieldName]
    }
  }

  private toggleSection(sectionName: string) {
    this.sectionsToggleState.set(
      sectionName,
      !this.sectionsToggleState.get(sectionName),
    )
  }

  private toggleBlock(blockName: string) {
    this.blocksToggleState.set(
      blockName,
      !this.blocksToggleState.get(blockName),
    )
  }
}
