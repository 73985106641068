import KnownTranslatorKeys from '../../shared/localization/knownTranslatorKeys'
import { enumToList } from '../../shared/utils/converters'

enum FormReportGroupingOption {
  NONE = 'None',
  COMPANY = 'Company',
  DAY = 'Day',
  WEEK = 'Week',
  MONTH = 'Month',
}

export const formReportGroupingOptionList = enumToList(FormReportGroupingOption)

export const getFormReportGroupingOptionTranslatorKey = (
  id: string,
): KnownTranslatorKeys => {
  switch (id) {
    case FormReportGroupingOption.NONE:
      return KnownTranslatorKeys.none
    case FormReportGroupingOption.COMPANY:
      return KnownTranslatorKeys.company
    case FormReportGroupingOption.DAY:
      return KnownTranslatorKeys.day
    case FormReportGroupingOption.WEEK:
      return KnownTranslatorKeys.week
    case FormReportGroupingOption.MONTH:
      return KnownTranslatorKeys.month
    default:
      throw new Error(id + ' is unhandled')
  }
}

export default FormReportGroupingOption
