import * as React from 'react'

import { inject, observer } from 'mobx-react'

import DesktopCalendarViewStore from '~/client/src/desktop/components/DesktopCalendar/DesktopCalendar.store'
import IEditableCalendarEvent from '~/client/src/shared/components/CalendarDayView/components/IEditableCalendarEvent'
import { withErrorBoundary } from '~/client/src/shared/components/ErrorBoundary'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import CalendarEvent from '~/client/src/shared/models/CalendarEvent'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import DesktopCalendarColumn from '../DesktopCalendarColumn'
import DesktopCalendarColumnUnsafe from './DesktopCalendarColumn'

// localization: no display text to translate

const DesktopCalendarColumnComponent = withErrorBoundary(
  DesktopCalendarColumnUnsafe,
)

interface IProps {
  calendarColumns: DesktopCalendarColumn[]
  store: DesktopCalendarViewStore
  isEventCreatingDisabled?: boolean
  isDayCalendarModeActive?: boolean
  projectDateStore?: ProjectDateStore
  onEventClicked?: (event: CalendarEvent) => void
  onEventMoved?: (e: CalendarEvent, x: number, y: number) => void
  onEventTimeChange?: (
    e: CalendarEvent,
    startDate: number,
    endDate: number,
  ) => void
  onLongSelect: (event: CalendarEvent) => void
  editableEvent: IEditableCalendarEvent
  setRef(ref: HTMLDivElement)
}

@inject('projectDateStore')
@observer
export default class CalendarBody extends React.Component<IProps> {
  public render() {
    const {
      setRef,
      calendarColumns,
      store,
      onEventClicked,
      isEventCreatingDisabled,
      isDayCalendarModeActive,
      onEventMoved,
      onEventTimeChange,
      onLongSelect,
      editableEvent,
      projectDateStore: { fromIsoString, isWorkingDay, isToday },
    } = this.props

    return (
      <div className="days-content row y-start" ref={setRef}>
        {calendarColumns.map((column, idx) => {
          const initialDate = fromIsoString(column.newEventData[FieldIds.DATE])

          return (
            <DesktopCalendarColumnComponent
              calendarColumn={column}
              store={store}
              key={idx}
              isEventCreatingDisabled={isEventCreatingDisabled}
              onEventClicked={onEventClicked}
              initialDate={initialDate}
              isWorkingDay={isWorkingDay(initialDate)}
              isDayCalendarModeActive={isDayCalendarModeActive}
              isToday={isToday(initialDate)}
              shouldRenderDot={!isDayCalendarModeActive || !idx}
              onEventMoved={onEventMoved}
              onLongSelect={onLongSelect}
              selectedEvent={editableEvent}
              onEventTimeChange={onEventTimeChange}
            />
          )
        })}
      </div>
    )
  }
}
