import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList, toggleClass } from 'react-classlist-helper'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized'

import DeliveryWorkflowCard from '~/client/src/shared/components/ExpandableWorkflowCard/components/DeliveryWorkflowCard'
import { ILWFCCategory } from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import Delivery from '~/client/src/shared/models/Delivery'
import { NOOP } from '~/client/src/shared/utils/noop'
import { areArraysEqual } from '~/client/src/shared/utils/util'

import './CompactDeliveriesList.scss'

const { CARET_DOWN, CARET_RIGHT } = IconNames

export interface ICompactRow {
  category?: ILWFCCategory
  delivery?: Delivery
}

interface IProps {
  rows: ICompactRow[]
  highlightedIds?: string[]
  groupingKey?: string
  scrollToIndex?: number
  activeDeliveryId?: string
  onFilterResetClick?: () => void
  collapsedCategories?: Map<string, boolean>
  onCardClick?: (deliveryId: string) => void
  onCategoryCollapsingToggle?: (categoryId: string) => void
  selectedDeliveryIds?: string[]

  className?: string
}

const NO_SCROLL = -1
const OVERSCAN_ROW_COUNT = 6

const deliveriesMapViewDescriptions = {
  noDeliveries: 'No deliveries matching your filter criteria...',
}

@observer
export default class CompactDeliveriesList extends React.Component<IProps> {
  public static defaultProps = {
    highlightedIds: [],
    scrollToIndex: NO_SCROLL,
    onFilterResetClick: NOOP,
    onCategoryCollapsingToggle: NOOP,
    onCardClick: NOOP,
    collapsedCategories: new Map<string, boolean>(),
    groupingKey: '',
  }

  @observable private scrollToIndex: number = NO_SCROLL
  private listRef: any = null
  private readonly cellMeasurerCache: CellMeasurerCache = null

  public constructor(props: IProps) {
    super(props)

    this.scrollToIndex = props.scrollToIndex
    this.cellMeasurerCache = new CellMeasurerCache({
      defaultHeight: 100,
      fixedWidth: true,
    })
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    if (
      !areArraysEqual(this.props.rows, prevProps.rows) ||
      this.props.groupingKey !== prevProps.groupingKey
    ) {
      this.recomputeGridSize()
    }

    if (this.props.scrollToIndex !== prevProps.scrollToIndex) {
      this.scrollToIndex = this.props.scrollToIndex
    }
  }

  public render() {
    const { rows, className = '' } = this.props
    const { scrollToIndex } = this // weird, but only this way it works

    return (
      <AutoSizer className={toggleClass(className, !!className)}>
        {({ width, height }) => (
          <List
            deferredMeasurementCache={this.cellMeasurerCache}
            data={rows}
            width={width}
            height={height}
            ref={this.setListRef}
            rowCount={rows.length}
            onScroll={this.onScroll}
            scrollToAlignment="start"
            overscanRowCount={OVERSCAN_ROW_COUNT}
            rowHeight={this.cellMeasurerCache.rowHeight}
            rowRenderer={this.rowRenderer}
            scrollToIndex={scrollToIndex}
            noRowsRenderer={this.noRowsRenderer}
            className="compact-deliveries-list"
          />
        )}
      </AutoSizer>
    )
  }

  private noRowsRenderer = () => {
    return (
      <div className="row text large pa12">
        <div>{deliveriesMapViewDescriptions.noDeliveries}</div>
        <Icon
          className="text light no-grow pointer"
          onClick={this.props.onFilterResetClick}
          icon={IconNames.FILTER_REMOVE}
        />
      </div>
    )
  }

  private rowRenderer = ({ index, key, style, parent }: any) => {
    const { category, delivery } = this.props.rows[index]

    return (
      <CellMeasurer
        cache={this.cellMeasurerCache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ measure, registerChild }) => (
          <div style={style} ref={registerChild} className="col">
            {category
              ? this.renderCategoryRow(category)
              : this.renderDeliveryRow(delivery, measure)}
          </div>
        )}
      </CellMeasurer>
    )
  }

  private renderCategoryRow = ({
    categoryId,
    shortCategoryLabel,
    instancesInfo,
  }: ILWFCCategory) => {
    const { collapsedCategories, onCategoryCollapsingToggle } = this.props

    return (
      <div
        className="row category no-select px12"
        onClick={onCategoryCollapsingToggle.bind(null, categoryId)}
      >
        <Icon
          className="pointer mr10"
          icon={collapsedCategories.get(categoryId) ? CARET_RIGHT : CARET_DOWN}
        />
        <label className="text bold white large ellipsis">
          {shortCategoryLabel}
        </label>
        <label className="text bold white no-grow">{instancesInfo}</label>
      </div>
    )
  }

  private renderDeliveryRow = (
    delivery: Delivery,
    measureSizeFn: () => void,
  ) => {
    const { highlightedIds, selectedDeliveryIds } = this.props

    const isDeliveryActive = this.isDeliveryActive(delivery.id)
    const isSelected = selectedDeliveryIds?.includes(delivery.id)

    return (
      <div
        className={classList({
          'bb-palette-brand-lighter card-wrapper full-height': true,
          highlight: isDeliveryActive || highlightedIds.includes(delivery.id),
          'active-delivery': isDeliveryActive,
          'selected-card': isSelected,
        })}
      >
        <DeliveryWorkflowCard
          className="full-height"
          delivery={delivery}
          onClick={this.onDeliveryCardClick}
          measureSizeFn={measureSizeFn}
        />
      </div>
    )
  }

  private isDeliveryActive(deliveryId: string): boolean {
    return this.props.activeDeliveryId === deliveryId
  }

  private recomputeGridSize = () => {
    this.listRef?.recomputeGridSize()
    this.cellMeasurerCache?.clearAll()
  }

  private onScroll = () => {
    Promise.resolve().then(() => (this.scrollToIndex = NO_SCROLL))
  }

  private setListRef = ref => (this.listRef = ref)

  private onDeliveryCardClick = (delivery: Delivery) => {
    this.props.onCardClick(delivery.id)
  }
}
