import { action, observable } from 'mobx'

import {
  CalendricalType,
  IConditionalField,
  IInspectionOptions,
  IPermitTypeField,
  IWorkflowStep,
  PermitFieldType,
  SitePermitStatus,
  WorkflowStepType,
} from '~/client/graph/types.generated'
import { allNotificationPermitFieldTypes } from '~/client/src/shared/constants/PermitFieldTypeConstants'
import formStatusesByStepsMap from '~/client/src/shared/constants/formStatusesByStepsMap'
import { getWorkflowStepLevel } from '~/client/src/shared/constants/formStatusesTags'
import {
  MAX_CONDITIONAL_DEPTH_LEVEL,
  PERMIT_TABLE_FIELDS_KEY,
} from '~/client/src/shared/constants/permitTypeFieldsConstants'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import {
  getCalendricalTypeDisplayName,
  getWorkflowStepDisplayName,
} from '~/client/src/shared/localization/enumDisplayTexts'
import ProjectDateStore, {
  HOURS_IN_DAY,
} from '~/client/src/shared/stores/ui/ProjectDate.store'
import { calendricalTypes } from '~/client/src/shared/utils/CalendricalTypeUtils'

const activeSteps = [WorkflowStepType.Approval, WorkflowStepType.Start]
const requestAndSubmissionSteps = [
  WorkflowStepType.Request,
  WorkflowStepType.Submission,
]
const allowedPrecededApprovalSteps = [
  WorkflowStepType.Request,
  WorkflowStepType.Submission,
  WorkflowStepType.Review,
  WorkflowStepType.OnSite,
]
const uniqueSteps = [
  WorkflowStepType.Approval,
  WorkflowStepType.BicInspection,
  WorkflowStepType.Finish,
  WorkflowStepType.Start,
  WorkflowStepType.RecurringInspection,
]

const isOnSiteStep = (stepType: WorkflowStepType): boolean =>
  stepType === WorkflowStepType.OnSite
const isReviewStep = (stepType: WorkflowStepType): boolean =>
  stepType === WorkflowStepType.Review
const isStartStep = (stepType: WorkflowStepType): boolean =>
  stepType === WorkflowStepType.Start
const isApprovalStep = (stepType: WorkflowStepType): boolean =>
  stepType === WorkflowStepType.Approval
const isRecurringStep = (stepType: WorkflowStepType): boolean =>
  stepType === WorkflowStepType.RecurringInspection
const isFinishStep = (stepType: WorkflowStepType): boolean =>
  stepType === WorkflowStepType.Finish

const MAX_MONTHS_VALUE = 12
const MAX_WEEKS_VALUE = 52
const MAX_DAYS_VALUE = 365
const DEADLINE_HOURS = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]

interface ISelectorOptions {
  name: string
  value: string | number
}

export interface INotificationOption {
  stepName?: string
  stepPosition?: number
  field?: IPermitTypeField
  fieldPosition?: number
  subFieldPosition?: number
  isDisabled?: boolean
  isConditional?: boolean
  shouldIndent?: boolean
}

export default class WorkflowStepConfiguratorStore {
  @observable public workflowSteps: IWorkflowStep[]
  @observable public inspectionDeadlineTime: number =
    DEADLINE_HOURS[DEADLINE_HOURS.length - 1]
  @observable public inspectionFrequency: number = 1
  @observable public inspectionFrequencyType: CalendricalType =
    CalendricalType.Day
  @observable public selectedDaysToRepeat: number[] = []
  @observable public isAutoActivationEnabled: boolean = false

  public constructor(
    workflowSteps: IWorkflowStep[],
    inspectionOptions: IInspectionOptions,
    isAutoActivationEnabled: boolean,
    private readonly projectDateStore: ProjectDateStore,
    private readonly saveInspectionOptions: (
      options: IInspectionOptions,
    ) => void,
  ) {
    this.init(workflowSteps, inspectionOptions, isAutoActivationEnabled)
  }

  @action.bound
  public init(
    workflowSteps: IWorkflowStep[],
    inspectionOptions: IInspectionOptions,
    isAutoActivationEnabled: boolean,
  ) {
    this.workflowSteps = workflowSteps
    this.isAutoActivationEnabled = isAutoActivationEnabled

    if (inspectionOptions) {
      this.inspectionDeadlineTime = inspectionOptions.deadlineTime
      this.inspectionFrequency = inspectionOptions.inspectionFrequency
      this.inspectionFrequencyType = inspectionOptions.inspectionFrequencyType
      this.selectedDaysToRepeat = inspectionOptions.selectedDaysToRepeat
    }
  }

  @action.bound
  public changeDeadlineTime(deadlineTime: number) {
    this.inspectionDeadlineTime = deadlineTime

    this.updateInspectionOptions()
  }

  @action.bound
  public changeFrequency(newFrequency: number) {
    this.inspectionFrequency =
      newFrequency > this.inspectionMaxNumber
        ? this.inspectionMaxNumber
        : newFrequency

    this.updateInspectionOptions()
  }

  @action.bound
  public changeFrequencyType(newFrequencyType: CalendricalType) {
    this.inspectionFrequencyType = newFrequencyType

    if (this.inspectionFrequency > this.inspectionMaxNumber) {
      return this.changeFrequency(this.inspectionMaxNumber)
    }

    this.updateInspectionOptions()
  }

  @action.bound
  public changeDayToRepeat(dayNumber: number) {
    if (!this.selectedDaysToRepeat) {
      this.selectedDaysToRepeat = []
    }

    const index = this.selectedDaysToRepeat.findIndex(day => day === dayNumber)
    if (index === -1) {
      this.selectedDaysToRepeat.push(dayNumber)
    } else {
      this.selectedDaysToRepeat.splice(index, 1)
    }

    this.updateInspectionOptions()
  }

  public isLastStep = (stepIdx: number): boolean => {
    return this.workflowSteps.length - 1 === stepIdx
  }

  public isAutomaticStartShown = (
    stepIdx: number,
    stepType: WorkflowStepType,
  ): boolean => {
    return (
      !this.isLastStep(stepIdx) &&
      !this.hasStartStep &&
      isApprovalStep(stepType)
    )
  }

  public isAutoStartToggleShown = (stepType: WorkflowStepType): boolean => {
    return this.hasStartStep
      ? isStartStep(stepType)
      : activeSteps.includes(stepType)
  }

  public isRequestOrSubmissionStep = (stepType: WorkflowStepType): boolean => {
    return requestAndSubmissionSteps.includes(stepType)
  }

  public isAddStepBtnShown = (stepType: WorkflowStepType): boolean => {
    return (
      !isFinishStep(stepType) &&
      (!this.hasRecurringStep || !this.canHaveRecurringStep(stepType))
    )
  }

  public getNotificationOptions = (stepId: string): INotificationOption[] => {
    const finalStepIdx = this.workflowSteps.findIndex(
      step => step.id === stepId,
    )

    return this.workflowSteps
      .slice(0, finalStepIdx + 1)
      .reduce((list, step, stepIdx) => {
        if (isRecurringStep(step.type)) {
          return list
        }

        const stepPosition = stepIdx + 1

        const options = step.fields.reduce((list, field, fieldIdx) => {
          if (allNotificationPermitFieldTypes.includes(field.type)) {
            list.push({
              field,
              stepPosition,
              fieldPosition: fieldIdx + 1,
            })
          }

          if (field.type === PermitFieldType.Table) {
            list.push(
              ...this.getTableNotifyOptions(
                step,
                stepPosition,
                field,
                fieldIdx,
              ),
            )
          }

          list.push(
            ...this.getConditionalNotifyOptions(
              step,
              step.conditionalFields.filter(
                cf =>
                  cf.fieldId === field.id && cf.key !== PERMIT_TABLE_FIELDS_KEY,
              ),
            ),
          )

          return list
        }, [] as INotificationOption[])

        if (options.length) {
          const stepLevel = getWorkflowStepLevel(step.id, this.workflowSteps)
          const stepName = getWorkflowStepDisplayName(step.type, stepLevel)

          list.push({
            stepPosition,
            stepName,
          })
          list.push(...options)
        }

        return list
      }, [] as INotificationOption[])
  }

  private getTableNotifyOptions(
    step: IWorkflowStep,
    stepIdx: number,
    field: IPermitTypeField,
    fieldIdx: number,
  ): INotificationOption[] {
    const tableConditional = step.conditionalFields.find(
      cf => cf.fieldId === field.id && cf.key === PERMIT_TABLE_FIELDS_KEY,
    )

    const options = tableConditional.values.reduce(
      (list, field, subFieldPosition) => {
        if (allNotificationPermitFieldTypes.includes(field.type)) {
          list.push({
            field,
            stepPosition: stepIdx,
            fieldPosition: fieldIdx + 1,
            subFieldPosition: subFieldPosition + 1,
            shouldIndent: true,
          })
        }
        return list
      },
      [] as INotificationOption[],
    )

    return options.length
      ? [
          {
            field,
            stepPosition: stepIdx,
            fieldPosition: fieldIdx + 1,
            isDisabled: true,
          },
          ...options,
        ]
      : []
  }

  private getConditionalNotifyOptions(
    step: IWorkflowStep,
    filteredConditionals: IConditionalField[],
    depthLevel: number = 0,
  ): INotificationOption[] {
    if (depthLevel === MAX_CONDITIONAL_DEPTH_LEVEL) {
      return []
    }

    return filteredConditionals.reduce((list, { values }) => {
      values.forEach(f => {
        if (allNotificationPermitFieldTypes.includes(f.type)) {
          list.push({
            field: f,
            isConditional: true,
          })
        }

        const childrenConditionals = step.conditionalFields.filter(
          cf => cf.fieldId === f.id,
        )
        const deeperDescendants = this.getConditionalNotifyOptions(
          step,
          childrenConditionals,
          depthLevel + 1,
        )

        list.push(...deeperDescendants)
      })
      return list
    }, [] as INotificationOption[])
  }

  public get hasStartStep(): boolean {
    return this.workflowSteps.some(s => isStartStep(s.type))
  }

  public get hasApprovalStep(): boolean {
    return this.workflowSteps.some(s => isApprovalStep(s.type))
  }

  public get hasRecurringStep(): boolean {
    return this.workflowSteps.some(s => isRecurringStep(s.type))
  }

  public get startStepIdx(): number {
    return this.workflowSteps.findIndex(s => isStartStep(s.type))
  }

  public get approvalStepIdx(): number {
    return this.workflowSteps.findIndex(s => isApprovalStep(s.type))
  }

  public get inspectionMaxNumber(): number {
    switch (this.inspectionFrequencyType) {
      case CalendricalType.Day:
        return MAX_DAYS_VALUE
      case CalendricalType.Week:
        return MAX_WEEKS_VALUE
      case CalendricalType.Month:
        return MAX_MONTHS_VALUE
    }
  }

  public get deadlineTimeOptions(): ISelectorOptions[] {
    const { getFullHourLabel } = this.projectDateStore
    return DEADLINE_HOURS.map(option => ({
      name:
        option === HOURS_IN_DAY
          ? Localization.translator.endOfTheDay
          : getFullHourLabel(option),
      value: option,
    }))
  }

  public get inspectionFrequencyTypeOptions(): ISelectorOptions[] {
    return calendricalTypes.map(option => ({
      name: getCalendricalTypeDisplayName(option, this.inspectionFrequency),
      value: option,
    }))
  }

  public get shouldShowRepeatOnDays(): boolean {
    return this.inspectionFrequencyType === CalendricalType.Week
  }

  public getStepResultStatus = (
    isLastStep: boolean,
    stepType: WorkflowStepType,
  ): SitePermitStatus => {
    if (isLastStep) return SitePermitStatus.Done

    const allStatuses = formStatusesByStepsMap[stepType].filter(
      s => s !== SitePermitStatus.Changed,
    )
    return allStatuses[allStatuses.length - 1]
  }

  public getModalStepOptions(
    stepType: WorkflowStepType,
    stepIdx: number,
    categorySteps: WorkflowStepType[],
  ): WorkflowStepType[] {
    const isApprovalBehind = this.approvalStepIdx <= stepIdx
    const isStartBehind = this.startStepIdx <= stepIdx
    const isLastStep = this.isLastStep(stepIdx)
    const canHaveRecurring = this.canHaveRecurringStep(stepType, true)
    const isApprovalHidden =
      (this.hasStartStep && isStartBehind) ||
      this.isApprovalHiddenInOptions(stepIdx)
    const isApprovalNotBehind = this.hasApprovalStep && !isApprovalBehind

    return categorySteps.reduce((list, step) => {
      if (
        (uniqueSteps.includes(step) &&
          this.workflowSteps.some(ws => step === ws.type)) ||
        (!isReviewStep(step) && !isOnSiteStep(step) && isApprovalNotBehind) ||
        (isApprovalStep(step) && isApprovalHidden) ||
        (isFinishStep(step) && !isLastStep) ||
        (isRecurringStep(step) && !canHaveRecurring)
      ) {
        return list
      }

      list.push(step)

      return list
    }, [] as WorkflowStepType[])
  }

  private canHaveRecurringStep = (
    stepType: WorkflowStepType,
    shouldCheckToggle?: boolean,
  ): boolean => {
    return this.hasStartStep
      ? isStartStep(stepType)
      : isApprovalStep(stepType) &&
          (!shouldCheckToggle || this.isAutoActivationEnabled)
  }

  private isApprovalHiddenInOptions = (stepIdx: number): boolean => {
    return this.workflowSteps
      .slice(0, stepIdx + 1)
      .some(s => !allowedPrecededApprovalSteps.includes(s.type))
  }

  private updateInspectionOptions = () => {
    this.saveInspectionOptions({
      deadlineTime: this.inspectionDeadlineTime,
      inspectionFrequency: this.inspectionFrequency,
      inspectionFrequencyType: this.inspectionFrequencyType,
      selectedDaysToRepeat: this.selectedDaysToRepeat,
    })
  }
}
