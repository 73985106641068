import * as React from 'react'

import {
  Classes,
  Icon,
  Popover,
  PopperModifiers,
  Position,
  Switch,
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import {
  BlockTypeEnum,
  IBookingDeadlineOptions,
} from '~/client/graph/types.generated'
import * as Icons from '~/client/src/shared/components/Icons'
import PermitTypeIcon from '~/client/src/shared/components/PermitTypeIcon/PermitTypeIcon'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import StruxhubSelect from '~/client/src/shared/components/StruxhubInputs/StruxhubSelect'
import StruxhubTextValueSelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubTextValueSelector'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { getBlockTypeDisplayName } from '~/client/src/shared/localization/enumDisplayTexts'
import {
  NO_DEADLINE_OPTION,
  deadlineOptions,
  getDeadlineOptionText,
} from '~/client/src/shared/utils/DeadlineOptions'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import ShowInAppModal from '../../ShowInAppModal/ShowInAppModal'

// localization: translated

interface IProps {
  formTypeName: string
  formTypeType: string
  isShownInApp: boolean
  workflowCategoryToDisplay: string
  shouldBlockOnNonWorkTimes: boolean
  lastUpdatedAtLabel: string
  bookingDeadlineOptions: IBookingDeadlineOptions

  onChange(
    formTypeName: string,
    isShownInApp: boolean,
    shouldBlockOnNonWorkTimes: boolean,
  ): void
  onBookingOptionsChange(bookingOptions: IBookingDeadlineOptions): void
}

const popoverPopperModifiers: PopperModifiers = {
  preventOverflow: { enabled: false },
  hide: { enabled: false },
  computeStyle: { gpuAcceleration: false },
  arrow: { enabled: false },
}

const highlightedBlockTypes = [BlockTypeEnum.Block, BlockTypeEnum.Hide]

@observer
export default class GeneralFormConfigurator extends React.Component<IProps> {
  public render() {
    const {
      formTypeName,
      formTypeType,
      isShownInApp,
      shouldBlockOnNonWorkTimes,
      lastUpdatedAtLabel,
      workflowCategoryToDisplay,
      bookingDeadlineOptions,
    } = this.props

    const hasBookingOptions = !!bookingDeadlineOptions?.blockType

    return (
      <div className="col mw400 pl10">
        <StruxhubInput
          className="overflow-hidden no-flex pb10"
          label={Localization.translator.workflowName}
          isRequired
          isValid
          isHelperTextCondensed
          value={formTypeName || EMPTY_STRING}
          onChange={this.changeFormName}
        />
        <StruxhubTextValueSelector
          className="overflow-hidden no-flex pb10"
          label={Localization.translator.workflowCategory}
          isRequired
          isReadOnly
          isHelperTextCondensed
          value={workflowCategoryToDisplay}
        />
        <div className="row pt8 pb10">
          <span className="text large line-extra-large">
            {Localization.translator.icon}
          </span>
          <StruxhubTextValueSelector
            className="overflow-hidden no-flex pb10"
            isMinimalisticMode
            isHelperTextCondensed
            isReadOnly
            customIconElement={
              <PermitTypeIcon
                className="row no-grow mr5"
                permitType={formTypeType}
              />
            }
          />
        </div>
        <ShowInAppModal
          formTypeId={null}
          modalClassName="mw400"
          isShown={isShownInApp}
          isDisplayedAsSelector
          onEnableChange={this.changeShowInApp}
        />
        <div className="row non-work-times-configurator py10">
          <div className="text large line-extra-large full-flex-basis">
            {Localization.translator.blockRequestsForNonWorkTimes}
          </div>
          <Switch
            className="primary-blue-switch bp3-align-right no-outline-container"
            checked={shouldBlockOnNonWorkTimes}
            onChange={this.changeBlockNonWorkTime}
          />
        </div>
        <div className="row py10">
          <div className="text large line-extra-large full-flex-basis">
            {Localization.translator.workflowBookingDeadline}
          </div>
          <Popover
            className="vertical-align-middle no-grow"
            autoFocus={false}
            enforceFocus={false}
            usePortal={false}
            minimal
            position={Position.BOTTOM_RIGHT}
            modifiers={popoverPopperModifiers}
            content={this.bookingDeadlineOptionsModal}
          >
            <div className="row pointer nowrap">
              <span
                className={classList({
                  'text large grey-light': true,
                  'blue-highlight':
                    !hasBookingOptions ||
                    highlightedBlockTypes.includes(
                      bookingDeadlineOptions.blockType,
                    ),
                })}
              >
                {getBlockTypeDisplayName(
                  bookingDeadlineOptions?.blockType || BlockTypeEnum.Hide,
                )}
              </span>
              <Icon
                className="ml5 text grey-light"
                icon={IconNames.CARET_DOWN}
              />
            </div>
          </Popover>
        </div>
        {hasBookingOptions &&
          bookingDeadlineOptions.blockType !== BlockTypeEnum.Hide && (
            <>
              <span className="text large light pb10">
                {Localization.translator.workflowDeadlineDescr}
              </span>
              <StruxhubSelect
                className="overflow-hidden no-flex pb10"
                label={Localization.translator.workflowsMustBeRequested}
                isRequired
                isRequiredTextHidden
                isHelperTextCondensed
                value={String(bookingDeadlineOptions.deadlineInterval || -1)}
                onChange={this.changeDeadlineOptions}
              >
                {deadlineOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {getDeadlineOptionText(option)}
                  </option>
                ))}
              </StruxhubSelect>
            </>
          )}
        <div className="row py10 text large line-extra-large">
          <span>{Localization.translator.lastUpdated}</span>
          <span className="no-grow nowrap">{lastUpdatedAtLabel}</span>
        </div>
      </div>
    )
  }

  private get bookingDeadlineOptionsModal(): JSX.Element {
    const { blockType } = this.props.bookingDeadlineOptions

    return (
      <div className="col px12 py6 nowrap">
        {this.renderDeadlineModalItem(blockType, BlockTypeEnum.Block)}
        {this.renderDeadlineModalItem(blockType, BlockTypeEnum.Warn)}
        <div className="bt-light-input-border my5" />
        {this.renderDeadlineModalItem(blockType, BlockTypeEnum.Hide)}
      </div>
    )
  }

  private renderDeadlineModalItem = (
    selectedBlockType: BlockTypeEnum,
    blockType: BlockTypeEnum,
  ): JSX.Element => {
    const isSelected = selectedBlockType === blockType
    return (
      <div
        className={classList({
          [Classes.POPOVER_DISMISS]: true,
          'row x-between h32': true,
          'unclickable-element': isSelected,
          pointer: !isSelected,
        })}
        onClick={this.changeBookingBlockType.bind(null, blockType)}
      >
        <span
          className={classList({
            'text large grey-light mr15': true,
            'blue-highlight': isSelected,
          })}
        >
          {getBlockTypeDisplayName(blockType)}
        </span>
        {isSelected && <Icons.CheckPaletteBlue className="no-flex" />}
      </div>
    )
  }

  private changeFormName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(
      event.target.value,
      this.props.isShownInApp,
      this.props.shouldBlockOnNonWorkTimes,
    )
  }

  private changeShowInApp = () => {
    this.props.onChange(
      this.props.formTypeName,
      !this.props.isShownInApp,
      this.props.shouldBlockOnNonWorkTimes,
    )
  }

  private changeBlockNonWorkTime = () => {
    this.props.onChange(
      this.props.formTypeName,
      this.props.isShownInApp,
      !this.props.shouldBlockOnNonWorkTimes,
    )
  }

  private changeBookingBlockType = (blockType: BlockTypeEnum) => {
    const { bookingDeadlineOptions, onBookingOptionsChange } = this.props

    let deadlineInterval =
      bookingDeadlineOptions?.deadlineInterval || NO_DEADLINE_OPTION
    if (
      blockType !== BlockTypeEnum.Hide &&
      deadlineInterval === NO_DEADLINE_OPTION
    ) {
      deadlineInterval = 1
    }

    onBookingOptionsChange({
      deadlineInterval,
      blockType,
    })
  }

  private changeDeadlineOptions = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { bookingDeadlineOptions, onBookingOptionsChange } = this.props
    const deadlineInterval = Number(event.target.value)

    if (deadlineInterval === NO_DEADLINE_OPTION) {
      this.changeBookingBlockType(BlockTypeEnum.Hide)
      return
    }

    onBookingOptionsChange({
      deadlineInterval,
      blockType: bookingDeadlineOptions.blockType,
    })
  }
}
