import React, { Component } from 'react'

import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import InitialState from '~/client/src/shared/stores/InitialState'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import { Frequency, Period } from '../../Constants/Enums'
import { chartFilters, mergeObjects } from '../../Utils/Utils'
import EmptyState from '../EmptyState'
import TopLevelMetric from '../TopLevelMetric'

export interface ChartIds {
  weekly: string
  monthly: string
  topLevelMetrics?: string
}

interface IProps {
  selectedFrequency: Frequency
  selectedPeriod: Period
  selectedCompany?: string
  state?: InitialState
  baseUrl: string
  chartIds: ChartIds
  title?: string
  lessIsGood?: boolean
  projectDateStore?: ProjectDateStore
}

@inject('state', 'projectDateStore')
@observer
export default class Chart extends Component<IProps> {
  @observable public chartData: any | null = null
  @observable public isLoading: boolean = true
  @observable public chartEmptyState: boolean = false

  private getChartIdsBySelectedOption(): string {
    const { chartIds, selectedFrequency } = this.props
    return chartIds[selectedFrequency]
  }

  @action.bound
  private setChartEmptyState(chartEmptyState: boolean) {
    this.chartEmptyState = chartEmptyState
  }

  @action.bound
  private setChartData(chartData: any) {
    this.chartData = chartData
  }

  private isChartContainerAvailable(chartId: string): boolean {
    return document.getElementById(`chart-${chartId}`) !== null
  }

  private renderChart(attempt: number = 0) {
    const chartId = this.getChartIdsBySelectedOption()

    const delay = 50,
      maxDelay = 5000
    const maxAttempts = maxDelay / delay
    if (attempt < maxAttempts && !this.isChartContainerAvailable(chartId)) {
      setTimeout(() => this.renderChart(attempt + 1), delay)
      return
    }

    const sdk = new ChartsEmbedSDK({
      baseUrl: this.props.baseUrl,
    })

    const filter = chartFilters(
      this.props.projectDateStore,
      this.props.selectedPeriod,
      this.props.state.activeProject.name,
      this.props.selectedCompany,
    )

    const chart = sdk.createChart({
      chartId,
      showAttribution: false,
      filter,
    })

    chart
      .render(document.getElementById(`chart-${chartId}`))
      .then(() => {
        return chart.getData()
      })
      .then((data: any) => {
        const documentsFound = data?.documents?.length > 0
        this.setChartEmptyState(!documentsFound)
      })
      .catch(error => {
        console.error('Error rendering chart', error)
      })

    return <div id={`chart-${chartId}`} />
  }

  private async fetchHiddenChartData() {
    const { chartIds, baseUrl } = this.props

    if (!chartIds.topLevelMetrics) {
      return
    }

    const sdk = new ChartsEmbedSDK({
      baseUrl,
    })

    const filter = { ProjectName: this.props.state.activeProject.name }

    const chart = sdk.createChart({
      chartId: chartIds.topLevelMetrics,
      showAttribution: false,
      filter,
    })

    chart
      .render(document.getElementById(`chart-${chartIds.topLevelMetrics}`))
      .then(() => chart.getData())
      .then((data: any) => {
        this.setChartData(mergeObjects(data))
      })
      .catch(console.error)
  }

  public componentDidMount() {
    this.fetchHiddenChartData()
    this.renderChart()
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (
      this.props.selectedFrequency === prevProps.selectedFrequency &&
      this.props.selectedPeriod === prevProps.selectedPeriod &&
      this.props.selectedCompany === prevProps.selectedCompany
    ) {
      return
    }

    if (this.props.selectedCompany !== prevProps.selectedCompany) {
      this.setChartData(null)
      this.fetchHiddenChartData()
    }

    this.setChartEmptyState(false)
    this.renderChart()
  }

  public renderHiddenChart(metrics: any) {
    if (!metrics) {
      return null
    }

    const { selectedPeriod, lessIsGood } = this.props
    const period = metrics[`last${selectedPeriod}days`] ?? metrics.value
    const delta = metrics[`delta${selectedPeriod}days`]

    return (
      <TopLevelMetric value={period} delta={delta} islessIsGood={lessIsGood} />
    )
  }

  public render() {
    const chartId = this.getChartIdsBySelectedOption()
    const { title, chartIds } = this.props
    const metrics = this.chartData
    const chartEmptyState = this.chartEmptyState
    const chartClassName = chartEmptyState ? 'hidden' : 'h300'

    return (
      <>
        <div className="hidden" id={`chart-${chartIds.topLevelMetrics}`} />
        <div className="h400">
          <div className="h300">
            {title && (
              <div className="h70 bg-palette-brand-lightest pa12">
                <div className="text large bold">{title}</div>
                {metrics && !chartEmptyState && this.renderHiddenChart(metrics)}
              </div>
            )}
            {chartEmptyState && <EmptyState />}
            <div className={chartClassName} id={`chart-${chartId}`} />
          </div>
        </div>
      </>
    )
  }
}
