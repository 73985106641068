import * as React from 'react'

import { action, observable } from 'mobx'
import { observer } from 'mobx-react'

import * as Icons from '~/client/src/shared/components/Icons'
import {
  AndOrOperator,
  LogicalOperation,
  getOperatorDisplayText,
} from '~/client/src/shared/models/LogicOperation'

import './PlusControl.scss'

// translated

interface IProps {
  onSelect: (operation: AndOrOperator) => void
  className?: string
}

const operations: LogicalOperation[] = [
  new LogicalOperation(
    AndOrOperator.OR,
    <Icons.BooleanOr className="row no-grow mr12" />,
  ),
  new LogicalOperation(
    AndOrOperator.AND,
    <Icons.BooleanAnd className="row no-grow mr12" />,
  ),
]

@observer
export default class PlusControl extends React.Component<IProps> {
  public static defaultProps = {
    className: '',
  }

  @observable private shouldShowPullDown: boolean = false

  public render() {
    const { className } = this.props

    return (
      <span
        className={`${className} row plus-container br-rounded pointer relative`}
        onClick={this.togglePullDown}
      >
        <Icons.PlusRounded className="row" />
        {this.shouldShowPullDown && this.renderPullDown()}
      </span>
    )
  }

  private renderPullDown() {
    return (
      <div className="pull-down col absolute brada4 pa10 beautiful-shadow">
        {operations.map(({ icon, type }) => (
          <div
            key={type}
            className="row pull-down-row pointer"
            onClick={this.handleSelect.bind(this, type)}
          >
            {icon}
            <span>{getOperatorDisplayText(type)}</span>
          </div>
        ))}
      </div>
    )
  }

  @action.bound
  private togglePullDown() {
    this.shouldShowPullDown = !this.shouldShowPullDown
  }

  @action.bound
  private handleSelect(logicalOperationType: AndOrOperator) {
    this.props.onSelect(logicalOperationType)
  }
}
