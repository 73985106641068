import * as React from 'react'

import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { NotificationType } from '~/client/graph'
import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import * as Icons from '~/client/src/shared/components/Icons'
import { HBox, VBox } from '~/client/src/shared/components/Layout'
import NotificationAuthor from '~/client/src/shared/components/NotificationAuthor/NotificationAuthor'
import NotificationLabel from '~/client/src/shared/components/NotificationLabel'
import Flag from '~/client/src/shared/models/Flag'
import Message from '~/client/src/shared/models/Message'
import BaseNotification from '~/client/src/shared/models/Notification'
import Rfi from '~/client/src/shared/models/Rfi'
import ScheduleComment from '~/client/src/shared/models/ScheduleComment'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import FlagsStore from '~/client/src/shared/stores/domain/Flags.store'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'
import RfisStore from '~/client/src/shared/stores/domain/Rfis.store'
import ScheduleCommentsStore from '~/client/src/shared/stores/domain/ScheduleComments.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import {
  isActivityType,
  isDeleteType,
  isDeliveryType,
  isFlagType,
  isPermitType,
  isRFIType,
  isScheduleCommentType,
  isStatusUpdateType,
} from '~/client/src/shared/types/NotificationTypes'

import desktopRoutes from '../../../constants/desktopRoutes'
import DesktopInitialState from '../../../stores/DesktopInitialState'
import DesktopEventStore from '../../../stores/EventStore/DesktopEvents.store'
import DesktopCommonStore from '../../../stores/ui/DesktopCommon.store'
import NotificationDetailedInformation from './NotificationDetailedInformation'
import NotificationPhotos from './NotificationPhotos'

import './Notifications.scss'

// translated

interface IProps {
  notification: BaseNotification
  deliveriesStore?: DeliveriesStore
  flagsStore?: FlagsStore
  rfisStore?: RfisStore
  messagesStore?: MessagesStore
  scheduleCommentsStore?: ScheduleCommentsStore
  statusUpdatesStore?: StatusUpdatesStore
  sitePermitsStore?: SitePermitsStore
  isSelected: boolean
  toggleNotification: (id: string) => void
  viewNotification: (notification: BaseNotification) => void
  openContentObject: (route: string) => void
  openActivity: (
    entityId: string,
    type: NotificationType,
    activityId: string,
  ) => void
  eventsStore?: DesktopEventStore
  state?: DesktopInitialState
  common?: DesktopCommonStore
}

@inject(
  'flagsStore',
  'rfisStore',
  'scheduleCommentsStore',
  'state',
  'eventsStore',
  'deliveriesStore',
  'statusUpdatesStore',
  'messagesStore',
  'sitePermitsStore',
  'common',
)
@observer
export default class NotificationsListItem extends React.Component<IProps> {
  @observable public isDisplayed = false
  @observable public isHovering = false

  public render() {
    const {
      notification,
      notification: { id, wasRead },
      isSelected,
    } = this.props

    return (
      <VBox
        key={id}
        className={classList({
          'overflow-hidden': true,
          'notifications-list-item-read': wasRead,
          'notifications-list-item-unread': !wasRead,
          'notifications-list-item-active': isSelected,
          'beautiful-shadow': this.isHovering,
        })}
        onClick={this.viewNotification}
        onMouseEnter={this.setHovering}
        onMouseLeave={this.resetHovering}
      >
        <HBox style={{ minHeight: '60px' }}>
          <div
            className="col pl10 y-between"
            style={{ minWidth: '18%', maxWidth: '18%' }}
          >
            <div className="row x-start y-center">
              <Checkbox
                isChecked={isSelected}
                onClick={this.selectNotification}
              />
              <div onClick={this.openContentObject}>
                <div
                  style={{ maxWidth: 'max-content' }}
                  className={classList({
                    text: true,
                    bold: !wasRead,
                  })}
                >
                  <NotificationAuthor
                    notification={notification}
                    showCompany={true}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              {this.renderMainIcon()}
              {this.renderAdditionalIcons()}
            </div>
          </div>
          <div
            className="col x-start y-start text large"
            style={{
              minWidth: this.labelWidthDependOnType(notification.type),
              maxWidth: this.labelWidthDependOnType(notification.type),
            }}
          >
            <NotificationLabel
              notification={notification}
              hideActivityName={true}
            />
          </div>
          <div
            className="col x-start y-start"
            style={{ minWidth: '40%', maxWidth: '40%' }}
          >
            <NotificationDetailedInformation notification={notification} />
          </div>
          <div
            className="col x-start y-start"
            style={{ minWidth: '20%', maxWidth: '20%' }}
          >
            <NotificationPhotos notification={notification} />
          </div>
        </HBox>
      </VBox>
    )
  }

  private labelWidthDependOnType = (type: NotificationType) => {
    switch (type) {
      case NotificationType.DeliveryDeleted:
      case NotificationType.DeliveryRequestedByLocationClosure:
        return '50%'
      case NotificationType.AnnouncementCreated:
      case NotificationType.AnnouncementStarted:
      case NotificationType.AnnouncementFollowed:
        return '30%'
      default:
        return '22%'
    }
  }

  private viewNotification = () => {
    const { notification, viewNotification } = this.props
    viewNotification(notification)
  }

  private selectNotification = (e: React.SyntheticEvent<HTMLSpanElement>) => {
    const {
      notification: { id },
      toggleNotification,
    } = this.props
    e.stopPropagation()
    e.preventDefault()
    e.nativeEvent.stopImmediatePropagation()
    toggleNotification(id)
  }

  private renderAdditionalIcons() {
    const {
      notification,
      flagsStore,
      rfisStore,
      messagesStore,
      deliveriesStore,
      statusUpdatesStore,
      scheduleCommentsStore,
    } = this.props

    const { type, entityId } = notification
    let messages: Message[] = []

    switch (true) {
      case isDeleteType(type):
        return <div />

      case isDeliveryType(type):
        const delivery = deliveriesStore.byId.get(entityId)
        messages =
          delivery &&
          messagesStore.list.filter(m => m.threadId === delivery.threadId)
        break

      case isStatusUpdateType(type):
        const statusUpdate =
          statusUpdatesStore.byId.get(entityId) || StatusUpdate.none
        messages = messagesStore.list.filter(
          m => m.threadId === statusUpdate.threadId,
        )
        break

      case isFlagType(type):
        const flag = flagsStore.byId.get(entityId) || Flag.none
        messages = messagesStore.list.filter(m => m.threadId === flag.threadId)
        break

      case isRFIType(type):
        const rfi = rfisStore.byId.get(entityId) || Rfi.none
        messages = messagesStore.list.filter(m => m.threadId === rfi.threadId)
        break

      case isScheduleCommentType(type):
        const scheduleComment =
          scheduleCommentsStore.byId.get(entityId) || ScheduleComment.none
        messages = messagesStore.list.filter(
          m => m.threadId === scheduleComment.threadId,
        )
        break

      case isPermitType(type):
        // const permit = sitePermitsStore.byId.get(entityId) || SitePermit.none
        // TODO: add action bar for permits
        messages = [] // messagesStore.list.filter(m => m.threadId === permit.threadId)
        break

      default:
        return <div />
    }

    const isContainPhoto = messages && !!messages.find(m => !!m.photoId)

    return (
      <div className="row">
        {!!(messages || []).filter(m => m.text).length && (
          <Icons.NoteBig className="no-grow mr10" />
        )}
        {isContainPhoto && <Icons.PhotoPlaceholder className="no-grow mr10" />}
      </div>
    )
  }

  private renderMainIcon() {
    const { type } = this.props.notification

    if (isDeleteType(type)) {
      return
    }

    if (isDeliveryType(type)) {
      return (
        <div onClick={this.openContentObject}>
          <Icons.NotificationDelivery />
        </div>
      )
    }

    return (
      <div onClick={this.openContentObject}>
        <Icons.NotificationSchedule />
      </div>
    )
  }

  @action.bound
  private setHovering() {
    this.isHovering = true
  }

  @action.bound
  private resetHovering() {
    this.isHovering = false
  }

  private openContentObject = () => {
    const { notification, openContentObject } = this.props

    const entityUrl = '?' + notification.entityId
    switch (true) {
      case isDeliveryType(notification.type):
        this.props.common._displayDeliveryDetailsView(notification.entityId)
        return
      case isPermitType(notification.type):
        openContentObject(desktopRoutes.HOME + entityUrl)
        return
      case isActivityType(notification.type):
        this.props.common.displayActivityDetailsView(
          notification.activityObjectId,
        )
        openContentObject(desktopRoutes.ACTIVITIES + entityUrl)
        this.openActivity()
        return
    }
  }

  private openActivity = () => {
    const { notification, openActivity } = this.props
    const { type, activityObjectId, entityId } = notification
    if (!isDeleteType(type)) {
      openActivity(entityId, type, activityObjectId)
    }
  }
}
