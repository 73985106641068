import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { CategoryName } from '~/client/graph'
import TwoMonthsDatePicker from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopActivityListStore from '~/client/src/desktop/views/SimpleGanttView/components/DesktopActivityList.store'
import FilterHandleButton from '~/client/src/shared/components/Filters/components/FilterHandleButton/FilterHandleButton'
import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import FilterFooter from '../FilterFooter/FilterFooter'
import FilterHeader from '../FilterHeader/FilterHeader'
import CategoryFilterStore from './CategoryFilter.store'
import FilterItem from './components/FilterItem'
import RecentlyUpdated from './components/RecentlyUpdated/RecentlyUpdated'
import RecentlyUpdatedStore from './components/RecentlyUpdated/RecentlyUpdated.store'
import StatusFilter from './components/StatusFilter/StatusFilter'
import StatusFilterStore from './components/StatusFilter/StatusFilter.store'

import './CategoryFilter.scss'

// localization: translated

interface ICategoryFilterProps {
  activityListStore: DesktopActivityListStore
  categoryFilterStore: CategoryFilterStore
  recentlyUpdatedStore: RecentlyUpdatedStore
  statusFilterStore: StatusFilterStore
  state?: DesktopInitialState
  projectDateStore?: ProjectDateStore
  onShowChanged?: (isShown: boolean) => void
  forceClose?: boolean
}

interface ICategoryOption {
  id: CategoryName
  getDisplayName: () => string
  icon: JSX.Element
}

@inject('state', 'projectDateStore')
@observer
export default class CategoryFilter extends React.Component<ICategoryFilterProps> {
  public componentDidUpdate() {
    const { forceClose, categoryFilterStore } = this.props
    if (forceClose) {
      categoryFilterStore.isShown = false
    }
  }

  public render() {
    const { isShown, isActive, toggle } = this.props.categoryFilterStore
    return (
      <div className="category-filter">
        <FilterHandleButton
          className="category-filter-handle-button"
          isSelected={isShown}
          isActive={isActive}
          onClick={toggle}
        >
          <span className="handle-button-circle no-grow circle">1</span>
        </FilterHandleButton>
        {isShown && (
          <div className="filter-container category">{this.filter}</div>
        )}
      </div>
    )
  }

  private get filter() {
    const {
      categoryFilterStore,
      recentlyUpdatedStore: { datePickerStore },
    } = this.props
    const {
      toggle,
      onClearAllClick,
      onApplyClick,
      onSelectAllClick,
      activitiesCountBySelectedCategories,
    } = categoryFilterStore

    return (
      <>
        <TwoMonthsDatePicker
          store={datePickerStore}
          className="calendar-holder ba-grey"
        />
        <FilterHeader
          clickOnClearAll={onClearAllClick}
          clickOnSelectAll={onSelectAllClick}
        >
          <div className="row bb-light-input-border mt25 pb10">
            <span className="text bold large primary-blue">
              {Localization.translator.type}
            </span>
            <span className="text bold large right">
              {Localization.translator.numberOfActivities}
            </span>
          </div>
        </FilterHeader>

        <div className={'bb-light-input-border filter-content'}>
          {this.content()}
        </div>

        <FilterFooter
          clickOnCancel={toggle}
          clickOnApply={onApplyClick}
          applyButtonText={Localization.translator.seeXActivities(
            activitiesCountBySelectedCategories,
          )}
        />
      </>
    )
  }

  private content() {
    return (
      <>
        <div className="mx15">
          {categoryOptions.map(this.renderCategoryRow)}
        </div>
      </>
    )
  }

  private renderCategoryRow = (category: ICategoryOption) => {
    const {
      activityListStore: {
        recentlyUpdatedActivitiesCount,
        activitiesWithSpecificStatusCount,
      },
      state,
      recentlyUpdatedStore,
      categoryFilterStore,
      statusFilterStore,
    } = this.props
    const { selectedRecentlyUpdatedMode } = state.filters
    const { id, getDisplayName, icon } = category

    switch (id) {
      case CategoryName.RecentlyUpdated:
        return (
          <RecentlyUpdated
            id={id}
            name={getDisplayName()}
            icon={icon}
            key={id}
            activitiesCount={recentlyUpdatedActivitiesCount}
            selectedMode={selectedRecentlyUpdatedMode}
            recentlyUpdatedStore={recentlyUpdatedStore}
            categoryFilterStore={categoryFilterStore}
          />
        )
      case CategoryName.Status:
        return (
          <StatusFilter
            id={id}
            displayName={getDisplayName()}
            icon={icon}
            key={id}
            activitiesCount={activitiesWithSpecificStatusCount}
            categoryFilterStore={categoryFilterStore}
            statusFilterStore={statusFilterStore}
          />
        )
      default:
        return (
          <FilterItem
            id={id}
            displayName={getDisplayName()}
            icon={icon}
            key={id}
            store={categoryFilterStore}
          />
        )
    }
  }
}

export const categoryOptions: ICategoryOption[] = [
  {
    id: CategoryName.UserAssociated,
    getDisplayName: () => Localization.translator.myTasks,
    icon: <Icons.FilledStar />,
  },
  {
    id: CategoryName.RecentlyUpdated,
    getDisplayName: () => Localization.translator.recentlyUpdated_activities,
    icon: <Icons.CheckFillBlue />,
  },
  {
    id: CategoryName.Status,
    getDisplayName: () => Localization.translator.activitiesWithSpecificStatus,
    icon: <Icons.StatusBadge />,
  },
  {
    id: CategoryName.ActualizedFromSchedule,
    getDisplayName: () =>
      Localization.translator.actualizedFromSchedule_activities,
    icon: <Icons.ActualisedFromSchedule />,
  },
  {
    id: CategoryName.ScheduleComment,
    getDisplayName: () =>
      Localization.translator.activitiesWithOpenScheduleComment,
    icon: <Icons.DateChangeBlue />,
  },
  {
    id: CategoryName.NotCompleted,
    getDisplayName: () => Localization.translator.notCompleted_activities,
    icon: <Icons.PieChart />,
  },
  {
    id: CategoryName.Critical,
    getDisplayName: () => Localization.translator.criticalPathActivities,
    icon: <Icons.Critical />,
  },
  {
    id: CategoryName.Flagged,
    getDisplayName: () => Localization.translator.flaggedActivities,
    icon: <Icons.Flag className="icon-red" />,
  },
  {
    id: CategoryName.Late,
    getDisplayName: () => Localization.translator.lateActivities,
    icon: <span>-1</span>,
  },
  {
    id: CategoryName.Rfi,
    getDisplayName: () => Localization.translator.activitiesWithOpenRFI,
    icon: <Icons.Rfi />,
  },
  {
    id: CategoryName.Deliveries,
    getDisplayName: () => Localization.translator.activitiesWithDeliveries,
    icon: <Icons.Delivery />,
  },
]
