import * as React from 'react'

import { observer } from 'mobx-react'

import SuperFilter from '~/client/src/desktop/components/SuperFilter/SuperFilter'
import SuperFilterHandleButton from '~/client/src/shared/components/Filters/SuperFilter/SuperFilterHandleButton'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { IFilters } from '~/client/src/shared/stores/InitialState'

import FormTypesFilterStore from './FormTypesFilter.store'

// localization: translated

interface IProps {
  forceCloseMap: { [filterType: string]: boolean }
  filters: IFilters

  store: FormTypesFilterStore
}

@observer
export default class FormTypesFilter extends React.Component<IProps> {
  public componentDidMount() {
    this.props.store.resetAllFilters()
  }

  public componentDidUpdate() {
    const {
      forceCloseMap,
      filters: { fieldsMap },
    } = this.props

    if (!forceCloseMap) return

    Object.keys(fieldsMap).forEach(filterType => {
      if (forceCloseMap[filterType]) {
        fieldsMap[filterType].isFilterShown = false
      }
    })
  }

  public render() {
    return (
      <div className="row full-height x-start relative">
        {this.renderHandleButtons()}
        {this.renderPopups()}
      </div>
    )
  }

  private renderPopups(): JSX.Element[] {
    return Object.keys(this.storesMap).map(filterType => {
      const store = this.storesMap[filterType]
      return <SuperFilter key={filterType} filterStore={store} />
    })
  }

  private renderHandleButtons(): JSX.Element {
    return (
      <>
        {Object.keys(this.storesMap).map(filterType => (
          <SuperFilterHandleButton
            className="no-grow no-select"
            key={filterType}
            filterStore={this.storesMap[filterType]}
          />
        ))}
        <div
          className="ml12 row nowrap h32 text large grey-30 line-extra-large medium-bold pointer"
          onClick={this.props.store.resetAllFilters}
        >
          {Localization.translator.clearAll_items}
        </div>
      </>
    )
  }

  private get storesMap() {
    return this.props.store.filterStoresByTypeMap
  }
}
