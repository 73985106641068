import * as React from 'react'

import { observer } from 'mobx-react'

import { PermitFieldType } from '~/client/graph'
import TooltipWrapper from '~/client/src/desktop/components/TooltipWrapper/TooltipWrapper'
import formFieldsCaptionMap from '~/client/src/desktop/constants/formFieldsCaptionMap'
import ConfirmDialog from '~/client/src/shared/components/ConfirmDialog/ConfirmDialog'
import PermitFieldIcon from '~/client/src/shared/components/PermitFieldIcon/PermitFieldIcon'
import StruxhubTextArea from '~/client/src/shared/components/StruxhubInputs/StruxhubTextArea'
import Keys from '~/client/src/shared/enums/Keys'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import TypeSelector from '../TypeSelector'
import BaseInstructionsConfiguratorStore, {
  BaseInstructions,
} from './BaseInstructionsConfigurator.store'

import './BaseInstructionsConfigurator.scss'

interface IProps<T> {
  className?: string
  instructions: BaseInstructions<T>
  onChange: (instructions: BaseInstructions<T>) => void
  store: BaseInstructionsConfiguratorStore<T>
  instructionItemRenderer: (item: T, index: number) => JSX.Element
  fieldType: PermitFieldType
  singleModeElement?: JSX.Element
  fieldStateElement?: JSX.Element
}

// translated

@observer
export default class BaseInstructionsConfigurator<T> extends React.Component<
  IProps<T>
> {
  public componentDidUpdate(prevProps: Readonly<IProps<T>>) {
    const { instructions, store, onChange } = this.props
    if (instructions !== prevProps.instructions) {
      store.setInstructions(instructions)
    }
    if (onChange !== prevProps.onChange) {
      store.setOnChangeHandler(onChange)
    }
  }

  public render() {
    const {
      store,
      className,
      instructionItemRenderer,
      singleModeElement,
      fieldStateElement,
      fieldType,
    } = this.props

    const {
      instructions: { list, listType },
      removeInstruction,
      shouldShowRemovingConfirmDialog,
      hideRemovingConfirmModal,
      listTypeAsOptions,
    } = store

    return (
      <>
        <ConfirmDialog
          doneTitle={Localization.translator.remove}
          onDoneClicked={removeInstruction}
          isOpen={shouldShowRemovingConfirmDialog}
          onCancelClicked={hideRemovingConfirmModal}
        >
          {Localization.translator.areYouSureWantToDeleteInstructionItem}
        </ConfirmDialog>

        <div className={`col permit-type-instructions ${className || ''}`}>
          <div className="row bg-palette-brand-lightest brada4 ba-light-input-border px15 mb15">
            <TooltipWrapper
              content={formFieldsCaptionMap[fieldType]}
              className="row no-grow"
            >
              <PermitFieldIcon fieldName={fieldType} />
            </TooltipWrapper>
            {singleModeElement ? (
              singleModeElement
            ) : (
              <>
                <span className="text extra-large bold mr15 no-grow no-white-space-wrap">
                  {Localization.translator.typeOfList}
                </span>
                <TypeSelector
                  selectedValue={listType}
                  options={listTypeAsOptions}
                  onChange={this.handleListTypeChange}
                />
              </>
            )}
            {fieldStateElement}
          </div>

          <div className="ml20">
            <div>{list.map(instructionItemRenderer)}</div>
            {!singleModeElement && this.renderAdditor()}
          </div>
        </div>
      </>
    )
  }

  private renderAdditor() {
    const {
      showTextarea,
      newInstructionText,
      addNewInstruction,
      shouldShowTextarea,
    } = this.props.store

    return shouldShowTextarea ? (
      <StruxhubTextArea
        isMinimalisticMode={true}
        placeholder={`${Localization.translator.enterInstruction}...`}
        value={newInstructionText}
        onBlur={addNewInstruction}
        onChange={this.handleTextareaChange}
        onKeyPress={this.handleTextareaKeyPress}
        autoFocus={true}
      />
    ) : (
      <div
        className="text large bold blue-highlight pointer"
        onClick={showTextarea}
      >
        {Localization.translator.addAdditionalItem}
      </div>
    )
  }

  private handleListTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    this.props.store.changeListType(event.target.value)
  }

  private handleTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    this.props.store.changeNewInstruction(event.target.value)
  }

  private handleTextareaKeyPress = (
    event: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    if (event.key === Keys.Enter && !event.shiftKey) {
      this.props.store.addNewInstruction()
    }
  }
}
