import * as React from 'react'

import './NumberSelector.scss'

interface IProps {
  value: number
  step?: number
  min?: number
  max?: number
  numberFormatter?: (value: number) => string
  onChange(newValue: number)
}

export default class NumberSelector extends React.Component<IProps> {
  public static defaultProps = {
    step: 1,
    numberFormatter: value => String(value),
  }

  public render() {
    const { value, numberFormatter } = this.props
    const displayedValue = numberFormatter(value)
    return (
      <div className="number-selector text light large">
        {displayedValue}
        <span className="increment-arrow" onClick={this.incrementNumber} />
        <span className="decrement-arrow" onClick={this.decrementNumber} />
      </div>
    )
  }

  private incrementNumber = () => {
    const { value, step, max, onChange } = this.props
    const newValue = value + step
    if (!max || newValue <= max) {
      onChange(newValue)
    }
  }

  private decrementNumber = () => {
    const { value, step, min, onChange } = this.props
    const newValue = value - step
    if (!min || newValue >= min) {
      onChange(newValue)
    }
  }
}
