import * as React from 'react'

import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { toggleClass } from 'react-classlist-helper'

import { IWhiteListItem } from '~/client/graph'
import { Loader } from '~/client/src/shared/components/Loader'
import { ISharedProps } from '~/client/src/shared/components/StruxhubInputs/BaseStruxhubInput'
import StruxhubTagsAsTextInput from '~/client/src/shared/components/StruxhubInputs/StruxhubTagsAsTextInput/StruxhubTagsAsTextInput'
import { TagIconType } from '~/client/src/shared/enums/TagIcon'
import WhiteListItemsStore from '~/client/src/shared/stores/domain/WhiteListItems.store'
import {
  DOMAIN_VALIDATOR_PATTERN,
  WITHOUT_SPECIAL_CHARACTERS_EXCEPT_DOT_DASH_PATTERN,
} from '~/client/src/shared/utils/regExpPatterns'

import './CompanyDomainsInput.scss'

interface IProps extends ISharedProps {
  companyId: string
  isLoading?: boolean
  searchKey?: string

  whiteListItemsStore?: WhiteListItemsStore
}

const domainIsNotValid = `The domain format is not valid. Try something like 'gmail.com'`
const domainIsAlreadyAdded = 'The domain is already added'

@inject('whiteListItemsStore')
@observer
export default class CompanyDomainsInput extends React.Component<IProps> {
  @observable private _isLoading: boolean = false

  @observable private isValid: boolean = true
  @observable private validationMessage: string = ''

  private get companyId(): string {
    return this.props.companyId
  }

  private get isLoading(): boolean {
    return this.props.isLoading || this._isLoading
  }

  private get wlItems(): IWhiteListItem[] {
    return this.props.whiteListItemsStore.getItemsByCompanyId(this.companyId)
  }

  @computed
  private get domains(): string[] {
    return this.wlItems.map(d => d.domainName)
  }

  public render() {
    return (
      <div className="relative company-domains-input">
        <div className={toggleClass('blur-filter', this.isLoading)}>
          <StruxhubTagsAsTextInput
            {...this.props}
            iconName={TagIconType.At}
            values={this.domains}
            onChange={this.handleChange}
            onInputChange={this.handleOnInputChange}
            isValid={this.isValid}
            validationMessage={this.validationMessage}
          />
        </div>
        {this.isLoading && <Loader className="row absolute loader-wrapper" />}
      </div>
    )
  }

  @action.bound
  private handleChange(newDomains: string[]) {
    if (newDomains.length === this.domains.length) {
      return
    }

    if (newDomains.length > this.domains.length) {
      this.handleAddDomain(newDomains[newDomains.length - 1])
    } else {
      const wlItemsToDelete = this.wlItems.filter(
        d => !newDomains.includes(d.domainName),
      )
      this.handleDeleteDomains(wlItemsToDelete)
    }
  }

  @action.bound
  private async handleAddDomain(newDomain: string) {
    if (!this.isValid) {
      return
    }

    this._isLoading = true
    try {
      await this.props.whiteListItemsStore.addItem(newDomain, this.companyId)
    } catch (e) {
      this.setError(e.message)
    } finally {
      this._isLoading = false
    }
  }

  @action.bound
  private async handleDeleteDomains(wlItems: IWhiteListItem[]) {
    this._isLoading = true

    try {
      await this.props.whiteListItemsStore.deleteItems(wlItems)
    } catch (e) {
      this.setError(e.message)
    } finally {
      this._isLoading = false
    }
  }

  @action.bound
  private validate(domain: string) {
    this.resetError()

    if (!DOMAIN_VALIDATOR_PATTERN.test(domain)) {
      this.setError(domainIsNotValid)
    }

    if (this.domains.includes(domain)) {
      this.setError(domainIsAlreadyAdded)
    }
  }

  @action.bound
  private setError(message: string) {
    this.isValid = false
    this.validationMessage = message
  }

  @action.bound
  private resetError() {
    this.isValid = true
    this.validationMessage = ''
  }

  @action.bound
  private handleOnInputChange(rawDomain: string): string {
    const formattedDomain = rawDomain.replace(
      WITHOUT_SPECIAL_CHARACTERS_EXCEPT_DOT_DASH_PATTERN,
      '',
    )

    this.validate(formattedDomain)

    return formattedDomain
  }
}
