import React from 'react'

import { inject, observer } from 'mobx-react'

import CommonDatePicker from '~/client/src/shared/components/CommonDatePicker/CommonDatePicker'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import TwoMonthsDatePickerStore from './TwoMonthsDatePicker.store'

// localization: no display text to translate

export interface IProps {
  store: TwoMonthsDatePickerStore
  date: Date
  isFirstMonth: boolean
  className?: string
  projectDateStore?: ProjectDateStore
}

@inject('projectDateStore')
@observer
export default class DatePickerMonths extends React.Component<IProps> {
  public render() {
    const { isFirstMonth, date, store, projectDateStore } = this.props
    const { startDate, endDate, onDateClick, isSeparatedMonths } = store
    const { getMonthAndYearToDisplay } = projectDateStore

    return (
      <div className="col">
        <div className="tm-dp-month-header col y-center x-stretch mb15 mt5">
          {isSeparatedMonths
            ? this.renderArrows(isFirstMonth)
            : this.renderArrow(true, isFirstMonth)}
          <div className="row y-center">
            <span className="text extra-large center bold-font light">
              {getMonthAndYearToDisplay(date)}
            </span>
          </div>
        </div>
        <CommonDatePicker
          monthDate={date}
          startDate={startDate}
          endDate={endDate}
          dateClickHandler={onDateClick}
        />
      </div>
    )
  }

  private renderArrow(isFirstMonth: boolean, isLeft: boolean) {
    const {
      switchFirstMonthToNext,
      switchFirstMonthToPrev,
      switchSecondMonthToPrev,
      switchSecondMonthToNext,
    } = this.props.store

    const leftArrowClickHandler = isFirstMonth
      ? switchFirstMonthToPrev
      : switchSecondMonthToPrev
    const rightArrowClickHandler = isFirstMonth
      ? switchFirstMonthToNext
      : switchSecondMonthToNext

    const handler = isLeft ? leftArrowClickHandler : rightArrowClickHandler
    const className = isLeft ? 'sw-left' : 'sw-right'
    return (
      <div
        className={`${className} tm-dp-month-switch row x-center y-center no-flex-children-direct`}
        onClick={handler}
      >
        <div className="arrow" />
      </div>
    )
  }

  private renderArrows(isFirstMonth: boolean) {
    return (
      <div>
        {this.renderArrow(isFirstMonth, true)}
        {this.renderArrow(isFirstMonth, false)}
      </div>
    )
  }
}
