import * as React from 'react'

import { Icon, IconSize } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DeliveryWorkflowCard from '~/client/src/shared/components/ExpandableWorkflowCard/components/DeliveryWorkflowCard'
import FormMultiDayEventLabel from '~/client/src/shared/components/FormMultiDayEventLabel'
import PermitCard from '~/client/src/shared/components/SitemapCards/PermitCard'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import CalendarEvent, {
  CalendarEventEntityType,
} from '~/client/src/shared/models/CalendarEvent'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import DesktopCalendarColumn from '../../DesktopCalendarColumn'
import DeliveryMultiDayEventLabel from './DeliveryMultiDayEventLabel'

import './MultiDayEventsBar.scss'

interface IProps {
  events: CalendarEvent[]
  calendarColumns: DesktopCalendarColumn[]
  onEventClicked: (event: CalendarEvent) => void
  setRef: (ref: HTMLDivElement) => void

  projectDateStore?: ProjectDateStore
  isExpanded: boolean
  toggleExpanded: () => void
  setExpandMultiDayContainerRef: (ref: HTMLDivElement) => void
}

const MAX_EVENTS_DISPLAYED = 6

@inject('projectDateStore')
@observer
export default class MultiDayEventsBar extends React.Component<IProps> {
  @observable private hoveredEventId: string = ''

  private clientX: number
  private clientY: number

  public render() {
    const {
      setRef,
      events,
      calendarColumns,
      onEventClicked,
      projectDateStore: { isSameDay },
      isExpanded,
    } = this.props

    if (!events?.length) return null

    return (
      <>
        {this.renderExpandButton()}
        <div
          className={classList({
            'md-events-bar row align-start nowrap': true,
            expanded: isExpanded,
          })}
          ref={setRef}
        >
          {calendarColumns.map((column, i) => {
            const columnEvents = isExpanded
              ? this.eventsByColumn[i]
              : this.eventsByColumn[i].slice(0, MAX_EVENTS_DISPLAYED)

            const isStartOfInterval = i === 0
            const isEndOfInterval = i === calendarColumns.length - 1

            const maxEventsDisplayed = isExpanded
              ? Infinity
              : MAX_EVENTS_DISPLAYED

            return (
              <div
                key={column.date.getTime()}
                className="md-events-bar-item bl-light-grey bb-light-grey x-axis-column-width"
              >
                {columnEvents.map((event, eventIndex) => {
                  if (
                    eventIndex === maxEventsDisplayed - 1 &&
                    this.isAnyColumnOverflowing
                  ) {
                    return this.renderShowMoreButton(i)
                  }

                  if (!event) {
                    return this.renderEmptyCell(
                      `column-${i}-event-${eventIndex}`,
                    )
                  }

                  const isEventStartDate = isSameDay(
                    event.startDate,
                    column.date,
                  )
                  const isEventEndDate = isSameDay(event.endDate, column.date)

                  const shouldAddPixelToWidth =
                    !isEventEndDate && !isEndOfInterval
                  const shouldLabelBeRendered =
                    isEventStartDate || isStartOfInterval
                  const isEventBeingHovered =
                    this.hoveredEventId === event.dataId

                  const eventLength =
                    this.eventLengthByDataIdMap[event.dataId] || 1

                  return (
                    <React.Fragment key={event.dataId}>
                      {isEventBeingHovered &&
                        shouldLabelBeRendered &&
                        !event.isNew && (
                          <div
                            style={{
                              left: `${this.clientX}px`,
                              top: `${this.clientY}px`,
                            }}
                            className="fixed z-index-10"
                          >
                            <div className="relative unclickable-element card-holder">
                              {this.renderEntityCard(event)}
                            </div>
                          </div>
                        )}

                      <div
                        className={classList({
                          'bg-white relative': true,
                          'opaque-underlay': shouldAddPixelToWidth,
                          'z-index-1': shouldLabelBeRendered,
                        })}
                        onMouseEnter={this.setHoveredEvent.bind(
                          null,
                          event.dataId,
                        )}
                        onMouseLeave={this.resetHoveredEvent}
                      >
                        <div
                          style={event.styles}
                          className={classList({
                            'md-events-bar-item-event row nowrap text white relative pointer':
                              true,
                            'brls4 ml5': isEventStartDate,
                            'brrs4 mr5': isEventEndDate,
                            'mr5 view-range end':
                              isEndOfInterval && !isEventEndDate,
                            'ml5 view-range start':
                              isStartOfInterval && !isEventStartDate,

                            'br-none': !isEventEndDate && !isEndOfInterval,
                            'bl-none': !isEventStartDate && !isStartOfInterval,
                            active:
                              event.isActive ||
                              (isEventBeingHovered && !event.isNew),
                          })}
                          onClick={onEventClicked.bind(null, event)}
                        >
                          {shouldLabelBeRendered && (
                            <div
                              style={{ width: `${100 * eventLength}%` }}
                              className="label-container row pl5 absolute overflow-hidden"
                            >
                              {this.renderLabel(event)}
                            </div>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  )
                })}
              </div>
            )
          })}
        </div>
      </>
    )
  }

  private renderLabel(event: CalendarEvent) {
    switch (event.entityType) {
      case CalendarEventEntityType.Form:
        return (
          <FormMultiDayEventLabel
            event={event}
            showPermitType
            iconClassName="event-icon form-type-icon"
          />
        )
      case CalendarEventEntityType.Delivery:
        return <DeliveryMultiDayEventLabel event={event} />

      default:
        return null
    }
  }

  @computed
  private get eventLengthByDataIdMap(): { [key: string]: number } {
    const { events, projectDateStore, calendarColumns } = this.props

    const viewIntervalStartTimestamp = calendarColumns.at(0).date.getTime()
    const viewIntervalEndTimestamp = calendarColumns.at(-1).date.getTime()

    return events.reduce((acc, e) => {
      acc[e.dataId] = projectDateStore.countDaysToDate(
        Math.max(e.startDate.getTime(), viewIntervalStartTimestamp),
        Math.min(e.endDate.getTime(), viewIntervalEndTimestamp),
        true,
      )

      return acc
    }, {} as any)
  }

  @action.bound
  private setHoveredEvent(eventDataId: string, e: React.MouseEvent) {
    this.clientX = e.clientX
    this.clientY = e.pageY
    this.hoveredEventId = eventDataId
  }

  @action.bound
  private resetHoveredEvent() {
    this.hoveredEventId = ''
  }

  private renderEntityCard(event: CalendarEvent) {
    switch (event.entityType) {
      case CalendarEventEntityType.Delivery:
        return (
          <DeliveryWorkflowCard
            className="calendar-day-view-event new highlighted-event unclickable-element"
            delivery={event.data}
            eventStyles={event.styles}
            isCalendarTooltip
          />
        )

      case CalendarEventEntityType.Form:
        return (
          <PermitCard
            className="brada8 inline-block vertical-align-middle no-select bg-white unclickable-element absolute beautiful-shadow"
            permit={event.data}
            key={event.data?.id}
          />
        )
    }
  }

  private findColumnIndexes(event: CalendarEvent) {
    const {
      projectDateStore: { isWithinDateInterval },
      calendarColumns,
    } = this.props

    const columnIndexes: number[] = []

    calendarColumns.forEach((column, i) => {
      if (isWithinDateInterval(event.startDate, event.endDate, column.date)) {
        columnIndexes.push(i)
      }
    })

    return columnIndexes
  }

  @computed
  private get eventsByColumn(): CalendarEvent[][] {
    const { calendarColumns, events } = this.props

    const defaultRow = Array(calendarColumns.length).fill(undefined)
    const rows = []

    const newEvent = events.find(e => e.isNew)

    events.forEach(e => {
      if (e.isNew) return
      const columnIndexes = this.findColumnIndexes(e)
      if (!columnIndexes.length) return

      let fittingRow = rows.find(row =>
        columnIndexes.every(index => !row[index]),
      )
      if (!fittingRow) {
        fittingRow = [...defaultRow]
        rows.push(fittingRow)
      }

      columnIndexes.forEach(columnIndex => {
        fittingRow[columnIndex] = e
      })
    })

    if (newEvent) {
      const newEventRow = [...defaultRow]
      const columnIndexes = this.findColumnIndexes(newEvent)
      columnIndexes.forEach(columnIndex => {
        newEventRow[columnIndex] = newEvent
      })
      rows.unshift(newEventRow)
    }

    const eventsByColumn = calendarColumns.map((column, i) =>
      rows.map(row => row[i]),
    )

    return eventsByColumn
  }

  @computed
  private get isAnyColumnOverflowing(): boolean {
    return this.eventsByColumn.some(
      (column, i) => this.getHiddenEventsCount(i) > 1,
    )
  }

  private getHiddenEventsCount(columnIndex: number) {
    const events = this.eventsByColumn[columnIndex]

    const hiddenEvents = events.slice(MAX_EVENTS_DISPLAYED - 1)

    return hiddenEvents.filter(e => !!e).length
  }

  private renderShowMoreButton(columnIndex: number) {
    const key = `show-more-${columnIndex}`

    const hiddenEventsCount = this.getHiddenEventsCount(columnIndex)
    if (!hiddenEventsCount) return this.renderEmptyCell(key)

    return (
      <div
        className="md-events-bar-item-event row y-center text pl6 pointer highlighted-hover-light"
        onClick={this.props.toggleExpanded}
        key={key}
      >
        {Localization.translator.showXMore(hiddenEventsCount)}
      </div>
    )
  }

  private renderEmptyCell(key: string) {
    return (
      <div
        key={key}
        className="md-events-bar-item-event bg-white relative z-index-1"
      />
    )
  }

  private renderExpandButton() {
    const { isExpanded, toggleExpanded, setExpandMultiDayContainerRef } =
      this.props

    if (!this.isAnyColumnOverflowing) return null

    return (
      <div ref={setExpandMultiDayContainerRef} className="absolute">
        <Icon
          icon={isExpanded ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN}
          size={IconSize.LARGE}
          className="pointer"
          onClick={toggleExpanded}
        />
      </div>
    )
  }
}
