import * as React from 'react'

import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'

import AlertDialog from '~/client/src/desktop/components/AlertDialog/AlertDialog'
import NavBar from '~/client/src/desktop/components/NavBar/NavBar'
import MaterialsHeaderBar from '~/client/src/desktop/views/Materials/components/MaterialsHeaderBar/MaterialsHeaderBar'
import MaterialsListStore from '~/client/src/desktop/views/Materials/components/MaterialsList/MaterialsList.store'
import UnsafeDeliveryDetails from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails'
import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import DeliveryStatusLabel from '~/client/src/shared/components/DeliveryStatusLabel/DeliveryStatusLabel'
import { withErrorBoundary } from '~/client/src/shared/components/ErrorBoundary'
import * as Layout from '~/client/src/shared/components/Layout'
import { Loader } from '~/client/src/shared/components/Loader'
import UnsafeSitePermitCreationForm from '~/client/src/shared/components/SitePermitCreationForm/SitePermitCreationForm'
import UnsafeWorkflowViewFormWrapper from '~/client/src/shared/components/SitePermitCreationForm/WorkflowViewFormWrapper'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import { DELIVERY_RECEIVED } from '~/client/src/shared/stores/EventStore/eventConstants'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import MaterialCategoryStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import MaterialConfigurationStore from '~/client/src/shared/stores/domain/MaterialConfiguration.store'
import MaterialsStore from '~/client/src/shared/stores/domain/Materials.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { NOOP } from '~/client/src/shared/utils/noop'
import { ToastTheme, showToast } from '~/client/src/shared/utils/toaster'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import DesktopFileInput from '../../components/FileInput/DesktopFileInput'
import DesktopEventsStore from '../../stores/EventStore/DesktopEvents.store'
import DeliveryActionSideBase from '../Deliveries/components/DeliveryActionSideWrapper/components/DeliveryActionSideBase'
import UnsafeMaterialsList from './components/MaterialsList/MaterialsList'

import './Materials.scss'

const MaterialsList = withErrorBoundary(UnsafeMaterialsList)
const DeliveryDetails = withErrorBoundary(UnsafeDeliveryDetails)
const SitePermitCreationForm = withErrorBoundary(UnsafeSitePermitCreationForm)
const WorkflowViewFormWrapper = withErrorBoundary(UnsafeWorkflowViewFormWrapper)

interface IProps {
  eventsStore?: DesktopEventsStore
  materialsStore?: MaterialsStore
  materialCategoryStore?: MaterialCategoryStore
  materialConfigurationStore?: MaterialConfigurationStore
  deliveriesStore?: DeliveriesStore
  projectDateStore?: ProjectDateStore
  companiesStore?: CompaniesStore
  locationAttributesStore?: LocationAttributesStore
  deliveryDetailsStore?: DeliveryDetailsStore
  tagsStore?: TagsStore
  sitePermitsStore?: SitePermitsStore
  permitTypesStore?: PermitTypesStore
}

const INFO_EMAIL = 'info@struxhub.com'
export const MAX_SELECTED_MATERIALS_FOR_FORM = 300
const LOADER_SIZE = 50

@inject(
  'eventsStore',
  'materialsStore',
  'materialCategoryStore',
  'materialConfigurationStore',
  'deliveriesStore',
  'projectDateStore',
  'companiesStore',
  'locationAttributesStore',
  'deliveryDetailsStore',
  'tagsStore',
  'sitePermitsStore',
  'permitTypesStore',
)
@observer
export default class Materials extends React.Component<IProps> {
  private readonly store: MaterialsListStore = null
  private readonly clearPostEventCallback: () => void = NOOP

  public constructor(props: IProps) {
    super(props)

    this.store = new MaterialsListStore(
      props.eventsStore.appState,
      props.materialsStore,
      props.materialCategoryStore,
      props.materialConfigurationStore,
      props.deliveriesStore,
      props.projectDateStore,
      props.companiesStore,
      props.locationAttributesStore,
      props.tagsStore,
      props.deliveryDetailsStore,
      props.sitePermitsStore,
      props.permitTypesStore,
    )

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.onDeliveriesUpdate,
    )
  }

  public componentWillUnmount() {
    this.clearPostEventCallback()
  }

  public render() {
    const {
      isDataPrepared,
      isDeliveryFormOpened,
      hideAllOpenedForms,
      isWarningDialogOpened,
      toggleWarningDialog,
      isWrongStatusDialogOpened,
      toggleWrongStatusDialog,
      isUpdating,
      isTransferCreationOpened,
      selectedTransferForm,
      transferFormTypeId,
      transferFormFields,
    } = this.store
    const {
      deliveryDetailsStore,
      eventsStore: { appState },
    } = this.props
    const { displayedDelivery } = deliveryDetailsStore

    return (
      <Layout.View className="overflow-hidden desktop-materials-view">
        <Layout.Header className="layout-navigation">
          <NavBar />
        </Layout.Header>
        <Layout.Content className="relative overflow-hidden">
          {isUpdating && (
            <Loader
              size={LOADER_SIZE}
              className="col x-center y-center full-height full-width absolute z-index-100 bg-white opacity7"
              hint={Localization.translator.updating}
            />
          )}
          <AlertDialog
            title={Localization.translator.error}
            isOpen={isWarningDialogOpened}
            onClose={toggleWarningDialog}
          >
            {this.warningLimitationMessage}
          </AlertDialog>
          <AlertDialog
            title={Localization.translator.error}
            isOpen={isWrongStatusDialogOpened}
            onClose={toggleWrongStatusDialog}
          >
            <span className="mr5">
              {Localization.translator.youCannotChangeDelivery}
            </span>
            <DeliveryStatusLabel status={displayedDelivery?.status} />
          </AlertDialog>
          {isDataPrepared && (
            <MaterialsHeaderBar materialsListStore={this.store} />
          )}
          <div className="relative-block">
            <div className="relative-block overflow-hidden multi-grid-container">
              {isDataPrepared ? (
                <MaterialsList store={this.store} />
              ) : (
                <Loader hint={Localization.translator.materialsLoading} />
              )}
            </div>
          </div>
          {isDeliveryFormOpened && (
            <DeliveryActionSideBase
              title={EMPTY_STRING}
              content={
                <DeliveryDetails
                  initProjectId={appState.activeProject.id}
                  FileInputType={DesktopFileInput}
                  backClicked={hideAllOpenedForms}
                  onCreationCb={this.hideFormAndShowToast}
                  displayedDeliveryId={displayedDelivery?.id}
                />
              }
              shouldShowHeader={false}
            />
          )}
          {isTransferCreationOpened && (
            <div className="permit-side-view">
              <SitePermitCreationForm
                close={hideAllOpenedForms}
                FileInputType={DesktopFileInput}
                isTypeReadOnly
                preselectedTypeId={transferFormTypeId}
                prepopulatedFields={transferFormFields}
              />
            </div>
          )}
          {selectedTransferForm && (
            <div className="permit-side-view">
              <WorkflowViewFormWrapper
                workflowToShow={selectedTransferForm}
                onClose={hideAllOpenedForms}
                FileInputType={DesktopFileInput}
              />
            </div>
          )}
        </Layout.Content>
        <Layout.Footer>
          <div />
        </Layout.Footer>
      </Layout.View>
    )
  }

  private get warningLimitationMessage(): JSX.Element {
    return (
      <div className="pa15 text large">
        <span>
          {Localization.translator.materialsInDeliveryFormWarning.onlyXMaterialsForForm(
            MAX_SELECTED_MATERIALS_FOR_FORM,
          )}
        </span>
        <a
          className="text large bold blue-highlight mx4"
          href={`mailto:${INFO_EMAIL}`}
        >
          {INFO_EMAIL}
        </a>
        <span>
          {Localization.translator.materialsInDeliveryFormWarning.andWeCanTalk}
        </span>
      </div>
    )
  }

  private hideFormAndShowToast = () => {
    this.store.hideAllOpenedForms()

    showToast(
      Localization.translator.deliverySuccessfullyCreated,
      ToastTheme.SUCCESS,
      IconNames.TICK_CIRCLE,
    )
  }

  private onDeliveriesUpdate = (eventContext: EventContext) => {
    const [eventType, subscriptionChangeEvents] = eventContext.event

    if (DELIVERY_RECEIVED === eventType) {
      this.store.resetValuesForOpenedDelivery(subscriptionChangeEvents)
    }
  }
}
