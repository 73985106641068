enum CompanyFieldId {
  Name = 'name',
  Code = 'code',
  LogoUrl = 'logoUrl',
  AvatarUrl = 'avatarUrl',
  BusinessPhone = 'businessPhone',
  BusinessEmail = 'businessEmail',
  TypeTags = 'typeTags',
  ReportsTo = 'reportsToId',
  Trades = 'tradeIds',
  Roles = 'roleIds',
  Address = 'address',
  Country = 'country',
  City = 'city',
  State = 'state',
  ZipCode = 'zipCode',
  ResponsibleContacts = 'responsibleContactIds',
  DBA = 'dba',
  LicenseNumber = 'licenseNumber',
}

export default CompanyFieldId
