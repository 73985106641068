import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action } from 'mobx'

import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import { ITreeNode } from './Tree'

export interface IProps {
  node: ITreeNode
  onNodeExpand(node: ITreeNode): void
  onNodeChecked(node: ITreeNode): void
}

export default class TreeNode extends React.Component<IProps> {
  public render() {
    const {
      isCheckboxDisplayed,
      isChecked,
      isExpanded,
      children,
      component,
      onOnlyClicked,
      hasOnly,
    } = this.props.node

    const hasChildren = children && !!children.length
    const icon = isExpanded ? IconNames.CHEVRON_DOWN : IconNames.CHEVRON_UP

    return (
      <div className="row y-start mt5 only-holder">
        <div className="no-grow col y-start mr5">
          {isCheckboxDisplayed && !hasOnly && (
            <Checkbox isChecked={isChecked} onClick={this.onCheckboxClick} />
          )}
          {hasOnly && (
            <div
              className="only-button"
              onClick={onOnlyClicked.bind(this, this.props.node)}
            >
              {Localization.translator.only}
            </div>
          )}
        </div>
        <div className="no-grow mr5">
          {isCheckboxDisplayed && hasOnly && (
            <Checkbox isChecked={isChecked} onClick={this.onCheckboxClick} />
          )}
          {hasChildren && (
            <div className="col">
              <Icon icon={icon} onClick={this.toggleChildren} />
            </div>
          )}
        </div>
        <div className="col">
          <div>{component}</div>
          {isExpanded && (
            <div className="child-block">
              {children.map((node, idx) => (
                <TreeNode
                  node={node}
                  key={idx}
                  onNodeExpand={this.props.onNodeExpand}
                  onNodeChecked={this.props.onNodeChecked}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }

  @action.bound
  private onCheckboxClick() {
    this.props.onNodeChecked(this.props.node)
  }

  @action.bound
  private toggleChildren(event) {
    event.preventDefault()
    event.stopPropagation()

    this.props.onNodeExpand(this.props.node)
  }
}
