import * as React from 'react'

import { Radio, RadioGroup } from '@blueprintjs/core'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import FilterFooter from '~/client/src/desktop/components/Filters/FilterFooter/FilterFooter'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import * as Icons from '~/client/src/shared/components/Icons'
import { Loader } from '~/client/src/shared/components/Loader'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import BaseGroupingOptionsStore from '../../BaseGroupingOptions.store'
import CustomBandCreation from './CustomBandCreation'

// localization: translated

interface IProps {
  store: BaseGroupingOptionsStore
  state?: DesktopInitialState
  shouldHideBandCreation?: boolean
}

const LOADER_SIZE = 50

@inject('state')
@observer
export default class BaseGroupingOptionsPopup extends React.Component<IProps> {
  public componentDidMount() {
    this.props.store.initPopup()
  }

  public render() {
    const { shouldHideBandCreation } = this.props
    const {
      areDefaultOptionsSelected,
      resetGroupingOptions,
      changeSelectedBands,
      selectedBandsOptionIdDraft,
      applyGroupingOptions,
      hideGroupingOptions,
      leftCaptionKey,
      rightCaptionKey,
      shouldIndent,
      isLoading,
      bandsOptions,
      doesGroupingAlreadyExist,
    } = this.props.store

    return (
      <div className="col filter-container grouping-options-container no-outline-container">
        {isLoading && (
          <Loader
            size={LOADER_SIZE}
            className="row x-center y-center full-height full-width absolute z-index-100 bg-white"
          />
        )}
        <div className="px20 py10 bb-light-grey grouping-options-container-body scrollable relative col">
          <button
            className={classList({
              'row x-between reset-button pointer brada2 px5 mb10': true,
              'inactive-element': areDefaultOptionsSelected,
            })}
            onClick={resetGroupingOptions}
          >
            {Localization.translator.reset}
          </button>
          <div className="text bold large pb5 mb10 bb-light-grey">
            {Localization.getText(leftCaptionKey)}
            <span className="float-right">
              {rightCaptionKey && Localization.getText(rightCaptionKey)}
            </span>
          </div>
          <RadioGroup
            className="theme-radio-group mb20 pr15 col scrollable relative min-height130"
            onChange={changeSelectedBands}
            selectedValue={selectedBandsOptionIdDraft}
          >
            {bandsOptions.map(
              ({
                id,
                name,
                nameTraslatorKey,
                num,
                disabled,
                canDelete,
                deletionIndex,
              }) => {
                const displayName = nameTraslatorKey
                  ? Localization.getText(nameTraslatorKey)
                  : name
                return (
                  <Radio
                    key={id + displayName + num}
                    value={id}
                    className={classList({
                      small: true,
                      'inactive-element': disabled,
                      'with-indent': shouldIndent(id),
                    })}
                    disabled={disabled}
                  >
                    {displayName}
                    {this.renderAdditionalInfo(deletionIndex, num, canDelete)}
                  </Radio>
                )
              },
            )}
          </RadioGroup>
          {!shouldHideBandCreation && (
            <>
              <CustomBandCreation store={this.props.store} />
              {this.renderEmptyBandsSection()}
              {doesGroupingAlreadyExist && (
                <div className="mb10 text red">
                  {Localization.translator.thisGroupAlreadyExists}
                </div>
              )}
            </>
          )}
        </div>
        <FilterFooter
          clickOnApply={applyGroupingOptions}
          clickOnCancel={hideGroupingOptions}
        />
      </div>
    )
  }

  private renderEmptyBandsSection() {
    const {
      shouldShowEmptyBandsSection,
      showEmptyBandsDraft,
      onShowEmptyWbsChange,
    } = this.props.store
    if (!shouldShowEmptyBandsSection) {
      return null
    }

    return (
      <>
        <div className="text bold large pb5 mb10 bb-light-grey">
          {Localization.translator.groupByOptions}
        </div>
        <div className="mb10">
          <Checkbox
            label={Localization.translator.showEmptyBandsLevels}
            isChecked={showEmptyBandsDraft}
            onClick={onShowEmptyWbsChange}
          />
        </div>
      </>
    )
  }

  private renderAdditionalInfo(
    deletionIndex: number,
    num: number,
    canDelete: boolean,
  ): JSX.Element {
    const { isAdmin } = this.props.state.userActiveProjectSettings
    const { deleteCustomGrouping } = this.props.store

    if (isAdmin && canDelete) {
      return (
        <span
          className="float-right pointer"
          onClick={deleteCustomGrouping.bind(this, deletionIndex)}
        >
          <Icons.Delete className="no-grow" />
        </span>
      )
    }

    return <span className="float-right">{num}</span>
  }
}
