import * as React from 'react'

import { Position, Tooltip } from '@blueprintjs/core'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { LocationType } from '~/client/graph'
import MapViewItemDrawnPart from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemDrawnPart'
import * as TagIcon from '~/client/src/shared/components/TagIcon'
import MapViewItemType from '~/client/src/shared/enums/MapViewItemType'
import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import MapViewSetUpStore from '../MapViewSetUp.store'
import { ViewType } from '../stores/MapBoxEditor.store'

import Colors from '~/client/src/shared/theme.module.scss'

const ICON_SIZE = 24
const gateEntry = 'Gate/Entry'
const textSticker = 'Text sticker'
const drawingSticker = 'Drawing sticker'

interface IObject {
  Icon: React.FC<any>
  getTooltip?: () => string
  tooltipPosition?: Position
  dataObjectType?: LocationType
  iconName?: MapViewLocationIcon
  sitemapItemType?: MapViewItemType
  className?: string
  children?: IObject[]
}

export interface IProps {
  store?: MapViewSetUpStore
}

@observer
export default class ObjectPallet extends React.Component<IProps> {
  private readonly objects: IObject[] = [
    {
      Icon: TagIcon.Building,
      dataObjectType: LocationType.Building,
      getTooltip: () => Localization.translator.building,
      className: 'left-rounded',
    },
    {
      Icon: TagIcon.Zone,
      dataObjectType: LocationType.Zone,
      getTooltip: () => Localization.translator.zone,
    },
    {
      Icon: TagIcon.Staging,
      dataObjectType: LocationType.Staging,
      getTooltip: () => Localization.translator.staging,
    },
    {
      Icon: TagIcon.Area,
      dataObjectType: LocationType.Area,
      getTooltip: () => Localization.translator.workArea,
    },
    {
      Icon: TagIcon.Stairs,
      dataObjectType: LocationType.VerticalObject,
      iconName: MapViewLocationIcon.Stairs,
      getTooltip: () => Localization.translator.verticalObject,
      tooltipPosition: Position.TOP,
      className: 'ml10 fully-rounded',
      children: [
        {
          Icon: TagIcon.Stairs,
          dataObjectType: LocationType.VerticalObject,
          iconName: MapViewLocationIcon.Stairs,
          getTooltip: () => Localization.translator.stairway,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Elevator,
          dataObjectType: LocationType.VerticalObject,
          iconName: MapViewLocationIcon.Elevator,
          getTooltip: () => Localization.translator.elevator,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Shaft,
          dataObjectType: LocationType.VerticalObject,
          iconName: MapViewLocationIcon.Shaft,
          getTooltip: () => Localization.translator.shaft,
          tooltipPosition: Position.RIGHT,
        },
      ],
    },
    {
      Icon: TagIcon.Route,
      dataObjectType: LocationType.Route,
      getTooltip: () => Localization.translator.route,
      className: 'ml10',
    },
    {
      Icon: TagIcon.Gate,
      dataObjectType: LocationType.Gate,
      getTooltip: () => gateEntry,
    },
    {
      Icon: TagIcon.InteriorPath,
      dataObjectType: LocationType.InteriorPath,
      getTooltip: () => Localization.translator.interiorPath,
    },
    {
      Icon: TagIcon.InteriorDoor,
      dataObjectType: LocationType.InteriorDoor,
      getTooltip: () => Localization.translator.interiorDoor,
    },
    {
      Icon: TagIcon.Equipment,
      dataObjectType: LocationType.OffloadingEquipment,
      iconName: MapViewLocationIcon.Equipment,
      getTooltip: () => Localization.translator.sharedResources,
      tooltipPosition: Position.TOP,
      className: 'ml10',
      children: [
        {
          Icon: TagIcon.Crane,
          dataObjectType: LocationType.OffloadingEquipment,
          iconName: MapViewLocationIcon.Crane,
          getTooltip: () => Localization.translator.crane,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Hoist,
          dataObjectType: LocationType.OffloadingEquipment,
          iconName: MapViewLocationIcon.Hoist,
          getTooltip: () => Localization.translator.hoist,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.AerialLift,
          dataObjectType: LocationType.OffloadingEquipment,
          iconName: MapViewLocationIcon.AerialLift,
          getTooltip: () => Localization.translator.aerialLift,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Gradall,
          dataObjectType: LocationType.OffloadingEquipment,
          iconName: MapViewLocationIcon.Gradall,
          getTooltip: () => Localization.translator.gradall,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.ForkLift,
          dataObjectType: LocationType.OffloadingEquipment,
          iconName: MapViewLocationIcon.ForkLift,
          getTooltip: () => Localization.translator.forkLift,
          tooltipPosition: Position.RIGHT,
        },
      ],
    },
    {
      Icon: TagIcon.LogisticsObject,
      dataObjectType: LocationType.LogisticsObject,
      iconName: MapViewLocationIcon.Logistics,
      getTooltip: () => Localization.translator.informationPoints,
      tooltipPosition: Position.TOP,
      className: 'right-rounded',
      children: [
        {
          Icon: TagIcon.Bathroom,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Bathroom,
          getTooltip: () => Localization.translator.restrooms,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Break,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Break,
          getTooltip: () => Localization.translator.breakText,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Canteen,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Canteen,
          getTooltip: () => Localization.translator.canteen,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Dumpster,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Dumpster,
          getTooltip: () => Localization.translator.dumpster,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Entrance,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Entrance,
          getTooltip: () => Localization.translator.access,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.HandWash,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.HandWash,
          getTooltip: () => Localization.translator.washArea,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Medical,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Medical,
          getTooltip: () => Localization.translator.medicalArea,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.MeetingPoint,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.MeetingPoint,
          getTooltip: () => Localization.translator.musterPoint,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Parking,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Parking,
          getTooltip: () => Localization.translator.parking,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Smoking,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Smoking,
          getTooltip: () => Localization.translator.smokingArea,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Temperature,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Temperature,
          getTooltip: () => Localization.translator.covidCheckpoint,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Tent,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Tent,
          getTooltip: () => Localization.translator.breakArea,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Toilet,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Toilet,
          getTooltip: () => Localization.translator.toilet,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Walkway,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Walkway,
          getTooltip: () => Localization.translator.walkway,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.ElectricalRoom,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.ElectricalRoom,
          getTooltip: () => Localization.translator.electricalRoom,
          tooltipPosition: Position.RIGHT,
        },
        {
          Icon: TagIcon.Trailer,
          dataObjectType: LocationType.LogisticsObject,
          iconName: MapViewLocationIcon.Trailer,
          getTooltip: () => Localization.translator.trailer,
          tooltipPosition: Position.RIGHT,
        },
      ],
    },
    {
      Icon: TagIcon.Text,
      sitemapItemType: MapViewItemType.TextBox,
      getTooltip: () => textSticker,
      className: 'ml10 left-rounded',
    },
    {
      Icon: TagIcon.Line,
      sitemapItemType: MapViewItemType.Line,
      getTooltip: () => drawingSticker,
      className: 'right-rounded',
    },
  ]

  public render() {
    return (
      <div
        className={classList({
          'object-pallet absolute': true,
          'inactive-element':
            this.props.store.mapViewItemsSetupStore.isSitemapBlocked,
        })}
      >
        {this.objects.map((object, idx) => {
          return (
            <div
              className={classList({
                'object relative': true,
                [object.className]: !!object.className,
              })}
              key={idx}
            >
              {this.renderObject(object, idx)}
              {this.renderObjectChildren(object)}
            </div>
          )
        })}
      </div>
    )
  }

  private renderObject = (object: IObject, key: React.Key): JSX.Element => {
    const isHighlighted = this.shouldHighlightObject(object)
    const color = isHighlighted ? Colors.neutral0 : Colors.neutral60

    const content = (
      <div
        key={key}
        className="object-icon"
        onClick={this.onObjectClicked.bind(this, object)}
      >
        <object.Icon color={color} size={ICON_SIZE} />
        {object.children && <div className="arrow" />}
      </div>
    )

    if (!object.getTooltip) {
      return content
    }

    return (
      <Tooltip
        key={key}
        className="bp3-dark"
        content={object.getTooltip()}
        position={object.tooltipPosition || Position.BOTTOM}
      >
        {content}
      </Tooltip>
    )
  }

  private renderObjectChildren = (object: IObject): JSX.Element => {
    if (!object.children) {
      return null
    }

    return (
      <div className="object-children absolute">
        {object.children.map((childObject, childIdx) => {
          return this.renderObject(childObject, childIdx)
        })}
      </div>
    )
  }

  private onObjectClicked(object: IObject) {
    if (!this.props.store) {
      return
    }
    const { mapViewItemsSetupStore, isGlobeMode, onViewTypeSelect } =
      this.props.store
    if (mapViewItemsSetupStore.isSitemapBlocked) {
      return
    }
    const { dataObjectType, sitemapItemType, iconName } = object

    if (isGlobeMode) {
      onViewTypeSelect(ViewType.Objects)
    }
    if (sitemapItemType && sitemapItemType === MapViewItemType.Line) {
      mapViewItemsSetupStore.createDataLessItem(sitemapItemType)
      mapViewItemsSetupStore.selectSitemapItemDrawnPart(
        MapViewItemDrawnPart.Shape,
      )
      return
    }
    if (sitemapItemType && sitemapItemType === MapViewItemType.TextBox) {
      mapViewItemsSetupStore.enableCreatingText()
      return
    }
    if (dataObjectType) {
      mapViewItemsSetupStore.enableCreatingAttribute(dataObjectType, iconName)
      return
    }
  }

  private shouldHighlightObject(object: IObject) {
    if (!this.props.store) {
      return
    }
    const {
      selectedMapViewItem,
      creatableAttributeType,
      creatableAttributeIcon,
      isTextStickerCreationActive,
    } = this.props.store.mapViewItemsSetupStore

    const { sitemapItemType, dataObjectType, iconName } = object

    if (sitemapItemType === MapViewItemType.TextBox) {
      return isTextStickerCreationActive
    }

    if (
      sitemapItemType &&
      selectedMapViewItem &&
      !selectedMapViewItem.id &&
      selectedMapViewItem.sitemapItem.type === sitemapItemType
    ) {
      return true
    }

    return (
      creatableAttributeType === dataObjectType &&
      creatableAttributeIcon === iconName
    )
  }
}
