import * as React from 'react'

import TooltipWrapper from '~/client/src/desktop/components/TooltipWrapper/TooltipWrapper'
import * as Icons from '~/client/src/shared/components/Icons'

interface IProps {
  tooltipContent: string | JSX.Element
}

export default function InfoIconWithTooltip(props: IProps) {
  return (
    <TooltipWrapper className="row no-grow" content={props.tooltipContent}>
      <Icons.Info className="row no-grow" />
    </TooltipWrapper>
  )
}
