import { action, computed } from 'mobx'

import { IUser, InviteStatus, ProjectAccessType } from '~/client/graph'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import { ILWFCColumn } from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import { InviteStatusCaption } from '~/client/src/shared/enums/InviteStatusCaption'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import User from '~/client/src/shared/models/User'
import UserProject from '~/client/src/shared/models/UserProject'
import { IProcoreUser } from '~/client/src/shared/services/ProcoreService'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import BaseListStore from '~/client/src/shared/stores/ui/BaseList.store'
import { IServiceUserDto } from '~/client/src/shared/types/UserDto'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import { sortCaseInsensitive } from '~/client/src/shared/utils/collections'
import { getFormattedPhoneNumberForSubmission } from '~/client/src/shared/utils/phoneNumberHelpers'

import { DataKeys } from '../../ProjectMembersList.store'

export interface IProcoreUserView extends IProcoreUser {
  uid: string // Company: project email
  name: string
  companyName: string
  accountType?: ProjectAccessType
  struxhubUser?: User
}

const ACCOUNT_TYPE_OPTIONS = [
  ProjectAccessType.Owner,
  ProjectAccessType.Admin,
  ProjectAccessType.Member,
]

export default class ProcoreDirectoryDialogStore extends BaseListStore<IProcoreUserView> {
  public readonly columns: ILWFCColumn[] = [
    {
      dataKey: DataKeys.CHECKBOX,
      width: 60,
    },
    {
      translatorKey: KnownTranslatorKeys.name,
      dataKey: DataKeys.NAME,
      width: 150,
    },
    {
      translatorKey: KnownTranslatorKeys.role,
      dataKey: DataKeys.ROLES,
      width: 150,
    },
    {
      translatorKey: KnownTranslatorKeys.email_noun,
      dataKey: DataKeys.EMAIL,
      width: 250,
    },
    {
      translatorKey: KnownTranslatorKeys.phone,
      dataKey: DataKeys.PHONE,
      width: 200,
    },
    {
      translatorKey: KnownTranslatorKeys.accountType,
      dataKey: DataKeys.ACCOUNT_TYPE,
      width: 140,
    },
    {
      translatorKey: KnownTranslatorKeys.status,
      dataKey: DataKeys.STATUS,
      width: 120,
    },
  ]

  public get selectedUsersCount() {
    return this.selection.size
  }

  public constructor(
    private readonly state: DesktopInitialState,
    sourceCollection: () => IProcoreUserView[],
    private readonly userProjectsStore: UserProjectsStore,
  ) {
    super({ searchKey: '', groupingKey: 'companyName' }, sourceCollection, [
      'name',
    ])
  }

  public get isAllSelected() {
    return this.rows.every(row => !row.category || row.category.isChecked)
  }

  public getSelectedUsers(): IServiceUserDto[] {
    return this.filteredCollection
      .filter(({ uid }) => this.selection.get(uid))
      .map(procoreUser => {
        const userDto = {
          id: null,
          email: procoreUser.email,
          firstName: procoreUser.firstName,
          globalRole: procoreUser.jobTitle,
          lastName: procoreUser.lastName,
          phoneNumber: getFormattedPhoneNumberForSubmission(
            procoreUser.mobilePhone,
          ),
        } as IUser

        return Object.assign(User.fromDto(userDto), {
          externalCompanyName: procoreUser.companyName,
          userProjectSettings: new UserProject(
            null,
            this.state.activeProject.id,
            null,
            InviteStatus.NotInvited,
            this.getAccountType(procoreUser),
          ).toDto(),
        })
      })
  }

  @action.bound
  public changeAccountType(userId: string, currentType: ProjectAccessType) {
    const nextTypeIndex =
      ACCOUNT_TYPE_OPTIONS.findIndex(option => option === currentType) + 1
    const userToUpdate = this.filteredCollection.find(
      ({ uid }) => uid === userId,
    )
    userToUpdate.accountType =
      ACCOUNT_TYPE_OPTIONS[nextTypeIndex] || ACCOUNT_TYPE_OPTIONS[0]
  }

  public canSelectInstance(inst: IProcoreUserView) {
    // allow to select users not added yet
    return !inst || !inst.struxhubUser
  }

  protected setInstanceSelection(userId: string, newState: boolean) {
    if (!newState) {
      this.selection.delete(userId)
      return
    }

    const userToSelect = this.filteredCollection.find(
      ({ uid }) => uid === userId,
    )
    // allow to select only one user with such email
    Array.from(this.selection.keys()).forEach(uid => {
      if (uid.endsWith(userToSelect.email)) {
        this.selection.delete(uid)
      }
    })

    if (this.canSelectInstance(userToSelect)) {
      this.selection.set(userId, true)
    }
  }

  @computed
  protected get categoryToInstancesMap() {
    const map = {
      [UNASSIGNED]: [],
    }

    this.filteredCollection.forEach(member => {
      const mapKey = member.companyName
      if (!mapKey) {
        map[UNASSIGNED].push(member)
        return
      }

      if (map[mapKey] && !map[mapKey].includes(member)) {
        map[mapKey].push(member)
      } else {
        map[mapKey] = [member]
      }
    })

    return map
  }

  protected sortingRules(keys: string[]): string[] {
    return sortCaseInsensitive(keys).sort((a: string, b: string) => {
      return a.toLowerCase().localeCompare(b.toLowerCase())
    })
  }

  protected toRows(users: IProcoreUserView[]) {
    return users.map(user => {
      return {
        data: {
          [DataKeys.ID]: user.uid,
          [DataKeys.CHECKBOX]: this.selection.get(user.uid),
          [DataKeys.NAME]: user.name,
          [DataKeys.ROLES]: user.jobTitle,
          [DataKeys.ACCOUNT_TYPE]: this.getAccountType(user),
          [DataKeys.EMAIL]: user.email,
          [DataKeys.PHONE]: user.mobilePhone,
          [DataKeys.PROJECTS]: user.struxhubProjects,
          [DataKeys.STATUS]: this.getInviteStatusCaption(user.struxhubUser),
          user,
        },
      }
    })
  }

  private getAccountType(user: IProcoreUserView) {
    if (user.struxhubUser) {
      return UserProject.getAccessType(
        user.struxhubUser,
        this.userProjectsStore,
      )
    }

    return user.accountType || ProjectAccessType.Member
  }

  private getInviteStatusCaption(struxhubUser?: User): InviteStatusCaption {
    if (struxhubUser) {
      const inviteStatus = UserProject.getInviteStatus(
        struxhubUser,
        this.userProjectsStore,
      )

      if (inviteStatus === InviteStatus.NotInvited) {
        return InviteStatusCaption.Added
      }
      return inviteStatus
    }

    return InviteStatusCaption.NotAdded
  }
}
