import * as React from 'react'

import { observer } from 'mobx-react'

import PurpleConfirm from '~/client/src/desktop/components/PurpleConfirm/PurpleConfirm'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import PresentationSetUpStore from '../PresentationSetUp.store'

export interface IProps {
  store: PresentationSetUpStore
}

@observer
export default class ConfirmationPresentationDialog extends React.Component<IProps> {
  public render() {
    const {
      isConfirmationPresentationDialogShown,
      hideConfirmationPresentationDialog,
      startPresentation,
    } = this.props.store
    return (
      <PurpleConfirm
        title={Localization.translator.presentationMode.toUpperCase()}
        actionTitle={Localization.translator.play}
        className="confirmation-presentation-dialog"
        isShown={isConfirmationPresentationDialogShown}
        onApply={startPresentation}
        onHide={hideConfirmationPresentationDialog}
      >
        {Localization.translator.presentationModeDescriptions.confirmText}
      </PurpleConfirm>
    )
  }
}
