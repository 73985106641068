import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import BaseObservableTable from '~/client/src/desktop/components/BaseObservableTable/BaseObservableTable'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import { Loader } from '~/client/src/shared/components/Loader'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import { MATERIAL_CATEGORIES_RECEIVED } from '~/client/src/shared/stores/EventStore/eventConstants'
import MaterialCategoryStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import MaterialsStore from '~/client/src/shared/stores/domain/Materials.store'

import { IDeliveryPulldownSettings } from './IDeliverySetting'
import MaterialCategoryEditorStore, {
  DataKeys,
} from './MaterialCategoryEditor.store'

import './MaterialCategoryEditor.scss'

const csiMaterialCategories = 'CSI Material Categories'
const csiMaterialsSubCategories = 'CSI Materials Sub-categories'

interface IProps {
  pullDownRenderer: (
    fieldName: string,
    deliveryToggleSettings: IDeliveryPulldownSettings,
    isBlockHidden: boolean,
    shouldBlockMandatorySelection?: boolean,
  ) => JSX.Element

  eventsStore?: DesktopEventStore
  materialsStore?: MaterialsStore
  materialCategoryStore?: MaterialCategoryStore
}

@inject('eventsStore', 'materialsStore', 'materialCategoryStore')
@observer
export default class MaterialCategoryEditor extends React.Component<IProps> {
  private store: MaterialCategoryEditorStore
  private clearPostEventCallback: () => void

  public constructor(props: IProps) {
    super(props)

    this.store = new MaterialCategoryEditorStore(
      props.eventsStore,
      props.materialsStore,
      props.materialCategoryStore,
    )

    if (props.materialCategoryStore.isDataReceived) {
      this.collapseAll()
      return
    }

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      (context: EventContext) => {
        const [eventType] = context.event

        if (eventType === MATERIAL_CATEGORIES_RECEIVED) {
          this.collapseAll()
        }
      },
    )
  }

  public componentWillUnmount() {
    this.clearPostEventCallback?.()
  }

  public render() {
    const { isCSIDisabled } = this.store.settings

    if (isCSIDisabled) {
      return null
    }

    if (!this.store.settings) {
      return <Loader />
    }

    return (
      <div className="pa20 material-category-editor col large">
        {this.store.isSaving ? (
          <Loader />
        ) : (
          <>
            {this.renderCSIPulldown()}
            {this.renderCSISubPulldown()}
            {this.renderCategoriesTable()}
          </>
        )}
      </div>
    )
  }

  private renderCSIPulldown() {
    return this.renderPulldown(
      csiMaterialCategories,
      FieldIds.MATERIAL_CATEGORY,
      this.store.csiCategoryPulldownSettings,
    )
  }

  private renderCSISubPulldown() {
    const {
      isCSICategoryOptional,
      csiSubPulldownSettings,
      isCSISubCategoriesDisabled,
    } = this.store

    return this.renderPulldown(
      csiMaterialsSubCategories,
      FieldIds.MATERIAL,
      csiSubPulldownSettings,
      isCSISubCategoriesDisabled,
      isCSICategoryOptional,
    )
  }

  private renderPulldown(
    label: string,
    fieldName: string,
    pullDownSettings: IDeliveryPulldownSettings,
    isHidden: boolean = false,
    shouldBlockMandatorySelection?: boolean,
  ): JSX.Element {
    return (
      <div className="mb24 row">
        <div className="text light large nowrap mr20">{label}</div>
        {this.props.pullDownRenderer(
          fieldName,
          pullDownSettings,
          isHidden,
          shouldBlockMandatorySelection,
        )}
      </div>
    )
  }

  private renderCategoriesTable() {
    const { isCSISubCategoriesDisabled } = this.store.settings
    return (
      <div className="material-category-table flex-item">
        <BaseObservableTable
          store={this.store}
          autoSizerClassName="full-width full-height"
          dataCellCustomClassName={classList({
            'data-cell pl10 no-select pointer': true,
            'inactive-element': isCSISubCategoriesDisabled,
          })}
          valueRenderer={this.renderValue}
          renderCategoryLabel={this.renderCategoryLabel}
          categoryRowCustomClassName="row text bold category-cell full-height no-select pointer"
          renderMainCheckbox={this.renderSelectAll}
        />
      </div>
    )
  }

  private renderSelectAll = () => {
    return (
      <Checkbox
        isCentered={true}
        isChecked={this.store.isAllSelected}
        onClick={this.store.toggleAll}
      />
    )
  }

  private renderValue = (value: any, dataKey: string) => {
    switch (dataKey) {
      case DataKeys.NAME:
        return value

      case DataKeys.CHECKBOX:
        return <Checkbox isCentered={true} isChecked={value} />

      default:
        return null
    }
  }

  private renderCategoryLabel = (categoryLabel: string) => {
    const countTextIndex = categoryLabel.lastIndexOf('(')
    const categoryName = categoryLabel.slice(0, countTextIndex)
    const countText = categoryLabel.slice(countTextIndex)

    return (
      <>
        <span className="ml10 mr10">{categoryName}</span>
        <span className="no-grow ml10 mr30">{countText}</span>
      </>
    )
  }

  private collapseAll() {
    this.props.materialCategoryStore.list.forEach(c => {
      this.store.collapsedCategories.set(c.id, true)
    })
  }
}
