import { enumToList } from '~/client/src/shared/utils/converters'

import Localization from '../../shared/localization/LocalizationManager'

enum ScanStationFilterType {
  SCANNER = 'scanner',
  COMPANY = 'company',
}

export const getScanStationFilterTypeCaption = (
  type: ScanStationFilterType,
) => {
  switch (type) {
    case ScanStationFilterType.SCANNER:
      return Localization.translator.scannerName
    case ScanStationFilterType.COMPANY:
      return Localization.translator.company
    default:
      throw new Error(type + ' is unhandled')
  }
}

export const scanStationFilterTypes = enumToList(ScanStationFilterType)

export default ScanStationFilterType
