import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { IReactionDisposer, action, observable, reaction } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import EntityNameFilter from '~/client/src/desktop/components/Filters/EntityNameFilter/EntityNameFilter'
import BaseHeaderBar from '~/client/src/desktop/components/HeaderBar/HeaderBar'
import ColumnsConfigurationMenu from '~/client/src/desktop/components/HeaderBar/components/ColumnsConfigurationMenu'
import ReportModal from '~/client/src/desktop/components/HeaderBar/components/ReportModal'
import MaterialsFilterType from '~/client/src/desktop/enums/MaterialsFilterType'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import DynamicOverflowListStore from '~/client/src/shared/components/DynamicOverflowList/DynamicOverflowList.store'
import * as Icons from '~/client/src/shared/components/Icons'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import MaterialsGroupingOption, {
  getMaterialsGroupingOptionTranslatorKey,
  materialsGroupingOptionList,
} from '~/client/src/shared/enums/MaterialsGroupingOption'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import AreasStore from '~/client/src/shared/stores/domain/Areas.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import LevelsStore from '~/client/src/shared/stores/domain/Levels.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import LogisticsObjectsStore from '~/client/src/shared/stores/domain/LogisticsObjects.store'
import MaterialCategoryStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import MaterialConfigurationStore from '~/client/src/shared/stores/domain/MaterialConfiguration.store'
import MaterialsStore from '~/client/src/shared/stores/domain/Materials.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import VerticalObjectsStore from '~/client/src/shared/stores/domain/VerticalObjects.store'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import MaterialsListStore, {
  DataKeys,
} from '../MaterialsList/MaterialsList.store'
import MaterialsHeaderBarStore from './MaterialsHeaderBar.store'
import MaterialReportButton from './components/MaterialReportButton/MaterialReportButton'
import MaterialsFilter from './components/MaterialsFilter/MaterialsFilter'
import MaterialsFilterStore from './components/MaterialsFilter/MaterialsFilter.store'
import MaterialsGroupingOptions from './components/MaterialsGroupingOptions/MaterialsGroupingOptions'
import MaterialsGroupingOptionsStore from './components/MaterialsGroupingOptions/MaterialsGroupingOptions.store'

interface IProps {
  state?: DesktopInitialState
  eventsStore?: DesktopEventStore
  materialsListStore: MaterialsListStore
  materialsStore?: MaterialsStore
  materialCategoryStore?: MaterialCategoryStore
  locationAttributesStore?: LocationAttributesStore
  levelsStore?: LevelsStore
  areasStore?: AreasStore
  logisticsObjectsStore?: LogisticsObjectsStore
  verticalObjectsStore?: VerticalObjectsStore
  companiesStore?: CompaniesStore
  tagsStore?: TagsStore
  materialConfigurationStore?: MaterialConfigurationStore
}

const ICON_SIZE = 16

@inject(
  'state',
  'eventsStore',
  'materialsStore',
  'materialCategoryStore',
  'locationAttributesStore',
  'levelsStore',
  'areasStore',
  'logisticsObjectsStore',
  'verticalObjectsStore',
  'companiesStore',
  'tagsStore',
  'materialConfigurationStore',
)
@observer
export default class MaterialsHeaderBar extends BaseHeaderBar<IProps> {
  protected store: MaterialsHeaderBarStore = null
  private readonly groupingOptionsStore: MaterialsGroupingOptionsStore = null
  private readonly filterStore: MaterialsFilterStore = null
  private readonly dynamicOverflowListStore: DynamicOverflowListStore = null

  private disposeConfigUpdateReaction: IReactionDisposer
  @observable private isMenuShown: boolean = false

  public constructor(props: IProps) {
    super(props)

    this.dynamicOverflowListStore = new DynamicOverflowListStore()

    this.store = new MaterialsHeaderBarStore(props.eventsStore)

    const { eventsStore } = props

    const { onShowGroupByChanged, onShowChanged } = this.store

    this.filterStore = new MaterialsFilterStore(
      props.eventsStore,
      props.materialsStore,
      props.materialsListStore,
      onShowChanged,
      props.locationAttributesStore,
      props.levelsStore,
      props.areasStore,
      props.logisticsObjectsStore,
      props.verticalObjectsStore,
      props.companiesStore,
      props.tagsStore,
      props.materialCategoryStore,
      this.dynamicOverflowListStore.enableRecalculation,
    )

    this.groupingOptionsStore = new MaterialsGroupingOptionsStore(
      eventsStore,
      onShowGroupByChanged,
      this.onGroupingChanged,
      eventsStore.appState.materialFilters,
      materialsGroupingOptionList,
      MaterialsGroupingOption.NONE,
      eventsStore.appState.materialFiltersSettings.desktopFilterMap,
      getMaterialsGroupingOptionTranslatorKey,
      props.materialConfigurationStore,
    )
  }

  public componentDidMount() {
    this.store.loadReportTemplate()

    this.disposeConfigUpdateReaction = reaction(
      () => this.props.materialConfigurationStore.configuration,
      this.groupingOptionsStore.resetGroupingIfNeed,
      { fireImmediately: false },
    )
  }

  public componentWillUnmount() {
    this.disposeConfigUpdateReaction?.()
  }

  protected renderLeftSection() {
    const { forceSearchClose, onShowSearchChanged } = this.store

    return (
      <div className="row x-start full-height left-bar no-grow">
        <EntityNameFilter
          filters={this.props.state.materialFilters}
          forceClose={forceSearchClose}
          onShowChanged={onShowSearchChanged}
        />
        {this.renderGroupingOptions()}
      </div>
    )
  }

  protected renderCenterSection() {
    const { eventsStore } = this.props
    return (
      <MaterialsFilter
        store={this.filterStore}
        forceCloseMap={this.store.forceCloseMap}
        eventsStore={eventsStore}
        filters={eventsStore.appState.materialFilters}
        dynamicOverflowListStore={this.dynamicOverflowListStore}
      />
    )
  }

  protected renderRightSection() {
    const { filterStoresByTypeMap } = this.filterStore
    return (
      <MaterialReportButton
        title={Localization.translator.createReport}
        materialsListStore={this.props.materialsListStore}
        className="ml10"
        toggleModal={this.toggleModal}
        shouldPreventCreation={true}
        filterStoresByTypeMap={filterStoresByTypeMap}
      />
    )
  }

  @action.bound
  private renderReportButton() {
    const { filterStoresByTypeMap } = this.filterStore
    const { additionalReportFiles, isAnyFileTypeChecked } = this.store
    return (
      <MaterialReportButton
        title={Localization.translator.createReport}
        materialsListStore={this.props.materialsListStore}
        className="ml10"
        toggleModal={this.toggleModal}
        shouldHideIcon={true}
        shouldInvertColors={true}
        filterStoresByTypeMap={filterStoresByTypeMap}
        additionalReportFiles={additionalReportFiles}
        isEnabled={isAnyFileTypeChecked}
      />
    )
  }

  @action.bound
  protected renderModal() {
    const { filterStoresByTypeMap } = this.filterStore
    const { additionalReportFiles, toggleAdditionalReportFiles } = this.store
    const now = new Date()

    return (
      <ReportModal
        startDate={now}
        endDate={now}
        toggleModal={this.toggleModal}
        getIconByFilter={this.getIconByFilter}
        filterStoresByTypeMap={filterStoresByTypeMap}
        renderButton={this.renderReportButton}
        reportName={Localization.translator.materialReport}
        additionalReportFiles={additionalReportFiles}
        toggleAdditionalReportFiles={toggleAdditionalReportFiles}
        groupedBy={this.groupingOptionsStore.groupByCaption}
        isFullReport
      />
    )
  }

  private getIconByFilter(filterType: string) {
    switch (filterType) {
      case MaterialsFilterType.STATUS:
        return <Icons.Info className="no-grow row mr10" />
      case MaterialsFilterType.MATERIAL:
        return <Icons.Material className="no-grow row mr10" />
      case MaterialsFilterType.COMPANY:
        return <Icons.CompanyCompact className="no-grow row mr10" />
      case MaterialsFilterType.PLANNED_INSTALL_LOCATION:
      case MaterialsFilterType.PLANNED_DELIVERY_LOCATION:
      case MaterialsFilterType.CURRENT_LOCATION:
        return <Icons.Location className="no-grow row mr10" />
    }
  }

  @action.bound
  private toggleMenu() {
    this.isMenuShown = !this.isMenuShown
  }

  protected renderActionBar() {
    const {
      displayedCount,
      isDataPrepared,
      isSomeRowSelected,
      selectedInstancesCount,
      canAddMaterialsToDelivery,
      isNewDeliveryBtnHidden,
      openNewFormAndInitIfNeed,
      isNewDeliveryBtnActive,
      collapseAllCategories,
      resetCollapsing,
    } = this.props.materialsListStore

    if (!isDataPrepared) {
      return null
    }

    return (
      <div className="row pb10 px24 bb-light-grey">
        <div className="row text large light">
          <div className="pr10 no-grow nowrap">
            {isSomeRowSelected
              ? Localization.translator.xOfYSelected(
                  selectedInstancesCount,
                  displayedCount,
                )
              : Localization.translator.xMaterialsShowing(displayedCount)}
          </div>
          <div className="row bl-light-cool-grey pl10">
            {this.renderCollapseBtn(collapseAllCategories)}
            {this.renderCollapseBtn(resetCollapsing, true)}
            {this.columnsConfiguration}
            {!isNewDeliveryBtnHidden && (
              <BaseActionButton
                className="ml8 primary-theme-inverted x-end no-select"
                isActive={isNewDeliveryBtnActive}
                isEnabled={canAddMaterialsToDelivery}
                title={Localization.translator.newDelivery}
                icon={<Icons.Truck />}
                onClick={openNewFormAndInitIfNeed}
              />
            )}
          </div>
        </div>
      </div>
    )
  }

  private get columnsConfiguration(): JSX.Element {
    if (!this.props.state.userActiveProjectSettings.isAdmin) {
      return null
    }

    const { searchKey, changeSearchKey } = this.store
    const { columns, updateColumnsConfiguration, resetColumnsConfiguration } =
      this.props.materialsListStore

    return (
      <div>
        <div className="row x-end">
          <div
            className={classList({
              'text grey large pointer no-grow icon-wrap': true,
              'blue unclickable-element': this.isMenuShown,
            })}
            onClick={this.toggleMenu}
            title={Localization.translator.configureTableColumns}
          >
            <Icon
              icon={IconNames.COG}
              size={ICON_SIZE}
              className={classList({
                'no-grow pa10 br-rounded highlighted-hover': true,
                'bg-blue-light': this.isMenuShown,
              })}
            />
          </div>
        </div>
        {this.isMenuShown && (
          <MenuCloser closeMenu={this.toggleMenu} className="row x-end mr40">
            <ColumnsConfigurationMenu
              columns={columns}
              restoreDefaults={resetColumnsConfiguration}
              searchKey={searchKey}
              changeSearchKey={changeSearchKey}
              updateColumnsConfiguration={updateColumnsConfiguration}
              excludedFromSort={[DataKeys.CHECKBOX, DataKeys.PROCUREMENT_ID]}
            />
          </MenuCloser>
        )}
      </div>
    )
  }

  private renderGroupingOptions() {
    return (
      <MaterialsGroupingOptions
        store={this.groupingOptionsStore}
        forceClose={this.store.forceGroupByClose}
      />
    )
  }

  private renderCollapseBtn(
    onClick: () => void,
    isExpand?: boolean,
  ): JSX.Element {
    return (
      <BaseActionButton
        className="mx4 gray-theme"
        isEnabled
        shouldShowBorder={false}
        title={EMPTY_STRING}
        icon={
          <Icon
            className="pl4"
            icon={isExpand ? IconNames.EXPAND_ALL : IconNames.COLLAPSE_ALL}
            size={ICON_SIZE}
          />
        }
        onClick={onClick}
      />
    )
  }

  private onGroupingChanged = () => {
    this.dynamicOverflowListStore.enableRecalculation()
    this.props.materialsListStore.resetCollapsing()
  }
}
