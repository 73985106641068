import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Table } from 'react-virtualized'

import Avatar from '~/client/src/shared/components/Avatar/Avatar'
import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import {
  ILWFCRow,
  LWFCRowData,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import SelectButton from '~/client/src/shared/components/SelectButton/SelectButton'
import SelectionPopUp, {
  SelectionPopUpOption,
} from '~/client/src/shared/components/SelectionPopUp/SelectionPopUp'
import UserProfilePreview from '~/client/src/shared/components/UserProfilePreview/UserProfilePreview'
import AvatarType from '~/client/src/shared/enums/AvatarType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import User from '~/client/src/shared/models/User'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import { SAVE_COMPANIES_SUCCESS } from '~/client/src/shared/stores/EventStore/eventConstants'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import WhiteListItemsStore from '~/client/src/shared/stores/domain/WhiteListItems.store'
import { NOOP } from '~/client/src/shared/utils/noop'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import DesktopEventStore from '../../stores/EventStore/DesktopEvents.store'
import BaseObservableTable from '../BaseObservableTable/BaseObservableTable'
import CompaniesListStore, { DataKeys } from './CompaniesList.store'

import './CompaniesList.scss'

// localization: translated

interface IProps {
  store: CompaniesListStore
  rows: ILWFCRow[]
  onEditClick: () => void
  initialScrollToId: string

  tagsStore?: TagsStore
  eventsStore?: DesktopEventStore
  whiteListItemsStore?: WhiteListItemsStore
}

const editIconSize = 14

@inject('tagsStore', 'eventsStore', 'whiteListItemsStore')
@observer
export default class CompaniesList extends React.Component<IProps> {
  private readonly clearPostEventCallback: () => void = null
  private tableRef: Table = null

  public constructor(props: IProps) {
    super(props)

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.onCompaniesUpdated,
    )
  }

  public componentDidMount() {
    this.props.whiteListItemsStore.requestAllItems()
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps.rows.length !== this.props.rows.length) {
      this.tableRef?.recomputeGridSize()
    }

    if (prevProps.initialScrollToId !== this.props.initialScrollToId) {
      this.store.scrollToInstance(this.props.initialScrollToId)
    }
  }

  public render() {
    const { getTableWidth, hideSelectionPopup, shouldShowSelectionPopup } =
      this.store

    return (
      <div className="companies-list-container full-height">
        <MenuCloser
          closeMenu={hideSelectionPopup}
          isOpen={shouldShowSelectionPopup}
        >
          <SelectionPopUp
            className="absolute bg-white ba-palette-brand-lightest z-index-1"
            isDisplayed={shouldShowSelectionPopup}
            options={this.selectionPopUpOptions}
          />
        </MenuCloser>

        <BaseObservableTable
          fixedWidth={getTableWidth()}
          store={this.store}
          autoSizerClassName="full-height full-width"
          valueRenderer={this.renderValue}
          renderMainCheckbox={this.renderMainCheckbox}
          setTableRef={this.setTableRef}
          handleCellClick={NOOP}
        />
      </div>
    )
  }

  public componentWillUnmount() {
    this.clearPostEventCallback?.()
    this.props.whiteListItemsStore.clear()
  }

  @computed
  private get selectionPopUpOptions(): SelectionPopUpOption[] {
    const { handleUnselectAll, handleSelectAll } = this.store

    return [
      {
        label: Localization.translator.selectAll_items,
        onClick: handleSelectAll,
      },
      {
        label: Localization.translator.clear,
        onClick: handleUnselectAll,
      },
    ]
  }

  public onCompaniesUpdated = (eventContext: EventContext) => {
    const [eventType] = eventContext.event

    if (eventType === SAVE_COMPANIES_SUCCESS) {
      this.tableRef?.recomputeGridSize()
    }
  }

  private setTableRef = (tableRef: Table) => {
    this.tableRef = tableRef
  }

  private get store(): CompaniesListStore {
    return this.props.store
  }

  private renderMainCheckbox = (): JSX.Element => {
    return <SelectButton onClick={this.store.toggleSelectionPopup} />
  }

  private renderValue = (
    value: any,
    dataKey: string,
    data: LWFCRowData,
  ): JSX.Element => {
    switch (dataKey) {
      case DataKeys.CHECKBOX:
        return (
          <Checkbox
            onClick={this.store.toggleInstance.bind(null, data[DataKeys.ID])}
            isCentered={true}
            isChecked={value}
          />
        )

      case DataKeys.NAME:
        return (
          <div
            className="row company-name-cell"
            onClick={this.handleEditClick.bind(this, data[DataKeys.ID])}
          >
            {value}
            <Icon
              icon={IconNames.EDIT}
              iconSize={editIconSize}
              className="edit-icon ml10"
            />
          </div>
        )

      case DataKeys.AVATAR:
        return <Avatar src={value} avatarType={AvatarType.Company} />

      case DataKeys.COMPANY_TYPE:
      case DataKeys.ROLES:
      case DataKeys.TRADES:
        return (
          BaseObservableTable.renderCellWithTags(
            this.props.tagsStore,
            dataKey,
            value,
          ) || <>{NO_VALUE}</>
        )

      case DataKeys.RESPONSIBLE_USERS:
        return this.renderUsers(value) || <>{NO_VALUE}</>

      case DataKeys.WHITELISTED_DOMAINS:
        const domains = value.join(', ')
        return domains ? (
          <div className="text-ellipsis" title={domains}>
            {domains}
          </div>
        ) : (
          <>{NO_VALUE}</>
        )

      default:
        return null
    }
  }

  private renderUsers(users: User[]): JSX.Element {
    if (!users.length) {
      return null
    }

    return (
      <div className="col text-ellipsis pr20">
        {users.map(user => (
          <UserProfilePreview key={user.id} className="py4" user={user} />
        ))}
      </div>
    )
  }

  private handleEditClick = (
    companyId: string,
    e: React.MouseEvent<HTMLElement>,
  ) => {
    e.stopPropagation()
    this.store.resetHiddenSelection()
    this.store.setHiddenInstanceAsLastSelected(companyId)
    this.props.onEditClick()
  }
}
