enum CDConfigurationFormFieldId {
  CDBaseURL = 'cDBaseURL',
  CDLogin = 'cDLogin',
  CDPassword = 'cDPassword',
  SiteId = 'siteId',
  SyncStartDate = 'syncStartDate',
  IsActive = 'isActive',
}

export default CDConfigurationFormFieldId
