import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { CategoryName, RecentlyUpdatedMode } from '~/client/graph'
import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import CategoryFilterStore from '../../CategoryFilter.store'
import RecentlyUpdatedStore from './RecentlyUpdated.store'

// localization: translated

interface ICategoryOption {
  id: CategoryName
  name: string
  icon: JSX.Element
  activitiesCount: number
  selectedMode: RecentlyUpdatedMode
  recentlyUpdatedStore: RecentlyUpdatedStore
  categoryFilterStore: CategoryFilterStore
}

@observer
export default class RecentlyUpdated extends React.Component<ICategoryOption> {
  public render() {
    const {
      id,
      name,
      icon,
      activitiesCount,
      categoryFilterStore,
      recentlyUpdatedStore,
    } = this.props

    const { onCheckboxClick, selectedCategories } = categoryFilterStore

    const {
      toggleDateSelector,
      shouldExpandDateSelector,
      selectedSelectorDateTitle,
    } = recentlyUpdatedStore

    return (
      <div key={id} className="option-row my10 row">
        <Checkbox
          isChecked={selectedCategories.includes(id)}
          onClick={onCheckboxClick.bind(this, id)}
        />
        <div className="row">
          <div className="icon-cell col x-center pt5">{icon}</div>
          <div className="recently-name-cell nowrap text large light">
            {name}
          </div>
          <div className="selector-cell text large light row ml20">
            <span className="selector-cell-name">
              {Localization.translator.since}:
            </span>
            <div
              className="selector no-grow title px5 ml10 ba-light-grey brada2 text large primary-blue"
              onClick={toggleDateSelector}
            >
              <div className="row x-between selector">
                {selectedSelectorDateTitle}
                {this.renderToggle()}
              </div>
              {shouldExpandDateSelector && (
                <div className="bg-white text large light date-selector-options-holder">
                  {this.options()}
                </div>
              )}
            </div>
          </div>
          <div className="amount-cell text large primary-blue right row x-end">
            {activitiesCount}
          </div>
        </div>
      </div>
    )
  }

  private options() {
    const { recentlyUpdatedStore, selectedMode } = this.props
    const { setSelectedSelectorDateMode } = recentlyUpdatedStore

    const options = Object.values(RecentlyUpdatedMode).map((mode, index) => {
      const isCustomMode = mode === RecentlyUpdatedMode.DateRange
      return (
        <span
          key={index}
          className={classList({
            'ba-light-grey option row px5 pointer': true,
            'text large primary-blue': selectedMode === mode,
            'x-between': isCustomMode,
          })}
          onClick={setSelectedSelectorDateMode.bind(this, mode)}
        >
          {recentlyUpdatedStore.getRecentlyUpdatedModeDisplayText(mode)}
          {isCustomMode && this.renderRangeToggle()}
        </span>
      )
    })
    return options
  }

  private renderRangeToggle() {
    const { CHEVRON_LEFT, CHEVRON_RIGHT } = IconNames
    const { isDatePickerShown } = this.props.recentlyUpdatedStore
    return (
      <Icon
        icon={isDatePickerShown ? CHEVRON_LEFT : CHEVRON_RIGHT}
        className="text primary-blue"
      />
    )
  }

  private renderToggle() {
    const { CHEVRON_DOWN, CHEVRON_UP } = IconNames
    const { shouldExpandDateSelector } = this.props.recentlyUpdatedStore
    return (
      <Icon
        icon={shouldExpandDateSelector ? CHEVRON_UP : CHEVRON_DOWN}
        className="text primary-blue"
      />
    )
  }
}
