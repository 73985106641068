import * as React from 'react'

import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import TwoMonthsDatePickerStore from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import BuildingLevelsSelector from '~/client/src/shared/components/BuildingLevelsSelector/BuildingLevelsSelector'
import * as Icons from '~/client/src/shared/components/Icons'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import * as TagIcon from '~/client/src/shared/components/TagIcon'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Level from '~/client/src/shared/models/LocationObjects/Level'
import VerticalObject from '~/client/src/shared/models/LocationObjects/VerticalObject'
import {
  DEFAULT_WORK_FINISH_HOUR,
  DEFAULT_WORK_START_HOUR,
} from '~/client/src/shared/models/Project'
import BuildingsStore from '~/client/src/shared/stores/domain/Buildings.store'
import LevelsStore from '~/client/src/shared/stores/domain/Levels.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import LevelsList from '../../../../../../../../../../../components/LevelsList'

const ICON_SIZE = 16

const levelsToClose = 'Levels to Close'
const closureDates = 'Closure dates'
const add = 'Add'

interface IProps {
  verticalObject: VerticalObject
  datePickerStore: TwoMonthsDatePickerStore
  levelsStore?: LevelsStore
  buildingsStore?: BuildingsStore
  projectDateStore?: ProjectDateStore
  saveChanges(): void
  onClose(): void
}

@inject('levelsStore', 'buildingsStore', 'projectDateStore')
@observer
export default class LevelsClosureModal extends React.Component<IProps> {
  @observable private selectedLevelIds = []
  @observable private startDate: Date
  @observable private endDate: Date
  @observable private isLevelSelectorDisplayed: boolean = false

  public render() {
    return (
      <MenuCloser closeMenu={this.onBackDropClick}>
        <div className="absolute levels-closure-modal beautiful-shadow brada4 pa12">
          <div className="row y-start pb24">
            <div className="no-grow pr8">
              <TagIcon.Level size={ICON_SIZE} />
            </div>
            <div>
              <div className="text bold uppercase grey-light">
                {levelsToClose}
              </div>
              <div
                className="grey-input col y-center pl12"
                onClick={this.toggleLevelsSelector}
              >
                <LevelsList levels={this.selectedLevels} />
              </div>
            </div>
          </div>
          {this.isLevelSelectorDisplayed && (
            <BuildingLevelsSelector
              building={this.buildingParent}
              selectedLevels={this.selectedLevelIds}
              allowedLevelIds={this.props.verticalObject.accessibleLevels}
              onApply={this.onApplyClosedLevels}
              onHide={this.hideLevelSelector}
            />
          )}
          <div className="row y-start pb24">
            <div className="no-grow pr8">
              <Icons.CalendarClose />
            </div>
            <div>
              <div className="text bold uppercase grey-light">
                {closureDates}
              </div>
              <div
                className="grey-input col y-center pl12 text large ellipsis"
                onClick={this.showDatePicker}
              >
                {this.formattedDates}
              </div>
            </div>
          </div>
          <div className="row x-end">
            <button
              onClick={this.addClosure}
              className={classList({
                'ba-none brada4 no-grow pa10 levels-closure-modal-submit pointer':
                  true,
                'inactive-element': !this.isFormValid,
              })}
            >
              {add}
            </button>
          </div>
        </div>
      </MenuCloser>
    )
  }

  private onBackDropClick = () => {
    if (this.props.datePickerStore.isShown) {
      return
    }
    this.props.onClose()
  }

  private toggleLevelsSelector = () => {
    this.isLevelSelectorDisplayed = !this.isLevelSelectorDisplayed
  }

  private hideLevelSelector = () => {
    this.isLevelSelectorDisplayed = false
  }

  private get selectedLevels(): Level[] {
    return this.props.levelsStore.list.filter(l =>
      this.selectedLevelIds.includes(l.id),
    )
  }

  private get buildingParent() {
    const { verticalObject, buildingsStore: buildingsStore } = this.props
    const { parent } = verticalObject
    return parent && buildingsStore.byId.get(parent.parentId)
  }

  private onApplyClosedLevels = (levelIds: string[]) => {
    this.selectedLevelIds = levelIds
    this.isLevelSelectorDisplayed = false
  }

  private showDatePicker = () => {
    const { setHours } = this.props.projectDateStore

    const startDate =
      this.startDate || setHours(new Date(), DEFAULT_WORK_START_HOUR, 0)
    const endDate =
      this.endDate || setHours(new Date(), DEFAULT_WORK_FINISH_HOUR, 0)

    this.props.datePickerStore.showWithOptions({
      initialRange: { startDate, endDate },
      handler: this.setDates,
      isSeparatedMonths: true,
      isTimeSensitive: true,
    })
  }

  private setDates = (startDate: Date, endDate: Date) => {
    this.startDate = startDate
    this.endDate = endDate
  }

  private get formattedDates() {
    if (!this.startDate) {
      return null
    }

    const {
      getWeekdayMonthDayAndTimeToDisplay,
      getWeekdayMonthDayYearAndTimeToDisplay,
      isThisYear,
    } = this.props.projectDateStore

    const getDateToDisplay = date => {
      return isThisYear(date)
        ? getWeekdayMonthDayAndTimeToDisplay(date)
        : getWeekdayMonthDayYearAndTimeToDisplay(date)
    }
    const startDateFormatted = getDateToDisplay(this.startDate)
    const endDateFormatted = this.endDate
      ? getDateToDisplay(this.endDate)
      : Localization.translator.forTheFuture

    return `${startDateFormatted} - ${endDateFormatted}`
  }

  private get isFormValid() {
    return this.startDate && this.selectedLevelIds.length
  }

  private addClosure = () => {
    const { saveChanges, onClose, verticalObject, projectDateStore } =
      this.props

    verticalObject.levelsClosures.push({
      levels: this.selectedLevelIds,
      interval: {
        startDate: this.startDate.getTime(),
        endDate: this.endDate
          ? this.endDate.getTime()
          : projectDateStore.endOfDay(this.startDate).getTime(),
      },
    })
    saveChanges()
    onClose()
  }
}
