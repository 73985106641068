import { DeliveryStatus } from '~/client/graph'
import CalendarEventLabelType from '~/client/src/shared/enums/CalendarEventLabelType'
import CalendarEvent, {
  CalendarEventEntityType,
} from '~/client/src/shared/models/CalendarEvent'
import Delivery from '~/client/src/shared/models/Delivery'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import CalendarViewStore, {
  PREVIEW_START_HOUR,
} from '~/client/src/shared/stores/ui/CalendarView.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import EventStyleCssAdapter from '~/client/src/shared/utils/EventStyleCssAdapter'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import Colors from '~/client/src/shared/theme.module.scss'

interface IPreviewCardEvent {
  startHours: number
  endHours: number
  endMinutes?: number
  deliveryStatus: DeliveryStatus
  color: string
  eventId: string
}

const previewCardEvents: IPreviewCardEvent[] = [
  {
    startHours: 6,
    endHours: 8,
    deliveryStatus: DeliveryStatus.Done,
    color: Colors.purple40,
    eventId: '1',
  },
  {
    startHours: 9,
    endHours: 14,
    endMinutes: 30,
    deliveryStatus: DeliveryStatus.OnSite,
    color: Colors.green20,
    eventId: '2',
  },
  {
    startHours: 10,
    endHours: 12,
    deliveryStatus: DeliveryStatus.Scheduled,
    color: Colors.green20,
    eventId: '3',
  },
  {
    startHours: 15,
    endHours: 18,
    deliveryStatus: DeliveryStatus.PassedInspection,
    color: Colors.green30,
    eventId: '4',
  },
  {
    startHours: 16,
    endHours: 18,
    deliveryStatus: DeliveryStatus.Requested,
    color: Colors.error70,
    eventId: '5',
  },
  {
    startHours: 15,
    endHours: 16,
    deliveryStatus: DeliveryStatus.Requested,
    color: Colors.error70,
    eventId: '6',
  },
  {
    startHours: 15,
    endHours: 17,
    deliveryStatus: DeliveryStatus.Requested,
    color: Colors.error70,
    eventId: '7',
  },
  {
    startHours: 17,
    endHours: 18,
    deliveryStatus: DeliveryStatus.Requested,
    color: Colors.error70,
    eventId: '8',
  },
]

export const previewViewHours = Array.from(Array(25).keys()).filter(
  h => h >= PREVIEW_START_HOUR && h <= 17,
)

export default class DeliveryCardPreviewStore extends CalendarViewStore {
  public constructor(
    projectDateStore: ProjectDateStore,
    companiesStore: CompaniesStore,
  ) {
    super(projectDateStore, companiesStore, true)
  }

  public get previewMapDelivery(): Delivery {
    const { deliveryStatus, startHours, endHours, endMinutes, eventId } =
      previewCardEvents[0]

    return this.createMockDelivery(
      new Date(),
      deliveryStatus,
      startHours,
      endHours,
      endMinutes,
      eventId,
    )
  }

  public get previewCalendarEvents(): CalendarEvent[] {
    const now = new Date()
    const { setHours } = this.projectDateStore

    return previewCardEvents.map(event => {
      const { startHours, endHours, endMinutes, deliveryStatus, eventId } =
        event

      const deliveryMock = this.createMockDelivery(
        now,
        deliveryStatus,
        startHours,
        endHours,
        endMinutes,
        eventId,
      )

      const startDate = setHours(now, startHours, 0)
      const endDate = setHours(now, endHours, endMinutes || 0)

      const eventStyle = deliveryMock.getEventStyleByColor(event.color)
      const cssAdapter = new EventStyleCssAdapter(eventStyle)

      return CalendarEvent.createEvent({
        startDate,
        endDate,
        dataId: deliveryMock.id,
        projectDateStore: this.projectDateStore,
        entityType: CalendarEventEntityType.Delivery,
        labelType: CalendarEventLabelType.Preview,
        styles: cssAdapter.getStyles(),
        data: deliveryMock,
        label: deliveryMock.codeToDisplay(this.companiesStore),
      })
    })
  }

  private createMockDelivery(
    date: Date,
    deliveryStatus: DeliveryStatus,
    startHours: number,
    endHours: number,
    endMinutes: number = 0,
    eventId: string,
  ): Delivery {
    const { setHours } = this.projectDateStore

    const deliveryMock = new Delivery(eventId)

    const startDate = setHours(date, startHours, 0)
    const endDate = setHours(date, endHours, endMinutes)

    deliveryMock.status = deliveryStatus
    deliveryMock.startDate = startDate.getTime()
    deliveryMock.endDate = endDate.getTime()
    deliveryMock.bookingId = EMPTY_STRING

    return deliveryMock
  }
}
