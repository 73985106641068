import { action, computed, observable } from 'mobx'

import { LocationType } from '~/client/graph'
import { GetWbsListDocument } from '~/client/graph/operations/generated/Schedule.generated'
import activityGroupingTypeToCaptionMap from '~/client/src/desktop/constants/activityGroupingTypeToCaptionMap'
import filterTypeToLocationTypeMap from '~/client/src/desktop/constants/filterTypeToAttributeTypeMap'
import ActivityGroupingType from '~/client/src/desktop/enums/ActivityGroupingType'
import GroupingOption from '~/client/src/desktop/enums/GroupingOptions'
import ViewModes from '~/client/src/desktop/enums/ViewModes'
import IWbsRow from '~/client/src/desktop/interfaces/IWbsRow'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopCommonStore from '~/client/src/desktop/stores/ui/DesktopCommon.store'
import DesktopActivityListStore, {
  tagTypes,
} from '~/client/src/desktop/views/SimpleGanttView/components/DesktopActivityList.store'
import SortOrder from '~/client/src/shared/enums/SortOrder'
import { TagType } from '~/client/src/shared/enums/TagType'
import Activity from '~/client/src/shared/models/Activity'
import ActivityCode from '~/client/src/shared/models/ActivityCode'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'
import User from '~/client/src/shared/models/User'
import Wbs from '~/client/src/shared/models/Wbs'
import WbsTreeNode from '~/client/src/shared/models/WbsTreeNode'
import EventStore from '~/client/src/shared/stores/EventStore/Events.store'
import { DELETE_ACTIVITIES } from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivityAssignmentsStore from '~/client/src/shared/stores/domain/ActivityAssignments.store'
import ActivityCodeTypesStore from '~/client/src/shared/stores/domain/ActivityCodeTypes.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import GatesStore from '~/client/src/shared/stores/domain/Gates.store'
import GraphExecutorStore from '~/client/src/shared/stores/domain/GraphExecutor.store'
import LocationsStore from '~/client/src/shared/stores/domain/Locations.store'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'
import OffloadingEquipmentsStore from '~/client/src/shared/stores/domain/OffloadingEquipments.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import RoutesStore from '~/client/src/shared/stores/domain/Routes.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import ThreadsStore from '~/client/src/shared/stores/domain/ThreadsStore/Threads.store'
import VerticalObjectsStore from '~/client/src/shared/stores/domain/VerticalObjects.store'
import WbsStore from '~/client/src/shared/stores/domain/WbsStore/Wbs.store'
import { ITreeNodeObj } from '~/client/src/shared/stores/ui/BaseList.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { getRowsFromGroup } from '~/client/src/shared/utils/EntityListHelpers'
import {
  UNASSIGNED,
  UNASSIGNED_FILTER_VALUE,
} from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import { listToMap } from '~/client/src/shared/utils/converters'

import {
  sortByCommonRules,
  sortByDate,
  sortByNumber,
} from '../DesktopActivityListSortingFunctions'

const MAX_COMPANY_LABEL_CHARACTERS = 10
const EMPTY_CELL_VALUE = ''

const DATA_COLUMNS_NAMES = [
  'activity.plannedStartDayFormatted',
  'activity.plannedFinishDayFormatted',
  'activity.actualStartDayFormatted',
  'activity.actualFinishDayFormatted',
]

enum sortValueCoefficients {
  first = '1',
  second = '2',
  third = '3',
}

export const LIST_VIEW_ROW_HEIGHT = 30
export const GANTT_VIEW_ROW_HEIGHT = 48

export default class ActivityGanttOrListViewStore {
  @observable public currentSortingOrder: SortOrder = SortOrder.ASC

  @observable
  private collapsedWbs: Map<string, boolean> = new Map<string, boolean>()
  @observable private shouldSortColumnAsDateColumn: boolean = false
  private isWbsLoading = false
  @observable private _tableScrollToIndex: number = 0

  public get areActivitiesBeingUpdated(): boolean {
    return this.state.loading.get(DELETE_ACTIVITIES)
  }

  public set tableScrollToIndex(index: number) {
    this._tableScrollToIndex = Math.ceil(index)
  }

  public get tableScrollToIndex(): number {
    return this._tableScrollToIndex
  }

  public get sortingDataKey(): string {
    return this.state.activityList.sortingDataKey
  }

  private get isProgressColumn(): boolean {
    return (
      this.sortingDataKey === 'manpower' || this.sortingDataKey === 'complete'
    )
  }

  private get newSortingOrderState(): SortOrder {
    switch (this.currentSortingOrder) {
      case SortOrder.ASC:
        return SortOrder.DESC
      case SortOrder.DESC:
        return SortOrder.DEFAULT
      case SortOrder.DEFAULT:
        return SortOrder.ASC
    }
  }

  private get viewMode() {
    return this.state.activityList.viewMode
  }

  public get rowHeight() {
    return this.viewMode === ViewModes.Gantt
      ? GANTT_VIEW_ROW_HEIGHT
      : LIST_VIEW_ROW_HEIGHT
  }

  public get isSortingOrderDesc(): boolean {
    return this.currentSortingOrder === SortOrder.DESC
  }

  public get startDate() {
    return this.activityListStore.dateFilters.startDate
  }

  public set startDate(date: Date) {
    this.activityListStore.dateFilters.startDate = date
  }

  public get bands() {
    const { allBands } = this.activityFiltersStore
    return [
      ...allBands,
      ActivityGroupingType.Equipment,
      ActivityGroupingType.VerticalObject,
      ActivityGroupingType.Gate,
      ActivityGroupingType.Route,
    ]
  }

  @computed
  private get bandActivityMap(): {
    [bandType: string]: { [groupName: string]: Activity[] }
  } {
    const map = {}
    this.bands.forEach(band => {
      const bandMap = (map[band] = {})

      this.filteredActivities.forEach(activity => {
        const groupNames: string[] = this.getGroupNames(activity, band)

        if (!groupNames.length) {
          groupNames.push('Unassigned ' + band)
        }

        groupNames.forEach(groupName => {
          if (!bandMap[groupName]) {
            bandMap[groupName] = []
          }

          const index = bandMap[groupName].findIndex(
            a => a.dates.planned.start.date > activity.dates.planned.start.date,
          )

          if (index === -1) {
            bandMap[groupName].push(activity)
          } else {
            bandMap[groupName].splice(index, 0, activity)
          }
        })
      })
    })

    return map
  }

  @computed
  public get filteredActivities(): Activity[] {
    return this.activityListStore.filteredActivities
  }

  @computed
  public get selectedActivitiesIds(): string[] {
    const selectedActivities: Activity[] = this.filteredActivities.filter(
      a => a && this.isActivitySelected(a.code),
    )
    return selectedActivities.map(a => a.code)
  }

  public get selectedActivitiesCount(): number {
    return this.selectedActivitiesIds.length
  }

  @computed
  public get orderedTableRowsList(): IWbsRow[] {
    return this.getTableRowList(false)
  }

  @computed
  public get expandedOrderedTableRowsList(): IWbsRow[] {
    return this.getTableRowList(true)
  }

  @computed
  public get wbsTreeNodes() {
    const activitiesWbsIdMap = this.wbsStore.getActivitiesWbsIdMap(
      this.filteredActivities,
    )

    const treeRootNodes = Object.values(this.wbsStore.wbsTree)

    const unassignedWbs = Wbs.createPlaceholder()
    unassignedWbs.id = UNASSIGNED + ' wbs'
    unassignedWbs.name = UNASSIGNED + ' wbs'

    treeRootNodes.push({
      wbs: unassignedWbs,
      children: [],
      startDate: null,
    })

    const wbsTree = this.state.filters.showEmptyBands
      ? treeRootNodes
      : this.wbsStore.filterWbsWithActivities(
          treeRootNodes,
          this.filteredActivities,
        )

    return this.wbsStore.sortWbsTreeByActivitiesDateDesc(
      wbsTree,
      activitiesWbsIdMap,
    )
  }

  @computed
  public get activeScheduleId(): string {
    return this.state.activeScheduleId
  }

  @computed
  public get isUserAdmin(): boolean {
    return this.state.userActiveProjectSettings?.isAdmin
  }

  public get areCompaniesEnabled(): boolean {
    return this.activityFiltersStore.areCompaniesEnabled
  }

  public constructor(
    private readonly activityListStore: DesktopActivityListStore,
    private readonly eventsStore: EventStore,
    private readonly state: DesktopInitialState,
    private readonly activityFiltersStore: ActivityFiltersStore,
    private readonly locationStore: LocationsStore,
    private readonly activityCodeTypesStore: ActivityCodeTypesStore,
    private readonly common: DesktopCommonStore,
    private readonly activityAssignmentsStore: ActivityAssignmentsStore,
    private readonly projectDateStore: ProjectDateStore,
    private readonly statusUpdatesStore: StatusUpdatesStore,
    private readonly graphExecutorStore: GraphExecutorStore,
    private readonly wbsStore: WbsStore,
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly companiesStore: CompaniesStore,
    private readonly tagsStore: TagsStore,
    private readonly threadsStore?: ThreadsStore,
    private readonly messagesStore?: MessagesStore,
    private readonly offloadingEquipmentsStore?: OffloadingEquipmentsStore,
    private readonly verticalObjectsStore?: VerticalObjectsStore,
    private readonly gatesStore?: GatesStore,
    private readonly routesStore?: RoutesStore,
  ) {
    this.loadWbs()
  }

  public async loadWbs() {
    const { activeScheduleId } = this.eventsStore.appState
    if (
      !activeScheduleId ||
      this.isWbsLoading ||
      this.wbsStore.isDataReceived
    ) {
      return
    }

    this.isWbsLoading = true

    this.wbsStore.clearList()

    const result = await this.graphExecutorStore.query(GetWbsListDocument, {
      scheduleId: activeScheduleId,
    })

    if (result.error) {
      this.isWbsLoading = false
      throw result.error
    }

    this.wbsStore.clearStoreAndReceiveJson(
      listToMap(
        result.data.workBreakdownStructures.data,
        e => e.id,
        e => e,
      ),
    )

    this.isWbsLoading = false
  }

  public get displayingDaysCount(): number {
    return this.activityListStore.daysToAdd
  }

  public removeSelectedActivities() {
    this.eventsStore.dispatch(DELETE_ACTIVITIES, this.selectedActivitiesIds)
  }

  public getLastMessage(activity: Activity) {
    const threads = this.threadsStore.list.filter(
      thread => thread.activityObjectId === activity.code,
    )

    return threads
      .flatMap(thread =>
        this.messagesStore.list.filter(
          message => message.threadId === thread.id,
        ),
      )
      .sort((a, b) => {
        return a.updatedAt - b.updatedAt
      })
      .pop()
  }

  @action.bound
  public handleRowClick(obj: any) {
    const { event, rowData } = obj
    event.stopPropagation()

    const { userActiveProjectSettings } = this.state
    const { activity, isWbs, company } = rowData as IWbsRow

    const { isActivityUpdateAvailable } = userActiveProjectSettings

    if (!isWbs && activity) {
      this.setSelection([activity.code])
      this.selectCompany(company)
      this.activityListStore.showActivityDetailsPanel()

      if (isActivityUpdateAvailable) {
        this.common.displayBulkStatusUpdateView(activity, company)
      }
    }
  }

  @action.bound
  public selectCompany(company: string) {
    this.state.activityList.selectedCompany = company
  }

  @action.bound
  public openActivityLog(activity: Activity) {
    if (activity) {
      this.setSelection([activity.code])
      this.activityListStore.showActivityDetailsPanel()
      this.common.toActivityDetails()
    }
  }

  public isActivitySelected = (id: string): boolean => {
    return this.activityListStore.multiSelection.includes(id)
  }

  public getActivitiesFromGroup = (
    wbsId: string,
    level: number,
  ): Activity[] => {
    const rowIndex =
      this.orderedTableRowsList.findIndex(row => row.wbsId === wbsId) + 1

    return getRowsFromGroup(rowIndex, this.orderedTableRowsList, level)
      .filter(rowData => !rowData.isWbs)
      .map(rowData => rowData.activity)
  }

  @action.bound
  public toggleActivitySelection(activityId: string) {
    const selectedActivitiesIds = this.activityListStore.multiSelection
    const isSelected = selectedActivitiesIds.includes(activityId)
    const newSelection = selectedActivitiesIds.filter(a => a !== activityId)

    if (!isSelected) {
      newSelection.push(activityId)
    }

    this.setSelection(newSelection)
    if (!this.activityListStore.selectedActivity) {
      this.state.activityList.isActivityDetailsPanelDisplayed = false
    }
  }

  public setSelection = (ids: string[]) => {
    this.activityListStore.setSelection(ids)
  }

  @action.bound
  public sortColumn(dataKey: string) {
    if (this.sortingDataKey === dataKey) {
      this.currentSortingOrder = this.newSortingOrderState
    } else {
      this.currentSortingOrder = SortOrder.ASC
    }
    this.state.activityList.sortingDataKey = dataKey
    this.shouldSortColumnAsDateColumn = DATA_COLUMNS_NAMES.includes(dataKey)
  }

  // used in CustomRowRender
  public isRowSelected({ activity }: { activity: Activity }) {
    return activity && this.isActivitySelected(activity.code)
  }

  @action.bound
  public deselectAll() {
    this.activityListStore.setSelection([])
  }

  @action.bound
  public selectAll() {
    const allIds = this.filteredActivities.map(a => a.code)
    this.activityListStore.setSelection(allIds)
  }

  @action.bound
  public toggleWbsGroup(name: string) {
    this.collapsedWbs.set(name, !this.collapsedWbs.get(name))
  }

  @action.bound
  public updateScrollValue() {
    const { selectedActivity } = this.activityListStore
    if (selectedActivity) {
      this.tableScrollToIndex = this.getScrollToValue(selectedActivity.code)
    }
  }

  public getRowValueByKey = (dataKey: string, rowData: IWbsRow) => {
    if (rowData && dataKey) {
      if (dataKey.indexOf('.') === -1) {
        return rowData[dataKey]
      }

      let value = rowData
      dataKey
        .split('.')
        .forEach(key => (value = value ? value[key] : EMPTY_CELL_VALUE))
      return value
    }

    return null
  }

  public getActivityAssignedUserNamesString(activity: Activity): string {
    const activityAssignment =
      this.activityAssignmentsStore.getAssignmentByEntityId(activity.code)
    const userIds = activityAssignment
      ? activityAssignment.getRecipientsByType(TagType.User)
      : []

    const assignedUsers = userIds
      .map(id => this.projectMembersStore.getById(id))
      .filter(m => !!m)

    switch (true) {
      case !assignedUsers.length:
        return ' - '
      case assignedUsers.length === 1:
        return this.getUserNameString(assignedUsers[0], true)
      default:
        const displayedUsers = assignedUsers.slice(0, 3)
        let result = displayedUsers
          .map(u => this.getUserNameString(u))
          .join(' ')
        const hiddenUsers = assignedUsers.slice(3)
        if (hiddenUsers.length) {
          result += ` + ${hiddenUsers.length}`
        }
        return result
    }
  }

  private getUserNameString({ firstName, lastName }: User, isFull?: boolean) {
    if (!firstName || !lastName) {
      return ''
    }
    if (isFull) {
      return `${firstName} ${lastName}`
    } else {
      return `${firstName[0]}${lastName[0]}`.toUpperCase()
    }
  }

  private getScrollToValue = searchKey => {
    const activityIndex = this.expandedOrderedTableRowsList.findIndex(
      ({ activity }) => activity && activity.code === searchKey,
    )

    return activityIndex
  }

  private getTableRowList(shouldHideCollapsed: boolean): IWbsRow[] {
    if (this.viewMode === ViewModes.Map) {
      return []
    }

    const { selectedBandsOption, locationsMap } = this.state.filters
    switch (selectedBandsOption.id) {
      case GroupingOption.None:
        return this.getSortedActivityRows(
          this.filteredActivities.map(activity =>
            this.createActivityRow(activity),
          ),
        )
      case GroupingOption.Wbs:
        return this.getWbsRows(this.wbsTreeNodes, 0, shouldHideCollapsed)
      default:
        return this.getBandTreeNodeRows(
          selectedBandsOption.bands.filter(
            band => locationsMap[band] || this.bands.includes(band),
          ),
          null,
          '',
          0,
          '',
          shouldHideCollapsed,
        )
    }
  }

  private getWbsRows(
    wbsTreeNodes: WbsTreeNode[],
    wbsLevel: number = 0,
    shouldHideCollapsed: boolean,
  ): IWbsRow[] {
    const rows: IWbsRow[] = []
    wbsTreeNodes.forEach(({ wbs, children }) => {
      const isExpanded = !this.collapsedWbs.get(wbs.name)
      rows.push({
        isWbs: true,
        name: wbs.name,
        isExpanded,
        level: wbsLevel,
        wbsId: wbs.id,
      })

      if (!isExpanded && shouldHideCollapsed) {
        return
      }

      const activities = this.wbsStore.filteredActivitiesWbsIdMap[wbs.id] || []
      const sortedRows = this.getSortedActivityRows(
        activities.map(activity =>
          this.createActivityRow(activity, wbsLevel, null, isExpanded),
        ),
      )

      rows.push(...sortedRows)

      if (children.length) {
        rows.push(
          ...this.getWbsRows(children, wbsLevel + 1, shouldHideCollapsed),
        )
      }
    })

    return rows
  }

  private getBandTreeNodeRows(
    bands: string[],
    parentActivities?: Activity[],
    parentName: string = '',
    levelNum: number = 0,
    companyName?: string,
    shouldHideCollapsedBands?: boolean,
  ): IWbsRow[] {
    const currentBand = bands[0]
    const subBands = bands.slice(1)
    const isCompanyBand = currentBand === GroupingOption.Company

    const treeNodeObjs = this.getTreeNodeObjsByBand(currentBand)
    const bandName =
      activityGroupingTypeToCaptionMap[currentBand] || currentBand

    treeNodeObjs.push({
      id: UNASSIGNED + ' ' + currentBand,
      name: UNASSIGNED + ' ' + bandName,
    })

    const rows: IWbsRow[] = []
    const objectMap = this.bandActivityMap[currentBand]
    const { showEmptyBands } = this.state.filters

    treeNodeObjs.forEach(({ id, name, object }) => {
      let bandActivities = objectMap[id] || []

      if (!bandActivities.length) {
        return
      }

      if (parentActivities) {
        bandActivities = bandActivities.filter(a =>
          parentActivities.includes(a),
        )
      }

      let children: IWbsRow[]
      const fullName = parentName + name
      const companyValue = name.includes(UNASSIGNED)
        ? UNASSIGNED_FILTER_VALUE
        : name
      const company = isCompanyBand ? companyValue : companyName

      if (subBands.length) {
        children = this.getBandTreeNodeRows(
          subBands,
          bandActivities,
          fullName,
          levelNum + 1,
          company,
          shouldHideCollapsedBands,
        )
      } else {
        children = this.getSortedActivityRows(
          bandActivities.map(a =>
            this.createActivityRow(
              a,
              levelNum,
              company,
              shouldHideCollapsedBands,
            ),
          ),
        )
      }

      if (showEmptyBands || bandActivities.length > 0) {
        const isExpanded =
          !shouldHideCollapsedBands || !this.collapsedWbs.get(fullName)

        rows.push({
          isWbs: true,
          name: fullName,
          caption: name,
          level: levelNum,
          isExpanded,
          wbsId: fullName,
          location: object,
        })

        if (isExpanded) {
          rows.push(...children)
        }
      }
    })

    return rows
  }

  private createActivityRow(
    activity: Activity,
    level: number = 0,
    company?: string,
    isExpanded?: boolean,
  ): IWbsRow {
    return {
      isWbs: false,
      name: activity.name,
      activity,
      isExpanded,
      level,
      company:
        company ||
        activity.companies[0] ||
        StatusUpdate.UNASSIGNED_COMPANY_VALUE,
      responsibilityCode: this.getResponsibilityCode(activity),
    }
  }

  private getSortedActivityRows(activityRows: IWbsRow[]) {
    return activityRows.sort((a, b) => {
      const aValue = this.getSortingValue(a)
      const bValue = this.getSortingValue(b)

      if (
        !this.sortingDataKey ||
        this.currentSortingOrder === SortOrder.DEFAULT
      ) {
        return (aValue as number) - (bValue as number)
      }
      const sortingCoef = this.isSortingOrderDesc ? -1 : 1

      if (this.shouldSortColumnAsDateColumn) {
        return sortByDate(aValue, bValue, sortingCoef)
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue) * sortingCoef
      }

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return sortByNumber(
          this.isProgressColumn,
          a,
          b,
          aValue,
          bValue,
          sortingCoef,
        )
      }

      return sortByCommonRules(b, aValue, sortingCoef)
    })
  }

  private getSortingValue(activityRow: IWbsRow): number | string {
    const { activity, company } = activityRow
    let code: ActivityCode
    const { plannedStartDate, companies, actualStatus } = activity
    if (
      !this.sortingDataKey ||
      this.currentSortingOrder === SortOrder.DEFAULT
    ) {
      return plannedStartDate?.getTime()
    }
    switch (this.sortingDataKey) {
      case 'company':
        return this.retrieveCompanyLabel(companies)
      case 'manpower':
        return activity.getStatusUpdateForCompany(company).manpower
      case 'complete':
        return activity.getStatusUpdateForCompany(company).percentComplete
      case tagTypes.building:
      case tagTypes.level:
      case tagTypes.zone:
        return this.getLocationLabel(this.sortingDataKey as tagTypes, activity)
      case tagTypes.assignedMembers:
        return this.getActivityAssignedUserNamesString(activity)
      case 'respName':
        code = this.getResponsibilityCode(activity)
        return code && code.name
      case 'respCode':
        code = this.getResponsibilityCode(activity)
        return code && code.shortName
      case 'actual-status':
        return actualStatus
      case 'planned-status':
        return activity.getPlannedStatus(
          this.projectDateStore,
          this.statusUpdatesStore,
        )
      case 'on-time':
        const deltaDays = activity.getDeltaDays(this.projectDateStore, company)
        return activity.getOnTimeStatus(this.projectDateStore, deltaDays)
      case 'activity.remainingDuration':
        return activity.getRemainingDuration(company)
      case 'activity.plannedStartDayFormatted':
        return activity.plannedStartDayFormatted(this.projectDateStore)
      case 'activity.plannedFinishDayFormatted':
        return activity.plannedFinishDayFormatted(this.projectDateStore)
      case 'activity.actualStartDayFormatted':
        return activity.actualStartDayFormatted(this.projectDateStore)
      case 'activity.actualFinishDayFormatted':
        return activity.actualFinishDayFormatted(this.projectDateStore)
      case 'comment':
        return this.getCommentSortValue(activity)
      default:
        return this.getRowValueByKey(this.sortingDataKey, activityRow)
    }
  }

  private getLocationLabel = (
    locationType: tagTypes,
    activity: Activity,
  ): string => {
    const { locations } = activity
    const attributeType = filterTypeToLocationTypeMap[locationType]

    const filteredLocations = locations?.reduce((list, loc) => {
      if (loc.type === attributeType) {
        const tag = this.tagsStore.getTag(attributeType, loc.id)
        if (tag?.name) {
          list.push(tag.name)
        }
      }
      return list
    }, [] as string[])

    if (filteredLocations?.length) {
      return filteredLocations.join(', ')
    }

    const { buildingCodeIds, levelCodeIds, zoneCodeIds } = this.locationStore
    let tagCodeIds: string[]

    switch (locationType) {
      case tagTypes.building:
        tagCodeIds = buildingCodeIds
        break
      case tagTypes.level:
        tagCodeIds = levelCodeIds
        break
      case tagTypes.zone:
        tagCodeIds = zoneCodeIds
        break
    }

    return ActivityCode.getLocationTagLabel(
      activity.codes.filter(c => tagCodeIds.includes(c.id)),
    )
  }

  private getResponsibilityCode(activity: Activity) {
    const { responsibilityCodeTypeId } = this.activityCodeTypesStore
    const responsibilityCodes = activity.codes.filter(
      c => c.activityCodeTypeId === responsibilityCodeTypeId,
    )

    const [activityCode] = responsibilityCodes
    return activityCode
  }

  private retrieveCompanyLabel(companies: string[]) {
    if (!companies.length) {
      return null
    }
    const company = companies[0]
    if (companies.length === 1) {
      return company.split(' ', 1)[0]
    }
    const firstCompanyLabel = company.slice(0, MAX_COMPANY_LABEL_CHARACTERS)
    const otherCompaniesCount = companies.length - 1
    return `${firstCompanyLabel} + ${otherCompaniesCount}`
  }

  private getTreeNodeObjsByBand(currentBand: string): ITreeNodeObj[] {
    const treeNodeObjs: ITreeNodeObj[] = []

    switch (currentBand) {
      case GroupingOption.Company:
        this.companiesStore.allCompanies.forEach(comp => {
          treeNodeObjs.push({ id: comp.id, name: comp.name })
        })
        break
      default:
        const bandName = filterTypeToLocationTypeMap[currentBand]

        this.tagsStore.tagListsByTagTypeMap[bandName]?.forEach(t => {
          treeNodeObjs.push({
            id: t.id,
            name: t.name,
            object: t,
          })
        })
        break
    }

    return treeNodeObjs
  }

  private getGroupNames(activity: Activity, band: string): string[] {
    let locations = []

    switch (band) {
      case GroupingOption.Building:
        return this.getActivityLocationsIds(activity, LocationType.Building)
      case GroupingOption.Level:
        return this.getActivityLocationsIds(activity, LocationType.Level)
      case GroupingOption.Zone:
        return this.getActivityLocationsIds(activity, LocationType.Zone)

      case GroupingOption.Company:
        return activity.companiesIds

      case GroupingOption.Equipment:
        locations = this.getActivityLocationsIds(
          activity,
          LocationType.OffloadingEquipment,
        )
        this.offloadingEquipmentsStore
          .getByIds(locations)
          .forEach(({ name }) => locations.push(name))
        return locations

      case GroupingOption.VerticalObject:
        locations = this.getActivityLocationsIds(
          activity,
          LocationType.VerticalObject,
        )
        this.verticalObjectsStore.list
          ?.filter(object => locations.includes(object.id))
          .forEach(({ name }) => locations.push(name))
        return locations

      case GroupingOption.Gate:
        locations = this.getActivityLocationsIds(activity, LocationType.Gate)
        this.gatesStore.list
          ?.filter(gate => locations.includes(gate.id))
          .forEach(({ name }) => locations.push(name))
        return locations

      case GroupingOption.Route:
        locations = this.getActivityLocationsIds(activity, LocationType.Route)
        this.routesStore.list
          ?.filter(routes => locations.includes(routes.id))
          .forEach(({ name }) => locations.push(name))
        return locations

      default:
        return this.locationStore
          .getCodesByFilter(band)
          .filter(entity => activity.codeIds.some(id => entity.id === id))
          .map(({ name }) => name)
    }
  }

  private getActivityLocationsIds(
    activity: Activity,
    locationType: LocationType,
  ): string[] {
    if (!activity.locations?.length) {
      return []
    }

    return activity.locations.reduce((list, { id, type }) => {
      if (locationType === type && !!this.tagsStore.getTag(type, id)) {
        list.push(id)
      }
      return list
    }, [] as string[])
  }

  private getCommentSortValue(activity: Activity) {
    const { user } = this.state
    const lastMessage = this.getLastMessage(activity)
    const messageAuthor = this.projectMembersStore.getById(lastMessage?.userId)

    if (!lastMessage) {
      return sortValueCoefficients.first
    }

    const isRead = lastMessage.readBy?.some(
      readByUser => readByUser.userId === user.id && readByUser.isRead,
    )

    const sortValueCoefficient = isRead
      ? sortValueCoefficients.second
      : sortValueCoefficients.third

    return sortValueCoefficient + messageAuthor.fullName + lastMessage.text
  }
}
