import * as React from 'react'

import SuperFilter from '~/client/src/desktop/components/SuperFilter/SuperFilter'
import SuperFilterHandleButton from '~/client/src/shared/components/Filters/SuperFilter/SuperFilterHandleButton'

import { MemberFiltersStore } from '../../stores/ui/MemberFilters.store'

interface IProps {
  store: MemberFiltersStore
}

export default class MemberFilters extends React.Component<IProps> {
  private get store(): MemberFiltersStore {
    return this.props.store
  }

  private get storesMap() {
    return this.store.filterStoresByTypeMap
  }

  public render() {
    return this.store.filterTypes.map(filterType => {
      const filterStore = this.storesMap[filterType]

      return (
        <React.Fragment key={filterType}>
          <SuperFilterHandleButton
            className="no-grow"
            filterStore={filterStore}
          />
          <SuperFilter
            filterStore={filterStore}
            hideOtherModalsFn={this.store.hideOtherModals}
          />
        </React.Fragment>
      )
    })
  }
}
