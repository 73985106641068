import * as React from 'react'

import {
  Button,
  Dialog,
  Icon,
  Position,
  Switch,
  Tooltip,
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import mapboxgl, { LngLat, LngLatBounds } from 'mapbox-gl'
import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { QRCode } from 'react-qrcode-logo'

import {
  DefaultLandingPage,
  IProjectAddressInput,
  IProjectClosedInterval,
  ProjectAccessType,
} from '~/client/graph'
import DeliveryWorkflowDatePicker from '~/client/src/desktop/components/DeliveryWorkflowDatePicker/DeliveryWorkflowDatePicker'
import ProjectSelector from '~/client/src/desktop/components/ProjectSelector/ProjectSelector'
import QRCodeHolder from '~/client/src/desktop/components/QRCodeHolder/QRCodeHolder'
import StruxhubMapboxAddressInput from '~/client/src/desktop/components/StruxhubDesktopInputs/StruxhubMapboxAddressInput/StruxhubMapboxAddressInput'
import TwoMonthsDatePickerStore from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import WhiteList from '~/client/src/desktop/components/WhiteList/WhiteList'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import ProjectSetUpPageStore from '~/client/src/desktop/views/ProjectSetUp/ProjectSetUpPage.store'
import SubmitButton from '~/client/src/desktop/views/ProjectSetUp/components/SubmitButton'
import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import * as Icons from '~/client/src/shared/components/Icons'
import ImageInput from '~/client/src/shared/components/ImageInput/ImageInput'
import ImagePreview from '~/client/src/shared/components/ImagePreview/ImagePreview'
import { HGap } from '~/client/src/shared/components/Layout'
import { mapboxFeatureToAddress } from '~/client/src/shared/components/MapBoxEditor/MapBoxViewer.store'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import BaseStruxhubInput from '~/client/src/shared/components/StruxhubInputs/BaseStruxhubInput'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import StruxhubSelect from '~/client/src/shared/components/StruxhubInputs/StruxhubSelect'
import StruxhubDateFormatSelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubDateFormatSelector'
import StruxhubTextValueSelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubTextValueSelector'
import UserProfilePreview from '~/client/src/shared/components/UserProfilePreview/UserProfilePreview'
import UsersDirectory from '~/client/src/shared/components/UsersDirectory/UsersDirectory'
import DateTimeFormat from '~/client/src/shared/enums/DateTimeFormat'
import { IMapBoxFeature } from '~/client/src/shared/interfaces/IMapBoxFeature'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import Project from '~/client/src/shared/models/Project'
import User from '~/client/src/shared/models/User'
import UserProject from '~/client/src/shared/models/UserProject'
import { SHOW_FULLSCREEN_PREVIEW } from '~/client/src/shared/stores/EventStore/eventConstants'
import GraphExecutorStore from '~/client/src/shared/stores/domain/GraphExecutor.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ProjectsStore from '~/client/src/shared/stores/domain/Projects.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import {
  getAddressCopy,
  toAddressBoundsInputFromMapBox,
  toLngLat,
  toLngLatBounds,
} from '~/client/src/shared/utils/Address'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'
import { NOOP } from '~/client/src/shared/utils/noop'
import {
  getTimezoneById,
  getTimezoneIdByCoordinates,
  getTimezoneName,
  timezones,
} from '~/client/src/shared/utils/timezones'
import { areArraysEqual, customDebounce } from '~/client/src/shared/utils/util'

import { Header } from '../SetupPageLayout/SetupPageLayout'
import ProjectSettingsStore from './ProjectSettings.store'
import ClosuresDatePicker from './components/ClosuresDatePicker'
import ClosuresDatePickerStore from './components/ClosuresDatePicker.store'
import {
  MapBoxView,
  areLngLatBoundsEqual,
  areLngLatEqual,
  getMapBoxPlaces,
} from './components/MapBoxView'
import ProjectClosingInterval from './components/ProjectClosingInterval'

import Colors from '~/client/src/shared/theme.module.scss'

import './ProjectWorkspaceSetUp.scss'

const QR_CODE_TO_PRINT_SIZE = 700
const QR_CODE_LOGO_SRC = '/static/icons/strux-hub-dark-with-background.svg'
const DONE_BUTTON_WIDTH = 75
const SUBMIT_BUTTON_WIDTH = 160

interface IProjectWorkspaceSetUp {
  projectSetUpPageStore: ProjectSetUpPageStore
  projectsStore?: ProjectsStore
  projectDateStore?: ProjectDateStore
  eventsStore?: DesktopEventStore
  tagsStore?: TagsStore
  initProjectId: string

  isProjectCreationAvailable: boolean
  isTrackerToggleAvailable: boolean
  isDeliveriesToggleAvailable: boolean
  isLogisticsToggleAvailable: boolean
  isMaterialsToggleAvailable: boolean
  isFormsToggleAvailable: boolean
  isPhotosAvailable: boolean
  isAnalyticsAvailable: boolean

  userProjectsStore?: UserProjectsStore
  projectMembersStore?: ProjectMembersStore
  graphExecutorStore?: GraphExecutorStore
}

@inject(
  'projectsStore',
  'eventsStore',
  'projectDateStore',
  'tagsStore',
  'userProjectsStore',
  'projectMembersStore',
  'graphExecutorStore',
)
@observer
export default class ProjectWorkspaceSetUp extends React.Component<IProjectWorkspaceSetUp> {
  public sectionsToggleState = observable(new Map<string, boolean>())
  private homeFullscreenHolder: HTMLDivElement
  private homeFullscreenPermits: HTMLDivElement
  private sigUpProjectFullscreen: HTMLDivElement

  @observable private workingDays: number[] = []
  @observable private showUsersList: boolean = false
  @observable private isProjectLogoChanged: boolean = false
  private readonly timePickerStore: TwoMonthsDatePickerStore = null
  private readonly store: ProjectSettingsStore
  private readonly datePickerStore: ClosuresDatePickerStore

  private readonly copySignUpLinkToClipboard = NOOP

  private readonly clearPostEventCallback: () => void = NOOP
  public constructor(props: IProjectWorkspaceSetUp) {
    super(props)

    this.timePickerStore = new TwoMonthsDatePickerStore(
      this.props.projectDateStore,
    )
    this.datePickerStore = new ClosuresDatePickerStore(
      this.props.projectDateStore,
    )

    this.store = new ProjectSettingsStore(
      props.projectsStore,
      props.eventsStore,
      props.projectSetUpPageStore,
      props.userProjectsStore,
      props.graphExecutorStore,
      props.projectDateStore,
    )

    this.copySignUpLinkToClipboard = customDebounce(
      this.store.copySignUpLinkToClipboard,
      200,
    )

    this.workingDays = this.store.activeProject.workingDays?.slice() || []

    this.mapBoxOnQueryChange = customDebounce(this.mapBoxOnQueryChange, 500)

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.store.onProjectUpdate,
    )
  }

  public componentDidMount() {
    this.store.setProjectDataValues()
  }

  public componentDidUpdate(prevProps: Readonly<IProjectWorkspaceSetUp>) {
    if (
      prevProps.initProjectId !== this.props.initProjectId ||
      !this.store.projectAddress
    ) {
      this.store.setProjectDataValues()
    }
  }

  public componentWillUnmount() {
    this.clearPostEventCallback()
  }

  public render() {
    const {
      isNewProjectSelected,
      isConfirmationModalDisplayed,
      showWhiteListDialog,
    } = this.store
    const { isFormsDisabled, isLogisticsDisabled } =
      this.props.eventsStore.appState
    const shouldRenderQRCodes =
      (!isFormsDisabled || !isLogisticsDisabled) && !isNewProjectSelected

    return (
      <div className="relative project-details">
        <div
          className={classList({
            'delivery-workflow-date-picker-container': true,
            shown: this.datePickerStore.isShown,
          })}
        >
          <ClosuresDatePicker
            store={this.datePickerStore}
            className="date-closures-picker delivery-workflow-date-picker ma-auto sticky tm-dp-container col x-stretch y-start"
          >
            {this.renderDateInputs()}
          </ClosuresDatePicker>
        </div>
        {isConfirmationModalDisplayed && this.renderConfirmationModal()}
        {this.renderWhiteListDialog()}
        <DeliveryWorkflowDatePicker store={this.timePickerStore} />
        <Header title={Localization.translator.projectSettings} />
        {this.projectSelectorBlock()}
        {this.renderSection(
          Localization.translator.projectDetails,
          this.renderProjectDetails,
        )}
        {!isNewProjectSelected &&
          this.renderSection(
            Localization.translator.projectAdmins,
            this.renderProjectAdmins,
          )}
        {this.renderSection(
          Localization.translator.projectTimeSettingsAndCalendar,
          this.renderTimeAndCalendarSection,
        )}
        {this.renderSection(
          Localization.translator.apps,
          this.renderProjectTypeSection,
        )}

        {!isNewProjectSelected &&
          this.renderSection('White list domains & Project Sign Up', () => (
            <div className="px30">
              <div className="text large pb10">
                {`When White List Domains is toggled ON it allows all users with
                verified emails addresses from these domains to join your
                StruxHub project without being specifically invited`}
              </div>
              <a
                className="text light-blue large bold underline"
                onClick={showWhiteListDialog}
              >
                {'Show white list domains'}
              </a>
              <div className="text large py10">
                <strong>{'NOTE'}:</strong>{' '}
                {`Do not White List generic email
                domains like @gmail.com, @outlook.com, @iCloud.com. Doing this
                would allow unverified users access to your project`}
              </div>
              <div className="row text large pt20">
                {'New users can create an account by going to this link'}:
                <div
                  className="row no-grow pointer ml4"
                  onClick={this.copySignUpLinkToClipboard}
                >
                  <Icons.CopyLink className="row no-grow" />
                  <span className="text large center underline no-white-space-wrap">
                    {'Copy sign-up link to clipboard'}
                  </span>
                </div>
              </div>
              <div className="text large">
                {'Or snapping this QR Code'}:{this.renderProjectSignUpQRCode()}
              </div>
            </div>
          ))}

        {shouldRenderQRCodes &&
          this.renderSection(
            Localization.translator.QRCodes,
            this.renderQRCodes,
          )}

        {isNewProjectSelected && (
          <div className="setup-form-footer">
            <SubmitButton
              text={Localization.translator.save}
              width={SUBMIT_BUTTON_WIDTH}
              clickHandler={this.handleClick}
            />
          </div>
        )}
      </div>
    )
  }

  private onProjectNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    this.store.setProjectName(event.target.value)
  }

  private onProjectLogoChange = (logoUrl: string) => {
    this.isProjectLogoChanged = true
    this.store.setProjectLogo(logoUrl)
  }

  private onProjectNameReset = () => {
    this.store.setProjectName('')
  }

  private onProjectNameSave = () => {
    this.store.saveProjectName()
  }

  private onProjectLogoSave = () => {
    this.store.saveProjectLogo()
  }

  private mapBoxOnQueryChange = async (query: string): Promise<void> => {
    const data: GeoJSON.FeatureCollection = await getMapBoxPlaces(
      query,
      mapboxgl.accessToken,
    )

    this.store.setProjectAddressSuggestions(
      data.features ? (data.features as IMapBoxFeature[]) : [],
    )
  }

  private projectSelectorBlock() {
    const { isProjectCreationAvailable, projectSetUpPageStore } = this.props
    const { projects, selectProject, onNewProjectSelected } = this.store
    const { activeProject } = projectSetUpPageStore.state

    return (
      <div className="setup-form-parameter px30">
        <div className="parameter-value">
          <BaseStruxhubInput
            label={Localization.translator.selectProject}
            isRequiredTextHidden={true}
          >
            {() => (
              <ProjectSelector
                isLoading={false}
                isDisabled={false}
                currentProject={activeProject}
                projects={projects}
                onItemSelect={selectProject}
                shouldShowNewProjectItem={isProjectCreationAvailable}
                onNewProjectSelected={onNewProjectSelected}
              />
            )}
          </BaseStruxhubInput>
        </div>
      </div>
    )
  }

  private mapBoxOnAddressValueChanged = (item: IMapBoxFeature) => {
    const addr = mapboxFeatureToAddress(item)

    this.store.setProjectAddress(addr)
  }

  private onSaveAddress = () => {
    this.store.saveProjectAdress()
  }

  private projectLogoBlock() {
    const { projectLogoUrl } = this.store
    return (
      <div className="row y-center setup-form-parameter px30">
        <div className="col">
          {projectLogoUrl && (
            <div className="h90">
              <ImagePreview>
                <img
                  alt={Localization.translator.image}
                  src={projectLogoUrl}
                  className="center"
                />
              </ImagePreview>
            </div>
          )}
          <ImageInput
            value={projectLogoUrl}
            label={Localization.translator.projectLogo}
            onChange={this.onProjectLogoChange}
          />
        </div>
        {!this.store.isNewProjectSelected &&
          this.renderDoneButton(
            this.onProjectLogoSave,
            !this.isProjectLogoChanged,
          )}
      </div>
    )
  }

  private projectLocationBlock() {
    const {
      projectAddressSuggestions,
      projectAddress: { address, city, state, zipcode, country },
      isNewProjectSelected,
    } = this.store

    const addressString = `${address} ${city} ${state} ${zipcode} ${country}`
    return (
      <div className="row y-center setup-form-parameter px30">
        <StruxhubMapboxAddressInput
          className="overflow-hidden"
          label={Localization.translator.projectLocation}
          isRequired={true}
          fullAddressLabel={addressString}
          items={projectAddressSuggestions}
          onItemSelect={this.mapBoxOnAddressValueChanged}
          onQueryChange={this.mapBoxOnQueryChange}
          value={address}
        />
        {!isNewProjectSelected &&
          this.renderDoneButton(
            this.onSaveAddress,
            this.isAdressButtonDisabled,
          )}
      </div>
    )
  }

  private renderAppTogglesHeader() {
    return (
      <div className="row mx30 mb20 bb2-brand-dark">
        <div>{Localization.translator.app}</div>
        <div className="apps-toggles-holder row text large center">
          <div>{Localization.translator.showInNavigation}</div>
          <div>{Localization.translator.setAsDefaultLandingPage}</div>
        </div>
      </div>
    )
  }

  private projectTypeBlock() {
    const {
      isDeliveriesToggleAvailable,
      isLogisticsToggleAvailable,
      isMaterialsToggleAvailable,
      isPhotosAvailable,
      isFormsToggleAvailable,
      isTrackerToggleAvailable,
      isAnalyticsAvailable,
    } = this.props
    const {
      isDeliveriesEnabled,
      isAnalyticsEnabled,
      isTrackerEnabled,
      isFormsEnabled,
      isLogisticsEnabled,
      isMaterialsEnabled,
      toggleFormsEnabled,
      toggleDeliveriesEnabled,
      toggleTrackerEnabled,
      toggleLogisticsEnabled,
      isPhotosEnabled,
      togglePhotosEnabled,
      toggleMaterialsEnabled,
      defaultLandingPage,
      setProjectDefaultLandingPage,
      toggleAnalyticsEnabled,
    } = this.store

    const hasOnlyOneActiveApp =
      [
        isDeliveriesEnabled,
        isAnalyticsEnabled,
        isTrackerEnabled,
        isMaterialsEnabled,
        isPhotosEnabled,
        isLogisticsEnabled,
        isFormsEnabled,
      ].filter(Boolean).length === 1

    return (
      <div className="setup-form-parameter project-type-setup">
        <div className="col">
          <div className="row">
            <div className="parameter-value pt7">
              {this.renderAppTogglesHeader()}
              {this.renderAppToggle(
                isLogisticsToggleAvailable,
                Localization.translator.home,
                isLogisticsEnabled,
                toggleLogisticsEnabled,
                <Icons.HomeRounded className="no-grow apps-icon" />,
                null,
                true,
                defaultLandingPage === DefaultLandingPage.Home,
                setProjectDefaultLandingPage.bind(
                  null,
                  DefaultLandingPage.Home,
                ),
                false,
                hasOnlyOneActiveApp && isLogisticsEnabled,
              )}
              {this.renderAppToggle(
                isDeliveriesToggleAvailable,
                Localization.translator.deliveries,
                isDeliveriesEnabled,
                toggleDeliveriesEnabled,
                <Icons.DeliveryRounded className="no-grow apps-icon" />,
                null,
                true,
                defaultLandingPage === DefaultLandingPage.Deliveries,
                setProjectDefaultLandingPage.bind(
                  null,
                  DefaultLandingPage.Deliveries,
                ),
                false,
                hasOnlyOneActiveApp && isDeliveriesEnabled,
              )}
              {this.renderAppToggle(
                isFormsToggleAvailable,
                Localization.translator.forms,
                isFormsEnabled,
                toggleFormsEnabled,
                <Icons.GeneralForm className="no-grow apps-icon" />,
                null,
                true,
                defaultLandingPage === DefaultLandingPage.Forms,
                setProjectDefaultLandingPage.bind(
                  null,
                  DefaultLandingPage.Forms,
                ),
                false,
                hasOnlyOneActiveApp && isFormsEnabled,
              )}
              {this.renderAppToggle(
                isTrackerToggleAvailable,
                Localization.translator.schedule,
                isTrackerEnabled,
                toggleTrackerEnabled,
                <Icons.ScheduleRounded className="no-grow apps-icon" />,
                null,
                true,
                defaultLandingPage === DefaultLandingPage.Tracker,
                setProjectDefaultLandingPage.bind(
                  null,
                  DefaultLandingPage.Tracker,
                ),
                false,
                hasOnlyOneActiveApp && isTrackerEnabled,
              )}

              {this.renderAppToggle(
                isMaterialsToggleAvailable,
                Localization.translator.materials,
                isMaterialsEnabled,
                toggleMaterialsEnabled,
                <Icons.MaterialsRounded className="no-grow apps-icon" />,
                null,
                true,
                defaultLandingPage === DefaultLandingPage.Materials,
                setProjectDefaultLandingPage.bind(
                  null,
                  DefaultLandingPage.Materials,
                ),
                false,
                hasOnlyOneActiveApp && isMaterialsEnabled,
              )}

              {this.renderAppToggle(
                isPhotosAvailable,
                Localization.translator.photos,
                isPhotosEnabled,
                togglePhotosEnabled,
                <Icons.CameraRounded className="no-grow apps-icon" />,
                null,
                true,
                defaultLandingPage === DefaultLandingPage.Photos,
                setProjectDefaultLandingPage.bind(
                  null,
                  DefaultLandingPage.Photos,
                ),
                false,
                hasOnlyOneActiveApp && isPhotosEnabled,
              )}

              {this.renderAppToggle(
                isAnalyticsAvailable,
                Localization.translator.analytics,
                isAnalyticsEnabled,
                toggleAnalyticsEnabled,
                <Icons.Print className="no-grow apps-icon" />,
                null,
                true,
                defaultLandingPage === DefaultLandingPage.Analytics,
                setProjectDefaultLandingPage.bind(
                  null,
                  DefaultLandingPage.Analytics,
                ),
                false,
                hasOnlyOneActiveApp && isAnalyticsEnabled,
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  private renderAppToggle(
    isAvailable: boolean,
    label: string,
    isEnabled: boolean,
    toggle: () => void,
    icon?: JSX.Element,
    tooltipText?: string,
    shouldRenderCheckbox?: boolean,
    isChecked?: boolean,
    toggleCheckboxState?: () => void,
    isHiddenForNow?: boolean,
    isDisabledToggle?: boolean,
  ) {
    return (
      isAvailable && (
        <div
          className={classList({
            'row mx30 mb10 pb10': true,
            'bb-light-grey': shouldRenderCheckbox,
          })}
        >
          <div className="row">
            {icon}
            <span
              className={classList({
                'text extra-large bold w-fit-content ml5': true,
                'toggle-text-with-tooltip': !!tooltipText,
              })}
            >
              {label}
              {tooltipText && this.renderQuestionTooltip(tooltipText)}
            </span>
          </div>
          <div className="apps-toggles-holder row">
            {isHiddenForNow ? (
              <div className="row x-start ba-primary-blue text center large primary book-demo-holder brada4">
                <a
                  className="text extra-large primary-blue"
                  href="mailto:info@struxhub.com?subject=Tracker App"
                >
                  {Localization.translator.bookDemo}
                </a>
              </div>
            ) : (
              <>
                <Switch
                  className={classList({
                    'primary-blue-switch no-outline-container': true,
                    'bp3-align-right': !shouldRenderCheckbox,
                  })}
                  checked={isEnabled}
                  onChange={toggle}
                  disabled={isDisabledToggle}
                />
                {shouldRenderCheckbox && (
                  <Checkbox
                    isCentered={true}
                    isChecked={isChecked}
                    onClick={toggleCheckboxState}
                    isDisabled={!isEnabled || isChecked}
                  />
                )}
              </>
            )}
          </div>
        </div>
      )
    )
  }

  private renderDateInputs() {
    const { getDashedFormattedDate } = this.props.projectDateStore
    const { startDate, endDate } = this.datePickerStore

    return (
      <div className="row pb20 y-start">
        <StruxhubInput
          type="date"
          id="start-date"
          value={startDate ? getDashedFormattedDate(startDate) : ''}
          isReadOnly={true}
          isRequiredTextHidden={true}
          onChange={undefined}
          label={Localization.translator.closureStartDate}
        />
        <HGap size="70" />
        <StruxhubInput
          type="date"
          id="end-date"
          value={endDate ? getDashedFormattedDate(endDate) : ''}
          isReadOnly={true}
          isRequiredTextHidden={true}
          onChange={undefined}
          label={Localization.translator.closureEndDate}
        />
      </div>
    )
  }

  private renderProjectDetails = (): JSX.Element => {
    if (!this.store.projectAddress) {
      return null
    }

    const {
      projectAddress,
      projectName,
      projectCode,
      isProjectErrorMessageVisible,
    } = this.store

    return (
      <div className="setup-form-map">
        <div>
          {this.renderInputBlock(
            projectName,
            Localization.translator.projectDetailsDescriptions.projectNameError,
            Localization.translator.projectName,
            this.onProjectNameChange,
            this.onProjectNameReset,
            this.onProjectNameSave,
            this.isProjectNameButtonDisabled,
            isProjectErrorMessageVisible,
          )}
          {this.renderInputBlock(
            projectCode,
            Localization.translator.projectDetailsDescriptions.projectCodeError,
            Localization.translator.projectCode,
            NOOP,
            NOOP,
            NOOP,
            true,
            false,
            true,
          )}
          {this.projectLogoBlock()}
          {this.projectLocationBlock()}
        </div>
        <MapBoxView
          bearing={projectAddress.bearing}
          bounds={toLngLatBounds(projectAddress.bounds)}
          center={toLngLat(projectAddress.center)}
          onAddressChanged={this.onAddressChanged}
          onDataChanged={this.onMapBoxDataChanged}
          zoom={projectAddress.zoom}
          pitch={projectAddress.pitch}
        />
      </div>
    )
  }

  private get isProjectNameButtonDisabled(): boolean {
    const { projectName, activeProject } = this.store
    return projectName === activeProject.name
  }

  private onAddressChanged = (addr: IProjectAddressInput) => {
    if (addr !== this.store.projectAddress) {
      this.store.setProjectAddress(addr)
    }
  }

  private get isAdressButtonDisabled(): boolean {
    return (
      this.store.projectAddress.address ===
      this.props.eventsStore.appState.projectAddress.address
    )
  }

  private onMapBoxDataChanged = (
    bearing: number,
    bounds: LngLatBounds,
    center: LngLat,
    zoom: number,
    pitch: number,
  ) => {
    const addr = getAddressCopy(this.store.projectAddress)
    let changed = false

    if (addr.bearing !== bearing) {
      changed = true
      addr.bearing = bearing
    }

    if (!areLngLatBoundsEqual(toLngLatBounds(addr.bounds), bounds)) {
      changed = true
      addr.bounds = toAddressBoundsInputFromMapBox(bounds)
    }

    if (!areLngLatEqual(toLngLat(addr.center), center) || !addr.center) {
      changed = true
      addr.center = center
    }

    if (zoom !== addr.zoom) {
      changed = true
      addr.zoom = zoom
    }

    if (pitch !== addr.pitch) {
      changed = true
      addr.pitch = pitch
    }

    if (changed) {
      this.store.setProjectAddress(addr)
    }
  }

  private renderConfirmationModal() {
    const { toggleConfirmationModal, confirmTimzoneChange } = this.store
    return (
      <div className="fixed-modal-container dark-background">
        <div className="confirmation-modal col bg-white pa20 fixed-modal-base">
          <div className="text large pa10">
            {
              Localization.translator.projectDetailsDescriptions
                .changeProjectLocationWarning
            }
          </div>
          <div className="row x-center bp3-dialog-footer-actions">
            <Button
              text={Localization.translator.cancel}
              className="confirm-dialog-btn-cancel"
              onClick={toggleConfirmationModal}
            />
            <Button
              text={Localization.translator.proceed}
              className="confirm-dialog-btn"
              onClick={confirmTimzoneChange}
            />
          </div>
        </div>
      </div>
    )
  }

  private renderWhiteListDialog(): JSX.Element {
    const { hideWhiteListDialog, shouldShowWhiteListedDomainDialog } =
      this.store

    return (
      <Dialog
        className="bg-white"
        isOpen={shouldShowWhiteListedDomainDialog}
        title={'White List Domains'}
        isCloseButtonShown={true}
        canOutsideClickClose={true}
        onClose={hideWhiteListDialog}
        style={{ maxHeight: '700px', minHeight: '700px' }}
      >
        <WhiteList />
      </Dialog>
    )
  }

  private renderTimeAndCalendarSection = (): JSX.Element => {
    const { latitude, longitude } = this.props.eventsStore.appState
    const { weekdayNamesList } = this.props.projectDateStore
    const { activeProject, timezoneId, dateTimeFormat, isNewProjectSelected } =
      this.store

    const currentTimezoneId =
      timezoneId || getTimezoneIdByCoordinates(longitude, latitude)
    const selectedTimezoneId = getTimezoneById(currentTimezoneId).tzCode

    return (
      <div className="setup-form-body">
        <div className="setup-form-parameter px30">
          <StruxhubSelect
            label={Localization.translator.projectTimeZone}
            value={selectedTimezoneId}
            onChange={this.onTimezoneChange}
            isRequired={true}
          >
            {timezones.map((timezone, i) => (
              <option value={timezone.tzCode} key={i}>
                {getTimezoneName(timezone)}
              </option>
            ))}
          </StruxhubSelect>
        </div>
        <div className="setup-form-parameter px30">
          <StruxhubDateFormatSelector
            label={Localization.translator.dateFormat}
            value={dateTimeFormat}
            onChange={this.onDateTimeFormatChange}
            isRequired={true}
          />
        </div>
        <div className="setup-form-parameter px30">
          <BaseStruxhubInput
            label={Localization.translator.defaultWorkingDays}
            isRequiredTextHidden={true}
          >
            {() => (
              <div className="row h40 y-center">
                {weekdayNamesList.map((day, index) => {
                  const dayNumber = index + 1
                  return (
                    <div
                      key={dayNumber}
                      className={classList({
                        'row no-grow mx10 x-center': true,
                      })}
                    >
                      <Checkbox
                        isChecked={this.isWorkingDay(dayNumber)}
                        onClick={this.setProjectWorkingDays.bind(
                          this,
                          dayNumber,
                        )}
                      />
                      <span className="ml5">{day}</span>
                    </div>
                  )
                })}
              </div>
            )}
          </BaseStruxhubInput>
          {!isNewProjectSelected &&
            this.renderDoneButton(
              this.saveWorkingDays,
              this.isWorkingDaysButtonDisabled,
            )}
        </div>
        <div className="setup-form-parameter px30">
          {this.renderOperatingInterval()}
        </div>
        <div className="setup-form-parameter row px30">
          <span className="text bold large">
            {Localization.translator.holidaysAndSiteClosures}
          </span>
          {!this.closedIntervals && this.renderRemoveClosingIntervalButton()}
        </div>
        <div className="closing-intervals-holder px30">
          {this.closedIntervals && (
            <>
              {this.closedIntervals.map((interval, idx) => (
                <ProjectClosingInterval
                  key={idx}
                  item={activeProject}
                  interval={interval}
                  onRemove={this.removeClosingInterval}
                  isLastRecord={idx === this.closedIntervals.length - 1}
                />
              ))}
              {this.renderRemoveClosingIntervalButton()}
            </>
          )}
        </div>
      </div>
    )
  }

  private renderRemoveClosingIntervalButton() {
    const { activeProject } = this.store
    return (
      <div className="row y-start y-center">
        <div className="col closure-date-label">
          <div className="no-flex-children">
            <button
              className="delivery-attribute-editor-schedule-button text light-blue"
              onClick={this.showDatePickerFor.bind(this, activeProject)}
            >
              {Localization.translator.addCustomProjectClosure}
            </button>
          </div>
        </div>
      </div>
    )
  }

  private renderQRCodes = (): JSX.Element => {
    const { isFormsDisabled, isLogisticsDisabled } =
      this.props.eventsStore.appState

    return (
      <div className="setup-form-body row x-evenly">
        {this.renderQRsToPrint()}
        {!isLogisticsDisabled && (
          <QRCodeHolder
            name={Localization.translator.struxHubHome}
            openQR={this.openHomeQR}
            getPrintContent={this.getPrintContentHome}
            url={this.store.homeUrl}
            icon={<Icons.HomeRounded className="no-grow" />}
          />
        )}
        {!isFormsDisabled && (
          <QRCodeHolder
            name={Localization.translator.struxHubForms}
            openQR={this.openPermitsQR}
            getPrintContent={this.getPrintContentPermits}
            url={this.store.formsUrl}
            icon={<Icons.GeneralForm className="no-grow" />}
          />
        )}
      </div>
    )
  }

  private renderProjectSignUpQRCode = (): JSX.Element => {
    return (
      <div className="setup-form-body row x-evenly">
        {this.renderSignUpQRToPrint()}
        <QRCodeHolder
          name={Localization.translator.signUp}
          openQR={this.openProjectSignUpQR}
          getPrintContent={() => this.sigUpProjectFullscreen}
          url={this.store.projectSignUpUrl}
          icon={<Icons.User className="mw20 no-grow" />}
        />
      </div>
    )
  }

  private openProjectSignUpQR = () => {
    this.props.eventsStore.dispatch(
      SHOW_FULLSCREEN_PREVIEW,
      null,
      null,
      null,
      this.store.projectSignUpUrl,
    )
  }

  private openHomeQR = () => {
    this.props.eventsStore.dispatch(
      SHOW_FULLSCREEN_PREVIEW,
      null,
      null,
      null,
      this.store.homeUrl,
    )
  }

  private openPermitsQR = () => {
    this.props.eventsStore.dispatch(
      SHOW_FULLSCREEN_PREVIEW,
      null,
      null,
      null,
      this.store.formsUrl,
    )
  }

  @action.bound
  private removeClosingInterval(
    item: Project,
    interval: IProjectClosedInterval,
  ) {
    if (!item.closedIntervals) {
      return
    }
    const intervalsArray = this.closedIntervals.filter(i => i !== interval)
    this.store.setProjectClosureDates(intervalsArray)
  }

  private onTimezoneChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.store.preConfimedTimezoneId = e.target.value
    this.store.toggleConfirmationModal()
  }

  private onDateTimeFormatChange = (value: DateTimeFormat) => {
    this.store.setDateTimeFormat(value)
  }

  private renderProjectTypeSection = (): JSX.Element => {
    return <div className="setup-form-body">{this.projectTypeBlock()}</div>
  }

  private showDatePickerFor() {
    const { projectDateStore, eventsStore } = this.props
    const { activeProject } = eventsStore.appState
    const { startOfDay, endOfDay } = projectDateStore

    const startDate = startOfDay(new Date())
    const endDate = endOfDay(new Date())

    this.datePickerStore.showWithOptions({
      titleTranslatorKey: KnownTranslatorKeys.addCustomProjectClosures,
      initialRange: { startDate, endDate },
      handler: this.closeItem.bind(this, activeProject),
      isSeparatedMonths: true,
      isTimeSensitive: true,
      activeProject,
      onRemove: this.removeClosingInterval,
    })
  }

  private closeItem = () => {
    const { activeProject } = this.props.eventsStore.appState
    const { endOfDay } = this.props.projectDateStore
    const { intevalName, endDate, startDate } = this.datePickerStore
    const { isNewProjectSelected } = this.store
    if (!startDate) {
      return
    }

    if (!isNewProjectSelected && !activeProject.closedIntervals) {
      activeProject.closedIntervals = []
    }

    const intervalArray = this.closedIntervals
    intervalArray.push({
      name: intevalName || 'Name Closure',
      startDate: startDate.getTime(),
      endDate: endDate ? endDate.getTime() : endOfDay(startDate).getTime(),
    })

    this.store.setProjectClosureDates(intervalArray)
  }

  private get closedIntervals(): IProjectClosedInterval[] {
    const { isNewProjectSelected, closedIntervals } = this.store
    const { activeProject } = this.props.eventsStore.appState

    return isNewProjectSelected
      ? closedIntervals
      : activeProject.closedIntervals
  }

  private handleClick = () => {
    if (!this.store.isProjectErrorMessageVisible) {
      this.store.submitForm()
    }
  }

  private setProjectWorkingDays = (dayNumber: number) => {
    if (this.store.isNewProjectSelected) {
      return this.store.toggleWorkingDayForNewProject(dayNumber)
    }

    const index = this.workingDays.findIndex(day => day === dayNumber)
    if (index === -1) {
      this.workingDays.push(dayNumber)
    } else {
      this.workingDays.splice(index, 1)
    }
  }

  private get isWorkingDaysButtonDisabled(): boolean {
    return areArraysEqual(
      this.workingDays.sort(),
      this.store.activeProject.workingDays.sort(),
    )
  }

  private saveWorkingDays = () => {
    this.store.setProjectWorkingDays(this.workingDays)
  }

  private isWorkingDay(dayIndex: number) {
    if (this.store.isNewProjectSelected) {
      return !!this.store.workingDays.some(d => d === dayIndex)
    }

    return !!this.workingDays.some(d => d === dayIndex)
  }

  private toggleSection(sectionName: string) {
    this.sectionsToggleState.set(
      sectionName,
      !this.sectionsToggleState.get(sectionName),
    )
  }

  private renderSection(sectionName: string, renderContent: () => JSX.Element) {
    const shouldRenderContent = !this.sectionsToggleState.get(sectionName)
    const iconName = this.sectionsToggleState.get(sectionName)
      ? IconNames.CARET_RIGHT
      : IconNames.CARET_DOWN
    return (
      <>
        <div
          className="setup-form-header text uppercase orange pointer"
          onClick={this.toggleSection.bind(this, sectionName)}
        >
          <Icon className="no-grow title-orange-icon" icon={iconName} />
          {sectionName}
        </div>
        {shouldRenderContent && renderContent()}
      </>
    )
  }

  private renderParameterLabel(
    text: string,
    tooltipText?: string,
    className: string = 'text extra-large bold w-fit-content',
  ) {
    return (
      <div className="parameter-label row">
        <span className={className}>{text}</span>
        {tooltipText && this.renderQuestionTooltip(tooltipText)}
      </div>
    )
  }

  private renderInputBlock(
    text: string,
    errorMessageText: string,
    label: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onValueReset?: () => void,
    onButtonClick?: () => void,
    isHidden?: boolean,
    isErrorVisible?: boolean,
    isReadOnly?: boolean,
  ) {
    return (
      <div className="row y-center setup-form-parameter px30">
        <StruxhubInput
          label={label}
          isValid={!isErrorVisible}
          isRequired={true}
          value={text}
          onChange={onChange}
          onValueReset={onValueReset}
          validationMessage={isErrorVisible && errorMessageText}
          isReadOnly={isReadOnly}
          isDisabled={isReadOnly}
        />
        {!this.store.isNewProjectSelected &&
          this.renderDoneButton(onButtonClick, isHidden, isErrorVisible)}
      </div>
    )
  }

  private renderDoneButton = (
    clickHandler: () => void,
    isHidden?: boolean,
    isDisabled?: boolean,
  ): JSX.Element => {
    return (
      <SubmitButton
        text={Localization.translator.save}
        width={DONE_BUTTON_WIDTH}
        clickHandler={clickHandler}
        isHidden={isHidden}
        disabled={isDisabled}
        className="no-grow setup-form-controls done-button-container"
      />
    )
  }

  private renderProjectAdmins = (): JSX.Element => {
    return (
      <div className="setup-form-parameter col relative">
        {this.renderParameterLabel(
          Localization.translator.admins,
          Localization.translator.theListOfProjectsAdministrators,
          'text small bold w-fit-content lp075 uppercase',
        )}
        <div className="col bt-input-border ml30 admins-list mt10 bw-2 col">
          {this.adminsList.map(admin => {
            return (
              <div key={admin.id} className="row bb-light-cool-grey py5">
                <UserProfilePreview user={admin} className="w-fit-content" />
                <Icons.CrossGrey
                  onClick={this.setUserAccessType.bind(
                    this,
                    admin,
                    ProjectAccessType.Member,
                  )}
                  className="no-grow pointer"
                />
              </div>
            )
          })}
        </div>
        <div
          className="text light-blue large bold pt10 pointer px30"
          onClick={this.toggleUsersList}
        >
          {Localization.translator.addNewAdmin}
        </div>
        {this.showUsersList && this.renderAddNewAdmin()}
      </div>
    )
  }

  private renderQuestionTooltip(tooltip: string) {
    return (
      <Tooltip content={tooltip} position={Position.TOP}>
        <Icons.Question className="no-grow pointer" />
      </Tooltip>
    )
  }

  private setUserAsAdmin = (user: User) => {
    this.setUserAccessType(user, ProjectAccessType.Admin)
  }

  private setUserAccessType = (user: User, accessType: ProjectAccessType) => {
    const { userProjectsStore } = this.props
    const userProjectSettings = userProjectsStore.getByUser(user)

    userProjectSettings.accessType = accessType

    userProjectsStore.save([userProjectSettings.toDto()])
  }

  private renderAddNewAdmin() {
    return (
      <MenuCloser className="relative" closeMenu={this.toggleUsersList}>
        <div
          className="admins-list-selector col absolute beautiful-shadow"
          onClick={this.stopPropagation}
        >
          <UsersDirectory
            selectedUsers={this.commonUsersList}
            onUserRowClick={this.setUserAsAdmin}
          />
        </div>
      </MenuCloser>
    )
  }

  private toggleUsersList = () => {
    this.showUsersList = !this.showUsersList
  }

  private stopPropagation = (ev: React.SyntheticEvent) => {
    ev?.stopPropagation()
  }

  @computed
  private get commonUsersList(): User[] {
    return this.props.projectMembersStore.list.filter(
      user => !UserProject.isAdmin(user, this.props.userProjectsStore),
    )
  }

  @computed
  private get adminsList(): User[] {
    return this.props.projectMembersStore.list.filter(user =>
      UserProject.isAdmin(user, this.props.userProjectsStore),
    )
  }

  private get formattedEndDate(): Date {
    return this.props.projectDateStore.projectWorkingHours.endTime
  }

  private get formattedStartDate(): Date {
    return this.props.projectDateStore.projectWorkingHours.startTime
  }

  private renderOperatingInterval = () => {
    const { getTimeToDisplay } = this.props.projectDateStore

    const intervalCaption = `${getTimeToDisplay(
      this.projectWorkingHours.startDate,
    )} - ${getTimeToDisplay(this.projectWorkingHours.endDate)}`

    return (
      <StruxhubTextValueSelector
        className="overflow-hidden"
        label={Localization.translator.defaultWorkingHours}
        isRequired={true}
        value={intervalCaption}
        onClick={this.onWorkingIntervalClick}
      />
    )
  }

  private get projectWorkingHours() {
    const { isNewProjectSelected, workingHours } = this.store

    const startDate = isNewProjectSelected
      ? new Date(workingHours.startTime)
      : this.formattedStartDate
    const endDate = isNewProjectSelected
      ? new Date(workingHours.endTime)
      : this.formattedEndDate

    return { startDate, endDate }
  }

  private onWorkingIntervalClick = () => {
    this.timePickerStore.showWithOptions({
      initialRange: {
        startDate: this.projectWorkingHours.startDate,
        endDate: this.projectWorkingHours.endDate,
      },
      handler: this.store.setProjectWorkingHours,
      isSeparatedMonths: true,
      shouldHideCalendar: true,
      isTimeSensitive: true,
    })
  }

  private renderPrintTrigger() {
    return <Icons.CloudDownload />
  }

  private getPrintContentHome = () => {
    return this.homeFullscreenHolder
  }

  private getPrintContentPermits = () => {
    return this.homeFullscreenPermits
  }

  private renderQR(url: string, caption: string, icon: JSX.Element) {
    return (
      <div className="col x-center">
        <QRCode
          value={url}
          logoImage={QR_CODE_LOGO_SRC}
          size={QR_CODE_TO_PRINT_SIZE}
          fgColor={ThemeMode.getHEXColor(Colors.neutral0)}
        />
        <div className="row x-center text bold size24">
          {icon}
          {caption}
        </div>
      </div>
    )
  }

  private renderSignUpQRToPrint() {
    return (
      <div className="hidden">
        <div
          ref={node => (this.sigUpProjectFullscreen = node)}
          className="qr-code-to-print-holder"
        >
          {this.renderQR(
            this.store.projectSignUpUrl,
            Localization.translator.signUp,
            <Icons.User className="no-grow" />,
          )}
        </div>
      </div>
    )
  }

  private renderQRsToPrint() {
    const { isFormsDisabled, isLogisticsDisabled } =
      this.props.eventsStore.appState
    return (
      <>
        {!isLogisticsDisabled && (
          <div className="hidden">
            <div
              ref={node => (this.homeFullscreenHolder = node)}
              className="qr-code-to-print-holder"
            >
              {this.renderQR(
                this.store.homeUrl,
                Localization.translator.struxHubHome,
                <Icons.HomeRounded className="no-grow" />,
              )}
            </div>
          </div>
        )}
        {!isFormsDisabled && (
          <div className="hidden">
            <div
              ref={node => (this.homeFullscreenPermits = node)}
              className="qr-code-to-print-holder"
            >
              {this.renderQR(
                this.store.formsUrl,
                Localization.translator.struxHubForms,
                <Icons.GeneralForm className="no-grow" />,
              )}
            </div>
          </div>
        )}
      </>
    )
  }
}
