import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import TooltipWrapper from '~/client/src/desktop/components/TooltipWrapper/TooltipWrapper'
import * as Icons from '~/client/src/shared/components/Icons'

// localization: no text to translate

interface IProps {
  title: string

  stepId?: string
  isColumn?: boolean
  isDisabled?: boolean
  isBorderHidden?: boolean
  hasPointer?: boolean
  hasFixedHeight?: boolean
  tooltipContent?: string | JSX.Element

  onClick?(stepId: string): void
}

@observer
export default class WorkflowStepBlock extends React.Component<IProps> {
  public render() {
    const {
      title,
      isColumn,
      isDisabled,
      isBorderHidden,
      hasPointer,
      hasFixedHeight,
      tooltipContent,
      children,
    } = this.props

    return (
      <div className="row y-start px15 mb8 bg-white">
        <span className="row mw160 py6 text grey-30 large line-extra-large">
          {title}
          {!!tooltipContent && (
            <TooltipWrapper
              content={tooltipContent}
              className="row no-grow ml8"
            >
              <Icons.Info className="row no-grow text grey-30" />
            </TooltipWrapper>
          )}
        </span>
        <div
          className={classList({
            'min-height32': true,
            row: !isColumn,
            col: isColumn,
            pointer: hasPointer,
            'px8 ba-light-grey brada4': !isBorderHidden,
            'inactive-element': isDisabled,
            h32: hasFixedHeight,
          })}
          onClick={this.onBlockClick}
        >
          {children}
        </div>
      </div>
    )
  }

  private onBlockClick = () => {
    const { isDisabled, stepId, onClick } = this.props
    if (!isDisabled && stepId && onClick) onClick(stepId)
  }
}
