import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'

import { SelectionMap } from '~/client/src/desktop/stores/ui/BaseTagSelection.store'
import CheckState from '~/client/src/shared/components/Checkbox/CheckState'
import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import TagIconByTagType from '~/client/src/shared/components/TagIconByTagType/TagIconByTagType'
import UserProfilePreview from '~/client/src/shared/components/UserProfilePreview/UserProfilePreview'
import { TagType } from '~/client/src/shared/enums/TagType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import { ITag } from '~/client/src/shared/models/Tag'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import { getBandTitleByTagType } from '~/client/src/shared/utils/TagHelper'
import { NOOP } from '~/client/src/shared/utils/noop'

import TagsSelectorStore, { NavigationItem } from './TagsSelector.store'
import { BreadCrumbs } from './components/BreadCrumbs'

import Colors from '~/client/src/shared/theme.module.scss'

import './TagsSelector.scss'

// localization: translated

interface IProps {
  availableTypes: TagType[]
  onChange: (selectionMap: SelectionMap) => void
  onTagSelected?: (tag: ITag) => void

  initSelection?: SelectionMap
  radioTagTypes?: TagType[]
  separatedCategories?: TagType[]

  searchKey?: string
  onSearchReset?: () => void

  tagsStore?: TagsStore

  className?: string
  shouldShowUserCount?: boolean
  projectMembersStore?: ProjectMembersStore
}

const categoryIconSize = 18
const tagIconSize = 16

@inject('tagsStore', 'projectMembersStore')
@observer
export default class TagsSelector extends React.Component<IProps> {
  public static defaultProps = {
    radioTagTypes: [],
    initSelection: new Map(),
    onSearchReset: NOOP,
    onTagSelected: NOOP,
    separatedCategories: [],
    shouldShowUserCount: true,
  }

  private readonly store: TagsSelectorStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new TagsSelectorStore(
      props.tagsStore,
      props.onSearchReset,
      props.availableTypes,
      props.radioTagTypes,
      props.separatedCategories,
      props.initSelection,
    )
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const { initSelection, searchKey } = this.props

    if (initSelection !== prevProps.initSelection) {
      this.store.setSelection(initSelection)
    }

    if (searchKey !== prevProps.searchKey) {
      this.store.setSearchKey(searchKey)
    }
  }

  public render() {
    const { className } = this.props
    const { isSearchMode, isSomeCategorySelected } = this.store

    let content
    switch (true) {
      case isSearchMode:
        content = this.renderSearchResults()
        break

      case isSomeCategorySelected:
        content = this.renderCategoryContent()
        break

      default:
        content = (
          <>
            {this.renderCategoriesSelector()}
            {this.renderSelectedTags()}
          </>
        )
    }

    return (
      <div className={`col tags-container ${className || ''}`}>{content}</div>
    )
  }

  private renderSearchResults(): JSX.Element[] {
    const { searchResByCategories, categories } = this.store

    return categories.map(category => {
      const list = searchResByCategories[category]

      if (!list || !list.length) {
        return null
      }

      return (
        <React.Fragment key={category}>
          <div className="row category-separator pt24 pb12">
            <span className="text light bold lp05 uppercase no-grow px4">
              {getBandTitleByTagType(category)}
            </span>
          </div>
          {list.map(tag => this.renderTagRow(tag, true))}
        </React.Fragment>
      )
    })
  }

  private renderUserRow = (tag: ITag) => {
    const user = this.props.projectMembersStore.getById(tag.id)

    return (
      <div key={tag.id} className="row py6">
        <span className="no-grow mr12">{this.renderToggle(tag)}</span>
        <UserProfilePreview user={user} />
      </div>
    )
  }

  private renderCategoryContent() {
    const {
      navigationChain,
      head: { categoryId, itemId: subCategoryId },
      handleBackClick,
      handleNavItemClick,
    } = this.store

    return (
      <>
        <BreadCrumbs
          navigationChain={navigationChain}
          onBackClick={handleBackClick}
          onItemClick={handleNavItemClick}
        />
        {this.renderTagsList(categoryId, subCategoryId)}
      </>
    )
  }

  private renderListPlaceholder(): JSX.Element {
    return (
      <div className="col">
        <span className="text large">
          {Localization.translator.thereIsNoInstanceInTheSelectedCategory}...
        </span>
        <span
          onClick={this.store.handleBackClick}
          className="text large primary-blue underline mt5 pointer"
        >
          {Localization.translator.goBack}
        </span>
      </div>
    )
  }

  private renderCategoriesSelector(): JSX.Element[] {
    const { categories, selectNavigationItem, withSeparator } = this.store

    return categories.map(category => {
      const title = getBandTitleByTagType(category)

      return (
        <React.Fragment key={category}>
          {withSeparator(category) && (
            <div className="bt-palette-brand-lighter my5" />
          )}
          <div
            className="row py15 pointer"
            onClick={selectNavigationItem.bind(this, {
              categoryId: category,
              itemId: null,
              title,
            } as NavigationItem)}
          >
            <TagIconByTagType
              size={categoryIconSize}
              tagType={category}
              className="no-grow mr8"
            />
            <span className="text large">{title}</span>
            <Icon icon={IconNames.CARET_RIGHT} color={Colors.neutral60} />
          </div>
        </React.Fragment>
      )
    })
  }

  private renderSelectedTags() {
    const selectedTags = [...this.store.selection.keys()].map(tag =>
      this.renderTagRow(tag, true),
    )

    if (!selectedTags.length) {
      return null
    }

    return (
      <>
        <div className="row category-separator pt24 pb12">
          <span className="text light bold lp05 uppercase no-grow px4">
            {Localization.translator.selected}
          </span>
        </div>
        {selectedTags}
      </>
    )
  }

  private renderTagRow = (tag: ITag, canNavigateToEmpty?: boolean) => {
    const { type: categoryId, id: tagId, name: tagName } = tag

    if (categoryId === TagType.User) {
      return this.renderUserRow(tag)
    }

    const { selectNavigationItem, canSpecificUserTagBeSelected, canNavigate } =
      this.store
    const {
      tagsStore: { usersByRelatedTagIdMap },
      shouldShowUserCount,
    } = this.props

    const usersAmount = (usersByRelatedTagIdMap[tagId] || []).length

    const shouldNavigateOnClick = canNavigateToEmpty
      ? canSpecificUserTagBeSelected && canNavigate(categoryId, tagId)
      : canSpecificUserTagBeSelected

    const onClick = shouldNavigateOnClick
      ? selectNavigationItem.bind(this, {
          categoryId,
          itemId: tagId,
          title: tagName,
        } as NavigationItem)
      : NOOP

    return (
      <div className="row py15 pointer" key={tagId} onClick={onClick}>
        <span className="no-grow">{this.renderToggle(tag)}</span>
        <SitemapAttributeTag
          dataObject={tag as LocationAttributeBase}
          shouldShowAsTag={false}
          iconSize={tagIconSize}
        >
          <span>{tag.name}</span>
        </SitemapAttributeTag>
        {shouldShowUserCount && (
          <span className="text light no-grow nowrap">
            ({Localization.translator.xUsers(usersAmount)})
          </span>
        )}
        {canSpecificUserTagBeSelected && (
          <Icon
            className="no-grow"
            icon={IconNames.CARET_RIGHT}
            color={Colors.neutral60}
          />
        )}
      </div>
    )
  }

  private renderTagsList(categoryId: string, subCategoryId?: string) {
    const list = this.store.getCategoryTags(categoryId, subCategoryId)

    return list && list.length
      ? list.map(tag => this.renderTagRow(tag))
      : this.renderListPlaceholder()
  }

  private handleToggleTag(
    tag: ITag,
    isRadio: boolean,
    event: React.MouseEvent<HTMLElement>,
  ) {
    event.stopPropagation()

    const { toggleRadio, toggleCheckbox, selection } = this.store

    if (isRadio) {
      toggleRadio(tag)
    } else {
      toggleCheckbox(tag)
    }

    this.props.onChange(selection)
    this.props.onTagSelected(tag)
  }

  private renderToggle(tag: ITag) {
    const { isRadioTag, selection } = this.store

    const checkState = selection.get(tag)
    const isRadio = isRadioTag(tag)
    const onToggle = this.handleToggleTag.bind(this, tag, isRadio)

    return (
      <Checkbox
        isChecked={checkState === CheckState.CHECKED}
        isMinus={checkState === CheckState.PARTIALLY_CHECKED}
        onClick={onToggle}
        shouldDisplayAsRadio={isRadio}
      />
    )
  }
}
