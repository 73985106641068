import { action, computed, observable } from 'mobx'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import {
  ILWFCCategory,
  ILWFCColumn,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import { sortCompaniesByTypeAndNamePredicate } from '~/client/src/shared/constants/companyType'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import User from '~/client/src/shared/models/User'
import UserProject from '~/client/src/shared/models/UserProject'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import BaseListStore, {
  CATEGORY_ROW_HEIGHT,
} from '~/client/src/shared/stores/ui/BaseList.store'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'

export enum DataKeys {
  CHECKBOX = 'checkbox',
  ID = 'id',
  NAME = 'name',
  ROLES = 'roles',
  TEAMS = 'teams',
  TRADES = 'trades',
  ACCOUNT_POSITION = 'account-position',
  ACCOUNT_TYPE = 'account-type',
  EMAIL = 'email',
  PHONE = 'phone',
  STATUS = 'status',
  PROJECTS = 'projects',
  EMPLOYEE_ID = 'employeeId',
  HIDDEN_SELECTION = 'hidden-selection',
}

const FILTER_KEYS = ['fullName', 'email', 'employeeId']

export default class ProjectMembersListStore extends BaseListStore<User> {
  @observable public isSelectorDisplayed: boolean = false
  @observable public userWithOpenDropdownId: string = ''
  public readonly columns: ILWFCColumn[] = [
    {
      dataKey: DataKeys.CHECKBOX,
      width: 60,
    },
    {
      translatorKey: KnownTranslatorKeys.name,
      dataKey: DataKeys.NAME,
      width: 150,
    },
    {
      translatorKey: KnownTranslatorKeys.inviteStatus,
      dataKey: DataKeys.STATUS,
      width: 120,
    },
    {
      translatorKey: KnownTranslatorKeys.projectRoles,
      dataKey: DataKeys.ROLES,
      width: 185,
    },
    {
      translatorKey: KnownTranslatorKeys.projectTrades,
      dataKey: DataKeys.TRADES,
      width: 185,
    },
    {
      translatorKey: KnownTranslatorKeys.teams,
      dataKey: DataKeys.TEAMS,
      width: 185,
    },
    {
      translatorKey: KnownTranslatorKeys.accountPosition,
      dataKey: DataKeys.ACCOUNT_POSITION,
      width: 120,
    },
    {
      translatorKey: KnownTranslatorKeys.accountType,
      dataKey: DataKeys.ACCOUNT_TYPE,
      width: 130,
    },
    {
      translatorKey: KnownTranslatorKeys.email_noun,
      dataKey: DataKeys.EMAIL,
      width: 270,
    },
    {
      translatorKey: KnownTranslatorKeys.phone,
      dataKey: DataKeys.PHONE,
      width: 150,
    },
    {
      translatorKey: KnownTranslatorKeys.employeeIDNumber,
      dataKey: DataKeys.EMPLOYEE_ID,
      width: 150,
    },
  ]

  public constructor(
    state: DesktopInitialState,
    private readonly companiesStore: CompaniesStore,
    private readonly userProjectsStore: UserProjectsStore,
    projectMembersStore: ProjectMembersStore,
  ) {
    super(
      state.teamMembersFilters,
      () => projectMembersStore.list,
      FILTER_KEYS,
      undefined,
      true,
    )
  }

  @computed
  public get filteredCollection() {
    return this.getFilteredCollectionExcludeFilter().sort(
      (
        { lastName: ln1, firstName: fn1 },
        { lastName: ln2, firstName: fn2 },
      ) => {
        return (
          ln1?.toLowerCase().localeCompare(ln2?.toLowerCase()) ||
          fn1?.toLowerCase().localeCompare(fn2?.toLowerCase())
        )
      },
    )
  }

  @computed
  public get userWithOpenDropDown(): User {
    return this.sourceCollection().find(
      inst => this.userWithOpenDropdownId === inst[this.idKey],
    )
  }

  public getTableWidth = (): number => {
    return this.columns.reduce((acc, { width }) => acc + width, 0)
  }

  @action.bound
  public toggleSelector() {
    this.isSelectorDisplayed = !this.isSelectorDisplayed
  }

  @action.bound
  public handleSelectAll() {
    this.selectAll()
    this.toggleSelector()
  }

  @action.bound
  public handleUnselectAll() {
    this.unselectAll()
    this.toggleSelector()
  }

  @action.bound
  public selectNotInvitedMembers() {
    this.filteredCollection.forEach(u => {
      this.setInstanceSelection(
        u.id,
        UserProject.isNotInvited(u, this.userProjectsStore),
      )
    })

    this.toggleSelector()
  }

  public getValidCompanyId(companyId: string) {
    if (!companyId || companyId === UNASSIGNED) {
      return null
    }

    return companyId
  }

  protected formatCategoryId(categoryId: any, user: User) {
    return Object.keys(this.categoryToInstancesMap).find(userCategory => {
      return this.categoryToInstancesMap[userCategory].includes(user)
    })
  }

  protected createCategoryRow(categoryId: string) {
    const instances = this.categoryToInstancesMap[categoryId]
    const category: ILWFCCategory = {
      categoryId,
      categoryLabel: categoryId,
      isChecked:
        instances.length &&
        instances.every(
          user => !this.canSelectInstance(user) || this.selection.get(user.id),
        ),
    }
    return { category, data: {}, height: CATEGORY_ROW_HEIGHT }
  }

  @computed
  protected get categoryToInstancesMap(): { [categoryId: string]: User[] } {
    const map = {}

    this.filteredCollection.forEach(member => {
      let companyId = UserProject.getCompanyId(member, this.userProjectsStore)
      const company = this.companiesStore.getCompanyById(companyId, true)

      companyId = (company && company.id) || UNASSIGNED

      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;(map[companyId] || (map[companyId] = [])).push(member)
    })

    return map
  }

  protected sortCategories(keys: string[]): string[] {
    const { getCompanyById, getCompanyTypeTagsByIds } = this.companiesStore

    return keys.sort((companyAId: string, companyBId: string) =>
      sortCompaniesByTypeAndNamePredicate(
        companyAId,
        companyBId,
        getCompanyById,
        getCompanyTypeTagsByIds,
      ),
    )
  }

  protected toRows(users: User[]) {
    return users.map(user => {
      const { id, phoneNumber, email, accountPosition, employeeId } = user

      const { roles, trades, defaultTeams, teams } =
        this.userProjectsStore.getByUser(user)

      const accountType = UserProject.getAccessType(
        user,
        this.userProjectsStore,
      )

      const inviteCaption = UserProject.getInviteStatusCaption(
        user,
        this.userProjectsStore,
      )

      const allTeams = (teams || []).concat(defaultTeams).filter(t => !!t)

      return {
        data: {
          [DataKeys.ID]: id,
          [DataKeys.CHECKBOX]: this.selection.get(id),
          [DataKeys.NAME]: id,
          [DataKeys.ROLES]: roles,
          [DataKeys.TEAMS]: allTeams,
          [DataKeys.TRADES]: trades,
          [DataKeys.ACCOUNT_POSITION]: accountPosition,
          [DataKeys.ACCOUNT_TYPE]: accountType,
          [DataKeys.EMAIL]: email,
          [DataKeys.PHONE]: phoneNumber,
          [DataKeys.EMPLOYEE_ID]: employeeId,
          [DataKeys.STATUS]: inviteCaption,
          // for status' actions dropdown
          [DataKeys.HIDDEN_SELECTION]: this.hiddenSelection.get(id),
        },
      }
    })
  }
}
