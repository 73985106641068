import * as React from 'react'

import { KonvaEventObject } from 'konva/types/Node'
import { Stage } from 'konva/types/Stage'
import { observable } from 'mobx'
import { MobXProviderContext, Provider, inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { isMacOs } from 'react-device-detect'

import { SitemapItemShapeType } from '~/client/graph'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import BaseMapView from '~/client/src/shared/components/BaseMapView/BaseMapView'
import { Loader } from '~/client/src/shared/components/Loader'
import GlobeViewItems from '~/client/src/shared/components/SitemapHelpers/components/GlobeViewItems'
import GlobeViewPlans from '~/client/src/shared/components/SitemapHelpers/components/GlobeViewPlans'
import SitemapItems from '~/client/src/shared/components/SitemapHelpers/components/SitemapItems'
import MapViewItemDrawnPart from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemDrawnPart'
import ICanvasImageCache from '~/client/src/shared/interfaces/ITextboxesCache'
import IGeoPosition from '~/client/src/shared/models/IGeoPosition'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import Awaiter from '~/client/src/shared/utils/Awaiter'

import MapViewSetUpStore from '../../MapViewSetUp.store'
import { ViewType } from '../../stores/MapBoxEditor.store'
import CreateNewItemArea from './CreateNewItemArea'
import GlobeVIewEditableItem from './GlobeViewEditableItem'
import RichTextEditor from './RichTextEditor'
import SitemapEditableItem from './SitemapEditableItem'

export interface IProps {
  store: MapViewSetUpStore
  setViewport: (
    viewport: IGeoPosition,
    isLoaded: boolean,
    isRubberMode?: boolean,
  ) => void
  opacity: number

  eventsStore?: DesktopEventStore
  basemapsStore?: BasemapsStore
  projectDateStore?: ProjectDateStore

  isLimited?: boolean
  isRubberMode?: boolean

  renderChildren?: (width: number, height: number) => JSX.Element
}

const ESCAPE_KEY_CODE = 27
const DELETE_KEY_CODE = 46
const BACKSPACE_KEY_CODE = 8
const ENTER_KEY_CODE = 13
const Z_KEY_CODE = 'KeyZ'
export const C_KEY_CODE = 'KeyC'
export const V_KEY_CODE = 'KeyV'

const INPUTS_NODE_NAMES = ['INPUT', 'TEXTAREA']
const nodeNameProp = 'nodeName'
const contentEditableProp = 'contentEditable'

@inject('eventsStore', 'basemapsStore', 'globeViewsStore', 'projectDateStore')
@observer
export default class MapEditor extends React.Component<IProps> {
  @observable private deliverySitemap: BaseMapView
  private clearPostEventCallback: () => void
  private clearPostEventCallbackItems: () => void
  private textboxesAwaiter: Awaiter = new Awaiter()
  private textboxesCache: ICanvasImageCache = {}
  @observable private isLoaded: boolean = false
  public static contextType = MobXProviderContext

  public constructor(props: IProps) {
    super(props)
    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.onSaveSitemapImageRequest,
    )
    !this.props.store.globeViewSetupStore.selectedGlobeView &&
      this.props.store.globeViewSetupStore.selectGlobe(
        this.props.store.globeViewsStore.list[0],
      )
    this.props.store.mapBoxViewerStore.setViewportFromAddress()
  }

  public UNSAFE_componentWillMount(): void {
    document.addEventListener('keydown', this.onKeyDown)
  }

  public componentWillUnmount(): void {
    const { removeRefs, setDefaultMapMode, resetMapboxInfo } =
      this.props.store.mapBoxViewerStore

    if (this.clearPostEventCallback) {
      this.clearPostEventCallback()
    }
    if (this.clearPostEventCallbackItems) {
      this.clearPostEventCallbackItems()
    }
    document.removeEventListener('keydown', this.onKeyDown)
    this.textboxesCache = {}
    removeRefs()
    setDefaultMapMode()
    resetMapboxInfo()
  }

  public render(): JSX.Element {
    const {
      store: {
        sitemapsSetupStore: { sitemapUrl, selectedSitemap },
        globeViewSetupStore: { selectedGlobeView },
        globeViewsStore,
        mapViewItemsSetupStore: {
          creatableAttributeType,
          isTextStickerCreationActive,
          isSitemapBlocked,
          selectedMapViewItem,
          createAttributeInPosition,
        },
        mapBoxViewerStore,
        mapBoxEditorStore,
        isGlobeMode,
      },
      opacity,
      basemapsStore,
      eventsStore: { appState },
      projectDateStore,
      isRubberMode,
    } = this.props
    const {
      isDraggingMode,
      createMapImage,
      onKeyDown,
      onKeyUp,
      onMouseDown,
      onMouseUp,
      onMouseMove,
      onMapClick,
      onMapDblClick,
    } = mapBoxEditorStore

    if (!selectedSitemap && !selectedGlobeView) {
      return <Loader />
    }

    return (
      <div
        className={classList({
          'sitemap-editor full-width full-height relative general-map-container':
            true,
          'unclickable-element': isSitemapBlocked,
        })}
        onClick={this.stopPropagation}
      >
        <BaseMapView
          globe={selectedGlobeView}
          selectedSitemap={selectedSitemap}
          globeSitemaps={selectedGlobeView?.sitemaps}
          globeViewsStore={globeViewsStore}
          setViewport={this.setViewport}
          appState={appState}
          projectDateStore={projectDateStore}
          sitemapUrl={sitemapUrl}
          isDraggable={true}
          onWhiteboardClick={this.onWhiteboardClick}
          isDraggingMode={isDraggingMode}
          opacity={opacity}
          mapBoxViewerStore={mapBoxViewerStore}
          ref={this.setDeliverySitemap}
          getEditableItem={this.getTextEditor}
          creatableAttributeType={creatableAttributeType}
          isEditorMode={true}
          basemapsStore={basemapsStore}
          projectAddress={appState.projectAddress}
          isRubberMode={isRubberMode}
          isTextStickerCreationActive={isTextStickerCreationActive}
          areAdditionalMarkersAvailable={true}
          selectedMapViewItem={selectedMapViewItem}
          createAttributeInPosition={createAttributeInPosition}
          createMapImage={createMapImage}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onMapClick={onMapClick}
          onMapDblClick={onMapDblClick}
        >
          {({ width, height }) => {
            if (!this.deliverySitemap) {
              return null
            }

            return (
              <Provider {...this.context}>
                {isGlobeMode
                  ? this.renderGlobeViewChildren()
                  : this.renderSitemapChildren(width, height)}
              </Provider>
            )
          }}
        </BaseMapView>
      </div>
    )
  }

  private setViewport = viewport => {
    if (!this.props.store.mapBoxEditorStore.shouldUpdateViewport) {
      return
    }
    this.props.store.mapBoxViewerStore.setViewport(viewport)
    this.props.setViewport?.(
      viewport,
      this.isLoaded,
      this.props.store.mapBoxEditorStore.isRubberMode,
    )

    // to prevent first viewport to be saved
    this.isLoaded = true
  }

  private renderGlobeViewChildren = (): JSX.Element => {
    const {
      store: {
        mapViewItemsSetupStore,
        mapBoxViewerStore,
        mapBoxEditorStore,
        sitemapsSetupStore,
      },
      isLimited,
    } = this.props
    const {
      displayedGlobeItems,
      isRubberMode,
      displayedSitemapWithBasemaps,
      selectMapViewItem,
    } = mapBoxEditorStore
    const { selectSitemapItemDrawnPart } = mapViewItemsSetupStore

    return (
      <>
        <GlobeViewPlans
          mapBoxViewerStore={mapBoxViewerStore}
          isEditor={true}
          sitemapWithBasemaps={displayedSitemapWithBasemaps}
          isRubberMode={isRubberMode}
          selectedSitemap={sitemapsSetupStore.selectedSitemap}
        />
        <GlobeViewItems
          items={displayedGlobeItems || []}
          onSelectItem={!isRubberMode && !isLimited && selectMapViewItem}
          selectSitemapItemDrawnPart={!isLimited && selectSitemapItemDrawnPart}
          mapBoxViewerStore={mapBoxViewerStore}
        />
        {!isLimited && (
          <GlobeVIewEditableItem
            store={mapViewItemsSetupStore}
            mapBoxViewerStore={mapBoxViewerStore}
            mapBoxEditorStore={mapBoxEditorStore}
          />
        )}
      </>
    )
  }

  private renderSitemapChildren = (
    width: number,
    height: number,
  ): JSX.Element => {
    const {
      store: { mapViewItemsSetupStore, mapBoxEditorStore, sitemapsSetupStore },
      isLimited,
    } = this.props
    const { selectedViewType } = mapBoxEditorStore
    const { selectMapViewItem, displayedSitemapItems } = mapViewItemsSetupStore
    return (
      <>
        <SitemapItems
          isDisabled={selectedViewType === ViewType.Plans}
          items={displayedSitemapItems || []}
          containerWidth={width}
          containerHeight={height}
          onSelectItem={!isLimited && selectMapViewItem}
          isEditMode={true}
          textboxesAwaiter={this.textboxesAwaiter}
          textboxesCache={this.textboxesCache}
        />
        {!isLimited && (
          <>
            <SitemapEditableItem
              containerWidth={width}
              containerHeight={height}
              store={mapViewItemsSetupStore}
              textboxesCache={this.textboxesCache}
              sitemapsSetupStore={sitemapsSetupStore}
            />
            <CreateNewItemArea
              containerWidth={width}
              containerHeight={height}
              store={mapViewItemsSetupStore}
              sitemapsSetupStore={sitemapsSetupStore}
            />
          </>
        )}
      </>
    )
  }

  private getTextEditor = (
    width: number,
    height: number,
    stage: Stage,
  ): JSX.Element => {
    const {
      sitemapsSetupStore: { selectedSitemap },
      mapViewItemsSetupStore: {
        selectedMapViewItem,
        isTextStickerCreationActive,
      },
      isGlobeMode,
    } = this.props.store

    if (isGlobeMode) {
      return null
    }

    return (
      !isTextStickerCreationActive &&
      selectedMapViewItem?.sitemapItemProperties?.labelProperties &&
      selectedMapViewItem.isRichTextBox && (
        <RichTextEditor
          canvasSize={{ width, height }}
          stage={stage.getStage()}
          selectedMapViewItem={selectedMapViewItem}
          isReferenced={selectedSitemap?.isReferenced}
        />
      )
    )
  }

  private onWhiteboardClick = (event: KonvaEventObject<MouseEvent>): void => {
    if (
      event?.evt?.cancelBubble ||
      this.props.store.mapBoxEditorStore.isRubberMode
    ) {
      return
    }
    this.deselectEditing()
    if (
      !!this.props.store.sitemapsSetupStore.selectedSitemap &&
      this.props.store.sitemapsSetupStore.selectedSitemap.isReferenced
    ) {
      this.props.store.sitemapsSetupStore.deselectSitemap()
    }
  }

  private onKeyDown = (event: KeyboardEvent): void => {
    const {
      selectedMapViewItem,
      selectedMapViewItemDrawnPart,
      showDeleteConfirmationDialog,
      disableCreatingAttribute,
      redoAction,
      undoAction,
      focusedLevel,
      isSitemapBlocked,
      isGlobeMode,
    } = this.props.store.mapViewItemsSetupStore
    const isCtrlPressed = event.ctrlKey || (isMacOs && event.metaKey)
    const isShiftPressed = event.shiftKey
    const { target } = event

    if (isMacOs && isCtrlPressed && !this.isCopyPasteMode(event.code)) {
      event.preventDefault()
    }

    switch (event.keyCode) {
      // Backspace === Delete on MacOS
      case BACKSPACE_KEY_CODE:
        if (!isMacOs) {
          break
        }
      // eslint-disable-next-line no-fallthrough
      case DELETE_KEY_CODE:
        if (
          INPUTS_NODE_NAMES.includes(target && target[nodeNameProp]) ||
          // check if it is rich text editor
          (target && target[contentEditableProp] === 'true')
        ) {
          return
        }

        if (
          selectedMapViewItem &&
          selectedMapViewItemDrawnPart === MapViewItemDrawnPart.Shape && // Line has custom behavior on DELETE click
          ((isGlobeMode &&
            selectedMapViewItem.globeViewItemProperties.shapeProperties &&
            selectedMapViewItem.globeViewItemProperties.shapeProperties.type ===
              SitemapItemShapeType.Polyline) ||
            (selectedMapViewItem.sitemapItemProperties.shapeProperties &&
              selectedMapViewItem.sitemapItemProperties.shapeProperties.type ===
                SitemapItemShapeType.Polyline))
        ) {
          return
        }
        showDeleteConfirmationDialog(selectedMapViewItem)
        disableCreatingAttribute()
        break
      case ESCAPE_KEY_CODE:
        if (isSitemapBlocked) {
          break
        }
        disableCreatingAttribute()
      // eslint-disable-next-line no-fallthrough
      case ENTER_KEY_CODE:
        if (
          (!selectedMapViewItem || !selectedMapViewItem.isRichTextBox) &&
          !focusedLevel
        ) {
          this.deselectEditing()
        }
        break
    }

    switch (event.code) {
      case Z_KEY_CODE:
        if (isShiftPressed && isCtrlPressed) {
          redoAction()
        } else if (isCtrlPressed) {
          undoAction()
        }
    }
  }

  private deselectEditing(): void {
    const {
      selectedMapViewItem,
      selectedMapViewItemDrawnPart,
      safelyDeselectMapViewItem,
      deselectMapViewItemDrawnPart,
      isSitemapBlocked,
    } = this.props.store.mapViewItemsSetupStore

    if (isSitemapBlocked) {
      return
    }

    if (
      selectedMapViewItemDrawnPart &&
      (selectedMapViewItem.sitemapItemProperties.hasMultipleParts ||
        selectedMapViewItem.globeViewItemProperties.hasMultipleParts)
    ) {
      deselectMapViewItemDrawnPart()
      return
    }
    safelyDeselectMapViewItem()
  }

  private setDeliverySitemap = ref => {
    this.deliverySitemap = ref
  }

  private stopPropagation(event: React.MouseEvent<HTMLDivElement>): void {
    event.nativeEvent.preventDefault()
    event.nativeEvent.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }

  private onSaveSitemapImageRequest = async (
    eventContext: EventContext,
  ): Promise<void> => {
    const [eventType] = eventContext.event
    if (eventType === e.SAVE_SITEMAP_IMAGE && this.deliverySitemap) {
      await this.textboxesAwaiter.ready()
      const base64ItemsImage = await this.deliverySitemap.getItemsDataURL()
      const base64Image = await this.deliverySitemap.getDataURL()
      await this.props.store.saveDeliverySitemapImage(
        base64Image,
        base64ItemsImage,
      )
    }
    if (eventType === e.SAVE_GLOBE_IMAGE && this.deliverySitemap) {
      const mapboxImage =
        await this.props.store.mapBoxEditorStore.createMapImage()
      await this.props.store.saveGlobeViewImage(mapboxImage)
    }
  }

  private isCopyPasteMode(code: string): boolean {
    return code === C_KEY_CODE || code === V_KEY_CODE
  }
}
