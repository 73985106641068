import * as React from 'react'

import { action } from 'mobx'

import ConfirmDialogTypes from '~/client/src/desktop/enums/ConfirmDialogTypes'
import ConfirmDialog from '~/client/src/shared/components/ConfirmDialog/ConfirmDialog'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

interface IProps<ItemType> {
  type?: ConfirmDialogTypes
  isOpen: boolean
  closeDialog: () => void
  performItems: (items: ItemType[]) => void
  loading: boolean
  itemsToPerform: ItemType[]
}

const defaultKeyProp = 'id'

interface ITableInfo<ItemType> {
  columnTitleKeys: KnownTranslatorKeys[]
  columnProps: (string | ((item: ItemType) => string))[]
  keyProp?: string
}

export default abstract class ConfirmItemsDialog<
  ItemType,
  AdditionalProps = void,
> extends React.Component<IProps<ItemType> & AdditionalProps> {
  protected dialogTitleKey: KnownTranslatorKeys
  protected dialogDoneBtnTitleKey: KnownTranslatorKeys
  protected questionKey: KnownTranslatorKeys
  protected tableInfo: ITableInfo<ItemType>

  protected get keyProp() {
    return this.tableInfo.keyProp || defaultKeyProp
  }

  public render() {
    const { isOpen, closeDialog, loading, itemsToPerform } = this.props

    return (
      <ConfirmDialog
        isOpen={isOpen}
        loading={loading}
        title={Localization.getText(this.dialogTitleKey)}
        question={Localization.getText(this.questionKey, itemsToPerform.length)}
        onCancelClicked={closeDialog}
        onDoneClicked={this.performItems}
        doneTitle={Localization.getText(this.dialogDoneBtnTitleKey)}
        className="confirm-items-dialog"
      >
        {this.renderItemsToPerform()}
      </ConfirmDialog>
    )
  }

  private renderItemsToPerform() {
    const { itemsToPerform } = this.props

    return (
      <div className="confirm-items-dialog-table">
        <div className="row confirm-items-dialog-row-header">
          {this.renderItemRow(this.headerColumnValues)}
        </div>
        <hr className="confirm-items-dialog-separator" />
        {itemsToPerform.map(item => (
          <div
            key={item[this.keyProp]}
            className="row confirm-items-dialog-row"
          >
            {this.renderItemRow(this.getColumnValuesByItem(item))}
          </div>
        ))}
      </div>
    )
  }

  private getFieldValue(
    item: ItemType,
    columnProp: string | ((item: ItemType) => string),
  ) {
    return (
      (typeof columnProp === 'string' ? item[columnProp] : columnProp(item)) ||
      NO_VALUE
    )
  }

  private get headerColumnValues(): string[] {
    return this.tableInfo.columnTitleKeys.map(headerColumnKey =>
      Localization.getText(headerColumnKey),
    )
  }

  private getColumnValuesByItem(item: ItemType): string[] {
    return this.tableInfo.columnProps.map(valueProp =>
      this.getFieldValue(item, valueProp),
    )
  }

  private renderItemRow(columnValues: string[]): JSX.Element[] {
    return columnValues.map((cValue, i) => (
      <span key={i} className="confirm-items-dialog-col">
        {cValue}
      </span>
    ))
  }

  @action.bound
  private performItems() {
    const { itemsToPerform, performItems } = this.props
    performItems(itemsToPerform)
  }
}
