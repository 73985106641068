import { DeliveryFilterType } from '~/client/graph'
import FormReportFilterType from '~/client/src/desktop/enums/FormReportFilterType'
import ScanStationFilterType from '~/client/src/desktop/enums/ScanStationFilterType'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import { UNASSIGNED_FILTER_OPTION } from '~/client/src/shared/components/Deliveries/DeliveriesView.store'
import Delivery from '~/client/src/shared/models/Delivery'
import IScannerHistoryPair from '~/client/src/shared/models/IScannerHistoryPair'
import SitePermit from '~/client/src/shared/models/Permit'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ScannersStore from '~/client/src/shared/stores/domain/Scanners.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import { LOCATION_SEPARATOR } from '~/client/src/shared/utils/usefulStrings'

import ReportsStore from '../../../../Reports.store'
import DeliveryReportListStore from '../../../ReportsList/DeliveryReport/DeliveryReportList.store'
import FormReportListStore from '../../../ReportsList/FormReportList/FormReportList.store'
import ScanStationListStore from '../../../ReportsList/ScanStation/ScanStationList.store'
import BaseReportsFilterStore, { ISourceMap } from './BaseReportsFilter.store'

export default abstract class ReportsFilterStore {
  public constructor(
    protected readonly eventsStore: DesktopEventStore,
    protected readonly instancesStore: ReportsStore,
    protected readonly instancesListStore:
      | ScanStationListStore
      | FormReportListStore
      | DeliveryReportListStore,
    protected readonly onShowChanged: (
      isShown: boolean,
      filterType: string,
    ) => void,
    protected readonly scannersStore: ScannersStore,
    protected readonly companiesStore: CompaniesStore,
    protected readonly projectMembersStore: ProjectMembersStore,
    protected readonly userProjectsStore: UserProjectsStore,
    protected readonly locationAttributesStore: LocationAttributesStore,
    protected readonly tagsStore: TagsStore,
  ) {}

  protected abstract get availableInstances():
    | IScannerHistoryPair[]
    | SitePermit[]
    | Delivery[]

  public abstract get filterStoresByTypeMap(): {
    [filterType: string]: BaseReportsFilterStore
  }

  protected abstract get sourceMapByFilterTypeMap(): {
    [filterType: string]: ISourceMap
  }

  public abstract resetAllFilters(): void

  protected abstract getDefaultSourceMapByType(
    type: ScanStationFilterType | FormReportFilterType | DeliveryFilterType,
  )

  protected getOptionName = (option: string, filterType: string): string => {
    switch (filterType) {
      case ScanStationFilterType.SCANNER:
        return this.getScannerOptionName(option)
      case FormReportFilterType.COMPANY:
      case DeliveryFilterType.Company:
      case ScanStationFilterType.COMPANY:
        return this.getCompanyOptionName(option)
      case FormReportFilterType.LOCATION:
      case FormReportFilterType.EQUIPMENT:
        return

      default:
        return option
    }
  }

  protected getLocationOptionName = (option: string): string => {
    const attributeId = option.split(LOCATION_SEPARATOR)[1]

    return this.locationAttributesStore.getById(attributeId)?.name || option
  }

  protected getScannerOptionName = (option: string): string => {
    const scanner = this.scannersStore.getScannerById(option)

    return scanner?.name || option
  }

  protected getCompanyOptionName = (option: string): string => {
    return this.companiesStore.getCompanyNameById(
      option,
      UNASSIGNED_FILTER_OPTION,
    )
  }

  protected abstract fieldsMap()
}
