import * as React from 'react'

import { Icon, Switch } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import {
  CalendricalType,
  IInspectionOptions,
  IWorkflowStep,
  SitePermitStatus,
  WorkflowStepType,
} from '~/client/graph'
import SelectWrapper from '~/client/src/desktop/components/SelectWrapper/SelectWrapper'
import * as Icons from '~/client/src/shared/components/Icons'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import WeekdaysSelector from '~/client/src/shared/components/WeekdaysSelector/WeekdaysSelector'
import WorkflowCardStatus from '~/client/src/shared/components/WorkflowCard/Status'
import WorkflowStepNameLabel from '~/client/src/shared/components/WorkflowStepNameLabel'
import { getWorkflowStepLevel } from '~/client/src/shared/constants/formStatusesTags'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { getWorkflowStepDisplayName } from '~/client/src/shared/localization/enumDisplayTexts'
import OperationRule from '~/client/src/shared/models/OperationRule'
import User from '~/client/src/shared/models/User'
import PermitBallInCourtStore from '~/client/src/shared/stores/ui/PermitBallInCourt.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import WorkflowStepConfiguratorStore from './WorkflowStepConfigurator.store'
import WorkflowAddStepModal from './components/WorkflowAddStepModal'
import WorkflowNotifiedUsersModal from './components/WorkflowNotifiedUsersModal'
import WorkflowStepActions from './components/WorkflowStepActions'
import WorkflowStepBlock from './components/WorkflowStepBlock'
import WorkflowStepMenuModal from './components/WorkflowStepMenuModal'
import WorkflowStepRulesModal from './components/WorkflowStepRulesModal'

// localization: translated

interface IProps {
  workflowSteps: IWorkflowStep[]
  inspectionOptions: IInspectionOptions
  formTypeId: string
  isWorkflowChanged: boolean
  isAutoActivationEnabled: boolean
  isUpdating: boolean

  permitBallInCourtStore: PermitBallInCourtStore

  onAddStep(index: number, stepType: WorkflowStepType): void
  onReplaceStep(index: number, stepType: WorkflowStepType): void
  onRemoveStep(index: number): void
  onInspectionChange(inspectionOptions: IInspectionOptions): void
  onFormSectionClick(stepId: string): void
  onAutoActivationChange(): void
  onNotificationFieldsChange(stepId: string, fieldId: string): void

  getUsersFromAssignment(assignmentExpressions: string[]): User[]
  onRemoveRuleClick(ruleId: string): void
  onDuplicateRuleClick(ruleId: string): void
  onChangeUserForRule(
    rule: OperationRule,
    userId: string,
    isNew?: boolean,
  ): void
  onRuleExpressionChange(
    rule: OperationRule,
    locationAndCompExpression: string,
  ): void
  onAddNewRuleClick(stepId: string): void

  projectDateStore?: ProjectDateStore
}

const isRecurringStep = (stepType: WorkflowStepType): boolean =>
  stepType === WorkflowStepType.RecurringInspection

@inject('projectDateStore')
@observer
export default class WorkflowStepConfigurator extends React.Component<IProps> {
  private readonly store: WorkflowStepConfiguratorStore

  public constructor(props: IProps) {
    super(props)

    this.store = new WorkflowStepConfiguratorStore(
      props.workflowSteps,
      props.inspectionOptions,
      props.isAutoActivationEnabled,
      props.projectDateStore,
      props.onInspectionChange,
    )
  }

  public componentDidUpdate(prevProps: IProps) {
    const { workflowSteps, inspectionOptions, isAutoActivationEnabled } =
      this.props
    if (
      workflowSteps.length !== prevProps.workflowSteps.length ||
      workflowSteps !== prevProps.workflowSteps ||
      inspectionOptions !== prevProps.inspectionOptions ||
      isAutoActivationEnabled !== prevProps.isAutoActivationEnabled
    ) {
      this.store.init(workflowSteps, inspectionOptions, isAutoActivationEnabled)
    }
  }

  public render() {
    const { workflowSteps, isLastStep, isAutomaticStartShown } = this.store
    return (
      <div className="col m10 workflow-configurator">
        {workflowSteps.map((step, idx) => (
          <React.Fragment key={step.id}>
            {this.renderStep(idx, step, isLastStep(idx))}
            {isAutomaticStartShown(idx, step.type) &&
              this.props.isAutoActivationEnabled &&
              this.renderAutomaticStep(step, idx)}
          </React.Fragment>
        ))}
      </div>
    )
  }

  private renderStep(
    stepIdx: number,
    step: IWorkflowStep,
    isLastStep: boolean,
  ): JSX.Element {
    const {
      isWorkflowChanged,
      isUpdating,
      formTypeId,
      onRemoveStep,
      onReplaceStep,
      getUsersFromAssignment,
      onRemoveRuleClick,
      onDuplicateRuleClick,
      onChangeUserForRule,
      onRuleExpressionChange,
      onAddNewRuleClick,
      onFormSectionClick,
      onNotificationFieldsChange,
      permitBallInCourtStore,
    } = this.props
    const { workflowSteps, getNotificationOptions, isRequestOrSubmissionStep } =
      this.store

    const stepLevel = getWorkflowStepLevel(step.id, workflowSteps)
    const stepName = getWorkflowStepDisplayName(step.type, stepLevel)
    const isRecurring = isRecurringStep(step.type)

    return (
      <div className="relative no-select workflow-configurator-step">
        <div className="brada8 ba-light-grey overflow-hidden">
          <div className="row y-center bg-palette-brand-lightest px15 mb8 bb-light-grey h40">
            <WorkflowStepNameLabel
              stepPosition={stepIdx + 1}
              stepName={stepName}
            />
            <WorkflowStepMenuModal
              stepType={step.type}
              stepIdx={stepIdx}
              onRemoveStep={onRemoveStep}
              onReplaceStep={onReplaceStep}
              isRequestOrSubmitStep={isRequestOrSubmissionStep(step.type)}
            />
          </div>

          {!!stepIdx && !isRecurring && (
            <WorkflowStepBlock
              title={Localization.translator.bicUser}
              isDisabled={isWorkflowChanged}
              hasPointer
              hasFixedHeight
              tooltipContent={
                <div className="mw300">
                  {
                    Localization.translator.workflowConfTooltips
                      .bicBlockFirstLine
                  }
                  <br />
                  <br />
                  {
                    Localization.translator.workflowConfTooltips
                      .bicBlockSecondLine
                  }
                </div>
              }
            >
              <WorkflowStepRulesModal
                workflowStep={step}
                formTypeId={formTypeId}
                isUpdating={isUpdating}
                getUsersFromAssignment={getUsersFromAssignment}
                onRemoveRuleClick={onRemoveRuleClick}
                onDuplicateRuleClick={onDuplicateRuleClick}
                onChangeUserForRule={onChangeUserForRule}
                onRuleExpressionChange={onRuleExpressionChange}
                onAddNewRuleClick={onAddNewRuleClick}
                permitBallInCourtStore={permitBallInCourtStore}
              />
            </WorkflowStepBlock>
          )}

          <WorkflowStepBlock
            title={Localization.translator.formSection}
            isDisabled={isWorkflowChanged}
            stepId={step.id}
            onClick={onFormSectionClick}
            hasPointer
          >
            {step.fields.length ? (
              <div className="row">
                <Icons.Docs className="no-outline row no-grow mr5" />
                <span className="row">{stepName}</span>
              </div>
            ) : (
              <span>{Localization.translator.none}</span>
            )}
            <Icon icon={IconNames.SETTINGS} className="text grey-30" />
          </WorkflowStepBlock>

          <WorkflowStepBlock title={Localization.translator.actions} isColumn>
            <WorkflowStepActions
              stepType={step.type}
              isLastStep={isLastStep}
              workflowStepLevel={stepLevel}
            />
          </WorkflowStepBlock>

          {!isRecurring && (
            <WorkflowStepBlock
              title={Localization.translator.notifiedUsers}
              hasPointer
              hasFixedHeight
            >
              <WorkflowNotifiedUsersModal
                stepId={step.id}
                stepNotificationFieldIds={step.notificationFieldIds}
                notificationOptions={getNotificationOptions(step.id)}
                isUpdating={isUpdating}
                addToNotifications={onNotificationFieldsChange}
              />
            </WorkflowStepBlock>
          )}

          {!isLastStep && this.renderAutoStartToggle(step.type)}
          {this.renderInspectionOptionsBlock(step.type)}
        </div>

        {this.renderLineAndAddStepModal(step, stepIdx, stepLevel, isLastStep)}
      </div>
    )
  }

  private renderAutoStartToggle(stepType: WorkflowStepType): JSX.Element {
    if (!this.store.isAutoStartToggleShown(stepType)) {
      return null
    }

    return (
      <WorkflowStepBlock title={Localization.translator.options} isBorderHidden>
        <div className="row px8">
          <span className="mr4 text large line-extra-large">
            {Localization.translator.workflowConfDescr.automaticallyStart}
            <span className="text red large line-extra-large no-grow">*</span>
          </span>
          <Switch
            className="primary-blue-switch automatic-start-switch bp3-align-right no-outline-container ml5 no-grow"
            checked={this.store.isAutoActivationEnabled}
            onChange={this.props.onAutoActivationChange}
          />
        </div>
      </WorkflowStepBlock>
    )
  }

  private renderInspectionOptionsBlock(
    stepType: WorkflowStepType,
  ): JSX.Element {
    if (!isRecurringStep(stepType)) return null

    const {
      inspectionMaxNumber,
      inspectionFrequency,
      inspectionFrequencyType,
      inspectionFrequencyTypeOptions,
      inspectionDeadlineTime,
      deadlineTimeOptions,
      selectedDaysToRepeat,
      shouldShowRepeatOnDays,
      changeDayToRepeat,
    } = this.store

    return (
      <>
        <WorkflowStepBlock
          title={Localization.translator.frequency}
          isBorderHidden
        >
          <div className="row">
            <span className="mr5 text large nowrap no-grow">
              {Localization.translator.everyX_withoutNum(inspectionFrequency)}
            </span>
            <StruxhubInput
              className="inspection-frequency-input"
              isMinimalisticMode
              isHelperTextCondensed
              type="number"
              max={inspectionMaxNumber}
              min={1}
              value={inspectionFrequency.toString()}
              onChange={this.changeInspectionFrequency}
            />
            <div className="mr8 no-grow" />
            <SelectWrapper>
              <select
                className="ba-light-grey brada4 pl8 pr24 pointer text large capitalize min-height32"
                value={inspectionFrequencyType}
                onChange={this.changeInspectionFrequencyType}
              >
                {inspectionFrequencyTypeOptions.map(({ value, name }) => (
                  <option
                    key={value}
                    className="text large capitalize"
                    value={value}
                  >
                    {name}
                  </option>
                ))}
              </select>
            </SelectWrapper>
          </div>
        </WorkflowStepBlock>

        {shouldShowRepeatOnDays && (
          <WorkflowStepBlock
            title={Localization.translator.repeatOn}
            isBorderHidden
          >
            <WeekdaysSelector
              className="py2"
              selectedDayNumbers={selectedDaysToRepeat}
              onClick={changeDayToRepeat}
            />
          </WorkflowStepBlock>
        )}

        <WorkflowStepBlock
          title={Localization.translator.deadline}
          isBorderHidden
        >
          <SelectWrapper>
            <select
              className="ba-light-grey brada4 pl8 pr24 pointer min-height32"
              value={inspectionDeadlineTime.toString()}
              onChange={this.changeInspectionDeadline}
            >
              {deadlineTimeOptions.map(({ value, name }) => (
                <option key={value} className="text large" value={value}>
                  {name}
                </option>
              ))}
            </select>
          </SelectWrapper>
        </WorkflowStepBlock>
      </>
    )
  }

  private renderLineAndAddStepModal(
    step: IWorkflowStep,
    stepIdx: number,
    stepLevel: number,
    isLastStep: boolean,
    lineStatus?: SitePermitStatus,
  ): JSX.Element {
    const { isAddStepBtnShown, getStepResultStatus } = this.store
    const shouldShowStatus = lineStatus || !isRecurringStep(step.type)

    return (
      <div
        className={classList({
          relative: true,
          'workflow-configurator-step-line': !isLastStep,
          'workflow-configurator-step-last-line': isLastStep,
        })}
      >
        {isAddStepBtnShown(step.type) && (
          <WorkflowAddStepModal
            stepType={step.type}
            stepIdx={stepIdx}
            onAddStep={this.props.onAddStep}
            store={this.store}
          />
        )}
        <div
          className={classList({
            'full-height full-width col y-center x-center': true,
            'y-end': isLastStep,
          })}
        >
          <div className="absolute-top full-height z-index-1 bl-light-grey" />
          {shouldShowStatus && (
            <div className="z-index-5 bg-white">
              <WorkflowCardStatus
                status={
                  lineStatus || getStepResultStatus(isLastStep, step.type)
                }
                workflowStepLevel={isLastStep ? 0 : stepLevel}
                isLate={false}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  private renderAutomaticStep(
    step: IWorkflowStep,
    stepIdx: number,
  ): JSX.Element {
    const { onAddStep, onRemoveStep, onReplaceStep } = this.props

    return (
      <div className="relative no-select workflow-configurator-step">
        <div className="brada8 ba-light-grey overflow-hidden">
          <div className="row y-center bg-palette-brand-lightest px15 mb8 bb-light-grey h40">
            <span className="row text large bold line-extra-large capitalize">
              {Localization.translator.automaticStart}
            </span>
            <WorkflowStepMenuModal
              stepType={step.type}
              stepIdx={stepIdx}
              onRemoveStep={onRemoveStep}
              onReplaceStep={onReplaceStep}
              isAutomaticStep
              isRequestOrSubmitStep={false}
              onAddStep={onAddStep}
            />
          </div>
          <div className="row y-start px15 mb8 bg-white">
            <span className="row py6 text large line-extra-large">
              {Localization.translator.workflowConfDescr.workflowStartsOnDate}
            </span>
          </div>
        </div>

        {this.renderLineAndAddStepModal(
          step,
          stepIdx,
          0,
          false,
          SitePermitStatus.Active,
        )}
      </div>
    )
  }

  private changeInspectionDeadline = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    this.store.changeDeadlineTime(+event?.target?.value)
  }

  private changeInspectionFrequency = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    this.store.changeFrequency(event.target.valueAsNumber || 1)
  }

  private changeInspectionFrequencyType = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    this.store.changeFrequencyType(event.target.value as CalendricalType)
  }
}
