import { action, computed, observable } from 'mobx'

import { OperationSubjectType } from '~/client/graph'
import { RoundBracket } from '~/client/src/shared/enums/Brackets'
import { TagType } from '~/client/src/shared/enums/TagType'
import { AndOrOperator } from '~/client/src/shared/models/LogicOperation'
import Tag from '~/client/src/shared/models/Tag'
import User from '~/client/src/shared/models/User'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import ExpressionElement from '~/client/src/shared/utils/ExpressionParser/ExpressionElement'
import ExpressionParser from '~/client/src/shared/utils/ExpressionParser/ExpressionParser'
import Pointer from '~/client/src/shared/utils/ExpressionParser/components/Pointer'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import SizeMeasureStore from '../../SizeMeasure.store'

export default class ExpressionControlStore {
  @observable public isHovering: boolean = false
  @observable public shouldShowTagsByGroupsModal: boolean = false
  @observable public expressionElements: ExpressionElement[] = [new Pointer()]
  @observable public shouldShowTagsSelector: boolean = false
  @observable public shouldShowUsersDirectory: boolean = false

  private readonly expParser: ExpressionParser = null
  private readonly sizeMeasureStore: SizeMeasureStore

  public constructor(
    private readonly tagsStore: TagsStore,
    private readonly onChange: (expression: string) => void,
    private readonly onPlusControlCustomClick: (
      operation: AndOrOperator,
      defaultHandler: (operation: AndOrOperator) => void,
    ) => void,
    measureSize: () => void,
    private readonly availableTags: Tag[],
    expression: string,
    subject: OperationSubjectType,
  ) {
    this.expParser = new ExpressionParser(tagsStore)
    this.sizeMeasureStore = new SizeMeasureStore(measureSize)

    this.init(expression, subject)
  }

  public init(expression: string, subject: OperationSubjectType) {
    this.expressionElements = this.expParser.fromStrToElements(
      expression,
      false,
      subject,
    )
    this.sizeMeasureStore.enableMeasure()
  }

  public get activePointerIndex(): number {
    return this.activePointer ? this.activePointer.index : null
  }

  public get activePointerValue(): string {
    return this.activePointer ? this.activePointer.value : EMPTY_STRING
  }

  public get shouldShowPullDown(): boolean {
    return this.activePointer && !this.activePointer.isLogical
  }

  public get isActivePointerFilled(): boolean {
    return this.activePointer && !!this.activePointer.value
  }

  @computed
  public get shouldShowAddUserTagsBtn(): boolean {
    return !this.shouldShowTagsSelector && !this.shouldShowUsersDirectory
  }

  @action.bound
  public toggleUsersDirectory() {
    this.shouldShowUsersDirectory = !this.shouldShowUsersDirectory
  }

  @action.bound
  public hideUsersDirectory() {
    this.shouldShowUsersDirectory = false
  }

  @action.bound
  public toggleTagsSelector() {
    this.shouldShowTagsSelector = !this.shouldShowTagsSelector
  }

  @action.bound
  public hideTagsSelector() {
    this.shouldShowTagsSelector = false
  }

  @action.bound
  public setHovering() {
    this.isHovering = true
    this.sizeMeasureStore.enableMeasure()
  }

  @action.bound
  public resetHovering() {
    this.isHovering = false
    this.sizeMeasureStore.enableMeasure()
  }

  @action.bound
  public toggleTagsByGroupsModal() {
    this.shouldShowTagsByGroupsModal = !this.shouldShowTagsByGroupsModal
  }

  @action.bound
  public hideTagsByGroupsModal() {
    this.shouldShowTagsByGroupsModal = false
  }

  @action.bound
  public hideUserTagsModals() {
    if (!this.shouldShowAddUserTagsBtn) {
      this.setActivePointerValue(EMPTY_STRING)
    }

    this.hideUsersDirectory()
    this.hideTagsSelector()
  }

  @action.bound
  public activatePointer(pointer: Pointer) {
    this.deactivateAllPointers()
    pointer.activate()
    this.sizeMeasureStore.enableMeasure()
  }

  @action.bound
  public removeElement(element: ExpressionElement, index: number) {
    switch (true) {
      case ExpressionParser.isPointerElement(element):
        return

      case ExpressionParser.isTagElement(element):
        this.removeSingleElement(index, false)
        break

      case ExpressionParser.isLogicalOperationElement(element):
        this.removeSingleElement(index, true)
        break

      default:
        this.removeExpressionGroup(element as string, index)
    }

    this.saveChanges()
    this.sizeMeasureStore.enableMeasure()
  }

  @action.bound
  public setActivePointerValue(newValue: string) {
    if (this.activePointer) {
      this.activePointer.value = newValue
    }
  }

  @action.bound
  public selectUser({ id }: User) {
    const tag = this.tagsStore.getTag(TagType.User, id)

    this.selectTag(tag as Tag)
  }

  @action.bound
  public selectTag(tag: Tag) {
    const nextElement = this.getNextElement(this.activePointerIndex)

    if (
      nextElement &&
      (ExpressionParser.isLogicalOperationElement(nextElement) ||
        ExpressionParser.isLogicalPointerElement(nextElement))
    ) {
      this.expressionElements.splice(this.activePointerIndex, 1, tag)
    } else {
      this.expressionElements.splice(
        this.activePointerIndex,
        1,
        tag,
        new Pointer(true),
      )
    }

    this.hideTagsByGroupsModal()
    this.hideUserTagsModals()

    this.saveChanges()
    this.sizeMeasureStore.enableMeasure()
  }

  @action.bound
  public startExpressionGroup() {
    this.expressionElements.splice(
      this.activePointer.index,
      1,
      RoundBracket.LEFT,
      new Pointer(),
      RoundBracket.RIGHT,
      new Pointer(true),
    )
    this.sizeMeasureStore.enableMeasure()
  }

  @computed
  public get activePointer(): Pointer {
    const pointerIndex = this.expressionElements.findIndex(element =>
      ExpressionParser.isActivePointerElement(element),
    )

    if (pointerIndex === -1) {
      return null
    }

    const pointer = this.expressionElements[pointerIndex] as Pointer
    pointer.setIndex(pointerIndex)

    return pointer
  }

  @action.bound
  public selectLogicOperation(operation: AndOrOperator) {
    const nextElement = this.getNextElement(this.activePointerIndex)

    if (
      nextElement &&
      (ExpressionParser.isTagElement(nextElement) ||
        ExpressionParser.isTagPointerElement(nextElement) ||
        nextElement === RoundBracket.LEFT)
    ) {
      this.expressionElements.splice(this.activePointerIndex, 1, operation)
    } else {
      this.expressionElements.splice(
        this.activePointerIndex,
        1,
        operation,
        new Pointer(),
      )
    }

    this.saveChanges()
  }

  @action.bound
  public clickOnPlusControl(operation: AndOrOperator) {
    if (this.onPlusControlCustomClick) {
      this.onPlusControlCustomClick(operation, this.selectLogicOperation)
    } else {
      this.selectLogicOperation(operation)
    }

    this.sizeMeasureStore.enableMeasure()
  }

  public measureSize() {
    this.sizeMeasureStore.measureSize()
  }

  @computed
  public get suggestedTags(): Tag[] {
    return this.availableTags.filter(tag =>
      (tag.name || '')
        .toLowerCase()
        .includes(this.activePointerValue.toLowerCase()),
    )
  }

  @action.bound
  private saveChanges() {
    if (!ExpressionParser.isElementsChainValid(this.expressionElements)) {
      return
    }

    const newExpression = ExpressionParser.fromElementsToStr(
      this.expressionElements,
    )
    this.onChange(newExpression)
  }

  @action.bound
  private deactivateAllPointers() {
    this.expressionElements
      .filter(element => ExpressionParser.isPointerElement(element))
      .forEach(element => (element as Pointer).deactivate())
  }

  @action.bound
  private removeSingleElement(elementIndex: number, isLogical: boolean) {
    const nextElement = this.getNextElement(elementIndex)

    const deleteCount =
      nextElement && ExpressionParser.isPointerElement(nextElement) ? 2 : 1

    return this.expressionElements.splice(
      elementIndex,
      deleteCount,
      new Pointer(isLogical),
    )
  }

  @action.bound
  private removeExpressionGroup(element: string, index: number) {
    if (element !== RoundBracket.LEFT && element !== RoundBracket.RIGHT) {
      return
    }

    let limit
    let nestingCounter = 1
    let direction
    let pairParenthesis
    let pairParenthesisIndex = null

    if (element === RoundBracket.LEFT) {
      pairParenthesis = RoundBracket.RIGHT
      direction = 1
      limit = this.expressionElements.length
    } else {
      pairParenthesis = RoundBracket.LEFT
      direction = -1
      limit = -1
    }

    for (let i = index + direction; i !== limit; i += direction) {
      if (this.expressionElements[i] === element) {
        nestingCounter++
      } else if (this.expressionElements[i] === pairParenthesis) {
        nestingCounter--

        if (!nestingCounter) {
          pairParenthesisIndex = i
          break
        }
      }
    }

    this.expressionElements.splice(
      Math.min(index, pairParenthesisIndex),
      Math.abs(pairParenthesisIndex - index) + 2,
      new Pointer(),
    )
  }

  private getNextElement(index: number) {
    return this.expressionElements[index + 1]
  }
}
