import { action } from 'mobx'

import CompanyFieldId from '~/client/src/desktop/enums/CompanyFieldId'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import AddEditCompanyDialogStore from './AddEditCompanyDialog.store'

export default class MergeCompanyDialogStore extends AddEditCompanyDialogStore {
  @action.bound
  public handlePreClose() {
    this.closeDialog()
  }

  @action.bound
  public handleCloseConfirmModal() {
    this.hideConfirmModal()
  }

  @action.bound
  public preSubmit() {
    this.showConfirmModal()
  }

  @action.bound
  public submit() {
    this.hideConfirmModal()

    this.setFieldsToItem(this.editingItem, this.activeFormStore.fields)

    const itemsToUpdate = this.itemsToEdit.slice()

    this.updateItems(itemsToUpdate, this.activeItemId)
  }

  public get canFormsBeSubmitted(): boolean {
    return super.areAllRequiredFieldsFilled && !super.hasActiveFormError
  }

  public get okActionCaption(): string {
    return Localization.translator.merge_verb
  }

  public get confirmationDoneBtnTitle(): string {
    return Localization.translator.merge_verb
  }

  public get confirmationMessage(): string {
    const sourceCompaniesNames = this.itemsToEdit
      .filter(c => c.id !== this.activeItemId)
      .map(c => `[${c.name}]`)

    const targetCompanyName = this.getFieldValueById(CompanyFieldId.Name)

    return Localization.translator.companyMerging.confirmation(
      sourceCompaniesNames.join(', '),
      `[${targetCompanyName}]`,
    )
  }

  public get dialogCaption(): string {
    return Localization.translator.targetCompany
  }
}
