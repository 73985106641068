import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { computed, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import BaseNotification from '~/client/src/shared/models/Notification'
import NotificationsStore from '~/client/src/shared/stores/domain/Notifications.store'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const allPredicate = (n: BaseNotification) => true
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const nonePredicate = (n: BaseNotification) => false
const wasNotRead = (n: BaseNotification) => !n.wasRead
const wasRead = (n: BaseNotification) => n.wasRead
const isArchived = (n: BaseNotification) => n.isArchived

// translated

interface IProps {
  notificationsStore: NotificationsStore
}

@observer
export default class NotificationSelectorDropdown extends React.Component<IProps> {
  @observable private isDropdownShown: boolean = false

  public render() {
    return (
      <>
        {this.isDropdownShown && this.renderDropdown()}
        <div
          className={classList({
            'dropdown no-grow row': true,
            'active unclickable-element': this.isDropdownShown,
          })}
          onClick={this.toggleDropdownMenu}
        >
          <Checkbox isChecked={this.isChecked} isMinus={this.isMinus} />
          <div className="no-grow">
            <Icon icon={IconNames.CHEVRON_DOWN} />
          </div>
        </div>
      </>
    )
  }

  private renderDropdown() {
    return (
      <MenuCloser className="w-fit-content" closeMenu={this.toggleDropdownMenu}>
        <div className="col options-list">
          <div
            className="option pointer"
            onClick={this.selectNotificationsByType.bind(this, allPredicate)}
          >
            {Localization.translator.all_items}
          </div>
          <div
            className="option pointer"
            onClick={this.selectNotificationsByType.bind(this, nonePredicate)}
          >
            {Localization.translator.none}
          </div>
          <div
            className="option pointer"
            onClick={this.selectNotificationsByType.bind(this, wasNotRead)}
          >
            {Localization.translator.unread_2form}
          </div>
          <div
            className="option pointer"
            onClick={this.selectNotificationsByType.bind(this, wasRead)}
          >
            {Localization.translator.read_2form}
          </div>
          <div
            className="option pointer"
            onClick={this.selectNotificationsByType.bind(this, isArchived)}
          >
            {Localization.translator.archived}
          </div>
        </div>
      </MenuCloser>
    )
  }

  private toggleDropdownMenu = () => {
    this.isDropdownShown = !this.isDropdownShown
  }

  private selectNotificationsByType(
    predicate: (n: BaseNotification) => boolean,
  ) {
    const { filteredNotifications, selectedNotifications } =
      this.props.notificationsStore
    selectedNotifications.clear()
    filteredNotifications.forEach(n => {
      if (predicate(n)) {
        selectedNotifications.set(n.id, true)
      }
    })
    this.toggleDropdownMenu()
  }

  @computed
  private get isMinus(): boolean {
    const { filteredNotifications, selectedNotifications } =
      this.props.notificationsStore
    return (
      !this.isChecked &&
      filteredNotifications.some(n => selectedNotifications.get(n.id))
    )
  }

  @computed
  private get isChecked(): boolean {
    const { filteredNotifications, selectedNotifications } =
      this.props.notificationsStore
    return filteredNotifications.every(n => selectedNotifications.get(n.id))
  }
}
