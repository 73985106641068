import * as React from 'react'

import { inject, observer } from 'mobx-react'

import DeliveriesMultiSelectFilter from '~/client/src/desktop/components/DeliveriesMultiSelectFilter'
import * as Icons from '~/client/src/shared/components/Icons'
import { IMultiSelectOption } from '~/client/src/shared/components/MultiSelectFilter/MultiSelectFilter'
import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'

import DesktopInitialState from '../../../stores/DesktopInitialState'
import PhotosPageStore from '../PhotosPageStore.store'

export interface IProps {
  store: PhotosPageStore
  state?: DesktopInitialState
}

@inject('state')
@observer
export default class DeliveriesPhotosSideBar extends React.Component<IProps> {
  public render() {
    const { store, state } = this.props
    if (!store.isDeliveriesModeActive) {
      return <div />
    }
    const { hiddenFields } = state.delivery
    const {
      allGates,
      selectedGates,
      setGateFilterValue,
      allZones,
      selectedZones,
      selectedStates,
      setZoneFilterValue,
      setStateFilterValue,
      setRouteFilterValue,
      setBuildingFilterValue,
      allBuildings,
      allRoutes,
      selectedBuildings,
      selectedRoutes,
    } = store

    return (
      <div className="deliveries-photos-side-bar scrollable no-grow full-height px10">
        {!hiddenFields[FieldIds.GATE] && (
          <div className="multi-select-container">
            <DeliveriesMultiSelectFilter
              title="Gates"
              options={allGates}
              values={selectedGates ? selectedGates.slice() : []}
              onChange={setGateFilterValue}
            />
          </div>
        )}
        {!hiddenFields[FieldIds.ZONE] && (
          <div className="multi-select-container">
            <DeliveriesMultiSelectFilter
              title="Zones"
              options={allZones}
              values={selectedZones ? selectedZones.slice() : []}
              onChange={setZoneFilterValue}
            />
          </div>
        )}
        {!hiddenFields[FieldIds.ROUTE] && (
          <div className="multi-select-container">
            <DeliveriesMultiSelectFilter
              title="Routes"
              options={allRoutes}
              values={selectedRoutes ? selectedRoutes.slice() : []}
              onChange={setRouteFilterValue}
            />
          </div>
        )}
        {!hiddenFields[FieldIds.BUILDING] && (
          <div className="multi-select-container">
            <DeliveriesMultiSelectFilter
              title="Buildings"
              options={allBuildings}
              values={selectedBuildings ? selectedBuildings.slice() : []}
              onChange={setBuildingFilterValue}
            />
          </div>
        )}
        <div className="multi-select-container">
          <DeliveriesMultiSelectFilter
            title="States"
            options={this.allStatuses}
            values={selectedStates ? selectedStates.slice() : []}
            onChange={setStateFilterValue}
            hasIcon={true}
          />
        </div>
      </div>
    )
  }

  private get allStatuses(): IMultiSelectOption[] {
    return this.props.store.allStatuses.map(({ label, value, disabled }) => {
      return {
        label: this.getStatusIcon(value, label),
        value,
        disabled,
      }
    })
  }
  private getStatusIcon = (status, label: string) => {
    let icon = null
    switch (status) {
      case DeliveryStatus.Requested:
        icon = <Icons.DeliveryRequested />
        break
      case DeliveryStatus.Scheduled:
      case DeliveryStatus.Canceled:
        icon = <Icons.DeliveryAccepted />
        break
      case DeliveryStatus.Delivering:
      case DeliveryStatus.OnSite:
      case DeliveryStatus.OnHold:
        icon = <Icons.DeliveryOnsite />
        break
      case DeliveryStatus.Paused:
      case DeliveryStatus.Denied:
      case DeliveryStatus.FailedInspection:
        icon = <Icons.DeliveryFailedInspection />
        break
      case DeliveryStatus.PassedInspection:
        icon = <Icons.DeliveryPassedInspection />
        break
      case DeliveryStatus.Done:
        icon = <Icons.DeliveryDone />
        break
    }
    return (
      <>
        {icon}
        <div className="text large ml5 capitalize">{label}</div>
      </>
    )
  }
}
