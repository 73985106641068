import * as React from 'react'

import { KonvaEventObject } from 'konva/types/Node'
import { action, computed, observable } from 'mobx'
import { MobXProviderContext, Provider, inject, observer } from 'mobx-react'

import BaseMapView from '~/client/src/shared/components/BaseMapView/BaseMapView'
import { ViewMode } from '~/client/src/shared/components/DraggableBar/DraggableBar'
import KonvaWorkflowActivityPin from '~/client/src/shared/components/Konva/KonvaWorkflowActivityPin'
import SitemapActivitiesDraggableModal from '~/client/src/shared/components/SitemapDraggableModalWrapper/components/SitemapActivitiesDraggableModal'
import SitemapElementsWrapper from '~/client/src/shared/components/SitemapElementsWrapper'
import GlobeViewActivityMapPin from '~/client/src/shared/components/SitemapHelpers/components/GlobePins/GlobeViewActivityMapPin'
import GlobeViewItems from '~/client/src/shared/components/SitemapHelpers/components/GlobeViewItems'
import GlobeViewPlans from '~/client/src/shared/components/SitemapHelpers/components/GlobeViewPlans'
import SitemapItems, {
  MAX_PERCENT,
} from '~/client/src/shared/components/SitemapHelpers/components/SitemapItems'
import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import ICanvasImageCache from '~/client/src/shared/interfaces/ITextboxesCache'
import Activity from '~/client/src/shared/models/Activity'
import GlobeView from '~/client/src/shared/models/GlobeView'
import Sitemap from '~/client/src/shared/models/Sitemap'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import BaseActivityListStore from '~/client/src/shared/stores/ui/BaseActivityList.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { clickPosition } from '~/client/src/shared/utils/SitemapCalculationHelpers'
import { NOOP } from '~/client/src/shared/utils/noop'

import ActivitiesMapViewSetUpStore from './ActivitiesMapViewSetUp.store'

const SAME_POSITION_OFFSET = 20
const OFFSET_Y = 4

interface IProps {
  globe?: GlobeView
  sitemap?: Sitemap
  activityListStore: BaseActivityListStore
  textboxesCache: ICanvasImageCache
  store: ActivitiesMapViewSetUpStore

  openActivity: (code: string) => void

  selectViewMode?: (mode: ViewMode) => void

  eventsStore?: EventsStore
  basemapsStore?: BasemapsStore
  activitiesStore?: ActivitiesStore
  sitemapsStore?: SitemapsStore
  projectDateStore?: ProjectDateStore
}

@inject(
  'eventsStore',
  'basemapsStore',
  'activitiesStore',
  'sitemapsStore',
  'projectDateStore',
)
@observer
export default class ActivitiesMapView extends React.Component<IProps> {
  @observable public swipeableItemIndex: number = 0
  @observable public swipeableItemId: string = null
  @observable private sitemap: BaseMapView
  @observable private containerRef: HTMLElement = null
  public static contextType = MobXProviderContext

  public constructor(props: IProps) {
    super(props)

    const { sitemap, store } = props
    store.deselectAll()
    store.selectSitemap(sitemap)

    store.mapBoxViewerStore.setViewportFromAddress()
    store.mapBoxViewerStore.setDefaultMapMode()
  }

  public render() {
    return (
      <div
        className="full-height full-width relative activities-map-container"
        ref={this.setContainerRef}
      >
        {this.mapViewElement}
        {this.draggableModal}
      </div>
    )
  }

  private get draggableModal(): JSX.Element {
    const {
      openActivity,
      activitiesStore: { selection: displayedActivityId },
    } = this.props

    const {
      selectedAttribute,
      selectedActivities,
      areActivitiesSelected,
      deselectAll,
      topOffset,
      leftOffset,
    } = this.props.store

    return (
      areActivitiesSelected &&
      !!this.containerRef && (
        <SitemapActivitiesDraggableModal
          item={selectedAttribute}
          containerRef={this.containerRef}
          selectedActivities={selectedActivities}
          selectedActivityId={displayedActivityId}
          openActivity={openActivity}
          onClose={deselectAll}
          topOffset={topOffset}
          leftOffset={leftOffset}
        />
      )
    )
  }

  private get mapViewElement(): JSX.Element {
    const {
      basemapsStore,
      store: { sitemapUrl, deselectAll },
      store,
      eventsStore: { appState },
      globe,
      sitemap,
    } = this.props

    const { setViewport } = store.mapBoxViewerStore

    return (
      <>
        <BaseMapView
          globe={globe}
          selectedSitemap={sitemap}
          globeSitemaps={globe?.sitemaps}
          sitemapUrl={sitemapUrl}
          isDraggable={true}
          onWhiteboardClick={deselectAll}
          onTouch={this.onSitemapTouch}
          mapBoxViewerStore={store.mapBoxViewerStore}
          isDraggingMode={false}
          setViewport={setViewport}
          basemapsStore={basemapsStore}
          projectAddress={appState.projectAddress}
          ref={this.setSitemapRef}
        >
          {({ width, height }) => {
            if (!this.sitemap) {
              return null
            }
            return (
              <Provider {...this.context}>
                {globe
                  ? this.renderGlobeViewChildren()
                  : this.renderSitemapChildren(width, height)}
              </Provider>
            )
          }}
        </BaseMapView>
      </>
    )
  }

  private renderGlobeViewChildren = () => {
    const { mapBoxViewerStore } = this.props.store
    const { displayedGlobeViewItems } = mapBoxViewerStore

    return (
      <>
        <GlobeViewPlans
          mapBoxViewerStore={mapBoxViewerStore}
          sitemapWithBasemaps={mapBoxViewerStore.sitemapWithBasemapsOnGlobe}
        />
        <GlobeViewItems
          items={displayedGlobeViewItems || []}
          onSelectItem={NOOP}
          mapBoxViewerStore={mapBoxViewerStore}
          renderMarkerPin={this.renderMarkerPin}
        />
      </>
    )
  }

  @computed
  private get attrsToActivityMap() {
    return this.props.store.getClosestAttrIdToActivitiesMap(
      this.displayedActivities,
      this.props.store.displayedGlobeItems,
    )
  }

  private renderMarkerPin = (item: MapViewItemBase): JSX.Element => {
    const { user } = this.props.eventsStore.appState
    const { selectedAttribute, selectActivities } = this.props.store
    const activities = this.attrsToActivityMap[item.id] || []

    if (!activities?.length) {
      return null
    }

    const { id: sitemapItemId } = item

    const isPinSelected = selectedAttribute?.id === sitemapItemId

    return (
      <GlobeViewActivityMapPin
        key={sitemapItemId}
        isDone={false}
        isAssigned={activities.some(a => a.requesterId === user.id)}
        isSelected={isPinSelected}
        isCanceled={false}
        itemsCount={activities.length}
        onClick={selectActivities.bind(null, activities, item)}
      />
    )
  }

  private renderSitemapChildren = (
    width: number,
    height: number,
  ): JSX.Element => {
    const { textboxesCache, store } = this.props

    return (
      <>
        <SitemapItems
          className="unclickable-element"
          items={store.displayedSitemapItems || []}
          containerWidth={width}
          containerHeight={height}
          textboxesCache={textboxesCache}
        />
        <SitemapElementsWrapper>
          {this.displayedActivities.map(
            this.renderActivityPin.bind(this, width, height),
          )}
        </SitemapElementsWrapper>
      </>
    )
  }

  public get viewport() {
    return this.props.store.mapBoxViewerStore.viewport
  }

  private renderActivityPin(width: number, height: number, activity: Activity) {
    const {
      store: { getRelatedItems },
    } = this.props

    const items = getRelatedItems(activity.locations)

    if (!items) {
      return
    }

    return items.map((item, lIndex) => {
      const { position } = item.sitemapItemProperties.iconProperties
      const samePositionActivities = this.displayedActivities.filter(a => {
        return getRelatedItems(a.locations).includes(item)
      })

      const samePositionIdx = samePositionActivities.indexOf(activity)

      if (samePositionIdx > 0) {
        return
      }

      const text =
        samePositionActivities.length > 1
          ? samePositionActivities.length.toString()
          : activity.code
      const x =
        (width * position.x) / MAX_PERCENT +
        samePositionIdx * SAME_POSITION_OFFSET
      const y = (height * position.y) / MAX_PERCENT

      const isSelected = this.isActivityDisplayed(activity.code)

      return (
        <KonvaWorkflowActivityPin
          text={text}
          isSelected={isSelected}
          isCanceled={false}
          shouldRenderCircle={samePositionActivities.length > 1}
          key={activity.code + lIndex}
          x={x}
          y={y}
          offsetY={OFFSET_Y}
          onTouchEnd={this.onActivityPillTouch.bind(
            this,
            samePositionActivities,
            item,
            width,
            height,
          )}
          onClick={this.onActivityPillClick.bind(
            this,
            samePositionActivities,
            item,
            width,
            height,
          )}
        />
      )
    })
  }

  @action.bound
  private setSitemapRef(ref: BaseMapView) {
    this.sitemap = ref
  }

  @action.bound
  private setContainerRef(ref: HTMLElement) {
    this.containerRef = ref
  }

  private onSitemapTouch = () => {
    this.props.store.deselectAll()
    this.selectMapViewMode()
    this.swipeableItemId = null
  }

  private onActivityPillTouch(
    activities: Activity[],
    item: MapViewItemBase,
    width: number,
    height: number,
    event: KonvaEventObject<TouchEvent>,
  ) {
    this.selectMapViewMode()
    const { mapRef } = this.props.store.mapBoxViewerStore
    const position = clickPosition(width, height, null, mapRef, null, item)
    this.props.store.selectActivities(activities, item, position.x, position.y)
    event.cancelBubble = true
  }

  private onActivityPillClick(
    activities: Activity[],
    item: MapViewItemBase,
    width: number,
    height: number,
    event: KonvaEventObject<MouseEvent>,
  ) {
    const { mapRef } = this.props.store.mapBoxViewerStore
    const position = clickPosition(width, height, null, mapRef, null, item)
    this.props.store.selectActivities(activities, item, position.x, position.y)
    event.cancelBubble = true
  }

  private isActivityDisplayed = (code: string): boolean => {
    const { selectedActivities = [] } = this.props.store
    return selectedActivities.some(activity => activity.code === code)
  }

  @computed
  public get displayedActivities(): Activity[] {
    const {
      activityListStore: { filteredActivities },
      store: {
        displayedSitemapItems,
        mapBoxViewerStore: { displayedGlobeViewItems: displayedGlobeItems },
      },
      globe,
      store,
    } = this.props

    return filteredActivities.filter(activity => {
      return activity.locations.some(
        l =>
          (globe ? displayedGlobeItems : displayedSitemapItems).some(
            sitemapItem => l.id === sitemapItem.id,
          ) || !!store.findParentItemByAttrId(l.id),
      )
    })
  }

  private selectMapViewMode() {
    const { selectViewMode } = this.props
    if (selectViewMode) {
      selectViewMode(ViewMode.Closed)
    }
  }
}
