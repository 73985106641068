import { action, computed, observable } from 'mobx'

import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import {
  ILWFCCategory,
  ILWFCColumn,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import Material from '~/client/src/shared/models/Material'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import { IFilters } from '~/client/src/shared/stores/InitialState'
import MaterialCategoryStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import MaterialsStore from '~/client/src/shared/stores/domain/Materials.store'
import BaseListStore, {
  CATEGORY_ROW_HEIGHT,
  DEFAULT_ID_KEY,
} from '~/client/src/shared/stores/ui/BaseList.store'

import {
  ConfigurationType,
  IDeliveryPulldownSettings,
} from './IDeliverySetting'

export const COMPANY_SEPARATOR = '*'
const SAVE_TIMEOUT_MS = 2000

export enum DataKeys {
  CHECKBOX = 'checkbox',
  ID = 'id',
  NAME = 'productName',
}

export default class MaterialCategoryEditorStore extends BaseListStore<Material> {
  @observable public isSaving = false
  @observable public scrollToRow: number = -1

  public readonly columns: ILWFCColumn[] = [
    {
      dataKey: DataKeys.CHECKBOX,
      width: 60,
    },
    {
      dataKey: DataKeys.NAME,
      width: 300,
    },
  ]

  private saveSettingsTimeoutId

  public constructor(
    private eventsStore: DesktopEventStore,
    private readonly materialsStore: MaterialsStore,
    private readonly materialCategoryStore: MaterialCategoryStore,
  ) {
    super({} as IFilters, () => materialsStore.list, [], DEFAULT_ID_KEY, true)
  }

  @computed
  public get isAllSelected() {
    return (
      !this.settings.hiddenCategoryIds?.length &&
      !this.settings.hiddenSubCategoryIds?.length
    )
  }

  @computed
  public get filteredCollection() {
    return this.getFilteredCollectionExcludeFilter().sort((m1, m2) =>
      m1.productName
        ?.toLowerCase()
        .localeCompare(m2.productName?.toLowerCase()),
    )
  }

  public getTableWidth = (): number => {
    return this.columns.reduce((acc, { width }) => acc + width, 0)
  }

  public get settings() {
    return this.eventsStore.appState.projectMaterialOptions
  }

  public get isCSISubCategoriesDisabled(): boolean {
    return this.settings?.isCSISubCategoriesDisabled
  }

  public get isCSICategoryOptional(): boolean {
    const { mandatoryFields } = this.eventsStore.appState.delivery

    return !mandatoryFields[FieldIds.MATERIAL_CATEGORY]
  }

  public get csiCategoryPulldownSettings(): IDeliveryPulldownSettings {
    return {
      configurationType: ConfigurationType.MANDATORITY,
      onMandatoryChange: this.onCSICategoryChange,
    }
  }

  public get csiSubPulldownSettings(): IDeliveryPulldownSettings {
    return {
      configurationType: ConfigurationType.MANDATORITY,
      onMandatoryChange: this.onCSISubCategoryChange,
      onHide: this.hideCSISubCategories,
    }
  }

  @action.bound
  public toggleAll() {
    const isSelectMode = !this.isAllSelected
    this.settings.hiddenCategoryIds = []
    this.settings.hiddenSubCategoryIds = []
    if (!isSelectMode) {
      Object.entries(this.categoryToInstancesMap).forEach(
        ([cId, cMaterials]) => {
          this.settings.hiddenCategoryIds.push(cId)
          this.settings.hiddenSubCategoryIds.push(...cMaterials.map(m => m.id))
        },
      )
    }

    this.saveSettings()
  }

  public toggleInstance = (materialId: string) => {
    const unselectedIds = this.settings.hiddenSubCategoryIds || []
    const index = unselectedIds.indexOf(materialId)
    if (index === -1) {
      this.settings.hiddenSubCategoryIds = unselectedIds.concat(materialId)
      this.saveSettings()
      return
    }

    unselectedIds.splice(index, 1)
    const { categoryId } = this.materialsStore.getInstanceById(materialId)
    const categoryIndex = (this.settings.hiddenCategoryIds || []).indexOf(
      categoryId,
    )
    if (categoryIndex > -1) {
      this.settings.hiddenCategoryIds.splice(categoryIndex, 1)
    }

    this.saveSettings()
  }

  public saveSettings() {
    clearTimeout(this.saveSettingsTimeoutId)
    // to avoid many save requests, save after SAVE_TIMEOUT_MS ms of user inactivity
    this.saveSettingsTimeoutId = setTimeout(
      this.dispatchSaveSettings,
      SAVE_TIMEOUT_MS,
    )
  }

  protected internalToggleCategory({ categoryId, isChecked }: ILWFCCategory) {
    const unselectedIds = this.settings.hiddenCategoryIds || []

    if (!isChecked) {
      const index = unselectedIds.indexOf(categoryId)
      unselectedIds.splice(index, 1)

      const subIdsToAdd =
        this.categoryToInstancesMap[categoryId]?.map(m => m.id) || []
      const unselectedSubIds = this.settings.hiddenSubCategoryIds || []

      this.settings.hiddenSubCategoryIds = unselectedSubIds.filter(
        id => !subIdsToAdd.includes(id),
      )

      return this.saveSettings()
    }

    this.settings.hiddenCategoryIds = unselectedIds.concat(categoryId)
    const unselectedSubIds = this.settings.hiddenSubCategoryIds || []
    const subIdsToHide = this.categoryToInstancesMap[categoryId].reduce(
      (ids, material) => {
        if (this.isMaterialSelected(material)) {
          ids.push(material.id)
        }

        return ids
      },
      [],
    )

    unselectedSubIds.push(...subIdsToHide)
    this.settings.hiddenSubCategoryIds = unselectedSubIds
    this.saveSettings()
  }

  protected createCategoryRow(categoryId: string, count: number) {
    const category: ILWFCCategory = {
      categoryId,
      categoryLabel: `${this.getCategoryLabelById(categoryId)}(${count})`,
      isChecked: !this.settings.hiddenCategoryIds?.includes(categoryId),
    }

    return { category, data: {}, height: CATEGORY_ROW_HEIGHT }
  }

  protected getCategoryLabelById(categoryId: string): string {
    return this.materialCategoryStore.getCategoryNameById(categoryId)
  }

  @computed
  protected get categoryToInstancesMap(): { [categoryId: string]: Material[] } {
    const map = this.materialCategoryStore.list.reduce((res, { id }) => {
      res[id] = []
      return res
    }, {})

    this.filteredCollection.forEach(material => {
      const mapKey = material.categoryId
      // exclude empty materials
      if (map[mapKey] && material.productName) {
        map[mapKey].push(material)
      }
    })

    return map
  }

  protected sortCategories(ids: string[]): string[] {
    const { getCategoryNameById } = this.materialCategoryStore
    return ids.sort((idA: string, idB: string) => {
      const aName = getCategoryNameById(idA)
      const bName = getCategoryNameById(idB)

      return aName.toLowerCase().localeCompare(bName.toLowerCase())
    })
  }

  protected toRows(materials: Material[]) {
    return materials.map(material => ({
      data: {
        [DataKeys.ID]: material.id,
        [DataKeys.CHECKBOX]: this.isMaterialSelected(material),
        [DataKeys.NAME]: material.productName,
      },
      height: CATEGORY_ROW_HEIGHT,
    }))
  }

  private dispatchSaveSettings = () => {
    this.isSaving = true
    this.eventsStore.dispatch(
      e.SAVE_MATERIAL_OPTIONS,
      null,
      this.onSavingComplete,
      this.onSavingComplete,
    )
  }

  private onSavingComplete = () => {
    this.isSaving = false
  }

  private isMaterialSelected(material: Material): boolean {
    return !this.settings.hiddenSubCategoryIds?.includes(material.id)
  }

  private onCSICategoryChange = (fieldName: string, value: boolean) => {
    if (!value) {
      const { mandatoryFields } = this.eventsStore.appState.delivery
      mandatoryFields[FieldIds.MATERIAL] = false
    }

    this.onMandatoryChange(fieldName, value)
  }

  private onCSISubCategoryChange = (fieldName: string, value: boolean) => {
    if (this.isCSISubCategoriesDisabled) {
      this.showCSISubCategories()
    }

    this.onMandatoryChange(fieldName, value)
  }

  private onMandatoryChange = (fieldName: string, value: boolean) => {
    this.isSaving = true

    const { mandatoryFields } = this.eventsStore.appState.delivery

    mandatoryFields[fieldName] = value

    this.eventsStore.dispatch(
      e.SAVE_DELIVERY_FIELDS_CONFIGURATIONS,
      this.onSavingComplete,
      this.onSavingComplete,
    )
  }

  private showCSISubCategories = () => {
    this.settings.isCSISubCategoriesDisabled = false

    this.saveSettings()
  }

  private hideCSISubCategories = () => {
    this.settings.isCSISubCategoriesDisabled = true

    this.saveSettings()
  }
}
