import { observer } from 'mobx-react'

import ConfirmDialogTypes from '~/client/src/desktop/enums/ConfirmDialogTypes'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import User from '~/client/src/shared/models/User'

import ConfirmItemsDialog from '../../../../ConfirmItemDialog/ConfirmItemDialog'

import '../../../../ConfirmItemDialog/ConfirmItemDialog.scss'

@observer
export default class ConfirmUsersDialog extends ConfirmItemsDialog<User> {
  public constructor(props) {
    super(props)

    this.setUpTitles()

    this.tableInfo = {
      columnTitleKeys: [
        KnownTranslatorKeys.name,
        KnownTranslatorKeys.email_noun,
        KnownTranslatorKeys.phoneNumber,
      ],
      columnProps: [
        (user: User) => user.firstName + ' ' + user.lastName,
        'email',
        'phoneNumber',
      ],
    }
  }

  private setUpTitles() {
    const { type } = this.props
    switch (type) {
      case ConfirmDialogTypes.DELETE:
        this.questionKey =
          KnownTranslatorKeys.confirmDeletionOfTheFollowingXProjectMembers
        this.dialogDoneBtnTitleKey = KnownTranslatorKeys.delete
        this.dialogTitleKey = KnownTranslatorKeys.confirmDeletion
        break
      case ConfirmDialogTypes.INVITE:
        this.questionKey =
          KnownTranslatorKeys.confirmInvitationOfTheFollowingXProjectMembers
        this.dialogDoneBtnTitleKey = KnownTranslatorKeys.invite
        this.dialogTitleKey = KnownTranslatorKeys.confirmInvitation
        break
      case ConfirmDialogTypes.SET_NOT_INVITED:
        this.questionKey =
          KnownTranslatorKeys.confirmSettingAsNotInvitedOfTheFollowingXProjectMembers
        this.dialogDoneBtnTitleKey = KnownTranslatorKeys.setNotInvited
        this.dialogTitleKey =
          KnownTranslatorKeys.confirmSettingUsersAsNotInvited
        break
    }
  }
}
