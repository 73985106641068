enum WorkflowTypes {
  FLAGS = 'FLAGS',
  RFIS = 'RFI',
  INSPECTIONS = 'INSPECTIONS',
  TAGS = 'TAGS',
  DATE_CHANGE_REQUEST = 'DATE_CHANGE_REQUEST',
  DELIVERY_REQUEST = 'DELIVERY_REQUEST',
  CRANETIME = 'CRANETIME',
}

export const WorkflowTypesInfo = {
  [WorkflowTypes.FLAGS]: {
    title: 'Flags',
    description: '',
  },
  [WorkflowTypes.RFIS]: {
    title: 'RFIs',
    description: '',
  },
  [WorkflowTypes.INSPECTIONS]: {
    title: 'Inspections',
    description: '',
  },
  [WorkflowTypes.TAGS]: {
    title: 'Tags',
    description: '',
  },
  [WorkflowTypes.DATE_CHANGE_REQUEST]: {
    title: 'Date Change Request',
    description: '',
  },
  [WorkflowTypes.DELIVERY_REQUEST]: {
    title: 'DELIVERY WORKFLOW',
    description:
      'Workflow to request and validate the scheduling and logistics of a mateiral delivery.',
  },
  [WorkflowTypes.CRANETIME]: {
    title: 'Crane Time',
    description: '',
  },
}

export default WorkflowTypes
