import { action, computed, observable } from 'mobx'

import { ICompany } from '~/client/graph'
import {
  ILWFCColumn,
  ILWFCRow,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import WhiteListItemsStore from '~/client/src/shared/stores/domain/WhiteListItems.store'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'

import BaseListStore from '../../../shared/stores/ui/BaseList.store'
import DesktopInitialState from '../../stores/DesktopInitialState'
import { DEFAULT_ROW_HEIGHT } from '../BaseObservableTable/BaseObservableTable'

// localization: translated

const FILTER_KEYS = ['name', 'code']

export enum DataKeys {
  CHECKBOX = 'checkbox',
  ID = 'id',
  NAME = 'name',
  CODE = 'code',
  WHITELISTED_DOMAINS = 'whitelisted-domains',
  ROLES = 'roles',
  TRADES = 'trades',
  COMPANY_TYPE = 'companyType',
  AVATAR = 'avatar',
  RESPONSIBLE_USERS = 'responsible-users',
  MEMBERS = 'members',
}

export default class CompaniesListStore extends BaseListStore<ICompany> {
  @observable public shouldShowSelectionPopup: boolean = false
  @observable public isTabWithActiveCompaniesActivated: boolean = true
  @observable public isTabWithVendorCompaniesActivated: boolean = false

  public readonly columns: ILWFCColumn[] = [
    {
      dataKey: DataKeys.CHECKBOX,
      width: 60,
    },
    {
      translatorKey: KnownTranslatorKeys.companyName,
      dataKey: DataKeys.NAME,
      width: 150,
    },
    {
      translatorKey: KnownTranslatorKeys.avatar,
      dataKey: DataKeys.AVATAR,
      width: 60,
    },
    {
      translatorKey: KnownTranslatorKeys.code,
      dataKey: DataKeys.CODE,
      width: 150,
    },
    {
      translatorKey: KnownTranslatorKeys.whitelistedDomains,
      dataKey: DataKeys.WHITELISTED_DOMAINS,
      width: 150,
    },
    {
      translatorKey: KnownTranslatorKeys.projectRoles,
      dataKey: DataKeys.ROLES,
      width: 185,
    },
    {
      translatorKey: KnownTranslatorKeys.projectTrades,
      dataKey: DataKeys.TRADES,
      width: 185,
    },
    {
      translatorKey: KnownTranslatorKeys.companyType,
      dataKey: DataKeys.COMPANY_TYPE,
      width: 185,
    },
    {
      translatorKey: KnownTranslatorKeys.responsibleUser,
      dataKey: DataKeys.RESPONSIBLE_USERS,
      width: 220,
    },
    {
      translatorKey: KnownTranslatorKeys.members,
      dataKey: DataKeys.MEMBERS,
      width: 60,
    },
  ]

  public constructor(
    private readonly tagsStore: TagsStore,
    private readonly whiteListItemsStore: WhiteListItemsStore,
    state: DesktopInitialState,
    private readonly companiesStore: CompaniesStore,
  ) {
    super(state.companiesFilters, null, FILTER_KEYS)
  }

  protected sourceCollection = (): ICompany[] => {
    return this.companiesStore.allCompanies.filter(c => {
      const activeCondition = this.isTabWithActiveCompaniesActivated
        ? !c.isDeleted
        : c.isDeleted

      if (!activeCondition) {
        return false
      }

      const isVendorOnly = this.companiesStore.isVendorOnlyCompany(c.id)
      const vendorCondition = this.isTabWithVendorCompaniesActivated
        ? isVendorOnly
        : !isVendorOnly

      return vendorCondition
    })
  }

  @action.bound
  public toggleActiveCompanies(value: boolean) {
    if (this.isTabWithActiveCompaniesActivated === value) return

    this.isTabWithActiveCompaniesActivated = value
    this.resetSelection()
  }

  @action.bound
  public toggleVendorCompanies(value: boolean) {
    if (this.isTabWithVendorCompaniesActivated === value) return

    this.isTabWithVendorCompaniesActivated = value
    this.resetSelection()
  }

  @computed
  protected get categoryToInstancesMap() {
    return {
      [UNASSIGNED]: this.filteredCollection,
    }
  }

  @action.bound
  public toggleSelectionPopup() {
    this.shouldShowSelectionPopup = !this.shouldShowSelectionPopup
  }

  @computed
  public get rows(): ILWFCRow[] {
    return [
      { data: [] },
      ...this.toRows(
        this.filteredCollection.sort(({ name: nameA }, { name: nameB }) =>
          nameA?.toLowerCase().localeCompare(nameB?.toLowerCase()),
        ),
      ),
    ]
  }

  @action.bound
  public hideSelectionPopup() {
    this.shouldShowSelectionPopup = false
  }

  public getTableWidth = (): number => {
    return this.columns.reduce((acc, { width }) => acc + width, 0)
  }

  @action.bound
  public handleSelectAll() {
    this.selectAll()
    this.hideSelectionPopup()
  }

  @action.bound
  public handleUnselectAll() {
    this.unselectAll()
    this.hideSelectionPopup()
  }

  public get alternateHint(): string {
    return Localization.translator.thereAreNoInstancesToDisplay(
      Localization.translator.companies,
    )
  }

  protected toRows(companies: ICompany[]) {
    const { allUsersByRelatedTagIdMap } = this.tagsStore

    return companies.map(company => {
      const {
        name,
        code,
        id,
        roleIds,
        tradeIds,
        typeTags,
        avatarUrl,
        responsibleContactIds,
      } = company

      const companyMembers = allUsersByRelatedTagIdMap[id] || []

      const responsibleMembers = companyMembers.filter(u =>
        responsibleContactIds.includes(u.id),
      )

      const whitelistedDomains =
        this.whiteListItemsStore.getItemsByCompanyId(id)

      return {
        data: {
          [DataKeys.ID]: id,
          [DataKeys.CHECKBOX]: this.selection.get(id),
          [DataKeys.NAME]: name,
          [DataKeys.CODE]: code,
          [DataKeys.WHITELISTED_DOMAINS]: whitelistedDomains.map(
            d => d.domainName,
          ),
          [DataKeys.ROLES]: roleIds,
          [DataKeys.TRADES]: tradeIds,
          [DataKeys.COMPANY_TYPE]: typeTags,
          [DataKeys.AVATAR]: avatarUrl,
          [DataKeys.MEMBERS]: companyMembers.length,
          [DataKeys.RESPONSIBLE_USERS]: responsibleMembers,
        },
        height:
          responsibleMembers.length * DEFAULT_ROW_HEIGHT || DEFAULT_ROW_HEIGHT,
      }
    })
  }

  public get displayedCompaniesCountLabel(): string {
    return Localization.translator.xCompaniesShowing(this.displayedCount)
  }
}
