import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { LocationType } from '~/client/graph'
import { AdditionalReportFile } from '~/client/src/desktop/components/HeaderBar/HeaderBar.store'
import BaseReportButton, {
  IReportInfo,
} from '~/client/src/desktop/components/HeaderBar/components/BaseReportButton/BaseReportButton'
import MaterialsFilterType from '~/client/src/desktop/enums/MaterialsFilterType'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import { IAppConfig } from '~/client/src/shared/Config'
import SortOrder from '~/client/src/shared/enums/SortOrder'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import MaterialCategoriesStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import UIFilterInfo from '~/client/src/shared/stores/substates/UIFilterInfo'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import SuperFilterStore from '~/client/src/shared/stores/ui/SuperFilter.store'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import { LOCATION_SEPARATOR } from '~/client/src/shared/utils/usefulStrings'
import { mapFiltered } from '~/client/src/shared/utils/util'

import MaterialsListStore from '../../../MaterialsList/MaterialsList.store'

// localization: translated

interface IProps {
  title: string
  materialsListStore: MaterialsListStore
  filterStoresByTypeMap: {
    [filterType: string]: SuperFilterStore
  }

  className?: string
  additionalReportFiles?: AdditionalReportFile[]
  isEnabled?: boolean
  state?: DesktopInitialState
  projectDateStore?: ProjectDateStore
  configuration?: IAppConfig
  companiesStore?: CompaniesStore
  materialCategoryStore?: MaterialCategoriesStore
  locationAttributesStore?: LocationAttributesStore
  toggleModal?: () => void
  shouldPreventCreation?: boolean
  shouldHideIcon?: boolean
  shouldInvertColors?: boolean
}

export const MATERIALS_REPORT_TEMPLATE_ID = 'materials_list_report_api'
const MATERIAL_REPORT_TITLE = 'Material list report'
const DEFAULT_SORT_KEY = 'name'

@inject(
  'state',
  'projectDateStore',
  'configuration',
  'companiesStore',
  'materialCategoryStore',
  'locationAttributesStore',
)
@observer
export default class MaterialReportButton extends React.Component<IProps> {
  public render() {
    const {
      className,
      title,
      toggleModal,
      shouldPreventCreation,
      shouldHideIcon,
      shouldInvertColors,
      isEnabled,
    } = this.props

    return (
      <BaseReportButton
        className={className}
        getReportInfo={this.getReportInfo}
        title={title}
        onClick={toggleModal}
        shouldPreventCreation={shouldPreventCreation}
        shouldHideIcon={shouldHideIcon}
        shouldInvertColors={shouldInvertColors}
        isEnabled={isEnabled}
      />
    )
  }

  private getReportInfo = (): IReportInfo => {
    const {
      configuration: { TENANT_ID: tenantName },
      state: {
        activeProject: { name, id: projectId, materialsUploadId },
        filters: { selectedMaterialBandsOption },
        materialFilters: { fieldsMap },
      },
      materialsListStore: { sortState, filteredCollection },
      projectDateStore: { getMonthAndDayToDisplay, getClientTimezoneId },
      additionalReportFiles,
    } = this.props

    const now = Date.now()

    const startProjectDate = getMonthAndDayToDisplay(now)
    const endProjectDate = getMonthAndDayToDisplay(now)

    const filename = `${tenantName}_${name}_${startProjectDate}-${endProjectDate}_${Localization.translator.materialReport}`

    const materialFilters = this.getMaterialFilters(fieldsMap)
    const { bands } = selectedMaterialBandsOption

    const materialIdsSet = filteredCollection.reduce((set, { material }) => {
      if (material.categoryId && !set.has(material.categoryId)) {
        set.add(material.id)
      }
      return set
    }, new Set<string>())

    const data = {
      projectId,
      materialsUploadId,
      projectTimeZone: getClientTimezoneId(),
      filteredIds: {
        materials: Array.from(materialIdsSet),
      },
      viewModelIds: filteredCollection.map(({ id }) => id),
      filters: materialFilters,
      grouping: {
        materials: bands,
      },
      sorting: {
        materials: [
          {
            ascending: sortState.order !== SortOrder.DESC,
            key: sortState.columnKey || DEFAULT_SORT_KEY,
          },
        ],
      },
    }

    return {
      projectId,
      dateFrom: now,
      dateTo: now,
      timezoneId: getClientTimezoneId(),
      templateId: MATERIALS_REPORT_TEMPLATE_ID,
      isFullTime: true,
      files: [
        ...additionalReportFiles
          .filter(file => file.isChecked)
          .map(file => ({
            fileId: file.value,
            fileName: `${filename}_${file.name}.${file.format.toLowerCase()}`,
          })),
      ],
      data,
      title: MATERIAL_REPORT_TITLE,
    }
  }

  private getMaterialFilters(fieldsMap: {
    [filterType: string]: UIFilterInfo
  }): { [filterType: string]: string[] } {
    const {
      filterStoresByTypeMap,
      materialCategoryStore,
      companiesStore,
      locationAttributesStore,
    } = this.props

    const result: { [filterType: string]: string[] } = {}

    Object.values(LocationType).forEach(type => {
      result[type] = []
    })

    Object.entries(fieldsMap).forEach(([filterType, filter]) => {
      if (filterStoresByTypeMap[filterType]?.areAllOptionsSelected) {
        return
      }
      const optionsKeys = Array.from(filter.selectedFilterOptions.keys())
      switch (filterType) {
        case MaterialsFilterType.COMPANY:
          result[filterType] = mapFiltered(
            optionsKeys,
            key => companiesStore.getCompanyById(key)?.name,
          )
          break
        case MaterialsFilterType.MATERIAL:
          result[filterType] = mapFiltered(optionsKeys, key =>
            materialCategoryStore.getCategoryNameById(key),
          )
          break
        case MaterialsFilterType.PLANNED_INSTALL_LOCATION:
        case MaterialsFilterType.PLANNED_DELIVERY_LOCATION:
        case MaterialsFilterType.CURRENT_LOCATION:
          optionsKeys
            .filter(key => key !== UNASSIGNED)
            .forEach(key => {
              const [id, type] = key.split(LOCATION_SEPARATOR)
              const itemName = locationAttributesStore.getById(id)?.name
              if (itemName) {
                result[type].push(itemName)
              }
            })
          break
        default:
          result[filterType] = optionsKeys
      }
    })

    return result
  }
}
