import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import { BlockTypeEnum, IDeliveryConfigurations } from '~/client/graph'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import ProjectSetUpPageStore from '~/client/src/desktop/views/ProjectSetUp/ProjectSetUpPage.store'
import * as Icons from '~/client/src/shared/components/Icons'
import * as TagIcons from '~/client/src/shared/components/TagIcon'
import { cancelationReasonsList } from '~/client/src/shared/enums/CancelationReason'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import DeliveryUnitsStore from '~/client/src/shared/stores/domain/DeliveryUnits.store'
import DeliveryVehicleTypesStore from '~/client/src/shared/stores/domain/DeliveryVehicleTypes.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import {
  NO_DEADLINE_OPTION,
  deadlineOptions,
} from '~/client/src/shared/utils/DeadlineOptions'
import { copyObject } from '~/client/src/shared/utils/util'

import DeliveryRequestSetUpStore from '../../DeliveryRequestSetUp.store'
import DeliveryDetailsConfigurationsSection from './components/DeliveryDetailsConfigurationsSection'
import IDeliverySettingDto, {
  ConfigurationType,
  DeliverySettingType,
  IDeliveryPulldownSettings,
} from './components/IDeliverySetting'

import './DeliveryDetailsConfigurations.scss'

export interface IProps {
  deliveryVehicleTypesStore?: DeliveryVehicleTypesStore
  eventsStore?: DesktopEventStore
  deliveryRequestSetUpStore: DeliveryRequestSetUpStore
  deliveryUnitsStore?: DeliveryUnitsStore
  projectSetUpPageStore?: ProjectSetUpPageStore
  locationAttributesStore?: LocationAttributesStore
}

export interface IDeliverySection {
  sectionFieldName?: string
  sectionTitle: string
  sectionDescription?: string
  sectionSettings?: IDeliveryPulldownSettings
  deliverySectionSettings: IDeliverySettingDto[]
}

export enum deliverySelectTypes {
  duration = 'duration',
  deadline = 'deadline',
}

const ICON_SIZE = 20

const { STAR, ENVELOPE, MOBILE_PHONE, SEARCH } = IconNames

const getBlueprintIcon = (iconName: any) => {
  return ({ className }) => (
    <Icon icon={iconName} iconSize={ICON_SIZE} className={className} />
  )
}

const blockLateRequestingFieldName = 'shouldBlockLateRequesting'
const durationOptions = [10, 15, 20, 30, 60]
const nonWorkTimesBlockTypeFieldName = 'nonWorkTimesBlockType'
const materialCsiCategoryFieldName = 'isCSIDisabled'

// display texts:
const bookingDate = 'Booking date'
const bookingDateEnTime = 'Booking date & time'
const defaultDeliveryDuration = 'Default Delivery Duration'
const deliveryBookingDeadline = 'Delivery Booking Deadline'
const fromToDeliveries = 'Enable Internal Delivery section'
const deliveriesMustBeRequestedPriorTo = 'Deliveries must be requested prior to'
const location = 'Location'
const buildings = 'Buildings'
const zones = 'Zones'
const levels = 'Levels'
const areas = 'Areas'
const gates = 'Gates'
const routes = 'Routes'
const installationZone = 'Installation Zone'
const internalPath = 'Internal Path'
const internalDoor = 'Internal Door'
const staging = 'Staging'
const equipment = 'Equipment'
const vehicleType = 'Vehicle Type'
const deliveryVehicleType = 'Delivery Vehicle Type'
const vehicleNumber = 'Vehicle number'
const vehicleLicensePlate = 'Vehicle Model & Lic.#'
const assigneesEnAdditionalContacts = 'Assignees & Additional Contacts'
const responsibleCompany = 'Responsible company'
const assignees = 'Assignees'
const vendorEnDriver = 'Vendor & Driver'
const vendor = 'Vendor'
const vendorEmails = 'Vendor emails'
const driverMobileNumbers = 'Driver mobile numbers'
const materials = 'Materials'
const materialsCategory = 'Materials Category'
const materialsDescription = 'Materials Description'
const materialDeliveryLocation = 'Material Delivery Location'
const materialsQuantities = 'Materials Quantities'
const materialUnits = 'Material Units'
const inspections = 'Inspections'
const inspection = 'Inspection'
const inspector = 'Inspector'
const specialInstructions = 'Special Instructions'
const linkedActivity = 'Linked Activity'
const customActionButton = 'Custom Action Button'
const resetMandatoryFieldsToDefaults = 'Reset Mandatory fields to defaults'
const resetHiddenFieldsToDefaults = 'Reset Hidden fields to defaults'
const blockUsers = 'Block Users'
const warnUsers = 'Warn Users'
const allowAddingNew = 'Allow Adding New'
const dontAllowAdding = 'Dont Allow Adding'
const allowMultipleEquipment = 'Allow multiple'
const dontAllowMultipleEquipment = 'Dont Allow multiple'
const show = 'Show'
const hide = 'Hide'
const useCSICategories = 'Use CSI Categories'
const userManualEntry = 'User Manual Entry'
const cancelationReason = 'Cancelation Reason'

const descriptions = {
  bookingDate:
    'The Delivery Calendar and Requests are automatically set to a default time period. This can be changed when creating a delivery. Deliveries can also be tagged or blocked if they are requested to close to their delivery date. This is referred to as the Delivery Deadline. Only Admins and the Delivery Approval Team can create deliveries inside the Delivery Deadline',
  defaultDeliveryDuration:
    'When scheduling a delivery on the calendar a new delivery request will be created with this default duration. The duration can be manually changed.',
  deliveryBookingDeadline:
    'Deliveries must be requested prior to the Delivery Deadline. Set how many days in advance below.  The toggle can be set to block the late requesting of deliveries. The user will receive a warning and will have to pick a day that is farther in advance',
  blockDeliveriesDuringNonWorkTimes: 'Block deliveries during non-work times',
  location:
    'Deliveries are routed to specific Locations. The StruxHub Location Breakdown Structure (LBS) as defined by your nested locations on the map helps direct Deliveries from Off-Site to their final destination at an Installation Area or Staging Area.\n In this section you can define what Location Fields in the Delivery From are shown. Smaller Projects may not need a lot of Location routing. More Complicated jobs may benefit from defined Routes, Gates, and nested Locations.\n Here you can define if a field is Shown or Hidden, and if it is Mandatory or Optional for your trades to fill out.',
  installationZone:
    'The installation zone is a user entered field indicating where material is to be used.',
  equipment: 'Add the available equipment on the jobsite to offload materials.',
  prohibitEquipmentCreation:
    'Prohibit the creation of new equipment in the delivery form',
  multipleOffloadingEquipment: 'Multiple offloading equipment',
  prohibitVehicleTypeCreation:
    'Prohibit the creation of new vehicle type in the delivery form',
  vehicleType:
    'The delivery Vehicles expected on-site can be configured below or entered when creating a delivery. The common vehicles are available in a pull-down list and can be added and deleted here. The number of vehicles expected with the delivery is also entered on the form.',
  deliveryVehicleType:
    'Add the typical delivery vehicle types and sizes arriving on the jobsite. This is a single select pull down field.',
  vehicleNumber: 'The number of vehicles expected with the delivery. ',
  vehicleLicensePlate: 'The number of license plate of vehicle',
  assigneesEnAdditionalContacts:
    'When a delivery is Scheduled the company that Requested the delivery receives an email with delivery instructions. If an Onsite Contact phone number is entered they will receive an SMS notification when the delivery is Scheduled, Onsite, Inspected and Done.',
  responsibleCompany:
    'Responsible company is a pull-down list of all the companies that have been added to the project (see Settings/Users).',
  assignees:
    'The on-site contact persons sent an SMS when the delivery arrives on site. ',
  vendorEnDriver:
    'The Supplier, Vendor or Delivery company can be entered to help the on-site team route the delivery. When an Email is added the address will receive a detailed delivery manifest with the Delivery Map, Material List, On-site contact information and a QR code that when scanned will open the specified delivery. ',
  vendorOrDeliveryCompany:
    'The vendor or delivery company will receive delivery instructions when the delivery is scheduled. The vendor email and delivery drivers phone number are also set with this toggle.',
  vendorOrDeliveryCompanyEmail:
    'The vendor or delivery company will receive an email with the delivery form and a QR code that can be shown at the gate to check the delivery onsite. ',
  driverMobileNumber:
    'The delivery driver will receive an SMS with a link to the delivery details and instructions.',
  driverMobileNumbers:
    'The delivery driver(s) will receive an SMS with a link to the delivery details and instructions.',
  materials:
    'To help with inspections and material loaded schedules the Material Type, Quantity, and the quantity Units can be entered and tracked with the delivery.  ',
  materialUnits:
    'List the common units of delivered material on the project. This is a single pull-down menu and can be added to when scheduling.  ',
  material: 'The material expected with the delivery.',
  inspections:
    'Inspections may be required or optional on a delivery. When an inspection is required an Inspection State is added to the delivery workflow. A list of job-site inspectors can be added to make sure the right inspector is looking over the delivery.',
  inspection: 'Inspections can be set to default YES or NO.',
  inspector:
    'When inspection is necessary an inspector is listed and can be added by the user.',
  specialInstructions: (withTrackerText: boolean) =>
    'A text field is provided for miscellaneous instructions that should be tracked with the delivery log. ' +
    withTrackerText
      ? 'Deliveries can be associated with specific Construction Schedule Activities through a pull down list base on the Requester Company and their associated activities within the delivery time frame. Activities can also be searched for by name or activity code.'
      : '',
  specialInstructionsField:
    'Special instructions is an optional field that will be included in the delivery instructions. ',
  linkedActivity:
    'A delivery can be linked to an activity. When linked the delivery and status will be visible in the Activity Details.',
  customActionButton:
    'A custom message and button with a link can be included in the Delivery Summary email sent to Vendors and Drivers.',
  cancelationReasons: 'Select the cancelation reasons you want to use.',
  fromToSwitchDescription:
    'Some jobs will store material in Staging Areas, and at a later date need to move this material internally. Toggling on Internal Deliveries adds a second section to the form, allowing the trades to indicate where the material is coming FROM, and then defining where it is going TO. When you toggle Internal Deliveries ON you can modify the Form, adding a new FROM section. For this section you can similarly set what fields you want Shown, Mandatory or Optional. Given that most material will be stored in Staging by default we show Staging as a Mandatory field, but can modify things to suit your jobsite. For your Jobsite, depending on how you have set up your Logistics your trades might be picking Material from a Zone, Area or other Location that you can show here.',
}
const numberOfPicks = 'Number of picks'

@inject(
  'eventsStore',
  'deliveryVehicleTypesStore',
  'deliveryUnitsStore',
  'locationAttributesStore',
)
@observer
export default class DeliveryDetailsConfigurations extends React.Component<IProps> {
  @observable private deliveryDuration: number
  @observable private deadlineInterval: number

  private get deliverySettings(): IDeliverySection[] {
    const {
      eventsStore: { appState },
      deliveryRequestSetUpStore,
      deliveryVehicleTypesStore,
    } = this.props
    const { isTrackerDisabled } = appState

    return [
      {
        sectionTitle: bookingDate,
        sectionDescription: descriptions.bookingDate,
        deliverySectionSettings: [
          {
            title: bookingDateEnTime,
            isKeySection: true,
            getIcon: Icons.CalendarGrey,
          },
          {
            title: defaultDeliveryDuration,
            description: descriptions.defaultDeliveryDuration,
            type: DeliverySettingType.SELECT,
            select: {
              hint: defaultDeliveryDuration,
              name: deliverySelectTypes.duration,
              options: durationOptions,
              onSelectChange: this.onDurationSelectChange,
            },
          },
          {
            fieldName: blockLateRequestingFieldName,
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.BOOKING_DEADLINE,
              onMandatoryChange: this.onDeadlineMandatoryChange,
              onHide: this.onHideDeadlineInterval,
              customModalText: {
                mandatory: blockUsers,
                optional: warnUsers,
              },
            },
            title: deliveryBookingDeadline,
            description: descriptions.deliveryBookingDeadline,
            type: DeliverySettingType.SELECT,
            select: {
              hint: deliveriesMustBeRequestedPriorTo,
              name: deliverySelectTypes.deadline,
              options: deadlineOptions,
              onSelectChange: this.onDeadlineIntervalChange,
            },
          },
          {
            fieldName: FieldIds.CANCELATION_REASON,
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.MANDATORITY,
              onMandatoryChange: this.onCancelationMandatoryChange,
              onHide: this.onHideField,
            },
            title: cancelationReason,
            description: descriptions.cancelationReasons,
          },
          {
            fieldName: nonWorkTimesBlockTypeFieldName,
            title: descriptions.blockDeliveriesDuringNonWorkTimes,
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.NON_WORK_TIMES_BLOCK,
              onMandatoryChange: this.onNonWorkTimesChange,
              onHide: this.onNonWorkTimesHide,
              customModalText: {
                mandatory: blockUsers,
                optional: warnUsers,
              },
            },
          },
        ],
      },
      {
        sectionTitle: location,
        sectionDescription: descriptions.location,
        deliverySectionSettings: [
          {
            title: fromToDeliveries,
            fieldName: FieldIds.FROM_TO_SWITCH,
            type: DeliverySettingType.SWITCH,
            description: descriptions.fromToSwitchDescription,
            onSwitchChange: this.onSwitchChange,
          },
          {
            fieldName: FieldIds.BUILDING,
            isKeySection: true,
            title: buildings,
            isEditable: true,
            getIcon: TagIcons.Building,
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.MANDATORITY,
              onMandatoryChange: this.onMandatoryChange,
              onHide: this.onHideField,
            },
          },
          {
            fieldName: FieldIds.ZONE,
            isKeySection: true,
            title: zones,
            getIcon: TagIcons.Zone,
            isEditable: true,
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.MANDATORITY,
              onMandatoryChange: this.onMandatoryChange,
              onHide: this.onHideField,
            },
          },
          {
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.MANDATORITY,
              onMandatoryChange: this.onMandatoryChange,
              onHide: this.onHideField,
            },
            fieldName: FieldIds.STAGING,
            title: staging,
            isKeySection: true,
            getIcon: TagIcons.Staging,
            isEditable: true,
          },
          {
            fieldName: FieldIds.LEVEL,
            isKeySection: true,
            title: levels,
            isEditable: true,
            getIcon: TagIcons.Level,
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.MANDATORITY,
              onMandatoryChange: this.onMandatoryChange,
              onHide: this.onHideField,
            },
          },
          {
            fieldName: FieldIds.AREA,
            isKeySection: true,
            title: areas,
            isEditable: true,
            getIcon: TagIcons.Area,
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.MANDATORITY,
              onMandatoryChange: this.onMandatoryChange,
              onHide: this.onHideField,
            },
          },
          {
            fieldName: FieldIds.ROUTE,
            isKeySection: true,
            title: routes,
            getIcon: TagIcons.Route,
            isEditable: true,
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.MANDATORITY,
              onMandatoryChange: this.onMandatoryChange,
              onHide: this.onHideField,
            },
          },
          {
            isKeySection: true,
            fieldName: FieldIds.GATE,
            title: gates,
            isEditable: true,
            getIcon: TagIcons.Gate,
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.MANDATORITY,
              onMandatoryChange: this.onMandatoryChange,
              onHide: this.onHideField,
            },
          },
          {
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.MANDATORITY,
              onMandatoryChange: this.onMandatoryChange,
              onHide: this.onHideField,
            },
            fieldName: FieldIds.INTERIOR_PATH,
            title: internalPath,
            isKeySection: true,
            getIcon: TagIcons.InteriorPath,
            isEditable: true,
          },
          {
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.MANDATORITY,
              onMandatoryChange: this.onMandatoryChange,
              onHide: this.onHideField,
            },
            fieldName: FieldIds.INTERIOR_DOOR,
            title: internalDoor,
            isKeySection: true,
            getIcon: TagIcons.InteriorDoor,
            isEditable: true,
          },
          {
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.MANDATORITY,
              onMandatoryChange: this.onMandatoryChange,
              onHide: this.onHideField,
            },
            fieldName: FieldIds.INSTALLATION_ZONE,
            title: installationZone,
            isKeySection: true,
            getIcon: TagIcons.Area,
            isEditable: true,
            description: descriptions.installationZone,
          },
        ],
      },
      {
        sectionTitle: equipment,
        deliverySectionSettings: [
          {
            fieldName: FieldIds.OFFLOADING_EQUIPMENT,
            title: equipment,
            isEditable: true,
            isKeySection: true,
            getIcon: TagIcons.Equipment,
            description: descriptions.equipment,
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.MANDATORITY,
              onMandatoryChange: this.onMandatoryChange,
              onHide: this.onHideField,
            },
          },
          {
            fieldName: FieldIds.OFFLOADING_EQUIPMENT,
            relatedHideableFieldName: FieldIds.OFFLOADING_EQUIPMENT,
            title: descriptions.prohibitEquipmentCreation,
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.EXTENSIBILITY,
              onMandatoryChange: this.onFieldExtensibilityToggle,
              customModalText: {
                mandatory: allowAddingNew,
                optional: dontAllowAdding,
              },
            },
          },
          {
            fieldName: FieldIds.IS_MULTIPLE_EQUIPMENT,
            relatedHideableFieldName: FieldIds.OFFLOADING_EQUIPMENT,
            title: descriptions.multipleOffloadingEquipment,
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.CONFIGURATION,
              onMandatoryChange: this.onFieldMultipleOffloadingToggle,
              customModalText: {
                mandatory: allowMultipleEquipment,
                optional: dontAllowMultipleEquipment,
              },
            },
          },
          {
            fieldName: FieldIds.NUMBER_OF_EQUIPMENT_PICKS,
            title: numberOfPicks,
            isEditable: true,
            isKeySection: true,
            getIcon: Icons.HashTag,
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.MANDATORITY,
              onMandatoryChange: this.onMandatoryChange,
              onHide: this.onHideField,
            },
          },
        ],
      },
      {
        sectionTitle: vehicleType,
        sectionDescription: descriptions.vehicleType,
        deliverySectionSettings: [
          {
            isCollapsible: true,
            fieldName: FieldIds.TRUCK_SIZE,
            title: deliveryVehicleType,
            isEditable: true,
            isKeySection: true,
            getIcon: Icons.Truck,
            description: descriptions.deliveryVehicleType,
            deliveryAttribute: {
              store: deliveryVehicleTypesStore,
              title: deliveryVehicleType,
              deliveryRequestSetUpStore,
              isEditAllowed: true,
            },
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.MANDATORITY,
              onMandatoryChange: this.onMandatoryChange,
              onHide: this.onHideField,
            },
          },
          {
            fieldName: FieldIds.TRUCK_SIZE,
            relatedHideableFieldName: FieldIds.TRUCK_SIZE,
            title: descriptions.prohibitVehicleTypeCreation,
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.EXTENSIBILITY,
              onMandatoryChange: this.onFieldExtensibilityToggle,
              customModalText: {
                mandatory: allowAddingNew,
                optional: dontAllowAdding,
              },
            },
          },
          {
            isCollapsible: true,
            fieldName: FieldIds.TRUCK_LICENSE_PLATE,
            isKeySection: true,
            getIcon: Icons.HashTag,
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.MANDATORITY,
              onMandatoryChange: this.onMandatoryChange,
              onHide: this.onHideField,
            },
            title: vehicleLicensePlate,
            isEditable: true,
            description: descriptions.vehicleLicensePlate,
          },
          {
            isCollapsible: true,
            fieldName: FieldIds.TRUCK_NUMBER,
            isKeySection: true,
            getIcon: Icons.HashTag,
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.MANDATORITY,
              onMandatoryChange: this.onMandatoryChange,
              onHide: this.onHideField,
            },
            title: vehicleNumber,
            isEditable: true,
            description: descriptions.vehicleNumber,
          },
        ],
      },
      {
        sectionTitle: assigneesEnAdditionalContacts,
        sectionDescription: descriptions.assigneesEnAdditionalContacts,
        deliverySectionSettings: [
          {
            isCollapsible: true,
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.MANDATORITY,
              onMandatoryChange: this.onMandatoryChange,
              onHide: this.onHideField,
            },
            isKeySection: true,
            getIcon: ({ className }) =>
              Icons.Team({ className: `${className} company-icon` }),
            fieldName: FieldIds.COMPANY,
            title: responsibleCompany,
            description: descriptions.responsibleCompany,
          },
          {
            isCollapsible: true,
            fieldName: FieldIds.ON_SITE_CONTACTS,
            isMultiValueInput: true,
            title: assignees,
            isKeySection: true,
            getIcon: getBlueprintIcon(STAR),
            description: descriptions.assignees,
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.MANDATORITY,
              onMandatoryChange: this.onMandatoryChange,
              onHide: this.onHideField,
            },
          },
        ],
      },
      {
        sectionTitle: vendorEnDriver,
        sectionDescription: descriptions.vendorEnDriver,
        deliverySectionSettings: [
          {
            isCollapsible: true,
            fieldName: FieldIds.VENDOR,
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.MANDATORITY,
              onMandatoryChange: this.onMandatoryChange,
              onHide: this.onHideField,
            },
            title: vendor,
            isEditable: true,
            isKeySection: true,
            getIcon: Icons.Vendor,
            description: descriptions.vendorOrDeliveryCompany,
          },
          {
            isCollapsible: true,
            fieldName: FieldIds.VENDOR_EMAILS,
            isMultiValueInput: true,
            isKeySection: true,
            getIcon: getBlueprintIcon(ENVELOPE),
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.MANDATORITY,
              onMandatoryChange: this.onMandatoryChange,
              onHide: this.onHideField,
            },
            title: vendorEmails,
            isEditable: true,
            description: descriptions.vendorOrDeliveryCompanyEmail,
          },
          {
            isCollapsible: true,
            fieldName: FieldIds.DRIVER_PHONE_NUMBERS,
            isMultiValueInput: true,
            isKeySection: true,
            getIcon: getBlueprintIcon(MOBILE_PHONE),
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.MANDATORITY,
              onMandatoryChange: this.onMandatoryChange,
              onHide: this.onHideField,
            },
            title: driverMobileNumbers,
            isEditable: true,
            description: descriptions.driverMobileNumber,
          },
        ],
      },
      {
        sectionFieldName: FieldIds.MATERIALS_SECTION,
        sectionTitle: materials,
        sectionDescription: descriptions.materials,
        sectionSettings: {
          configurationType: ConfigurationType.MATERIAL_SECTION,
          onMandatoryChange: this.onHideSection,
          customModalText: {
            mandatory: show,
            optional: hide,
          },
        },
        deliverySectionSettings: this.deliveryMaterialSectionSettings,
      },
      {
        sectionFieldName: FieldIds.INSPECTION_SECTION,
        sectionTitle: inspections,
        sectionDescription: descriptions.inspections,
        sectionSettings: {
          configurationType: ConfigurationType.INSPECTION_SECTION,
          onMandatoryChange: this.onInspectionChange,
          onHide: this.onHideField,
        },
        deliverySectionSettings: [
          {
            isCollapsible: true,
            fieldName: FieldIds.IS_INSPECTION_REQUIRED,
            hasOptionsPreview: true,
            title: inspection,
            isEditable: true,
            isKeySection: true,
            getIcon: getBlueprintIcon(SEARCH),
            description: descriptions.inspection,
          },
          {
            isCollapsible: true,
            fieldName: FieldIds.INSPECTOR,
            title: inspector,
            isEditable: true,
            isKeySection: true,
            getIcon: getBlueprintIcon(STAR),
            description: descriptions.inspector,
            isInspector: true,
          },
        ],
      },
      {
        sectionTitle: specialInstructions,
        sectionDescription: descriptions.specialInstructions(
          !isTrackerDisabled,
        ),
        deliverySectionSettings: [
          {
            isCollapsible: true,
            fieldName: FieldIds.NOTE,
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.MANDATORITY,
              onMandatoryChange: this.onMandatoryChange,
              onHide: this.onHideField,
            },
            title: specialInstructions,
            isKeySection: true,
            isEditable: true,
            description: descriptions.specialInstructionsField,
          },
          !isTrackerDisabled && {
            isCollapsible: true,
            fieldName: FieldIds.ACTIVITY_ID,
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.MANDATORITY,
              onMandatoryChange: this.onMandatoryChange,
              onHide: this.onHideField,
            },
            title: linkedActivity,
            isEditable: true,
            isKeySection: true,
            description: descriptions.linkedActivity,
          },
          {
            isCollapsible: true,
            fieldName: FieldIds.CUSTOM_ACTION_BUTTON,
            deliveryPulldownSettings: {
              configurationType: ConfigurationType.CONFIGURATION,
              onMandatoryChange: this.onCustomActionMandatoryChange,
              onHide: this.onCustomActionHide,
            },
            title: customActionButton,
            isEditable: true,
            isKeySection: true,
            description: descriptions.customActionButton,
          },
        ].filter(s => !!s),
      },
    ]
  }

  public UNSAFE_componentWillReceiveProps() {
    this.deliveryDuration = null
    this.deadlineInterval = null
  }

  public render(): JSX.Element {
    return (
      <div className="delivery-details-configurations bt-light-input-border">
        {this.renderHeader()}
        {this.renderSections()}
      </div>
    )
  }

  private renderHeader(): JSX.Element {
    return (
      <div className="row x-end delivery-details-configuration-header">
        <a
          onClick={this.resetHideOptions}
          className="text blue-highlight large w-fit-content mr20"
        >
          {resetHiddenFieldsToDefaults}
        </a>
        <a
          onClick={this.resetMandatoryOptions}
          className="text blue-highlight large w-fit-content"
        >
          {resetMandatoryFieldsToDefaults}
        </a>
      </div>
    )
  }

  private renderSections(): JSX.Element {
    const { projectSetUpPageStore, deliveryRequestSetUpStore } = this.props

    return (
      <DeliveryDetailsConfigurationsSection
        deliverySettings={this.deliverySettings}
        deliveryDuration={this.deliveryDuration}
        deadlineInterval={this.deadlineInterval}
        projectSetUpPageStore={projectSetUpPageStore}
        deliveryRequestSetUpStore={deliveryRequestSetUpStore}
      />
    )
  }

  private resetMandatoryOptions = () => {
    const { eventsStore } = this.props
    const { delivery } = eventsStore.appState

    delivery.mandatoryFields = copyObject(delivery.defaultMandatoryFields)

    eventsStore.dispatch(e.SAVE_DELIVERY_FIELDS_CONFIGURATIONS)
  }

  private onMandatoryChange = (fieldName: string, value: boolean) => {
    const { eventsStore } = this.props
    const { mandatoryFields, hiddenFields } = eventsStore.appState.delivery

    if (hiddenFields[fieldName]) {
      hiddenFields[fieldName] = false
    }

    mandatoryFields[fieldName] = value

    eventsStore.dispatch(e.SAVE_DELIVERY_FIELDS_CONFIGURATIONS)
  }

  private onSwitchChange = (fieldName: string, value: boolean) => {
    const { eventsStore } = this.props
    const { hiddenFields } = eventsStore.appState.delivery
    hiddenFields[fieldName] = value

    eventsStore.dispatch(e.SAVE_DELIVERY_FIELDS_CONFIGURATIONS)
  }

  private get isFromToModeEnabled() {
    return !this.props.eventsStore.appState.delivery.hiddenFields[
      FieldIds.FROM_TO_SWITCH
    ]
  }

  private get deliveryMaterialSectionSettings(): IDeliverySettingDto[] {
    const {
      eventsStore: { appState },
      deliveryRequestSetUpStore,
      deliveryUnitsStore,
    } = this.props
    const { projectMaterialOptions } = appState

    const categoryField: IDeliverySettingDto = {
      fieldName: materialCsiCategoryFieldName,
      isMaterialCategory: true,
      deliveryPulldownSettings: {
        configurationType: ConfigurationType.PROJECT_MATERIAL_OPTION,
        onMandatoryChange: this.onMaterialCategoryChange,
        customModalText: {
          mandatory: useCSICategories,
          optional: userManualEntry,
        },
      },
      title: materialsCategory,
      isKeySection: true,
      getIcon: Icons.Material,
      isCollapsible: true,
    }

    const descriptionField: IDeliverySettingDto = {
      fieldName: FieldIds.MATERIAL_NOTE,
      deliveryPulldownSettings: {
        configurationType: ConfigurationType.MANDATORITY,
        onMandatoryChange: this.onMandatoryChange,
        onHide: !projectMaterialOptions.isCSIDisabled ? this.onHideField : null,
      },
      title: materialsDescription,
      isKeySection: true,
      isEditable: true,
      getIcon: (props: any) => (
        <Icon
          {...props}
          icon={IconNames.MANUALLY_ENTERED_DATA}
          iconSize={props.size}
        />
      ),
    }

    const quantityField: IDeliverySettingDto = {
      fieldName: FieldIds.MATERIAL_QUANTITY,
      deliveryPulldownSettings: {
        configurationType: ConfigurationType.MANDATORITY,
        onMandatoryChange: this.onMandatoryChange,
        onHide: this.onHideField,
      },
      title: materialsQuantities,
      isKeySection: true,
      getIcon: Icons.HashTag,
    }

    const unitsField: IDeliverySettingDto = {
      fieldName: FieldIds.MATERIAL_UNITS,
      title: materialUnits,
      isEditable: true,
      isKeySection: true,
      description: descriptions.materialUnits,
      deliveryAttribute: {
        store: deliveryUnitsStore,
        title: materialUnits,
        deliveryRequestSetUpStore,
      },
      isCollapsible: true,
      getIcon: Icons.Measure,
    }

    const locationField = {
      fieldName: FieldIds.MATERIAL_LOCATION,
      isEditable: true,
      isKeySection: true,
      deliveryPulldownSettings: {
        configurationType: ConfigurationType.MANDATORITY,
        onMandatoryChange: this.onMandatoryChange,
        onHide: this.onHideField,
      },
      title: materialDeliveryLocation,
      getIcon: Icons.Location,
    }

    return [
      categoryField,
      quantityField,
      unitsField,
      locationField,
      descriptionField,
    ]
  }

  private resetHideOptions = () => {
    const { eventsStore } = this.props
    const { delivery, projectMaterialOptions } = eventsStore.appState

    delivery.hiddenFields = copyObject(delivery.defaultHiddenFields)

    projectMaterialOptions.isCSIDisabled = false
    projectMaterialOptions.isCSISubCategoriesDisabled = false

    eventsStore.dispatch(e.SAVE_DELIVERY_FIELDS_CONFIGURATIONS)
    eventsStore.dispatch(e.SAVE_MATERIAL_OPTIONS)
  }

  private onHideField = (fieldName: string, value: boolean) => {
    const { eventsStore } = this.props
    const { hiddenFields } = eventsStore.appState.delivery

    hiddenFields[fieldName] = value

    eventsStore.dispatch(e.SAVE_DELIVERY_FIELDS_CONFIGURATIONS)
  }

  private onHideSection = (fieldName: string, value: boolean) => {
    this.onHideField(fieldName, !value)
  }

  private onInspectionChange = (fieldName: string, value: boolean) => {
    const { eventsStore } = this.props
    const { hiddenFields } = eventsStore.appState.delivery

    if (hiddenFields[fieldName]) {
      hiddenFields[fieldName] = false
    }

    this.onMandatoryChange(FieldIds.IS_INSPECTION_REQUIRED, value)
  }

  @action.bound
  private onDurationSelectChange(event: any) {
    this.deliveryDuration = Number(event.target.value)
    this.updateDefaultDeliveryDuration()
  }

  @action.bound
  private updateDefaultDeliveryDuration() {
    const { eventsStore } = this.props
    const { deliveryDuration } = eventsStore.appState.delivery.configurations

    if (this.deliveryDuration !== deliveryDuration) {
      eventsStore.dispatch(e.SAVE_DELIVERY_CONFIGURATIONS, {
        deliveryDuration: this.deliveryDuration,
      })
    }
  }

  @action.bound
  private onDeadlineIntervalChange(event: any) {
    this.deadlineInterval = Number(event.target.value)
    this.updateDefaultDeadlineInterval()
  }

  @action.bound
  private onHideDeadlineInterval() {
    this.deadlineInterval = NO_DEADLINE_OPTION
    this.updateDefaultDeadlineInterval()
  }

  @action.bound
  private onDeadlineMandatoryChange(
    configurationField: string,
    value: boolean,
  ) {
    const { eventsStore } = this.props
    const { deadlineInterval } = eventsStore.appState.delivery.configurations

    if (deadlineInterval === NO_DEADLINE_OPTION) {
      this.deadlineInterval = deadlineOptions[1]
    }

    eventsStore.dispatch(e.SAVE_DELIVERY_CONFIGURATIONS, {
      [configurationField]: value,
      deadlineInterval: this.deadlineInterval || deadlineInterval,
    })
  }

  private onNonWorkTimesChange = (
    nonWorkTimesFieldName: string,
    value: boolean,
  ) => {
    const { eventsStore } = this.props

    eventsStore.dispatch(e.SAVE_DELIVERY_CONFIGURATIONS, {
      [nonWorkTimesFieldName]: value ? BlockTypeEnum.Block : BlockTypeEnum.Warn,
    })
  }

  private onNonWorkTimesHide = () => {
    const { eventsStore } = this.props

    eventsStore.dispatch(e.SAVE_DELIVERY_CONFIGURATIONS, {
      [nonWorkTimesBlockTypeFieldName]: BlockTypeEnum.Hide,
    })
  }

  @action.bound
  private updateDefaultDeadlineInterval() {
    const { eventsStore } = this.props
    const { deadlineInterval } = eventsStore.appState.delivery.configurations

    if (this.deadlineInterval !== deadlineInterval) {
      eventsStore.dispatch(e.SAVE_DELIVERY_CONFIGURATIONS, {
        deadlineInterval: this.deadlineInterval,
      } as IDeliveryConfigurations)
    }
  }

  private onConfigurationChange = (
    configurationField: string,
    value: boolean,
  ) => {
    const { eventsStore } = this.props

    eventsStore.dispatch(e.SAVE_DELIVERY_CONFIGURATIONS, {
      [configurationField]: value,
    })
  }

  private onCustomActionMandatoryChange = (field: string, value: boolean) => {
    this.onConfigurationChange(field, true)
    this.onMandatoryChange(field, value)
  }

  private onCustomActionHide = (field: string) => {
    this.onConfigurationChange(field, false)
    this.onHideField(field, true)
  }

  private onMaterialCategoryChange = () => {
    const { eventsStore } = this.props
    const { delivery, projectMaterialOptions } = eventsStore.appState
    const { hiddenFields } = delivery

    projectMaterialOptions.isCSIDisabled = !projectMaterialOptions.isCSIDisabled

    if (projectMaterialOptions.isCSIDisabled) {
      projectMaterialOptions.isCSISubCategoriesDisabled = true

      if (hiddenFields[FieldIds.MATERIAL_NOTE]) {
        this.onHideField(FieldIds.MATERIAL_NOTE, false)
      }
    }

    eventsStore.dispatch(e.SAVE_MATERIAL_OPTIONS)
  }

  private onFieldExtensibilityToggle = (fieldId: FieldIds) => {
    const { eventsStore } = this.props
    const { unextendableFields } = eventsStore.appState.delivery.configurations

    eventsStore.dispatch(e.SAVE_DELIVERY_CONFIGURATIONS, {
      unextendableFields: unextendableFields.includes(fieldId)
        ? unextendableFields.filter(f => f !== fieldId)
        : [...unextendableFields, fieldId],
    })
  }

  private onFieldMultipleOffloadingToggle = (
    fieldId: FieldIds,
    value: boolean,
  ) => {
    const { eventsStore } = this.props
    eventsStore.dispatch(e.SAVE_DELIVERY_CONFIGURATIONS, {
      isMultipleEquipmentAllowed: value,
    })
  }

  private onCancelationMandatoryChange = (
    fieldName: string,
    value: boolean,
  ) => {
    const { eventsStore } = this.props
    const { configurations } = eventsStore.appState.delivery

    const areAllReasonsHidden = cancelationReasonsList.every(r =>
      configurations.hiddenCancelationReasons.includes(r),
    )

    if (areAllReasonsHidden) {
      configurations.hiddenCancelationReasons = []

      eventsStore.dispatch(e.SAVE_DELIVERY_CONFIGURATIONS)
    }

    this.onMandatoryChange(fieldName, value)
  }
}
