import React from 'react'

import { Icon, Popover } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { PermitFieldType, SitePermitStatus } from '~/client/graph'
import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import UsernameFromUid from '~/client/src/shared/components/UsernameFromUid'
import WorkflowCardStatus from '~/client/src/shared/components/WorkflowCard/Status'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import PermitField from '~/client/src/shared/models/PermitField'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import ReportsListTable from '../components/ReportsListTable/ReportsListTable'
import FormReportListStore, { DataKeys } from './FormReportList.store'
import FormReportListLocationCell from './components/FormReportListLocationCell'

interface IProps {
  store: FormReportListStore
}

@observer
export default class FormReportList extends React.Component<IProps> {
  public render() {
    const {
      rows,
      columns,
      columnsWidthState,
      groupingKey,
      collapsedCategories,
      toggleCategoryCollapsing,
      sortState,
      handleColumnSort,
      onRowClick,
      toggleCategory,
      getRowHeight,
    } = this.props.store

    return (
      <ReportsListTable
        rows={rows}
        groupingKey={groupingKey}
        sortedColumnKey={sortState.columnKey}
        sortingOrder={sortState.order}
        columns={columns}
        columnsWidthState={columnsWidthState}
        onColumnSort={handleColumnSort}
        collapsedCategories={collapsedCategories}
        columnKeyToCellRenderer={this.columnKeyToCellRenderer}
        onCategoryCollapsingToggle={toggleCategoryCollapsing}
        onCellClick={onRowClick}
        onCategoryCheckboxToggle={toggleCategory}
        rowHeightGetter={getRowHeight}
      />
    )
  }

  private get columnKeyToCellRenderer() {
    const columnsHandler = {
      [DataKeys.CHECKBOX]: value => <Checkbox isChecked={value} />,
      [DataKeys.NAME]: this.renderTitle,
      [DataKeys.STATUS]: this.renderStatus,
      [DataKeys.START_DATE]: (date: string) => this.renderStrings([date]),
      [DataKeys.END_DATE]: (date: string) => this.renderStrings([date]),
      [DataKeys.COMPANY]: this.renderStrings,
      [DataKeys.LOCATION]: this.renderLocations,
      [DataKeys.REQUESTER]: this.renderUsers,
    }

    this.props.store.dynamicColumns.forEach(dynamicColumn => {
      columnsHandler[dynamicColumn.dataKey] = this.renderDynamicColumn
    })

    return columnsHandler
  }

  private renderTitle = (
    title: string | { isInspection: boolean; value: string },
  ) => {
    if (typeof title === 'string') {
      return this.renderStrings([title])
    }
    if (title.isInspection) {
      return <div className="pl15">{this.renderStrings([title.value])}</div>
    }
  }

  private renderDynamicColumn = (field: PermitField) => {
    if (!field) return NO_VALUE

    if (
      [
        PermitFieldType.User,
        PermitFieldType.Subscriber,
        PermitFieldType.Assignee,
      ].includes(field.type)
    ) {
      return this.renderUsers(field?.values || [])
    }

    if (field.type === PermitFieldType.File) {
      return this.renderStrings([
        field.values?.length
          ? Localization.translator.xFiles(field.values?.length)
          : NO_VALUE,
      ])
    }

    if (field.type === PermitFieldType.Measure) {
      return this.renderStrings(
        field.values.map(fieldValue =>
          [fieldValue.value, fieldValue.units].join(' '),
        ),
      )
    }

    if (field.values?.length > 2) {
      return (
        <Popover
          content={
            <div className="col px15 py15">
              {this.renderStrings(field.values)}
            </div>
          }
          placement="bottom"
          interactionKind="hover"
        >
          <span className="inline-flex y-center">
            {this.renderStrings(field.values.slice(0, 1))}
            <Icon icon={IconNames.CARET_RIGHT} className="row" iconSize={14} />
          </span>
        </Popover>
      )
    }

    return this.renderStrings(field.values)
  }

  private renderLocations = (locations: LocationBase[]): JSX.Element => (
    <FormReportListLocationCell locations={locations} />
  )

  private renderUsers = (userIds: string[]): JSX.Element => {
    return (
      <>
        {userIds.map((userId, i) => (
          <div
            key={userId}
            className={classList({
              col: true,
              pb8: userIds?.length > 1 && userIds?.length !== i + 1,
            })}
          >
            <UsernameFromUid userId={userId} isClickable />
          </div>
        ))}
      </>
    )
  }

  private renderStatus = (data: {
    status: SitePermitStatus
    workflowStepLevel: number
    isLate: boolean
  }) => (
    <div className="row no-flex-children">
      <WorkflowCardStatus
        status={data.status}
        workflowStepLevel={data.workflowStepLevel}
        isLate={data.isLate}
      />
    </div>
  )

  private renderStrings = (values: string[]): JSX.Element => {
    return (
      <>
        {values.map((value, i) => (
          <div key={value + i} title={value} className="text ellipsis">
            {value}
          </div>
        ))}
      </>
    )
  }
}
