import { action, computed, observable } from 'mobx'

import { SelectionMap } from '~/client/src/desktop/stores/ui/BaseTagSelection.store'
import CheckState from '~/client/src/shared/components/Checkbox/CheckState'
import { TagType } from '~/client/src/shared/enums/TagType'
import { ITag } from '~/client/src/shared/models/Tag'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

// localization: no display text to translate

export type NavigationItem = {
  categoryId: string
  itemId: string
  title: string
}

export default class TagsSelectorStore {
  @observable public navigationChain: NavigationItem[] = []
  @observable public searchKey: string = EMPTY_STRING

  @observable public selection: SelectionMap = new Map()

  public constructor(
    private readonly tagsStore: TagsStore,
    private readonly onSearchReset: () => void,
    public readonly categories: TagType[],
    public readonly radioTagTypes: TagType[],
    public readonly separatedCategories: TagType[],
    selection: SelectionMap,
  ) {
    this.setSelection(selection)
  }

  public get isSomeCategorySelected(): boolean {
    return !!this.navigationChain.length
  }

  public get isSearchMode(): boolean {
    return !!this.searchKey
  }

  public setSelection(selection: SelectionMap) {
    if (selection) {
      this.selection = new Map(selection)
    }
  }

  public setSearchKey(searchKey: string) {
    this.searchKey = searchKey
  }

  public get canSpecificUserTagBeSelected(): boolean {
    return this.categories.some(type => type === TagType.User)
  }

  public get head(): NavigationItem {
    return this.navigationChain[this.navigationChain.length - 1]
  }

  public getCategoryTags(categoryId: string, subCategoryId?: string): ITag[] {
    const {
      tagListsWithDefaultsTagsMap,
      usersByRelatedTagIdMap,
      getTagsByIds,
    } = this.tagsStore

    if (!subCategoryId) {
      return tagListsWithDefaultsTagsMap[categoryId]
    }

    const users = usersByRelatedTagIdMap[subCategoryId]

    return getTagsByIds(
      TagType.User,
      users.map(u => u.id),
    )
  }

  @computed
  public get searchResByCategories(): { [key: string]: ITag[] } {
    const searchKey = this.searchKey.toLowerCase()

    return this.categories.reduce((acc, category) => {
      acc[category] = this.tagsStore.tagListsWithDefaultsTagsMap[
        category
      ].filter(({ name }) => name.toLowerCase().includes(searchKey))

      return acc
    }, {})
  }

  @action.bound
  public selectNavigationItem(navItem: NavigationItem) {
    this.navigationChain.push(navItem)
    this.onSearchReset()
  }

  @action.bound
  public handleBackClick() {
    this.navigationChain.pop()
  }

  @action.bound
  public handleNavItemClick(navItemIndex: number) {
    this.navigationChain = this.navigationChain.slice(0, navItemIndex + 1)
    this.onSearchReset()
  }

  @action.bound
  public toggleRadio(instance: any) {
    for (const [tag] of this.selection) {
      if (tag.type === instance.type) {
        this.selection.delete(tag)
      }
    }

    this.selection.set(instance, CheckState.CHECKED)
  }

  @action.bound
  public toggleCheckbox(instance: ITag) {
    const checkState = this.selection.get(instance)

    if (checkState === CheckState.CHECKED) {
      this.selection.delete(instance)
    } else {
      this.selection.set(instance, CheckState.CHECKED)
    }
  }

  public isRadioTag = ({ type }: ITag) => {
    const tagType = this.convertDefaultTagType(type)
    return this.radioTagTypes.includes(tagType)
  }

  public withSeparator = (tagType: TagType) => {
    return this.separatedCategories.includes(tagType)
  }

  public canNavigate = (category: string, tagId: string): boolean => {
    const list = this.getCategoryTags(category, tagId)
    return !!list?.length
  }

  private convertDefaultTagType = (tagType: TagType): TagType => {
    switch (tagType) {
      case TagType.DefaultTeam:
        return TagType.Team
      default:
        return tagType
    }
  }
}
