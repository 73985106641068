import * as React from 'react'

import { action, computed } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import FilterSourceTypeCategory from '~/client/src/shared/enums/FilterSourceTypeCategory'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import {
  FilterSourceType,
  FilterSourceTypeCategories,
} from '~/client/src/shared/stores/substates/ActivityFilterInfo'

import ActivityFiltersViewStore from '../ActivityFiltersView.store'

export interface IProps {
  filterName: string
  store: ActivityFiltersViewStore
}

@observer
export default class FilterSourceTypeSelector extends React.Component<IProps> {
  private get filter() {
    const { filterName, store } = this.props
    return store.getFilter(filterName)
  }

  @computed
  private get selectedSourceCategory() {
    const { sourceType } = this.filter
    return Object.keys(FilterSourceTypeCategories).find(categoryName => {
      return FilterSourceTypeCategories[categoryName].includes(sourceType)
    })
  }

  public render() {
    const { sourceType, isRemovable } = this.filter
    return (
      <div className="mt20">
        <div className="row">
          {Object.keys(FilterSourceTypeCategories).map(categoryName => {
            const isSelected = this.selectedSourceCategory === categoryName
            return (
              <div
                key={categoryName}
                onClick={this.changeSourceTypeCategory.bind(this, categoryName)}
                className={classList({
                  'bold large mt20 no-grow nowrap pa5 px30 text pointer': true,
                  'bb2-primary-blue primary-blue': isSelected,
                  light: !isSelected,
                  'inactive-element': !isRemovable && !isSelected,
                })}
              >
                {this.getSourceTitleTranslate(categoryName)}
              </div>
            )
          })}
        </div>

        <div className="col">
          {FilterSourceTypeCategories[this.selectedSourceCategory].map(
            sourceTypeItem => {
              const isSelected = sourceTypeItem === sourceType
              return (
                <div
                  key={sourceTypeItem}
                  onClick={this.changeSourceType.bind(this, sourceTypeItem)}
                  className={classList({
                    'primary-blue large mt10 no-grow nowrap text pointer row':
                      true,
                    'inactive-element': !isRemovable && !isSelected,
                  })}
                >
                  <Checkbox isChecked={isSelected} />
                  {this.getSourceTitleTranslate(sourceTypeItem)}
                </div>
              )
            },
          )}
        </div>
      </div>
    )
  }

  private getSourceTitleTranslate(sourceTitle: string) {
    switch (sourceTitle) {
      case FilterSourceTypeCategory.ScheduleData:
        return Localization.translator.scheduleData

      case FilterSourceTypeCategory.StruxHubData:
        return Localization.translator.struxHubData

      case FilterSourceType.ActivityCode:
        return Localization.translator.activityCodes

      case FilterSourceType.ProjectMember:
        return Localization.translator.projectMembers

      default:
        throw new Error(sourceTitle + ' is not added to translator')
    }
  }

  @action.bound
  private changeSourceTypeCategory(newSourceTypeCategory: string) {
    if (newSourceTypeCategory === this.selectedSourceCategory) {
      return
    }

    const newSourceType = FilterSourceTypeCategories[newSourceTypeCategory][0]
    const { filterName, store } = this.props
    store.changeFilterSource(filterName, newSourceType)
  }

  @action.bound
  private changeSourceType(newSourceType: string) {
    if (this.filter.sourceType === newSourceType) {
      return
    }

    const { filterName, store } = this.props
    store.changeFilterSource(filterName, newSourceType)
  }
}
