import * as React from 'react'

import {
  Icon,
  Popover,
  PopoverPosition,
  PopperModifiers,
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { AutoSizer, List } from 'react-virtualized'

import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import * as Icons from '~/client/src/shared/components/Icons'
import PermitFieldIcon from '~/client/src/shared/components/PermitFieldIcon/PermitFieldIcon'
import WorkflowStepNameLabel from '~/client/src/shared/components/WorkflowStepNameLabel'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import { INotificationOption } from '../WorkflowStepConfigurator.store'

// localization: translated

interface IProps {
  stepId: string
  stepNotificationFieldIds: string[]
  notificationOptions: INotificationOption[]
  isUpdating: boolean

  addToNotifications(stepId: string, fieldId: string): void
}

const popoverPopperModifiers: PopperModifiers = {
  preventOverflow: { enabled: false },
  hide: { enabled: false },
  arrow: { enabled: false },
  computeStyle: { gpuAcceleration: false },
}

const MODAL_HEIGHT_RATIO = 0.4
const ROW_HEIGHT = 40
const OVERSCAN_ROW_COUNT = 10

@observer
export default class WorkflowNotifiedUsersModal extends React.Component<IProps> {
  public render() {
    return (
      <Popover
        className="full-height"
        targetClassName="full-height full-width"
        popoverClassName="workflow-notified-users-modal brada8 beautiful-shadow overflow-hidden"
        canEscapeKeyClose={false}
        position={PopoverPosition.BOTTOM_LEFT}
        modifiers={popoverPopperModifiers}
        content={this.fieldsList}
      >
        <div className="row full-height">
          <span className="text large">{this.title}</span>
          <Icon icon={IconNames.SETTINGS} className="text grey-30" />
        </div>
      </Popover>
    )
  }

  private get fieldsList(): JSX.Element {
    const { isUpdating, notificationOptions } = this.props

    return (
      <div className="col no-outline-container">
        {isUpdating && (
          <div className="full-height full-width absolute modal-overlay" />
        )}
        <span className="text large line-extra-large pt20 px24">
          {Localization.translator.selectNotificationUsers}:
        </span>
        <div className="pt10 pb20 virtualized-list-smart-wrapper px24">
          {!notificationOptions.length ? (
            <div className="text center large bold py20">
              {Localization.translator.none}
            </div>
          ) : (
            <AutoSizer disableHeight={true}>
              {({ width }) => (
                <List
                  data={notificationOptions}
                  width={width}
                  height={Math.min(
                    window.innerHeight * MODAL_HEIGHT_RATIO,
                    ROW_HEIGHT * notificationOptions.length,
                  )}
                  rowHeight={ROW_HEIGHT}
                  rowCount={notificationOptions.length}
                  rowRenderer={this.renderOption}
                  scrollToAlignment="start"
                  overscanRowCount={OVERSCAN_ROW_COUNT}
                />
              )}
            </AutoSizer>
          )}
        </div>
      </div>
    )
  }

  private renderOption = ({ key, style, index }: any): JSX.Element => {
    const { notificationOptions, stepNotificationFieldIds } = this.props
    const option = notificationOptions[index]

    if (!option) return null

    const {
      stepName,
      stepPosition,
      field,
      fieldPosition,
      subFieldPosition,
      shouldIndent,
      isConditional,
      isDisabled,
    } = option

    return (
      <div
        key={key}
        style={style}
        className="row y-center bb-light-grey px24 text-ellipsis"
      >
        {stepName ? (
          <WorkflowStepNameLabel
            stepPosition={stepPosition}
            stepName={stepName}
          />
        ) : (
          <>
            <span
              className={classList({
                'text large ellipsis mr10 no-flex': true,
                ml10: shouldIndent,
              })}
            >
              {isConditional ? (
                <Icons.ConditionalLogic className="row text grey-30" />
              ) : (
                [stepPosition, fieldPosition, subFieldPosition]
                  .filter(p => p)
                  .join('.')
              )}
            </span>
            <PermitFieldIcon fieldName={field.type} />
            <span className="text large ellipsis mr5" title={field.caption}>
              {field.caption}
            </span>
            {!isDisabled && (
              <Checkbox
                isChecked={stepNotificationFieldIds.includes(field.id)}
                onClick={this.addToNotifications.bind(this, field.id)}
              />
            )}
          </>
        )}
      </div>
    )
  }

  private get title(): string {
    const { stepNotificationFieldIds } = this.props
    if (!stepNotificationFieldIds?.length) {
      return Localization.translator.none
    }
    return `${
      stepNotificationFieldIds.length
    } ${Localization.translator.selected.toLowerCase()}`
  }

  private addToNotifications(fieldId: string) {
    this.props.addToNotifications(this.props.stepId, fieldId)
  }
}
