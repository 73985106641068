import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized'

import UnsafeNotificationsListItem from '~/client/src/desktop/views/Notifications/components/NotificationsListItem'
import DaySeparator from '~/client/src/shared/components/DaySeparator'
import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import { withErrorBoundary } from '~/client/src/shared/components/ErrorBoundary'
import { NO_SCROLL } from '~/client/src/shared/components/ListWithFixedColumns/BaseListWithFixedColumns'
import { Loader } from '~/client/src/shared/components/Loader'
import INotificationListRow from '~/client/src/shared/interfaces/INotificationListRow'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import BaseNotification from '~/client/src/shared/models/Notification'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import NotificationsStore from '~/client/src/shared/stores/domain/Notifications.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import CommonStore from '~/client/src/shared/stores/ui/Common.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import {
  isActivityType,
  isPermitType,
} from '~/client/src/shared/types/NotificationTypes'
import { areArraysEqual } from '~/client/src/shared/utils/util'

import DesktopInitialState from '../../../../stores/DesktopInitialState'
import NotificationListStore from './NotificationList.store'

const NotificationsListItem = withErrorBoundary(UnsafeNotificationsListItem)

// translated

interface IProps {
  rows: INotificationListRow[]
  selectedNotificationsIds: string[]
  notificationsStore: NotificationsStore
  eventsStore?: EventsStore
  common?: CommonStore
  deliveryDetailsStore?: DeliveryDetailsStore
  deliveriesStore?: DeliveriesStore
  activitiesStore?: ActivitiesStore
  projectDateStore?: ProjectDateStore
  state?: DesktopInitialState
  sitePermitsStore?: SitePermitsStore
}

const ACTIVITY_TYPE_HEIGHT = 70
const BASE_TYPE_HEIGHT = 127
const DATE_TYPE_HEIGHT = 36
const LOCATION_TAG_HEIGHT = 24
const DAY_SEPARATOR_LEFT_OFFSET = '20%'

@inject(
  'eventsStore',
  'common',
  'deliveryDetailsStore',
  'deliveriesStore',
  'activitiesStore',
  'projectDateStore',
  'state',
  'sitePermitsStore',
)
@observer
export default class NotificationsList extends React.Component<IProps> {
  private readonly store: NotificationListStore = null

  @observable private scrollToIndex: number = NO_SCROLL
  private readonly clearPostEventCallback: () => void = null
  private listRef: any = null
  private readonly cellMeasurerCache: CellMeasurerCache = null

  public constructor(props: IProps) {
    super(props)

    this.store = new NotificationListStore(
      this.props.notificationsStore,
      this.props.common,
      this.props.eventsStore,
      this.props.state,
      this.props.activitiesStore,
    )

    this.cellMeasurerCache = new CellMeasurerCache({
      defaultHeight: BASE_TYPE_HEIGHT,
      fixedWidth: true,
    })

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.onNotificationsUpdated,
    )
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (
      !areArraysEqual(this.props.rows, prevProps.rows) ||
      !areArraysEqual(
        this.props.selectedNotificationsIds,
        prevProps.selectedNotificationsIds,
      )
    ) {
      this.recomputeGridSize()
    }
  }

  public componentWillUnmount(): void {
    this.clearPostEventCallback?.()
  }

  public render(): JSX.Element {
    const { loading } = this.props.state

    const isLoading =
      loading.get(e.MARK_NOTIFICATION_AS_READ) ||
      loading.get(e.MARK_NOTIFICATIONS_AS_ARCHIVED) ||
      loading.get(e.MARK_NOTIFICATIONS_AS_READ)

    return isLoading ? <Loader /> : this.renderList()
  }

  private renderList = (): JSX.Element => {
    const { rows } = this.props

    return rows.length ? (
      this.renderNotifications()
    ) : (
      <div className="full-height row x-center y-center text huge">
        <Icon icon="warning-sign" iconSize={20} className="warning-icon mr5" />
        {Localization.translator.noNotificationsPassedFilters}
      </div>
    )
  }

  private renderNotifications = (): JSX.Element => {
    const { rows } = this.props
    const { scrollToIndex } = this

    return (
      <div className="relative full-height">
        <AutoSizer>
          {({ width, height }) => (
            <List
              deferredMeasurementCache={this.cellMeasurerCache}
              data={rows}
              width={width}
              height={height}
              ref={this.setListRef}
              rowCount={rows.length}
              onScroll={this.onScroll}
              scrollToAlignment="start"
              rowHeight={this.getRowHeight}
              rowRenderer={this.rowRenderer}
              scrollToIndex={scrollToIndex}
            />
          )}
        </AutoSizer>
      </div>
    )
  }

  private getRowHeight = ({ index }): number => {
    const row = this.props.rows[index]

    if (!row.notification) {
      return DATE_TYPE_HEIGHT
    }

    const {
      notification: { type, entityId },
    } = row

    switch (true) {
      case isPermitType(type):
        const permit = this.props.sitePermitsStore.getFormById(entityId)

        const locationsCount = permit?.locationsWithEquipment?.length || 0
        return locationsCount > 1
          ? BASE_TYPE_HEIGHT + (locationsCount - 1) * LOCATION_TAG_HEIGHT
          : BASE_TYPE_HEIGHT

      case isActivityType(type):
        return ACTIVITY_TYPE_HEIGHT

      default:
        return BASE_TYPE_HEIGHT
    }
  }

  private rowRenderer = ({ index, key, style, parent }: any): JSX.Element => {
    const { rows } = this.props
    const { title, date, notification } = rows[index]
    let content: JSX.Element

    if (title) {
      content = this.renderTitleRow(title)
    } else if (date) {
      content = this.renderDateRow(date)
    } else {
      content = this.renderNotificationRow(notification)
    }

    return (
      <CellMeasurer
        cache={this.cellMeasurerCache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ registerChild }) => (
          <div style={style} ref={registerChild} className="col">
            {content}
          </div>
        )}
      </CellMeasurer>
    )
  }

  private renderTitleRow = (title: string): JSX.Element => {
    return (
      <span className="text huge bold notifications-group-label">{title}</span>
    )
  }

  private renderDateRow = (date: Date): JSX.Element => {
    const { isToday } = this.props.projectDateStore

    return (
      <DaySeparator
        date={date}
        isToday={isToday(date)}
        leftOffset={DAY_SEPARATOR_LEFT_OFFSET}
        bottomOffset="0"
        shouldHideBorder={true}
      />
    )
  }

  private renderNotificationRow = (
    notification: BaseNotification,
  ): JSX.Element => {
    const {
      toggleNotification,
      viewNotification,
      openContentObject,
      openActivity,
    } = this.store
    const { selectedNotificationsIds } = this.props

    const isSelected = selectedNotificationsIds.includes(notification.id)

    return (
      <NotificationsListItem
        notification={notification}
        isSelected={isSelected}
        toggleNotification={toggleNotification}
        viewNotification={viewNotification}
        openContentObject={openContentObject}
        openActivity={openActivity}
      />
    )
  }

  private onScroll = (): void => {
    Promise.resolve().then(() => (this.scrollToIndex = NO_SCROLL))
  }

  private onNotificationsUpdated = (eventContext: EventContext) => {
    const [eventType] = eventContext.event

    if (eventType === e.SITE_PERMITS_RECEIVED) {
      this.recomputeGridSize()
    }
  }

  private setListRef = (ref: any): void => (this.listRef = ref)

  private recomputeGridSize = (): void => {
    this.listRef?.recomputeGridSize()
    this.cellMeasurerCache?.clearAll()
  }
}
