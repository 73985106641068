import * as React from 'react'

import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import Dropzone from 'react-dropzone'
import * as Progress from 'react-progress'

import UploadButton from '~/client/src/desktop/components/UploadButton/UploadButton'
import * as Icons from '~/client/src/shared/components/Icons'
import FileType from '~/client/src/shared/enums/FileType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import InitialState from '~/client/src/shared/stores/InitialState'

import './DndArea.scss'

interface IProps {
  fileUploadHandler?: (fileData: any, file: File) => void
  title?: string | JSX.Element
  fileType: FileType
  accept?: string | string[]
  onDrop: (
    file: File | Buffer | Blob,
    onProgress?: (percent: number) => void,
  ) => void
  isHidden?: boolean
  isSmall?: boolean
  state?: InitialState
  shouldRenderIcon?: boolean
  className?: string
}

@inject('state')
@observer
export default class DndArea extends React.Component<IProps> {
  private readonly defaultAccept = '.xer,.xlsx,.xlsm'
  private uploadStates = {
    NotSelected: 0,
    Selected: 1,
    Uploaded: 2,
  }

  @observable private uploadState = this.uploadStates.NotSelected
  @observable private uploadPercent = 0
  private file: File

  @action.bound
  public onDrop(acceptedFiles: File[]) {
    if (!acceptedFiles || !acceptedFiles.length) {
      return
    }

    this.file = acceptedFiles[0]
    this.uploadState = this.uploadStates.Selected
    this.props.onDrop(this.file, percent => {
      this.uploadPercent = percent
      if (percent === 100) {
        this.uploadState = this.uploadStates.Uploaded
      }
    })
  }

  public render() {
    const {
      accept,
      isHidden,
      isSmall,
      shouldRenderIcon,
      className = 'upload-schedule-dnd-container col mb20 mt20 x-center y-center',
    } = this.props

    return (
      <div className={className} style={{ display: isHidden ? 'none' : '' }}>
        <Progress percent={this.uploadPercent} />
        <Dropzone
          className={classList({
            'upload-schedule-block upload-schedule-block-bordered col x-center y-center':
              true,
            small: isSmall,
          })}
          accept={accept || this.defaultAccept}
          onDrop={this.onDrop}
        >
          {shouldRenderIcon && <Icons.DropZone className="no-grow" />}
          {this.title}
        </Dropzone>
      </div>
    )
  }

  @computed
  private get title() {
    const { title } = this.props

    if (title) {
      if (typeof title === 'string') {
        return <span>{this.props.title}</span>
      }

      return title
    }

    switch (this.uploadState) {
      case this.uploadStates.NotSelected:
        return (
          <>
            <UploadButton
              text={
                Localization.translator
                  .uploadYourScheduleFileInP6XerOrExcelXlsxXlsmFormat
              }
            />
            <div className="row">
              <span className="text large">
                {Localization.translator.orDragYourFileHere}
              </span>
            </div>
          </>
        )
      case this.uploadStates.Selected:
        return <span>{Localization.translator.uploading}...</span>
      case this.uploadStates.Uploaded:
        return (
          <span>{Localization.translator.xIsUploaded(this.file.name)}</span>
        )
    }
  }
}
