import React from 'react'

import { observer } from 'mobx-react'

import FilterResetter from '~/client/src/desktop/components/FilterResetter/FilterResetter'
import SuperFilter from '~/client/src/desktop/components/SuperFilter/SuperFilter'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import SuperFilterHandleButton from '~/client/src/shared/components/Filters/SuperFilter/SuperFilterHandleButton'
import { IFilters } from '~/client/src/shared/stores/InitialState'
import { NOOP } from '~/client/src/shared/utils/noop'

import ReportsFilterStore from './ReportsFilter.store'

interface IProps {
  store: ReportsFilterStore
  forceCloseMap: { [filterType: string]: boolean }
  eventsStore: DesktopEventStore
  filters: IFilters
  activeReportViewId: string
}

@observer
export default class ReportsFilter extends React.Component<IProps> {
  private readonly clearPostEventCallback: () => void = NOOP

  public constructor(props: IProps) {
    super(props)

    props.store.resetAllFilters()
  }

  public componentDidUpdate() {
    const {
      forceCloseMap,
      filters: { fieldsMap },
    } = this.props

    if (forceCloseMap) {
      Object.keys(fieldsMap).forEach(filterType => {
        if (forceCloseMap[filterType]) {
          fieldsMap[filterType].isFilterShown = false
        }
      })
    }
  }

  public render() {
    return (
      <div className="row full-height location-filter x-start">
        {this.renderHandleButtons()}
        {this.renderReset()}
        {this.renderPopups()}
      </div>
    )
  }

  public componentWillUnmount() {
    this.clearPostEventCallback()
  }

  private renderPopups() {
    const { activeReportViewId } = this.props
    return Object.keys(this.storesMap).map(filterType => {
      const store = this.storesMap[filterType]
      return (
        <SuperFilter
          key={`${filterType}_${activeReportViewId}`}
          filterStore={store}
        />
      )
    })
  }

  private renderHandleButtons() {
    const { activeReportViewId } = this.props
    return Object.keys(this.storesMap).map(filterType => {
      const store = this.storesMap[filterType]
      return (
        <SuperFilterHandleButton
          className="no-grow"
          key={`${filterType}_${activeReportViewId}`}
          filterStore={store}
        />
      )
    })
  }

  private renderReset() {
    return <FilterResetter color="light" onReset={this.resetAllFilters} />
  }

  private get storesMap() {
    return this.props.store.filterStoresByTypeMap
  }

  private resetAllFilters = () => {
    this.props.store.resetAllFilters()
  }
}
