import * as React from 'react'

import { Classes, Dialog } from '@blueprintjs/core'
import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import { FilterType } from '~/client/graph'
import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import HierarchyConfigurationStore from '~/client/src/shared/stores/domain/HierarchyConfiguration.store'

import './AddBandDialog.scss'

export interface IProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (selectedBands: FilterType[]) => void
  hierarchyConfigurationStore?: HierarchyConfigurationStore
}

@inject('hierarchyConfigurationStore')
@observer
export default class AddBandDialog extends React.Component<IProps> {
  @observable private selectedBands: FilterType[] = []

  private get store(): HierarchyConfigurationStore {
    return this.props.hierarchyConfigurationStore
  }

  public render() {
    const { isOpen } = this.props

    return (
      <Dialog
        className="add-bands-dialog activity-filter-setup-dialog"
        isOpen={isOpen}
        title={Localization.translator.selectBands}
        isCloseButtonShown={true}
        canOutsideClickClose={false}
        onClose={this.onClose}
      >
        <div className={Classes.DIALOG_BODY}>
          <div className="text large mb20">
            {Localization.translator.multipleSelectionsArePermitted}
          </div>
          <div>{this.list}</div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <div
              className="pointer text extra-large primary-blue"
              onClick={this.onClose}
            >
              {Localization.translator.cancel}
            </div>
            <div
              className="pointer text extra-large primary-blue bold"
              onClick={this.onSubmit}
            >
              {Localization.translator.add}
            </div>
          </div>
        </div>
      </Dialog>
    )
  }

  @computed
  private get list(): JSX.Element[] {
    const { allBands, orderedAppliedBands, bandTypesToCaptions } = this.store

    return allBands.map((band, index) => {
      const isApplied = orderedAppliedBands.includes(band)
      const isChecked = this.selectedBands.includes(band) || isApplied
      return (
        <div className="row" key={index}>
          <Checkbox
            isChecked={isChecked}
            isDisabled={isApplied}
            onClick={this.onCheckboxClick.bind(this, band)}
          />
          <div className="text large capitalize">
            {bandTypesToCaptions[band]}
          </div>
        </div>
      )
    })
  }

  @action.bound
  private onCheckboxClick(band: FilterType) {
    const index = this.selectedBands.indexOf(band)

    if (index !== -1) {
      this.selectedBands.splice(index, 1)
    } else {
      this.selectedBands.push(band)
    }
  }

  @action.bound
  private onClose() {
    this.reset()
    this.props.onClose()
  }

  @action.bound
  private onSubmit() {
    this.props.onSubmit(this.selectedBands)
    this.onClose()
  }

  private reset() {
    this.selectedBands = []
  }
}
