import { IconNames } from '@blueprintjs/icons'
import { action, computed, observable } from 'mobx'

import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import ActivityDetailsStore from '~/client/src/shared/components/ActivityDetails/ActivityDetails.store'
import { IActivityField } from '~/client/src/shared/components/ActivityDetails/ActivityDetailsInterfaces'
import {
  FIELDS_AND_NOTE_SEPARATOR,
  FIELD_LINES_SEPARATOR,
} from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import activityFieldIdToLinkingSettingNameMap from '~/client/src/shared/constants/activityFieldIdToLinkingSettingNameMap'
import ActivityDataFieldId from '~/client/src/shared/enums/ActivityDataFieldId'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Activity from '~/client/src/shared/models/Activity'
import { EDIT_BULK_ACTIVITIES } from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import {
  ToastTheme,
  showErrorToast,
  showToast,
} from '~/client/src/shared/utils/toaster'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

const FIELDS_AVAILABLE_FOR_BULK_EDIT: string[] = [
  ActivityDataFieldId.COMPANY_ID,
  ActivityDataFieldId.REQUESTER_ID,
  ActivityDataFieldId.EQUIPMENT_ID,
  ActivityDataFieldId.VERTICAL_OBJECT_ID,
  ActivityDataFieldId.BUILDING_ID,
  ActivityDataFieldId.ZONE_ID,
  ActivityDataFieldId.LEVEL_ID,
  ActivityDataFieldId.AREA_ID,
  ActivityDataFieldId.GATE_ID,
  ActivityDataFieldId.ROUTE_ID,
]

export default class ActivityBulkEditorStore {
  @observable public shouldShowConfirmModal: boolean = false
  @observable public shouldShowAlertModal: boolean = false
  @observable public editingFieldIds: string[] = [EMPTY_STRING]
  @observable private selectedActivities: Activity[] = []

  private editingProps = {
    activities: [],
    projectId: '',
  }

  public constructor(
    private readonly onClose: () => void,
    private readonly eventsStore: DesktopEventStore,
    private readonly activityDetailsStore: ActivityDetailsStore,
    private readonly activitiesStore?: ActivitiesStore,
    selectedActivities: Activity[] = [],
  ) {
    this.selectedActivities = selectedActivities
  }

  @computed
  public get fieldOptions(): IActivityField[] {
    return this.bulkEditFields.filter(
      f => !this.editingFieldIds.includes(f.fieldId),
    )
  }

  @computed
  public get canEdit(): boolean {
    return this.isSomeFieldSelected
  }

  public get isSomeFieldSelected(): boolean {
    return this.editingFieldIds[0] !== EMPTY_STRING
  }

  public get bulkEditFields(): IActivityField[] {
    const { fields } = this.activityDetailsStore.activityFormStore
    return fields.filter(field =>
      FIELDS_AVAILABLE_FOR_BULK_EDIT.includes(field.fieldId),
    )
  }

  public get shouldShowNewHandleButton(): boolean {
    return (
      this.editingFieldIds.length < this.bulkEditFields.length &&
      this.editingFieldIds[this.editingFieldIds.length - 1] !== EMPTY_STRING
    )
  }

  public get shouldShowDataLinkingWarning(): boolean {
    const linkableFieldIds = this.editingFieldIds.filter(fieldId => {
      return this.activitiesStore.checkIsFieldLinkable(fieldId)
    })

    return this.selectedActivities.some(activity => {
      const fieldIdsThatCanBeLinked = Object.keys(activity.externalData).filter(
        id => linkableFieldIds.includes(id),
      )
      return fieldIdsThatCanBeLinked.some(
        fieldId =>
          activity.activityLinkingSettings[
            activityFieldIdToLinkingSettingNameMap[fieldId]
          ],
      )
    })
  }

  public get actionHandleButtonTitle(): string {
    return Localization.translator.editXFieldsOnYActivities(
      this.editingFieldIds.length,
      this.selectedActivities.length,
    )
  }

  public get confirmMessage(): string {
    const selectedCount = this.selectedActivities.length
    const editingCount = this.editingProps.activities.length

    return selectedCount === editingCount
      ? Localization.translator.editXActivities_question(editingCount)
      : Localization.translator.onlyXOfYSelectedActivitiesCanBeChanged(
          editingCount,
          selectedCount,
        )
  }

  public reinit = (selectedActivities: Activity[]) => {
    this.selectedActivities = selectedActivities.slice()
  }

  public isEditingFieldSelectedByIndex = (index: number) => {
    return this.editingFieldIds[index] !== EMPTY_STRING
  }

  @action.bound
  public getBulkEditField(fieldId: string) {
    return this.bulkEditFields.find(f => f.fieldId === fieldId)
  }

  @action.bound
  public handleAddNewEditingField() {
    this.editingFieldIds.push(EMPTY_STRING)
  }

  @action.bound
  public handleSelectEditedField(index: number, fieldId: string) {
    this.editingFieldIds[index] = fieldId
  }

  @action.bound
  public resetEditedField(index: number) {
    const removedElements = this.editingFieldIds.splice(index, 1)
    this.activityDetailsStore.activityFormStore.resetFieldValue(
      removedElements[0],
    )
  }

  @action.bound
  public resetAllFields() {
    this.activityDetailsStore.activityFormStore.initDraftData()
  }

  @action.bound
  public async prepareEditingData() {
    if (!this.canEdit) {
      return
    }

    const {
      newActivityData,
      getChangedFieldMessage,
      getChangedLinkingSettingsMessages,
    } = this.activityDetailsStore.activityFormStore

    this.editingProps = {
      activities: [],
      projectId: this.eventsStore.appState.activeProject.id,
    }

    this.selectedActivities.forEach(activity => {
      const activityFields = activity.toInput(true)

      let companyId = activityFields.companyId
      let requesterId = activityFields.requesterId
      const newLocations = []
      const changedFieldLines = []
      const newActivityLinkingSettings = JSON.parse(
        JSON.stringify(activityFields.activityLinkingSettings),
      )

      this.bulkEditFields.forEach(field => {
        const oldValue = activityFields[field.fieldId]
        const newValue = newActivityData[field.fieldId]
        const value = newValue || oldValue

        const isValueSelectorAdded = this.editingFieldIds.includes(
          field.fieldId,
        )
        const isValueCleared = oldValue && isValueSelectorAdded && !newValue

        if (!value) {
          return
        }

        if (isValueSelectorAdded) {
          const isFieldLinkable = this.activitiesStore.checkIsFieldLinkable(
            field.fieldId,
          )

          const isFieldLinkingEnabled =
            activityFields.activityLinkingSettings[
              activityFieldIdToLinkingSettingNameMap[field.fieldId]
            ]

          if (oldValue !== newValue) {
            changedFieldLines.push(
              getChangedFieldMessage(oldValue, newValue, field),
            )
          }

          if (isFieldLinkable && isFieldLinkingEnabled) {
            newActivityLinkingSettings[
              activityFieldIdToLinkingSettingNameMap[field.fieldId]
            ] = false
            changedFieldLines.push(
              ...getChangedLinkingSettingsMessages(
                activity.activityLinkingSettings,
                newActivityLinkingSettings,
              ),
            )
          }

          if (field.fieldId === ActivityDataFieldId.COMPANY_ID) {
            companyId = isValueCleared ? null : value
          }

          if (field.fieldId === ActivityDataFieldId.REQUESTER_ID) {
            requesterId = isValueCleared ? null : value
          }
        }

        // the location array is overwritten with each bulk operation.
        // to keep non-updated locations, they should be added to the array as well.
        // to remove a location from the activity, simply do not add the cleared value to the new locations array.
        if (field.locationType && !isValueCleared) {
          newLocations.push({
            id: value,
            type: field.locationType,
          })
        }
      })

      const changedFieldsMessage =
        changedFieldLines.join(FIELD_LINES_SEPARATOR) +
        FIELDS_AND_NOTE_SEPARATOR

      this.editingProps.activities.push({
        id: activity.databaseId,
        companyId: companyId,
        requesterId: requesterId,
        locations: newLocations,
        activityLinkingSettings: newActivityLinkingSettings,
        message: changedFieldLines.length
          ? { text: changedFieldsMessage }
          : null,
      })
    })

    if (this.editingProps.activities.length) {
      return this.openConfirm()
    }
  }

  @action.bound
  public handleEdit() {
    if (!this.canEdit) {
      return
    }

    this.eventsStore.dispatch(
      EDIT_BULK_ACTIVITIES,
      this.editingProps,
      this.handleSuccessEditing,
      this.handleErrorSubscription,
    )
    this.onClose()
  }

  @action.bound
  public openConfirm() {
    this.shouldShowConfirmModal = true
  }

  @action.bound
  public closeConfirm() {
    this.shouldShowConfirmModal = false
  }

  private handleSuccessEditing = () => {
    showToast(
      Localization.translator.editedXFieldsOnYActivities(
        this.editingFieldIds.length,
        this.selectedActivities.length,
      ),
      ToastTheme.SUCCESS,
      IconNames.EDIT,
    )
  }

  private handleErrorSubscription = () => {
    showErrorToast(Localization.translator.failedToEdit)
  }
}
