import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'

import EntityNameFilter from '~/client/src/desktop/components/Filters/EntityNameFilter/EntityNameFilter'
import BaseHeaderBar from '~/client/src/desktop/components/HeaderBar/HeaderBar'
import TooltipWrapper from '~/client/src/desktop/components/TooltipWrapper/TooltipWrapper'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import * as Icons from '~/client/src/shared/components/Icons'
import {
  FormTypeGroupingOption,
  formTypeGroupingOptionList,
  getFormTypeGroupingOptionTranslatorKey,
} from '~/client/src/shared/enums/FormTypeGroupingOption'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'

import FormTypesListStore from '../../FormTypesList.store'
import FormTypesHeaderBarStore from './FormTypesHeaderBar.store'
import FormTypesFilter from './components/FormTypesFilter/FormTypesFilter'
import FormTypesFilterStore from './components/FormTypesFilter/FormTypesFilter.store'
import FormTypesGroupingOptions from './components/FormTypesGroupingOptions/FormTypesGroupingOptions'
import FormTypesGroupingOptionsStore from './components/FormTypesGroupingOptions/FormTypesGroupingOptions.store'

interface IProps {
  formTypesListStore: FormTypesListStore

  eventsStore?: DesktopEventStore
  permitTypesStore?: PermitTypesStore
}

@inject('eventsStore', 'permitTypesStore')
@observer
export default class FormTypesHeaderBar extends BaseHeaderBar<IProps> {
  protected store: FormTypesHeaderBarStore
  private readonly groupingOptionsStore: FormTypesGroupingOptionsStore
  private readonly filterStore: FormTypesFilterStore

  public constructor(props: IProps) {
    super(props)

    this.store = new FormTypesHeaderBarStore(props.eventsStore)

    this.filterStore = new FormTypesFilterStore(
      props.eventsStore,
      props.permitTypesStore,
      props.formTypesListStore,
      this.store.onShowChanged,
    )

    this.groupingOptionsStore = new FormTypesGroupingOptionsStore(
      props.eventsStore,
      this.store.onShowGroupByChanged,
      props.formTypesListStore.resetCollapsing,
      props.eventsStore.appState.formTypesFilters,
      formTypeGroupingOptionList,
      FormTypeGroupingOption.CATEGORY,
      props.eventsStore.appState.formTypesFilterSettings.desktopFilterMap,
      getFormTypeGroupingOptionTranslatorKey,
    )
  }

  protected renderLeftSection(): JSX.Element {
    const {
      eventsStore: { appState },
      formTypesListStore,
    } = this.props
    const {
      forceSearchClose,
      forceGroupByClose,
      forceCloseMap,
      onShowSearchChanged,
    } = this.store
    const { collapseAllCategories, resetCollapsing } = formTypesListStore

    return (
      <div className="row x-start full-height left-bar no-grow no-select">
        <EntityNameFilter
          filters={appState.formTypesFilters}
          forceClose={forceSearchClose}
          onShowChanged={onShowSearchChanged}
        />
        <div className="row ml12 pl12 bl-light-cool-grey">
          <FormTypesGroupingOptions
            store={this.groupingOptionsStore}
            forceClose={forceGroupByClose}
          />
          <div className="row br-light-cool-grey px12">
            <div
              className="pointer br-rounded highlighted-hover highlighted-active pa8 mr4"
              onClick={collapseAllCategories}
            >
              <Icons.CollapseAll className="row text grey-30" />
            </div>
            <div
              className="pointer br-rounded highlighted-hover highlighted-active pa8"
              onClick={resetCollapsing}
            >
              <Icons.ExpandAll className="row text grey-30" />
            </div>
          </div>
        </div>
        <FormTypesFilter
          store={this.filterStore}
          forceCloseMap={forceCloseMap}
          filters={appState.formTypesFilters}
        />
      </div>
    )
  }

  protected renderActionBar(): JSX.Element {
    const {
      displayedCount,
      isSomeRowSelected,
      selectedInstancesCount,
      isTransferOrDefaultSelected,
      showDeleteFormsDialog,
    } = this.props.formTypesListStore

    return (
      <div className="row py15 text large light no-grow nowrap">
        <span className="row no-grow pr12 h32">
          {isSomeRowSelected
            ? Localization.translator.xOfYSelected(
                selectedInstancesCount,
                displayedCount,
              )
            : `${displayedCount} ${Localization.translator.workflows}`}
        </span>
        {isSomeRowSelected && (
          <div className="row bl-light-cool-grey pl12">
            <TooltipWrapper
              className="no-grow"
              content={Localization.translator.deletionOfDefaultWorkflows}
              disabled={!isTransferOrDefaultSelected}
            >
              <BaseActionButton
                icon={<Icon icon={IconNames.TRASH} />}
                className="mx4 gray-theme"
                shouldShowBorder={false}
                isEnabled={!isTransferOrDefaultSelected}
                title={Localization.translator.delete}
                onClick={showDeleteFormsDialog}
              />
            </TooltipWrapper>
          </div>
        )}
      </div>
    )
  }

  protected renderRightSection(): JSX.Element {
    return null
  }

  protected renderCenterSection(): JSX.Element {
    return null
  }

  protected renderViewToggle(): JSX.Element {
    return null
  }
}
