import * as React from 'react'

import { MenuItem } from '@blueprintjs/core'
import { Suggest } from '@blueprintjs/select'
import { observer } from 'mobx-react'

import { mapboxFeatureToAddress } from '~/client/src/shared/components/MapBoxEditor/MapBoxViewer.store'
import BaseStruxhubInput, {
  ISharedProps,
} from '~/client/src/shared/components/StruxhubInputs/BaseStruxhubInput'
import { IMapBoxFeature } from '~/client/src/shared/interfaces/IMapBoxFeature'

import { highlightText } from '../../../utils/highlightText'

import './StruxhubMapboxAddressInput.scss'

// localization: no text to translate

// this is a component are we use that casing
const MapBoxSuggest = Suggest.ofType<IMapBoxFeature>()
const MAPBOX_LIST_CLASS_NAME = 'map-box-suggest-list-items'

interface IProps extends ISharedProps {
  fullAddressLabel: string
  items: IMapBoxFeature[]
  onItemSelect: (
    item: IMapBoxFeature,
    event?: React.SyntheticEvent<HTMLElement, Event>,
  ) => void
  onQueryChange: (
    query: string,
    event?: React.ChangeEvent<HTMLInputElement>,
  ) => void
}

@observer
export default class StruxhubMapboxAddressInput extends React.Component<IProps> {
  public render() {
    const { onQueryChange, value, fullAddressLabel, items } = this.props

    return (
      <BaseStruxhubInput {...this.props}>
        {(_, isInFocus, onFocus, onBlur) => {
          return isInFocus ? (
            <MapBoxSuggest
              inputProps={{
                className: 'map-box-suggest-input',
                autoFocus: true,
                placeholder: '',
                onBlur: this.handleInputBlur.bind(this, onBlur),
              }}
              popoverProps={{ popoverClassName: MAPBOX_LIST_CLASS_NAME }}
              fill={true}
              inputValueRenderer={mapBoxOnRenderInputValue}
              items={items}
              itemRenderer={mapBoxItemRenderer}
              onItemSelect={this.onItemSelect.bind(this, onBlur)}
              onQueryChange={onQueryChange}
              query={value}
            />
          ) : (
            <div
              className="row nowrap y-center full-width bg-palette-brand-lightest no-outline brada4 pa8 h40 ba-palette-brand-lighter overflow-hidden"
              onClick={onFocus}
            >
              <span
                className="text extra-large line-24 text-ellipsis"
                title={fullAddressLabel}
              >
                {fullAddressLabel}
              </span>
            </div>
          )
        }}
      </BaseStruxhubInput>
    )
  }

  private onItemSelect(
    onBlurFn: () => void,
    item: IMapBoxFeature,
    event?: React.SyntheticEvent<HTMLElement, Event>,
  ) {
    this.props.onItemSelect?.(item, event)
    onBlurFn()
  }

  private handleInputBlur(
    onBlurFn: () => void,
    event: React.FocusEvent<HTMLInputElement>,
  ) {
    const parent = (event?.relatedTarget as HTMLElement)?.offsetParent
      ?.parentElement

    if (!parent?.classList?.contains(MAPBOX_LIST_CLASS_NAME)) {
      onBlurFn()
    }
  }
}

function mapBoxItemRenderer(
  item: IMapBoxFeature,
  { handleClick, modifiers, query },
) {
  if (!modifiers.matchesPredicate) {
    return null
  }

  const addr = mapboxFeatureToAddress(item)
  let label = ''

  label += addr.city
  label += (label ? ' ' : '') + addr.state
  label += (label ? ' ' : '') + addr.country

  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      label={label}
      key={item.id}
      onClick={handleClick}
      text={highlightText(addr.address, query)}
    />
  )
}

function mapBoxOnRenderInputValue(item: IMapBoxFeature): string {
  return item.place_name
}
