import { action, computed } from 'mobx'

import ObjectFilterType, {
  getObjectFilterTypeDisplayName,
} from '~/client/src/desktop/enums/ObjectsFilterType'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import ObjectPropertiesSetupStore from '~/client/src/desktop/views/ProjectSetUp/components/ObjectPropertiesSetup/ObjectPropertiesSetup.store'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import { RESET_ALL_FILTERS } from '~/client/src/shared/stores/EventStore/eventConstants'
import SuperFilterStore, {
  IRootOption,
} from '~/client/src/shared/stores/ui/SuperFilter.store'
import Guard from '~/client/src/shared/utils/Guard'

export type ISourceMap = { [optionId: string]: string[] }

export default class BaseObjectPropertiesFilterStore extends SuperFilterStore {
  public get totalHint(): string {
    return Localization.translator.totalObjects
  }

  public constructor(
    public readonly type: ObjectFilterType,
    protected readonly state: DesktopInitialState,
    protected readonly sourceMap: ISourceMap,
    protected readonly objectPropertiesSetupStore: ObjectPropertiesSetupStore,
    protected readonly onShowChanged: (
      isShown: boolean,
      filterType: string,
    ) => void,
    public readonly shouldHideCount?: boolean,
    private readonly getOptionName?: (
      optionId: string,
      filterType: string,
    ) => string,
  ) {
    super(
      state.objectFilters.fieldsMap[type],
      type,
      state,
      KnownTranslatorKeys.seeXObjects,
      getObjectFilterTypeDisplayName,
      onShowChanged,
      shouldHideCount,
    )

    Guard.requireAll({ type, state, sourceMap, objectPropertiesSetupStore })
  }

  @action.bound
  public onFilterActionRequest(eventContext: EventContext) {
    const [eventType] = eventContext.event

    switch (eventType) {
      case RESET_ALL_FILTERS:
        return this.setInitialFilterValues()
    }
  }

  @computed
  public get optionsTree(): IRootOption[] {
    const options = Object.keys(this.sourceMap)

    return [
      {
        id: this.type,
        name: this.typeCaption,
        options: options.map(option => ({
          id: option,
          name: this.getOptionName?.(option, this.type) || option,
          instancesIds: this.sourceMap[option],
        })),
      },
    ]
  }

  @action.bound
  public clickOnApply() {
    this.handleApply()
  }

  public filterOptionInstances = (objectIds: string[]) => {
    return this.allSitemapAttributes.filter(o => objectIds.includes(o.id))
  }

  @computed
  private get allSitemapAttributes(): LocationBase[] {
    return this.objectPropertiesSetupStore.sitemapAttributes
  }
}
