import * as React from 'react'

import { Icon, Radio, Switch } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from 'react-sortable-hoc'

import { LocationType } from '~/client/graph'
import {
  SaveBuildingsDocument,
  SaveLevelsDocument,
} from '~/client/graph/operations/generated/LocationType.generated'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import * as Icons from '~/client/src/shared/components/Icons'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import BuildingDto from '~/client/src/shared/models/LocationObjects/Building'
import LevelDto from '~/client/src/shared/models/LocationObjects/Level'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import GraphExecutorStore from '~/client/src/shared/stores/domain/GraphExecutor.store'
import LevelsStore from '~/client/src/shared/stores/domain/Levels.store'
import { undergroundLevelBaseName } from '~/client/src/shared/utils/usefulStrings'

import MapViewItemsSetupStore from '../../stores/MapViewItemsSetup.store'
import LevelCombobox from './LevelCombobox'

const buildingProperties = {
  buildingProperties: 'Building Properties',
  numbersOfLevels: 'Number of levels',
  selectGround: 'Select ground',
  index: 'Index',
  levelName: 'Level name',
  code: 'Code',
  levelNamingOptions: 'Level naming options',
  setGroundIndexToZero: 'Set ground floor index to 0',
  defaultIndexIsOne: 'Default index = 1',
  skipIndexNumber: 'Skip index number',
  floorNamePrefix: 'Floor name prefix',
}

const COMA = ','
const DASH = '-'
const NUMBER_REGEX = /\d+/g

export interface ILevelDisplayInfo {
  level: LevelDto
  isGround: boolean
  index: number
  name: string
  code: string
}

const DragHandle = SortableHandle(() => (
  <Icons.DoubleVerticalDots className="vertical-dots pointer" />
))

const SortableItem = SortableElement(({ item }) => item)

const SortableItemsContainer = SortableContainer(({ items }) => {
  return (
    <div>
      {items.map((item, index) => (
        <SortableItem item={item} index={index} key={index} />
      ))}
    </div>
  )
})

interface IProps {
  store: MapViewItemsSetupStore
  building: BuildingDto
  levelsStore?: LevelsStore
  state?: DesktopInitialState
  graphExecutorStore?: GraphExecutorStore
  setLoading(isLoading: boolean): void
}

@inject('levelsStore', 'state', 'graphExecutorStore')
@observer
export default class BuildingProperties extends React.Component<IProps> {
  @observable private isLevelNamingOptionsShown: boolean = false

  public render() {
    const { isGroundIndexZero, skipLevelIndexTemplate, levelBaseName } =
      this.props.building

    return (
      <div className="building-properties properties-section pa12">
        <div className="text title uppercase">
          {buildingProperties.buildingProperties}
        </div>
        <div className="inline-block vertical-align-top">
          <LevelCombobox
            label={buildingProperties.numbersOfLevels}
            value={this.levels.length}
            onChange={this.onLevelsCountChange}
          />
        </div>
        <div className="pt12 row relative">
          <div className="bb-brand-dark pb5">
            <div className="inline-block vertical-align-bottom select-ground-width">
              <div className="text brand-dark center">
                {buildingProperties.selectGround}
              </div>
            </div>
            <div className="inline-block vertical-align-bottom index-width">
              <div className="text brand-dark center">
                {buildingProperties.index}
              </div>
            </div>
            <div className="inline-block vertical-align-bottom level-name-width">
              <div className="text brand-dark center">
                {buildingProperties.levelName}
              </div>
            </div>

            <div
              className={classList({
                'config-icon': true,
                active: this.isLevelNamingOptionsShown,
              })}
              onClick={this.toggleLevelNamingOption}
            >
              <Icon icon={IconNames.PROPERTIES} />
            </div>
          </div>
          {this.isLevelNamingOptionsShown && (
            <MenuCloser closeMenu={this.toggleLevelNamingOption}>
              <div className="level-naming-options-dialog pa12">
                <div className="text title uppercase pb12">
                  {buildingProperties.levelNamingOptions}
                </div>
                <div className="row py12">
                  <div className="col y-end">
                    <div className="text large brand-dark">
                      {buildingProperties.setGroundIndexToZero}
                    </div>
                    <div className="text grey-lighter">
                      {buildingProperties.defaultIndexIsOne}
                    </div>
                  </div>
                  <div className="no-grow">
                    <Switch
                      className="level-naming-options-switch"
                      checked={isGroundIndexZero}
                      onChange={this.toggleIsGroundLevelZero}
                    />
                  </div>
                </div>
                <StruxhubInput
                  label={buildingProperties.skipIndexNumber}
                  isRequiredTextHidden={true}
                  value={skipLevelIndexTemplate}
                  onBlur={this.saveImplicitLevelNamesIfChanged}
                  onChange={this.onSkipIndexTemplateChange}
                  placeholder="e.g. 13, 40-49"
                />
                <StruxhubInput
                  label={buildingProperties.floorNamePrefix}
                  isRequiredTextHidden={true}
                  value={levelBaseName}
                  onBlur={this.saveImplicitLevelNamesIfChanged}
                  onChange={this.onLevelBaseNameChange}
                />
              </div>
            </MenuCloser>
          )}
        </div>
        <SortableItemsContainer
          useDragHandle={true}
          lockAxis="y"
          onSortEnd={this.onLevelsOrderChange}
          items={this.levelsComponents}
        />
      </div>
    )
  }

  private onLevelsCountChange = (count: number) => {
    const currentLength = this.levels.length
    const { setLoading } = this.props

    if (count > currentLength) {
      const levelsToAdd = count - currentLength

      setLoading(true)
      this.addLevels(levelsToAdd)
      return
    }
    if (count < currentLength) {
      const levelsToRemove = currentLength - count

      setLoading(true)
      this.removeLevels(levelsToRemove)
      return
    }
  }

  private async addLevels(count: number) {
    if (count <= 0) {
      return
    }

    let shouldSetZeroLevelAsGround: boolean = false
    const {
      building,
      state: { activeProject },
      store,
      setLoading,
      graphExecutorStore,
    } = this.props
    const { levelBaseName, color } = building
    const deniedIndexMap = this.getDeniedIndexMap()

    if (!building.id) {
      await store.saveDataObject(building, true)
    }

    shouldSetZeroLevelAsGround = !this.levels.length
    let prevIndex = this.groundIndex - 1
    if (this.levelsDisplayInfo.length) {
      prevIndex =
        this.levelsDisplayInfo[this.levelsDisplayInfo.length - 1].index
    }
    const indexes = []

    for (let i = 0; i < count; i++) {
      do {
        prevIndex++
      } while (deniedIndexMap[prevIndex])

      indexes.push(prevIndex)
    }

    const levels = indexes.map(index => {
      const name = `${levelBaseName} ${index}`
      const level = new LevelDto(
        null,
        name,
        color,
        MapViewLocationIcon.Level,
        activeProject.id,
        LocationBase.generateCodeFromName(name),
        [],
        {
          parentType: LocationType.Building,
          parentId: building.id,
        },
      )

      level.isNameImplicit = true
      return level.getDto()
    })

    const {
      data: { saveManyLevels: savedLevels },
    } = await graphExecutorStore.mutate(SaveLevelsDocument, { levels })

    const savedLevelsIds = savedLevels.map(({ id }) => id)
    const levelsOrder: string[] = savedLevelsIds.concat(building.levelsOrder)

    await this.saveLevelsOrder(levelsOrder)

    await store.addLevelsToAccessibles(building, savedLevelsIds)
    if (shouldSetZeroLevelAsGround) {
      this.setGroundLevel(savedLevels[savedLevels.length - 1].id)
    }
    setLoading(false)
  }

  private async removeLevels(count: number) {
    if (count <= 0) {
      return
    }

    const lastLevelIndex = this.levelsDisplayInfo.length - 1
    const levelIds = this.levelsDisplayInfo
      .sort((a, b) => {
        return Number(b.level.isNameImplicit) - Number(a.level.isNameImplicit)
      })
      .map(l => l.level.id)
      .slice(lastLevelIndex - count, lastLevelIndex)

    const { store, building, setLoading } = this.props
    await store.deleteSitemapItemsByIds(levelIds)

    if (
      !!building.groundLevel &&
      !this.orderedLevels?.find(lvl => building.groundLevel === lvl.id)
    ) {
      building.groundLevel = null
    }

    await this.saveLevelsOrder()

    setLoading(false)
  }

  @action.bound
  private async onLevelsOrderChange({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number
    newIndex: number
  }) {
    const { setLoading } = this.props
    setLoading(true)

    const levelIds = this.levelsDisplayInfo.map(({ level }) => level.id)
    const orderedLevelIds = arrayMove(levelIds, oldIndex, newIndex).reverse()

    await this.saveLevelsOrder(orderedLevelIds)

    setLoading(false)
  }

  @action.bound
  private async saveLevelsOrder(levelsOrder?: string[]) {
    const { building, store } = this.props

    building.levelsOrder =
      levelsOrder || this.orderedLevels.map(({ id }) => id).reverse()

    if (!building.groundLevel) {
      building.groundLevel = this.groundLevelId
    }

    await this.saveImplicitLevelNamesIfChanged()
    await store.saveDataObject(building, true)
  }

  @computed
  private get levels(): LevelDto[] {
    const { building, levelsStore: levelsStore } = this.props
    return levelsStore.list.filter(l => l.isParent(building)).map(l => l.copy())
  }

  private get orderedLevels(): LevelDto[] {
    return this.props.building.sortLevelsByCustomOrder(this.levels)
  }

  private getDeniedIndexMap() {
    const { skipLevelIndexTemplate } = this.props.building

    const deniedIndexMap = {}
    skipLevelIndexTemplate.split(COMA).forEach(exp => {
      if (!exp.includes(DASH)) {
        const expMatch = exp.match(NUMBER_REGEX)
        if (!expMatch) {
          return
        }
        const index = Number(expMatch.join(''))
        deniedIndexMap[index] = true
        return
      }

      const [fromExp, toExp] = exp.split(DASH)
      const fromMatch = fromExp.match(NUMBER_REGEX)
      const toMatch = toExp.match(NUMBER_REGEX)
      if (!fromMatch || !toMatch) {
        return
      }
      const from = Number(fromMatch.join(''))
      const to = Number(toMatch.join(''))
      for (let i = from; i <= to; i++) {
        deniedIndexMap[i] = true
      }
    })

    return deniedIndexMap
  }

  private get groundIndex() {
    const { isGroundIndexZero } = this.props.building
    return isGroundIndexZero ? 0 : 1
  }

  private get levelsDisplayInfo(): ILevelDisplayInfo[] {
    const { levelBaseName, isGroundIndexZero } = this.props.building

    const deniedIndexMap = this.getDeniedIndexMap()

    const groundLevelPosition = this.orderedLevels.findIndex(
      l => l.id === this.groundLevelId,
    )

    const indexes = []
    const firstLevelIndex = 0
    const lastLevelsIndex = this.levels.length - 1

    // set top indexes [above ground level in the table]
    let topIndex = -groundLevelPosition - 1
    for (let i = firstLevelIndex; i < groundLevelPosition; i++) {
      do {
        topIndex++
      } while (deniedIndexMap[i])

      indexes.unshift(topIndex)
    }

    // set bottom indexes [below ground level in the table]
    let prevIndex = isGroundIndexZero ? -1 : 0
    for (let i = groundLevelPosition; i <= lastLevelsIndex; i++) {
      do {
        prevIndex++
      } while (deniedIndexMap[prevIndex])

      indexes.unshift(prevIndex)
    }

    return this.orderedLevels.map((level, idx) => {
      const index = indexes[this.orderedLevels.length - idx - 1]
      const { isNameImplicit } = level
      const name = this.createLevelName(index, level, levelBaseName)
      const code = isNameImplicit
        ? LocationBase.generateCodeFromName(name)
        : level.code

      return {
        level,
        index,
        isGround: level.id === this.groundLevelId,
        name,
        code,
      }
    })
  }

  private createLevelName(
    index: number,
    { isNameImplicit, name }: LevelDto,
    levelBaseName: string,
  ) {
    if (!isNameImplicit) {
      return name
    }
    return index < 0
      ? `${undergroundLevelBaseName} ${-index}`
      : `${levelBaseName} ${index}`
  }

  private get levelsComponents() {
    return this.levelsDisplayInfo.map(item => (
      <div className="level-row row y-center py6">
        <div className="no-grow select-ground-width row y-center">
          <DragHandle />
          <Radio
            onChange={this.setGroundLevel.bind(this, item.level.id)}
            checked={item.isGround}
          />
        </div>
        <div className="no-grow index-width px4">
          <div className="text large right light">{item.index}</div>
        </div>
        <div className="px4">
          <StruxhubInput
            isMinimalisticMode={true}
            value={item.level.name}
            onFocus={this.onFocusLevelName.bind(this, item)}
            onChange={this.changeLevelName.bind(this, item.level)}
            onBlur={this.saveLevelNames.bind(this, [item])}
            customRightIcon={
              !item.level.isNameImplicit && (
                <div
                  className="cross-icon"
                  onClick={this.setLevelImplicitName.bind(this, item.level)}
                >
                  ✖
                </div>
              )
            }
          />
        </div>
      </div>
    ))
  }

  @computed
  private get groundLevelId(): string {
    const { groundLevel } = this.props.building
    if (this.orderedLevels.length) {
      return groundLevel || this.orderedLevels[this.orderedLevels.length - 1].id
    }
  }

  private setGroundLevel = (levelId: string) => {
    this.props.building.groundLevel = levelId
    this.saveImplicitLevelNamesIfChanged()
  }

  private toggleLevelNamingOption = () => {
    this.isLevelNamingOptionsShown = !this.isLevelNamingOptionsShown
  }

  private toggleIsGroundLevelZero = () => {
    const { building } = this.props
    building.isGroundIndexZero = !building.isGroundIndexZero
    this.saveImplicitLevelNamesIfChanged()
  }

  private onSkipIndexTemplateChange = e => {
    const { building } = this.props
    building.skipLevelIndexTemplate = e.target.value
  }

  private onLevelBaseNameChange = e => {
    const { building } = this.props
    building.levelBaseName = e.target.value
  }

  private changeLevelName(
    level: LevelDto,
    e: React.ChangeEvent<HTMLInputElement>,
  ) {
    level.name = e.target.value
    level.isNameImplicit = false
  }

  private setLevelImplicitName(level: LevelDto) {
    level.isNameImplicit = true
    const data = this.levelsDisplayInfo.find(d => d.level.id === level.id)
    this.saveLevelNames([data])
  }

  private saveImplicitLevelNamesIfChanged = async () => {
    const levels = this.levelsDisplayInfo.filter(d => {
      return d.name !== d.level.name || d.code !== d.level.code
    })
    this.saveLevelNames(levels)

    await this.props.graphExecutorStore.mutate(SaveBuildingsDocument, {
      buildings: [this.props.building.getDto()],
    })
  }

  private async saveLevelNames(levelsData: ILevelDisplayInfo[]) {
    document.removeEventListener('keydown', this.onKeyDown)
    this.props.store.focusedLevel = null
    const { setLoading, building } = this.props
    setLoading(true)

    const levels = levelsData.map(d => {
      const { level } = d

      level.name = d.name
      level.code = d.code

      return level.getDto()
    })

    if (levels.length) {
      if (!building.levelsOrder?.length) {
        await this.saveLevelsOrder()
      }

      await this.props.graphExecutorStore.mutate(SaveLevelsDocument, {
        levels,
      })
    }

    setLoading(false)
  }

  private onFocusLevelName = (level: ILevelDisplayInfo) => {
    this.props.store.focusedLevel = level
    document.addEventListener('keydown', this.onKeyDown)
  }

  private onKeyDown = (event: KeyboardEvent) => {
    const { focusedLevel } = this.props.store
    if (event.key === 'Enter') {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;(document.activeElement as HTMLElement).blur?.()
      event.stopPropagation()
      focusedLevel.name = focusedLevel.level.name
      this.saveLevelNames([focusedLevel])
    }
  }
}
