import { action, computed, observable } from 'mobx'

import { DeliveryFilterType, LocationType } from '~/client/graph'
import TwoMonthsDatePickerStore, {
  TwoMonthsDatePickerMode,
} from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import DeliveryActionTypes from '~/client/src/desktop/enums/DeliveryActionTypes'
import DeliveryViewPeriodMode from '~/client/src/desktop/enums/DeliveryViewPeriodMode'
import ViewModes from '~/client/src/desktop/enums/ViewModes'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import CalendarPlaceholderStore from '~/client/src/shared/components/CalendarDayView/components/CalendarPlaceholder.store'
import IEditableCalendarEvent from '~/client/src/shared/components/CalendarDayView/components/IEditableCalendarEvent'
import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import { formatStatusToDisplay } from '~/client/src/shared/constants/DeliveryStatus'
import AssociationStatuses from '~/client/src/shared/enums/AssociationStatuses'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import CalendarEvent, {
  CalendarEventEntityType,
  NEW_EVENT_DATA_ID,
} from '~/client/src/shared/models/CalendarEvent'
import Company from '~/client/src/shared/models/Company'
import Delivery from '~/client/src/shared/models/Delivery'
import Area from '~/client/src/shared/models/LocationObjects/Area'
import Building from '~/client/src/shared/models/LocationObjects/Building'
import Gate from '~/client/src/shared/models/LocationObjects/Gate'
import InteriorDoor from '~/client/src/shared/models/LocationObjects/InteriorDoor'
import InteriorPath from '~/client/src/shared/models/LocationObjects/InteriorPath'
import Level from '~/client/src/shared/models/LocationObjects/Level'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import OffloadingEquipment from '~/client/src/shared/models/LocationObjects/OffloadingEquipment'
import Route from '~/client/src/shared/models/LocationObjects/Route'
import Staging from '~/client/src/shared/models/LocationObjects/Staging'
import Zone from '~/client/src/shared/models/LocationObjects/Zone'
import Material from '~/client/src/shared/models/Material'
import {
  ACTIVATE_PROJECT,
  GET_SCHEDULE,
  RESET_ALL_FILTERS,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ConcreteDirectIntegrationStore from '~/client/src/shared/stores/domain/ConcreteDirectStores/ConcreteDirectIntegration.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import DeliveryAssignmentsStore from '~/client/src/shared/stores/domain/DeliveryAssignments.store'
import DeliveryFollowingsStore from '~/client/src/shared/stores/domain/DeliveryFollowings.store'
import DeliveryVehicleTypesStore from '~/client/src/shared/stores/domain/DeliveryVehicleTypes.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import MaterialCategoriesStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import MaterialsStore from '~/client/src/shared/stores/domain/Materials.store'
import ProjectDateStore, {
  isBetween,
  padWithZero,
} from '~/client/src/shared/stores/ui/ProjectDate.store'
import IDeliveryAttributeDto from '~/client/src/shared/types/IDeliveryAttributeDto'
import {
  NEW_DELIVERY_PATH_KEYWORD,
  NO_VALUE,
} from '~/client/src/shared/utils/usefulStrings'

import DesktopCommonStore from '../../stores/ui/DesktopCommon.store'
import DesktopDeliveriesCalendarStore from './components/DeliveriesCalendar/DeliveriesCalendar.store'

import Colors from '~/client/src/shared/theme.module.scss'

// localization: translated

export interface IAttributesFilterValues {
  levelFilterValue?: string[]
  areaFilterValue?: string[]
  zoneFilterValue?: string[]
  routeFilterValue?: string[]
  buildingFilterValue?: string[]
  gateFilterValue?: string[]
  equipmentFilterValue?: string[]
  companyFilterValue?: string[]
  statusFilterValue?: string[]
}

const PERIOD_FUNCTIONS = {
  [DeliveryViewPeriodMode.TodayPeriod]: {
    start: (date: Date | number, projectDateStore: ProjectDateStore) =>
      projectDateStore.startOfDay(new Date()),
    end: (date: Date | number, projectDateStore: ProjectDateStore) =>
      projectDateStore.endOfDay(new Date()),
  },
  [DeliveryViewPeriodMode.DayPeriod]: {
    start: (date: Date | number, projectDateStore: ProjectDateStore) =>
      projectDateStore.startOfDay(date),
    end: (date: Date | number, projectDateStore: ProjectDateStore) =>
      projectDateStore.endOfDay(date),
  },
  [DeliveryViewPeriodMode.WeekPeriod]: {
    start: (date: Date | number, projectDateStore: ProjectDateStore) =>
      projectDateStore.startOfWeek(date),
    end: (date: Date | number, projectDateStore: ProjectDateStore) =>
      projectDateStore.endOfWeek(date),
  },
  [DeliveryViewPeriodMode.MonthPeriod]: {
    start: (date: Date | number, projectDateStore: ProjectDateStore) =>
      projectDateStore.startOfMonth(date),
    end: (date: Date | number, projectDateStore: ProjectDateStore) =>
      projectDateStore.endOfMonth(date),
  },
  [DeliveryViewPeriodMode.FullPeriod]: {
    start: (date: Date | number, projectDateStore: ProjectDateStore) =>
      projectDateStore.getMinAvailableDate(),
    end: (date: Date | number, projectDateStore: ProjectDateStore) =>
      projectDateStore.getMaxAvailableDate(),
  },
}

const SPECIFIC_FOR_LIST_VIEW_PERIOD_MODES = [
  DeliveryViewPeriodMode.TodayPeriod,
  DeliveryViewPeriodMode.FullPeriod,
  DeliveryViewPeriodMode.MonthPeriod,
]

export default class DesktopDeliveryViewStore {
  @observable public shouldSubscriptionConfirmModalShow: boolean = false

  @observable public _startDate: Date

  public set startDate(date: Date) {
    this._startDate = date
  }

  public get startDate() {
    // work with copy to avoid side effect of implicit modification date anywhere
    return new Date(this._startDate)
  }

  @observable public calendarMode: DeliveryViewPeriodMode =
    DeliveryViewPeriodMode.WeekPeriod
  @observable public activeActionType: DeliveryActionTypes = null
  @observable public editableCalendarEvent: IEditableCalendarEvent = null
  @observable public isMyDeliveriesOnly: boolean = false

  @observable private shouldShowAllAttributesColumns: boolean = false

  public datePickerStore: TwoMonthsDatePickerStore
  public calendarStore: DesktopDeliveriesCalendarStore

  private isDateSelectedByUser: boolean = false

  public constructor(
    private deliveryDetailsStore: DeliveryDetailsStore,
    private eventsStore: DesktopEventStore,
    private deliveriesStore: DeliveriesStore,
    private locationAttributesStore: LocationAttributesStore,
    private deliveryVehicleTypesStore: DeliveryVehicleTypesStore,
    private readonly materialCategoriesStore: MaterialCategoriesStore,
    private readonly materialsStore: MaterialsStore,
    private projectDateStore: ProjectDateStore,
    private deliveryAssignmentsStore: DeliveryAssignmentsStore,
    private deliveryFollowingsStore: DeliveryFollowingsStore,
    private readonly companiesStore: CompaniesStore,
    private readonly common: DesktopCommonStore,
    private readonly concreteDirectIntegrationStore: ConcreteDirectIntegrationStore,
    private calendarPlaceholderStore?: CalendarPlaceholderStore,
  ) {
    this.startDate = this.projectDateStore.startOfWeek(new Date())
    this.calendarStore = new DesktopDeliveriesCalendarStore(
      this,
      this.calendarPlaceholderStore,
      this.projectDateStore,
      this.companiesStore,
    )

    this.datePickerStore = new TwoMonthsDatePickerStore(this.projectDateStore)
    this.datePickerStore.setMode(TwoMonthsDatePickerMode.CUSTOM)
  }

  public get isDeliveryDetailsShown(): boolean {
    return [DeliveryActionTypes.VIEW, DeliveryActionTypes.NEW].includes(
      this.activeActionType,
    )
  }

  public get deliveryConfigurations() {
    return this.eventsStore.appState.delivery.configurations
  }

  @computed
  public get hasCDIntegration(): boolean {
    return (
      this.concreteDirectIntegrationStore.isIntegrationEnabled &&
      this.concreteDirectIntegrationStore.isIntegrationActive
    )
  }

  @computed
  public get areCDOrdersHidden() {
    return (
      !this.hasCDIntegration ||
      this.concreteDirectIntegrationStore.areCDOrdersHidden
    )
  }

  public deliveryCardColor = (delivery: Delivery): string => {
    const { cardColoringLocationType } =
      this.eventsStore.appState.delivery.configurations
    switch (cardColoringLocationType) {
      case LocationType.Area:
        return this.getAreaById(delivery.area)?.color || Colors.primary20
      case LocationType.Building:
        return (
          this.getBuildingById(delivery.building)?.color || Colors.primary20
        )
      case LocationType.Gate:
        return this.getGateById(delivery.gate)?.color || Colors.primary20
      case LocationType.Level:
        return this.getLevelById(delivery.level)?.color || Colors.primary20
      case LocationType.Route:
        return this.getRouteById(delivery.route)?.color || Colors.primary20
      case LocationType.Zone:
      default:
        return this.getZoneById(delivery.zone)?.color || Colors.primary20
    }
  }

  public get viewMode() {
    return this.appState.deliveriesList.viewMode
  }

  public get isCalendarViewMode(): boolean {
    return this.viewMode === ViewModes.Calendar
  }

  public get isListViewMode(): boolean {
    return this.viewMode === ViewModes.List
  }

  public get isMapViewMode(): boolean {
    return this.viewMode === ViewModes.Map
  }

  public get isDeliveryMovable(): boolean {
    return this.deliveryDetailsStore.isDeliveryMovable
  }

  public get isDataPrepared(): boolean {
    return !this.isProjectLoading && this.isDataReceived
  }

  public get isProjectLoading(): boolean {
    const { loading } = this.appState
    return loading.get(ACTIVATE_PROJECT) || loading.get(GET_SCHEDULE)
  }

  public getFieldName = (id: FieldIds): string => {
    return this.deliveryDetailsStore.getFieldName(id)
  }

  @action.bound
  public toggleShowingOfCDOrders() {
    this.concreteDirectIntegrationStore.updateShowingOfOrders()
  }

  @action.bound
  public setShowingOfAllZoneColumns(shouldShowAllAttributesColumns: boolean) {
    this.shouldShowAllAttributesColumns = shouldShowAllAttributesColumns
  }

  @action.bound
  public setInitialDateValue() {
    this.startDate = this.projectDateStore.startOfWeek(new Date())
    this.setCalendarMode(this.calendarMode)
  }

  @action.bound
  public activateDeliveryById(deliveryId: string, shouldAddMaterial?: boolean) {
    if (!deliveryId || this.activeDeliveryId === deliveryId) {
      return
    }

    const delivery = this.deliveriesStore.byId.get(deliveryId)
    this.onEventClicked({ data: delivery } as CalendarEvent, shouldAddMaterial)
  }

  @action.bound
  public prepareCalendar() {
    if (!this.activeDelivery) {
      return
    }

    const dateFunction = PERIOD_FUNCTIONS[this.calendarMode]
    this.startDate = dateFunction.start(
      this.activeDelivery.startDate,
      this.projectDateStore,
    )
    this.isDateSelectedByUser = true
  }

  @action.bound
  public resetInappropriatePeriodModeIfActive() {
    if (this.isSpecificForListViewModeActive) {
      this.resetToDefaultPeriodMode()
    }
  }

  @action.bound
  public setActionType(actionType: DeliveryActionTypes) {
    if (actionType === DeliveryActionTypes.SUBSCRIBE) {
      return this.openSubscriptionConfirmModal()
    }

    this.activeActionType = actionType
  }

  @action.bound
  public resetActionType() {
    this.activeActionType = null
  }

  public get isDataReceived(): boolean {
    return (
      this.deliveriesStore.isDataReceived &&
      this.locationAttributesStore.zonesStore.isDataReceived &&
      this.locationAttributesStore.gatesStore.isDataReceived &&
      this.locationAttributesStore.offloadingEquipmentsStore.isDataReceived &&
      this.appState.delivery.isDataReceived &&
      this.materialCategoriesStore.isDataReceived &&
      this.materialsStore.isDataReceived
    )
  }

  public get activeDelivery(): Delivery {
    const { displayedDelivery } = this.deliveryDetailsStore
    return displayedDelivery && !displayedDelivery.isDeleted
      ? displayedDelivery
      : null
  }

  public get activeDeliveryId(): string {
    return this.activeDelivery?.id || null
  }

  public get endDate(): Date {
    const dateFunctions = PERIOD_FUNCTIONS[this.calendarMode]
    return dateFunctions.end(this.startDate, this.projectDateStore)
  }

  @computed
  public get availableDeliveries(): Delivery[] {
    return this.deliveriesStore.availableDeliveries
  }

  @computed
  public get currentViewDeliveries(): Delivery[] {
    return this.availableDeliveries.filter(delivery =>
      delivery.isScheduledWithinRange(this.startDate, this.endDate),
    )
  }

  public isZoneUnassigned = (delivery: Delivery) => {
    return !this.zonesIds.includes(delivery.zone)
  }

  public isInteriorDoorUnassigned = (delivery: Delivery) => {
    return !this.interiorDoorsIds.includes(delivery.interiorDoor)
  }

  public isInteriorPathUnassigned = (delivery: Delivery) => {
    return !this.interiorPathsIds.includes(delivery.interiorPath)
  }

  public isStagingUnassigned = (delivery: Delivery) => {
    return !this.stagingsIds.includes(delivery.staging)
  }

  @computed
  public get areUnassignedZoneDeliveries() {
    return this.currentViewDeliveries.some(delivery =>
      this.isZoneUnassigned(delivery),
    )
  }

  public isLevelUnassigned = (delivery: Delivery) => {
    return !this.levelIds.includes(delivery.level)
  }

  @computed
  public get areUnassignedLevelDeliveries() {
    return this.currentViewDeliveries.some(delivery =>
      this.isLevelUnassigned(delivery),
    )
  }

  public isAreaUnassigned = (delivery: Delivery) => {
    return !this.areaIds.includes(delivery.area)
  }

  public isDeliveryAttributeUnassigned = (delivery: Delivery) => {
    switch (this.calendarStore.selectedType) {
      case LocationType.Zone:
        return this.isZoneUnassigned(delivery)
      case LocationType.Area:
        return this.isAreaUnassigned(delivery)
      case LocationType.Building:
        return this.isBuildingUnassigned(delivery)
      case LocationType.Gate:
        return this.isGateUnassigned(delivery)
      case LocationType.Level:
        return this.isLevelUnassigned(delivery)
      case LocationType.OffloadingEquipment:
        return this.isOffloadingEquipmentUnassigned(delivery)
      case LocationType.Route:
        return this.isRouteUnassigned(delivery)
      case LocationType.InteriorDoor:
        return this.isInteriorDoorUnassigned(delivery)
      case LocationType.InteriorPath:
        return this.isInteriorPathUnassigned(delivery)
      case LocationType.Staging:
        return this.isStagingUnassigned(delivery)
      default:
        return false
    }
  }

  @computed
  public get areUnassignedAreaDeliveries() {
    return this.currentViewDeliveries.some(delivery =>
      this.isAreaUnassigned(delivery),
    )
  }

  @computed
  public get filteredDeliveries(): Delivery[] {
    return this.currentViewDeliveries.filter(d => this.deliveryPredicate(d))
  }

  @computed
  public get filteredZones(): Zone[] {
    if (this.shouldShowAllAttributesColumns) {
      return this.zones
    }

    const filteredZones = this.zones.filter(zone =>
      this.filteredDeliveries.some(delivery => delivery.zone === zone.id),
    )

    return filteredZones.length || this.hasUnassignedFilteredZoneDeliveries
      ? filteredZones
      : this.zones
  }

  @computed
  public get filteredLevels(): Level[] {
    if (this.shouldShowAllAttributesColumns) {
      return this.levels
    }

    const filteredLevels = this.levels.filter(level =>
      this.filteredDeliveries.some(delivery => delivery.level === level.id),
    )

    return filteredLevels.length || this.hasUnassignedFilteredLevelDeliveries
      ? filteredLevels
      : this.levels
  }

  @computed
  public get filteredBuildings(): Building[] {
    if (this.shouldShowAllAttributesColumns) {
      return this.buildings
    }

    const filteredBuildings = this.buildings.filter(building =>
      this.filteredDeliveries.some(
        delivery => delivery.building === building.id,
      ),
    )

    return filteredBuildings.length ||
      this.hasUnassignedFilteredBuildingDeliveries
      ? filteredBuildings
      : this.buildings
  }

  @computed
  public get filteredRoutes(): Route[] {
    if (this.shouldShowAllAttributesColumns) {
      return this.routes
    }

    const filteredRoutes = this.routes.filter(route =>
      this.filteredDeliveries.some(delivery => delivery.route === route.id),
    )

    return filteredRoutes.length || this.hasUnassignedFilteredRouteDeliveries
      ? filteredRoutes
      : this.routes
  }

  @computed
  public get filteredAreas(): Area[] {
    if (this.shouldShowAllAttributesColumns) {
      return this.areas
    }

    const filteredAreas = this.areas.filter(area =>
      this.filteredDeliveries.some(delivery => delivery.area === area.id),
    )

    return filteredAreas.length || this.hasUnassignedFilteredAreaDeliveries
      ? filteredAreas
      : this.areas
  }

  @computed
  public get filteredGates(): Gate[] {
    if (this.shouldShowAllAttributesColumns) {
      return this.gates
    }

    const filteredGates = this.gates.filter(gate =>
      this.filteredDeliveries.some(delivery => delivery.gate === gate.id),
    )

    return filteredGates.length || this.hasUnassignedFilteredGateDeliveries
      ? filteredGates
      : this.gates
  }

  @computed
  public get filteredOffloadingEquipment(): OffloadingEquipment[] {
    if (this.shouldShowAllAttributesColumns) {
      return this.offloadingEquipments
    }

    const filteredEquipment = this.offloadingEquipments.filter(equipment =>
      this.filteredDeliveries.some(delivery =>
        delivery.offloadingEquipmentIds.includes(equipment.id),
      ),
    )

    return filteredEquipment.length ||
      this.hasUnassignedFilteredEquipmentDeliveries
      ? filteredEquipment
      : this.offloadingEquipments
  }

  @computed
  public get hasUnassignedFilteredZoneDeliveries(): boolean {
    return this.filteredDeliveries.some(delivery =>
      this.isZoneUnassigned(delivery),
    )
  }

  @computed
  public get hasUnassignedFilteredAreaDeliveries(): boolean {
    return this.filteredDeliveries.some(delivery =>
      this.isAreaUnassigned(delivery),
    )
  }

  @computed
  public get hasUnassignedFilteredGateDeliveries(): boolean {
    return this.filteredDeliveries.some(delivery =>
      this.isGateUnassigned(delivery),
    )
  }

  @computed
  public get hasUnassignedFilteredBuildingDeliveries(): boolean {
    return this.filteredDeliveries.some(delivery =>
      this.isBuildingUnassigned(delivery),
    )
  }

  @computed
  public get hasUnassignedFilteredRouteDeliveries(): boolean {
    return this.filteredDeliveries.some(delivery =>
      this.isRouteUnassigned(delivery),
    )
  }

  @computed
  public get hasUnassignedFilteredLevelDeliveries(): boolean {
    return this.filteredDeliveries.some(delivery =>
      this.isLevelUnassigned(delivery),
    )
  }

  @computed
  public get hasUnassignedFilteredEquipmentDeliveries(): boolean {
    return this.filteredDeliveries.some(delivery =>
      this.isOffloadingEquipmentUnassigned(delivery),
    )
  }

  @computed
  public get shouldAddUnassignedAttributeColumn(): boolean {
    if (this.shouldShowAllAttributesColumns) {
      return this.areUnassignedAttributeDeliveries
    }

    return (
      this.areUnassignedAttributeDeliveries &&
      (this.hasUnassignedFilteredAttributeDeliveries ||
        !this.filteredDeliveries.length)
    )
  }

  public isGateUnassigned = (delivery: Delivery) => {
    return !this.gatesIds.includes(delivery.gate)
  }

  @computed
  public get areUnassignedGateDeliveries() {
    return this.currentViewDeliveries.some(delivery =>
      this.isGateUnassigned(delivery),
    )
  }

  public isRouteUnassigned = (delivery: Delivery) => {
    return !this.routesIds.includes(delivery.route)
  }

  @computed
  public get areUnassignedRouteDeliveries() {
    return this.currentViewDeliveries.some(delivery =>
      this.isRouteUnassigned(delivery),
    )
  }

  public isBuildingUnassigned = (delivery: Delivery) => {
    return !this.buildingsIds.includes(delivery.building)
  }

  @computed
  public get areUnassignedBuildingDeliveries() {
    return this.currentViewDeliveries.some(delivery =>
      this.isBuildingUnassigned(delivery),
    )
  }

  public isOffloadingEquipmentUnassigned = ({
    offloadingEquipmentIds,
  }: Delivery) => {
    if (offloadingEquipmentIds?.length) {
      return offloadingEquipmentIds.some(
        equipmentId => !this.offloadingEquipmentsIds.includes(equipmentId),
      )
    }
    return true
  }

  @computed
  public get areUnassignedEquipmentDeliveries() {
    return this.currentViewDeliveries.some(delivery =>
      this.isOffloadingEquipmentUnassigned(delivery),
    )
  }

  public isDeliveryCompanyUnassigned = (delivery: Delivery) => {
    return !this.companiesStore.allCompaniesIds.includes(delivery.company)
  }

  @computed
  public get areUnassignedCompanyDeliveries() {
    return this.currentViewDeliveries.some(delivery =>
      this.isDeliveryCompanyUnassigned(delivery),
    )
  }

  @action.bound
  public prepareViewWhenFollowingDirectLink(deliveryIdFromUrl: string) {
    if (
      !deliveryIdFromUrl ||
      deliveryIdFromUrl === NEW_DELIVERY_PATH_KEYWORD ||
      this.deliveryDetailsStore.displayedDelivery
    ) {
      return
    }

    const delivery = this.deliveriesStore.byId.get(deliveryIdFromUrl)
    if (!delivery) {
      return
    }

    this.deliveryDetailsStore.setChangeMode(delivery)
    this.activeActionType = DeliveryActionTypes.VIEW
    this.moveToDeliveryStart()
  }

  public deliveryPredicate(
    delivery: Delivery,
    filterValues?: IAttributesFilterValues,
  ): boolean {
    return (
      delivery.isScheduledWithinRange(this.startDate, this.endDate) &&
      this.isDeliveryInAttributesFilters(delivery, filterValues)
    )
  }

  public isDeliveryInAttributesFilters(
    delivery: Delivery,
    filterValues?: IAttributesFilterValues,
  ) {
    const deliveryFilters: IAttributesFilterValues = this.getStoreFilters()
    const { hiddenFields } = this.appState.delivery

    const filteredByZone =
      filterValues?.zoneFilterValue || deliveryFilters?.zoneFilterValue
    const filteredByBuilding =
      filterValues?.buildingFilterValue || deliveryFilters?.buildingFilterValue
    const filteredByRoute =
      filterValues?.routeFilterValue || deliveryFilters?.routeFilterValue
    const filteredByGate =
      filterValues?.gateFilterValue || deliveryFilters?.gateFilterValue
    const filteredByEquipment =
      filterValues?.equipmentFilterValue ||
      deliveryFilters?.equipmentFilterValue
    const filteredByCompanies =
      filterValues?.companyFilterValue || deliveryFilters?.companyFilterValue
    const filteredByStatus =
      filterValues?.statusFilterValue || deliveryFilters?.statusFilterValue
    const filteredByLevel =
      filterValues?.levelFilterValue || deliveryFilters?.levelFilterValue
    const filteredByArea =
      filterValues?.areaFilterValue || deliveryFilters?.areaFilterValue

    if (this.isMyDeliveriesOnly && !this.isMyDelivery(delivery)) {
      return false
    }

    if (this.areCDOrdersHidden && delivery.isFromConcreteDirect) {
      return false
    }

    if (
      !filteredByLevel?.includes(delivery.id) &&
      !hiddenFields[FieldIds.LEVEL]
    ) {
      return false
    }
    if (
      !filteredByArea?.includes(delivery.id) &&
      !hiddenFields[FieldIds.AREA]
    ) {
      return false
    }
    if (
      !filteredByZone?.includes(delivery.id) &&
      !hiddenFields[FieldIds.ZONE]
    ) {
      return false
    }
    if (
      !filteredByGate?.includes(delivery.id) &&
      !hiddenFields[FieldIds.GATE]
    ) {
      return false
    }
    if (
      !filteredByBuilding?.includes(delivery.id) &&
      !hiddenFields[FieldIds.BUILDING]
    ) {
      return false
    }
    if (
      !filteredByRoute?.includes(delivery.id) &&
      !hiddenFields[FieldIds.ROUTE]
    ) {
      return false
    }
    if (
      !filteredByEquipment?.includes(delivery.id) &&
      !hiddenFields[FieldIds.OFFLOADING_EQUIPMENT]
    ) {
      return false
    }
    if (
      filteredByStatus &&
      filteredByStatus.length &&
      !filteredByStatus.includes(delivery.id)
    ) {
      return false
    }

    return filteredByCompanies?.includes(delivery.id)
  }

  public get levelFilterOptions() {
    const options: Array<LocationAttributeBase | string> = this.levels.slice()

    if (this.areUnassignedLevelDeliveries) {
      options.push(Localization.translator.unassigned)
    }
    return options
  }

  public get areaFilterOptions() {
    const options: Array<LocationAttributeBase | string> = this.areas.slice()

    if (this.areUnassignedAreaDeliveries) {
      options.push(Localization.translator.unassigned)
    }
    return options
  }

  public get zoneFilterOptions() {
    const options: Array<LocationAttributeBase | string> = this.zones.slice()

    if (this.areUnassignedZoneDeliveries) {
      options.push(Localization.translator.unassigned)
    }
    return options
  }

  public get gateFilterOptions() {
    const options: Array<LocationAttributeBase | string> = this.gates.slice()
    if (this.areUnassignedGateDeliveries) {
      options.push(Localization.translator.unassigned)
    }
    return options
  }

  public get routeFilterOptions() {
    const options: Array<LocationAttributeBase | string> = this.routes.slice()

    if (this.areUnassignedRouteDeliveries) {
      options.push(Localization.translator.unassigned)
    }
    return options
  }

  public get buildingFilterOptions() {
    const options: Array<LocationAttributeBase | string> =
      this.buildings.slice()
    if (this.areUnassignedBuildingDeliveries) {
      options.push(Localization.translator.unassigned)
    }
    return options
  }

  public get equipmentFilterOptions() {
    const options: Array<LocationAttributeBase | string> =
      this.offloadingEquipments.slice()

    if (this.areUnassignedEquipmentDeliveries) {
      options.push(Localization.translator.unassigned)
    }
    return options
  }

  public get companyFilterOptions() {
    const options: Array<Company | string> =
      this.companiesStore.allCompanies.slice()

    if (this.areUnassignedCompanyDeliveries) {
      options.push(Localization.translator.unassigned)
    }
    return options
  }

  public getFilterValueDescription(type: string, values: string[]): string[] {
    let store: { byId: Map<string, { name: string }> }

    switch (type) {
      case DeliveryFilterType.Status:
        return values.map(status => formatStatusToDisplay(status))
      case DeliveryFilterType.Company:
        return Array.from(values).map(value =>
          this.companiesStore.getCompanyNameById(value),
        )
      case DeliveryFilterType.Zone:
        store = this.locationAttributesStore.zonesStore
        break
      case DeliveryFilterType.Gate:
        store = this.locationAttributesStore.gatesStore
        break
      case DeliveryFilterType.Building:
        store = this.locationAttributesStore.buildingsStore
        break
      case DeliveryFilterType.Route:
        store = this.locationAttributesStore.routesStore
        break
      case DeliveryFilterType.Level:
        store = this.locationAttributesStore.levelsStore
        break
      case DeliveryFilterType.Area:
        store = this.locationAttributesStore.areasStore
        break
      case DeliveryFilterType.Staging:
        store = this.locationAttributesStore.stagingsStore
        break
      case DeliveryFilterType.InteriorDoor:
        store = this.locationAttributesStore.interiorDoorsStore
        break
      case DeliveryFilterType.InteriorPath:
        store = this.locationAttributesStore.interiorPathsStore
        break
      case DeliveryFilterType.Equipment:
        store = this.locationAttributesStore.offloadingEquipmentsStore
        break
      default:
        return values
    }

    return values.map(value => {
      const description = store.byId.get(value)
      return description?.name || NO_VALUE
    })
  }

  public getDeliveryById = (id: string): Delivery => {
    return this.deliveriesStore.byId.get(id)
  }

  public get deliveryDuration() {
    return this.appState.delivery.configurations.deliveryDuration
  }

  public get isTodayBetweenSelectedDates(): boolean {
    return isBetween(this.startDate, this.endDate, new Date())
  }

  @computed
  public get levels(): Level[] {
    return this.locationAttributesStore.levelsStore.list
  }

  @computed
  public get areas(): Area[] {
    return this.locationAttributesStore.areasStore.list
  }

  @computed
  public get zones(): Zone[] {
    return this.locationAttributesStore.zonesStore.list
  }

  @computed
  public get interiorDoors(): InteriorDoor[] {
    return this.locationAttributesStore.interiorDoorsStore.list
  }

  @computed
  public get interiorPaths(): InteriorPath[] {
    return this.locationAttributesStore.interiorPathsStore.list
  }

  @computed
  public get stagings(): Staging[] {
    return this.locationAttributesStore.stagingsStore.list
  }

  @computed
  public get zonesIds(): string[] {
    return this.zones.map(({ id }) => id)
  }

  @computed
  public get interiorDoorsIds(): string[] {
    return this.interiorDoors.map(({ id }) => id)
  }

  @computed
  public get interiorPathsIds(): string[] {
    return this.interiorPaths.map(({ id }) => id)
  }

  @computed
  public get stagingsIds(): string[] {
    return this.stagings.map(({ id }) => id)
  }

  @computed
  public get offloadingEquipments(): OffloadingEquipment[] {
    return this.locationAttributesStore.offloadingEquipmentsStore.list
  }

  @computed
  public get offloadingEquipmentsIds(): string[] {
    return this.offloadingEquipments.map(({ id }) => id)
  }

  @computed
  public get gates(): Gate[] {
    return this.locationAttributesStore.gatesStore.list
  }

  @computed
  public get routes(): Route[] {
    return this.locationAttributesStore.routesStore.list
  }

  @computed
  public get buildings(): Building[] {
    return this.locationAttributesStore.buildingsStore.list
  }

  @computed
  public get gatesIds(): string[] {
    return this.gates.map(({ id }) => id)
  }

  @computed
  public get routesIds(): string[] {
    return this.routes.map(({ id }) => id)
  }

  @computed
  public get buildingsIds(): string[] {
    return this.buildings.map(({ id }) => id)
  }

  @computed
  public get levelIds(): string[] {
    return this.levels.map(({ id }) => id)
  }

  @computed
  public get areaIds(): string[] {
    return this.areas.map(({ id }) => id)
  }

  @action.bound
  public setCalendarMode(mode: DeliveryViewPeriodMode) {
    const baseDate =
      this.isDateSelectedByUser && !this.isFullPeriodModeActive
        ? this.startDate
        : new Date()

    this.calendarMode = mode
    const dateFunctions = PERIOD_FUNCTIONS[this.calendarMode]

    this.startDate = dateFunctions.start(baseDate, this.projectDateStore)

    if (this.datePickerStore.isShown) {
      this.datePickerStore.startDate = this.startDate
      this.datePickerStore.endDate = this.endDate
    }
  }

  public get areDayTodayCalendarModesActive() {
    return (
      this.calendarMode === DeliveryViewPeriodMode.DayPeriod ||
      this.calendarMode === DeliveryViewPeriodMode.TodayPeriod
    )
  }

  public get isFullPeriodModeActive() {
    return this.calendarMode === DeliveryViewPeriodMode.FullPeriod
  }

  public get isSpecificForListViewModeActive() {
    return SPECIFIC_FOR_LIST_VIEW_PERIOD_MODES.includes(this.calendarMode)
  }

  @action.bound
  public showDatePicker() {
    this.datePickerStore.showWithOptions({
      initialRange: {
        startDate: this.startDate,
        endDate: this.endDate,
      },
      onCustomDateClick: this.onDatePickerDateClick,
      handler: this.applyNewPeriod,
    })
  }

  @action.bound
  public setPreviousPeriod() {
    const dateFunctions = PERIOD_FUNCTIONS[this.calendarMode]
    const newDate = this.projectDateStore.addDays(this.startDate, -1)
    this.startDate = dateFunctions.start(newDate, this.projectDateStore)
    this.isDateSelectedByUser = true
  }

  @action.bound
  public setNextPeriod() {
    const dateFunctions = PERIOD_FUNCTIONS[this.calendarMode]
    const newDate = this.projectDateStore.addDays(this.endDate, 1)
    this.startDate = dateFunctions.start(newDate, this.projectDateStore)
    this.isDateSelectedByUser = true
  }

  @action.bound
  public onEventClicked(event: CalendarEvent, shouldAddMaterial?: boolean) {
    if (event.dataId === NEW_EVENT_DATA_ID) {
      return
    }

    if (shouldAddMaterial) {
      this.deliveryDetailsStore.setViewModeWithNewMaterial(event.data)
    } else {
      this.deliveryDetailsStore.setViewMode(event.data)
    }

    this.activeActionType = DeliveryActionTypes.VIEW

    if (this.calendarPlaceholderStore.eventPlaceholder) {
      this.calendarPlaceholderStore.resetEventPlaceholder()
    }

    this.common._displayDeliveryDetailsView(event.data.id)
  }

  @action.bound
  public onEventDateChange(
    event: CalendarEvent,
    startDate: Date,
    endDate: Date,
  ) {
    const { getHoursMinutes, getDashedFormattedDate, convertToProjectDate } =
      this.projectDateStore
    if (event.dataId === NEW_EVENT_DATA_ID) {
      const newEvent = this.calendarStore.getNewEvent(
        CalendarEventEntityType.Delivery,
      )
      const dateStart = convertToProjectDate(startDate)
      const dateEnd = convertToProjectDate(endDate)

      event.dateInterval.startDate = newEvent.startDate
      event.dateInterval.endDate = newEvent.endDate

      this.deliveryDetailsStore.onFieldValueChange(
        FieldIds.DATE,
        getDashedFormattedDate(dateStart),
      )
      this.deliveryDetailsStore.onFieldValueChange(
        FieldIds.END_DATE,
        getDashedFormattedDate(dateEnd),
      )

      const [startHours, startMinutes] = getHoursMinutes(newEvent.startDate)
      const startTime = `${padWithZero(Number(startHours))}:${padWithZero(
        Number(startMinutes),
      )}`

      this.deliveryDetailsStore.onFieldValueChange(
        FieldIds.START_TIME,
        startTime,
      )

      const [endHours, endMinutes] = getHoursMinutes(newEvent.endDate)
      const endTime = `${padWithZero(Number(endHours))}:${padWithZero(
        Number(endMinutes),
      )}`

      this.deliveryDetailsStore.onFieldValueChange(FieldIds.END_TIME, endTime)
      this.calendarPlaceholderStore.setEventPlaceholder(newEvent)
    } else {
      this.deliveryDetailsStore.openMoveDeliveryDates({
        event,
        startDate,
        endDate,
      })

      if (this.calendarPlaceholderStore.eventPlaceholder) {
        this.calendarPlaceholderStore.resetEventPlaceholder()
      }
    }
  }

  @action.bound
  public onNewDeliveryCreate(
    startDate?: Date,
    endDate?: Date,
    columnFields: any = {},
  ) {
    this.common._displayDeliveryDetailsView(NEW_DELIVERY_PATH_KEYWORD)

    this.deliveryDetailsStore.setNewDeliveryMode()
    const { draftEventData } = this.calendarPlaceholderStore
    const prePopulatedFields =
      draftEventData?.toFields(this.projectDateStore) ||
      Object.assign({}, columnFields)

    if (startDate) {
      prePopulatedFields[FieldIds.DATE] =
        this.projectDateStore.getDashedFormattedDate(startDate)
      prePopulatedFields[FieldIds.START_TIME] =
        this.projectDateStore.getISOTime(startDate)
    }
    if (endDate) {
      prePopulatedFields[FieldIds.END_TIME] =
        this.projectDateStore.getISOTime(endDate)
      if (!this.projectDateStore.isSameDay(startDate, endDate)) {
        prePopulatedFields[FieldIds.END_DATE] =
          this.projectDateStore.getDashedFormattedDate(endDate)
      }
    }

    this.deliveryDetailsStore.setPrepopulatedFields(prePopulatedFields)
    this.deliveryDetailsStore.updateFieldsByCachedValues()
    this.activeActionType = DeliveryActionTypes.NEW
  }

  @action.bound
  public hideDeliveryDetails() {
    this.common.displayDeliveriesView()
    this.resetActionType()
    this.calendarPlaceholderStore.resetEventPlaceholder()
    this.editableCalendarEvent = null
  }

  public getLevelById = (levelId: string) => {
    return this.locationAttributesStore.levelsStore.byId.get(levelId)
  }

  public getStagingById = (stagingId: string) => {
    return this.locationAttributesStore.stagingsStore.byId.get(stagingId)
  }

  public getInteriorDoorById = (interiorDoorId: string) => {
    return this.locationAttributesStore.interiorDoorsStore.byId.get(
      interiorDoorId,
    )
  }

  public getInteriorPathById = (interiorPathId: string) => {
    return this.locationAttributesStore.interiorPathsStore.byId.get(
      interiorPathId,
    )
  }

  public getAreaById = (areaId: string) => {
    return this.locationAttributesStore.areasStore.byId.get(areaId)
  }

  public getZoneById = (zoneId: string) => {
    return this.locationAttributesStore.zonesStore.byId.get(zoneId)
  }

  public getRouteById = (routeId: string) => {
    return this.locationAttributesStore.routesStore.byId.get(routeId)
  }

  public getBuildingById = (buildingId: string) => {
    return this.locationAttributesStore.buildingsStore.byId.get(buildingId)
  }

  public getGateById = (gateId: string) => {
    return this.locationAttributesStore.gatesStore.byId.get(gateId)
  }

  public getVehicleTypeById = (vehicleTypeId: string) => {
    return this.deliveryVehicleTypesStore.getInstanceById(vehicleTypeId, true)
  }

  public getCompanyById = (companyId: string) => {
    return this.companiesStore.getCompanyById(companyId)
  }

  public getOffloadEquipmentsByIds = (
    offloadingEquipmentIds: string[],
  ): IDeliveryAttributeDto[] => {
    return this.locationAttributesStore.offloadingEquipmentsStore.getByIds(
      offloadingEquipmentIds,
    )
  }

  public getDeliveryAssociationStatus = (
    deliveryId: string,
  ): AssociationStatuses => {
    const { getAssignedEntitiesByUserId } = this.deliveryAssignmentsStore
    const { isEntityFollowed } = this.deliveryFollowingsStore
    const { id } = this.appState.user

    switch (true) {
      case !!getAssignedEntitiesByUserId(id).includes(deliveryId):
        return AssociationStatuses.ASSIGNED
      case isEntityFollowed(deliveryId):
        return AssociationStatuses.FOLLOWED
      default:
        return AssociationStatuses.DEFAULT
    }
  }

  public getMaterialsByIds = (materialIds: string[]): Material[] => {
    return this.materialsStore.getByIds(materialIds)
  }

  public isMyDelivery = (delivery: Delivery): boolean => {
    const { getEntityFollowersIds } = this.deliveryFollowingsStore
    const { user } = this.appState

    return (
      delivery.isAssociatedWithUser(user) ||
      getEntityFollowersIds(delivery.id).includes(user.id)
    )
  }

  @action.bound
  public openSubscriptionConfirmModal() {
    this.shouldSubscriptionConfirmModalShow = true
  }

  @action.bound
  public closeSubscriptionConfirmModal() {
    this.shouldSubscriptionConfirmModalShow = false
  }

  @action.bound
  public resetAllFilters() {
    this.eventsStore.dispatch(RESET_ALL_FILTERS)

    if (!this.isCalendarViewMode) {
      this.setCalendarMode(DeliveryViewPeriodMode.FullPeriod)
    }
  }

  @action.bound
  public setEditableEvent(event: IEditableCalendarEvent) {
    this.editableCalendarEvent = event
  }

  public get allBuildingObjects() {
    return this.locationAttributesStore.buildingsStore.listWithDeletedItems
  }

  public get allGateObjects() {
    return this.locationAttributesStore.gatesStore.listWithDeletedItems
  }

  public get allZoneObjects() {
    return this.locationAttributesStore.zonesStore.listWithDeletedItems
  }

  public get allRouteObjects() {
    return this.locationAttributesStore.routesStore.listWithDeletedItems
  }

  public get allEquipmentObjects() {
    return this.locationAttributesStore.offloadingEquipmentsStore
      .listWithDeletedItems
  }

  private moveToDeliveryStart() {
    const dateFunctions = PERIOD_FUNCTIONS[this.calendarMode]
    this.startDate = dateFunctions.start(
      this.activeDelivery.startDate,
      this.projectDateStore,
    )
    this.isDateSelectedByUser = true
  }

  private getStoreFilters(): IAttributesFilterValues {
    const { fieldsMap } = this.eventsStore.appState.deliveryFilters
    const deliveryFilters: IAttributesFilterValues = {
      statusFilterValue: fieldsMap.status.appliedFilterOptions,
      companyFilterValue: fieldsMap.company.appliedFilterOptions,
    }

    if (this.isCalendarViewMode) {
      deliveryFilters.areaFilterValue = fieldsMap.area.appliedFilterOptions
      deliveryFilters.buildingFilterValue =
        fieldsMap.building.appliedFilterOptions
      deliveryFilters.equipmentFilterValue =
        fieldsMap.equipment.appliedFilterOptions
      deliveryFilters.gateFilterValue = fieldsMap.gate.appliedFilterOptions
      deliveryFilters.levelFilterValue = fieldsMap.level.appliedFilterOptions
      deliveryFilters.routeFilterValue = fieldsMap.route.appliedFilterOptions
      deliveryFilters.zoneFilterValue = fieldsMap.zone.appliedFilterOptions
    }

    return deliveryFilters
  }

  @action.bound
  private applyNewPeriod(startDate: Date) {
    this.startDate = startDate
    this.isDateSelectedByUser = true
  }

  @action.bound
  private onDatePickerDateClick(date: Date) {
    const dateFunctions = PERIOD_FUNCTIONS[this.calendarMode]
    return {
      startDate: dateFunctions.start(date, this.projectDateStore),
      endDate: dateFunctions.end(date, this.projectDateStore),
    }
  }

  private resetToDefaultPeriodMode() {
    this.setCalendarMode(DeliveryViewPeriodMode.WeekPeriod)
  }

  private get appState() {
    return this.eventsStore.appState
  }

  @computed
  private get areUnassignedAttributeDeliveries(): boolean {
    switch (this.calendarStore.selectedType) {
      case LocationType.Zone:
        return this.areUnassignedZoneDeliveries
      case LocationType.Area:
        return this.areUnassignedAreaDeliveries
      case LocationType.Building:
        return this.areUnassignedBuildingDeliveries
      case LocationType.Gate:
        return this.areUnassignedGateDeliveries
      case LocationType.Level:
        return this.areUnassignedLevelDeliveries
      case LocationType.OffloadingEquipment:
        return this.areUnassignedEquipmentDeliveries
      case LocationType.Route:
        return this.areUnassignedRouteDeliveries
      default:
        return false
    }
  }

  @computed
  public get hasUnassignedFilteredAttributeDeliveries(): boolean {
    switch (this.calendarStore.selectedType) {
      case LocationType.Zone:
        return this.hasUnassignedFilteredZoneDeliveries
      case LocationType.Area:
        return this.hasUnassignedFilteredAreaDeliveries
      case LocationType.Building:
        return this.hasUnassignedFilteredBuildingDeliveries
      case LocationType.Gate:
        return this.hasUnassignedFilteredGateDeliveries
      case LocationType.Level:
        return this.hasUnassignedFilteredLevelDeliveries
      case LocationType.OffloadingEquipment:
        return this.hasUnassignedFilteredEquipmentDeliveries
      case LocationType.Route:
        return this.hasUnassignedFilteredRouteDeliveries
      default:
        return false
    }
  }
}
