import * as React from 'react'

import { action } from 'mobx'
import { observer } from 'mobx-react'

import SuperFilter from '~/client/src/desktop/components/SuperFilter/SuperFilter'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import SuperFilterHandleButton from '~/client/src/shared/components/Filters/SuperFilter/SuperFilterHandleButton'
import { NOOP } from '~/client/src/shared/utils/noop'

import ObjectPropertiesFilterStore from './ObjectPropertiesFilter.store'

interface IProps {
  store: ObjectPropertiesFilterStore
  forceCloseMap: { [filterType: string]: boolean }
  eventsStore: DesktopEventStore
}

@observer
export default class ObjectPropertiesFilter extends React.Component<IProps> {
  private readonly clearPostEventCallback: () => void = NOOP

  public constructor(props: IProps) {
    super(props)

    props.store.syncFilters()

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      props.store.onObjectUpdated,
    )
  }

  public componentDidMount() {
    this.closeAllFilterPopups()
  }

  public componentDidUpdate() {
    const { forceCloseMap, eventsStore } = this.props

    if (forceCloseMap) {
      const { fieldsMap } = eventsStore.appState.objectFilters
      Object.keys(fieldsMap).forEach(filterType => {
        if (forceCloseMap[filterType]) {
          fieldsMap[filterType].isFilterShown = false
        }
      })
    }
  }

  public render() {
    return (
      <div className="row full-height location-filter x-start no-grow">
        {this.renderHandleButtons()}
        {this.renderPopups()}
      </div>
    )
  }

  public componentWillUnmount() {
    this.clearPostEventCallback()
  }

  private renderPopups() {
    return Object.keys(this.storesMap).map(filterType => {
      const store = this.storesMap[filterType]
      return <SuperFilter key={filterType} filterStore={store} />
    })
  }

  private renderHandleButtons() {
    return Object.keys(this.storesMap).map(filterType => {
      const store = this.storesMap[filterType]
      return (
        <SuperFilterHandleButton
          className="no-grow"
          key={filterType}
          filterStore={store}
        />
      )
    })
  }

  @action.bound
  private closeAllFilterPopups() {
    const { fieldsMap } = this.props.eventsStore.appState.objectFilters
    Object.keys(fieldsMap).forEach(filterType => {
      fieldsMap[filterType].isFilterShown = false
    })
  }

  private get storesMap() {
    return this.props.store.filterStoresByTypeMap
  }
}
