import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import SitemapItemsColorPicker from '~/client/src/desktop/components/SitemapItemsColorPicker/SitemapItemsColorPicker'
import TwoMonthsDatePickerStore from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import MapViewSetUpStore from '~/client/src/desktop/views/ProjectSetUp/components/AppsSitemap/MapViewSetUp.store'
import ParentsSelector from '~/client/src/desktop/views/ProjectSetUp/components/AppsSitemap/components/PropertiesPanel/ParentSelector'
import EditableLabel from '~/client/src/desktop/views/ProjectSetUp/components/AppsSitemap/components/dialogs/ViewSelectDialog/components/EditableLabel'
import * as Icons from '~/client/src/shared/components/Icons'
import { Loader } from '~/client/src/shared/components/Loader'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import OpeningIndicatorLabel from '~/client/src/shared/components/OpeningIndicatorLabel/OpeningIndicatorLabel'
import PermitTypeIcon from '~/client/src/shared/components/PermitTypeIcon/PermitTypeIcon'
import Location from '~/client/src/shared/components/SitemapAttributeSelector/Location'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import TableSeparators from '~/client/src/shared/components/TableSeparators'
import * as TagIcon from '~/client/src/shared/components/TagIcon'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Announcement from '~/client/src/shared/models/Announcement'
import Closure from '~/client/src/shared/models/Closure'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import AnnouncementsStore from '~/client/src/shared/stores/domain/Announcements.store'
import ClosuresStore from '~/client/src/shared/stores/domain/Closures.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import LevelsStore from '~/client/src/shared/stores/domain/Levels.store'
import {
  FORBIDDEN_SITE_NAME,
  FORBIDDEN_SITE_NAME_MESSAGE,
} from '~/client/src/shared/stores/domain/LocationBase.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SyncRestrictionsStore from '~/client/src/shared/stores/domain/SyncRestrictions.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { NOOP } from '~/client/src/shared/utils/noop'

import { ColumnKey } from '../../ObjectsList.store'
import ObjectCellStore from './ObjectCell.store'
import CellWrapper from './components/CellWrapper/CellWrapper'
import PermittedCompaniesPickerModal from './components/PermittedCompaniesPickerModal/PermittedCompaniesPickerModal'

import './ObjectCell.scss'

// translated

export const FIXED_CELL_WIDTH = 205
const FIXED_SMALL_WIDTH = 80

interface IProps {
  obj: LocationAttributeBase
  level: number
  onChange: () => void
  datePickerStore: TwoMonthsDatePickerStore
  paddingY: number
  columnKey: ColumnKey
  onAddAnnouncement: (location: LocationAttributeBase) => void
  mapViewSetUpStore: MapViewSetUpStore
  onObjectDuplicate: (obj: LocationAttributeBase) => void
  onObjectDelete: (obj: LocationAttributeBase) => void
  onPermitClick: (id: string) => void
  onAnnouncementClick: (announcement: Announcement) => void

  toggleOldClosures: (id: string) => void
  shouldShowOldClosures: boolean
  sitemapItemsStore?: SitemapItemsStore
  state?: DesktopInitialState
  tagsStore?: TagsStore
  projectDateStore?: ProjectDateStore
  syncRestrictionsStore?: SyncRestrictionsStore
  permitTypesStore?: PermitTypesStore
  levelsStore?: LevelsStore
  companiesStore?: CompaniesStore
  closuresStore?: ClosuresStore
  sitePermitsStore?: SitePermitsStore
  announcementsStore?: AnnouncementsStore
}
enum propKeys {
  maxBookingDuration = 'maxBookingDuration',
  maxOverlappingDeliveries = 'maxOverlappingDeliveries',
}

export class ObjectCellBase extends React.Component<IProps> {
  protected static renderPermittedCompanies(
    hasCompanyRestriction: boolean,
    permittedCompanies: string[],
  ) {
    if (!hasCompanyRestriction) {
      return Localization.translator.allCompanies
    }

    return permittedCompanies.map(company => (
      <div key={company} className="row" title={company}>
        <TagIcon.Company className="no-grow mr4" />
        <div className="text-ellipsis">{company}</div>
      </div>
    ))
  }

  protected readonly store: ObjectCellStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new ObjectCellStore(
      props.tagsStore,
      props.projectDateStore,
      props.closuresStore,
      props.datePickerStore,
      props.onChange,
      props.obj,
      props.mapViewSetUpStore.mapViewItemsSetupStore,
      props.syncRestrictionsStore,
      props.mapViewSetUpStore.sitemapsSetupStore,
    )
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const { obj } = this.props

    if (prevProps.obj !== obj) {
      this.store.reinit(obj)
    }
  }

  public render() {
    const { obj, columnKey, closuresStore, shouldShowOldClosures } = this.props
    const {
      shouldShowBookingDurationModal,
      shouldShowOverlappingBookingsModal,
      hideBookingDurationModal,
      hideOverlappingBookingsModal,
      toggleBookingDurationModal,
      toggleOverlappingBookingsModal,
      setBookingDurationValue,
      setOverlappingBookingsValue,
    } = this.store
    const { addDuration, addNumber } = Localization.translator
    const { id, maxBookingDuration, maxOverlappingDeliveries } = obj
    const activeClosedIntervals = closuresStore.getLocationActiveClosures(id)
    const inactiveClosedIntervals =
      closuresStore.getLocationInactiveClosures(id)

    switch (columnKey) {
      case ColumnKey.name:
        return this.renderNameCell()
      case ColumnKey.status:
        return this.renderStatusCell(obj)
      case ColumnKey.levelsToClose:
        return this.renderLevelsToCloseCell()
      case ColumnKey.permittedCompanies:
        return this.renderCompaniesCell(obj)
      case ColumnKey.operatingIntervals:
        return (
          <div className="full-height">
            {this.renderCellWithOperatingIntervals()}
          </div>
        )
      case ColumnKey.closedIntervals:
        return this.renderClosedIntervals()
      case ColumnKey.associatedPermits:
        return (
          <div className="col pt28 full-height full-width">
            {shouldShowOldClosures &&
              this.renderCellWithPermits(inactiveClosedIntervals)}
            {this.renderCellWithPermits(activeClosedIntervals)}
          </div>
        )
      case ColumnKey.associatedAnnouncements:
        return (
          <div className="col pt28 full-height full-width">
            {shouldShowOldClosures &&
              this.renderCellWithAnnouncements(inactiveClosedIntervals)}
            {this.renderCellWithAnnouncements(activeClosedIntervals)}
          </div>
        )
      case ColumnKey.maxBookingDuration:
        return this.renderCellWithNumber(
          maxBookingDuration,
          addDuration,
          shouldShowBookingDurationModal,
          setBookingDurationValue,
          hideBookingDurationModal,
          toggleBookingDurationModal,
          propKeys.maxBookingDuration,
        )
      case ColumnKey.maxOverlappingDeliveries:
        return this.renderCellWithNumber(
          maxOverlappingDeliveries,
          addNumber,
          shouldShowOverlappingBookingsModal,
          setOverlappingBookingsValue,
          hideOverlappingBookingsModal,
          toggleOverlappingBookingsModal,
          propKeys.maxOverlappingDeliveries,
        )
      default:
        return null
    }
  }

  protected renderNameCellContent() {
    const { obj } = this.props
    return <Location dto={obj} tagClassName="full-width" />
  }

  protected renderNameCell(): JSX.Element {
    const { obj, level, mapViewSetUpStore } = this.props
    const { isUpdatingSitemapLoaderShown } = mapViewSetUpStore

    const onCellClick = isUpdatingSitemapLoaderShown
      ? NOOP
      : this.store.showMenu

    return (
      <>
        <TableSeparators
          level={level}
          className="pl10 no-grow full-height br-palette-grey"
        />
        <CellWrapper
          paddingY={this.props.paddingY}
          className="row full-height full-width px12 name-cell"
          onClick={onCellClick}
        >
          {this.store.isMenuOpened ? (
            this.renderObjectEditModal(obj)
          ) : (
            <>
              <div className="full-height full-width overflow-hidden">
                {this.renderNameCellContent()}
              </div>
              <Icon
                className="additional-properties-icon mr10 full-height"
                icon={IconNames.CHEVRON_DOWN}
              />
            </>
          )}
        </CellWrapper>
      </>
    )
  }

  protected renderObjectEditModal(obj: LocationAttributeBase): JSX.Element {
    const {
      onObjectDelete,
      onObjectDuplicate,
      mapViewSetUpStore: {
        mapViewItemsSetupStore,
        isUpdatingSitemapLoaderShown,
      },
    } = this.props
    const {
      updateObjectParent,
      updateObjectName,
      hideMenu,
      draftName,
      saveNameAndHideMenu,
    } = this.store

    if (isUpdatingSitemapLoaderShown) {
      return (
        <MenuCloser closeMenu={hideMenu}>
          <div className="name-cell opened text brada4 ba-palette-grey pa10">
            <Loader />
          </div>
        </MenuCloser>
      )
    }

    return (
      <MenuCloser closeMenu={saveNameAndHideMenu}>
        <div
          className="name-cell opened text brada4 ba-palette-grey"
          onClick={this.stopPropagation}
        >
          <div className="relative">
            <div className="name-cell-content full-height scrollable pa10">
              <div className="row x-end">
                <Icons.Duplicate
                  className="no-grow mx10 action-button"
                  onClick={onObjectDuplicate.bind(this, obj)}
                />
                <Icons.Delete
                  className="no-grow mx10 action-button"
                  onClick={onObjectDelete.bind(this, obj)}
                />
                <Icon
                  className="no-grow mx10 action-button"
                  icon={IconNames.CHEVRON_DOWN}
                  onClick={hideMenu}
                />
              </div>
              <StruxhubInput
                label={Localization.translator.objectName}
                isRequired={true}
                isChanged={draftName !== obj.name}
                value={draftName}
                isValid={draftName !== FORBIDDEN_SITE_NAME}
                validationMessage={FORBIDDEN_SITE_NAME_MESSAGE}
                onBlur={updateObjectName}
                onChange={this.handleNameChange}
              />
              <ParentsSelector
                label={Localization.translator.objectNestedUnder}
                store={mapViewItemsSetupStore}
                updateObjectParent={updateObjectParent}
              />
              <div className="no-grow pr12">
                <div className="text pb4">
                  {Localization.translator.iconColor}
                </div>
                <div>
                  <SitemapItemsColorPicker
                    value={obj.color}
                    onChange={this.store.updateObjectItemColor}
                  />
                </div>
              </div>
              {this.renderAdditionalEditControls()}
            </div>
          </div>
        </div>
      </MenuCloser>
    )
  }

  protected renderAdditionalEditControls() {
    // overwritten
    return null
  }

  protected renderLevelsToCloseCell() {
    // overwritten
    return null
  }

  protected renderStatusCell(obj: LocationAttributeBase): JSX.Element {
    const isOpen = this.props.closuresStore.isLocationOpen(obj.id)

    return (
      <CellWrapper
        paddingY={this.props.paddingY}
        style={{ maxWidth: FIXED_SMALL_WIDTH, minWidth: FIXED_SMALL_WIDTH }}
      >
        {this.renderAdditionalStatusCell()}
        <div className="mt10">
          <OpeningIndicatorLabel
            closeLabel={Localization.translator.closed}
            openLabel={Localization.translator.opened}
            isOpen={isOpen}
          />
        </div>
      </CellWrapper>
    )
  }

  protected renderAdditionalStatusCell() {
    // overwritten
    return null
  }
  protected renderCellWithPermits(closures: Closure[]) {
    const {
      onPermitClick,
      projectDateStore: { isThisYear },
      permitTypesStore,
    } = this.props

    return (
      <CellWrapper paddingY={0} className="col full-height px12 no-grow pt28">
        <div className="col">
          {closures.map(closure => {
            const interval = closure.closureInterval
            const isAnotherYear =
              !isThisYear(interval.endDate) || !isThisYear(interval.startDate)
            if (closure.associatedPermitId) {
              const permit = this.props.sitePermitsStore.getFormById(
                closure.associatedPermitId,
              )

              if (!permit) return

              return (
                <div
                  key={closure.id}
                  title={permit.getCaption(permitTypesStore)}
                  className={classList({
                    'row interval-wrapper': true,
                    'another-year': isAnotherYear,
                  })}
                  onClick={onPermitClick.bind(this, permit.id)}
                >
                  <div className="row pointer text-ellipsis">
                    <PermitTypeIcon
                      permitType={permit.getTypeOfPermitType(permitTypesStore)}
                      className="permit-type-icon row no-grow mr4"
                    />
                    <span className="text ellipsis monospace large">
                      {permit.code || Localization.translator.notSpecified}
                    </span>
                    <Icon icon={IconNames.CHEVRON_RIGHT} />
                  </div>
                </div>
              )
            } else {
              return (
                <div
                  key={closure.id}
                  className={classList({
                    'row interval-wrapper': true,
                    'another-year': isAnotherYear,
                  })}
                />
              )
            }
          })}
        </div>
      </CellWrapper>
    )
  }

  protected renderCellWithAnnouncements(closures: Closure[]) {
    const {
      onAnnouncementClick,
      projectDateStore: { isThisYear },
    } = this.props

    return (
      <CellWrapper paddingY={0} className="col full-height px12 no-grow pt28">
        <div className="col">
          {closures.map(closure => {
            const interval = closure.closureInterval
            const isAnotherYear =
              !isThisYear(interval.endDate) || !isThisYear(interval.startDate)
            if (closure.associatedAnnouncementId) {
              const announcement = this.props.announcementsStore.byId.get(
                closure.associatedAnnouncementId,
              )

              if (!announcement) return

              return (
                <div
                  key={closure.id}
                  title={'announcement'}
                  className={classList({
                    'row interval-wrapper': true,
                    'another-year': isAnotherYear,
                  })}
                  onClick={onAnnouncementClick.bind(this, announcement)}
                >
                  <div className="row pointer text-ellipsis">
                    <Icons.Megaphone className="no-grow" />
                    <span className="text ellipsis monospace large">
                      {announcement.title ||
                        Localization.translator.notSpecified}
                    </span>
                    <Icon icon={IconNames.CHEVRON_RIGHT} />
                  </div>
                </div>
              )
            } else {
              return (
                <div
                  key={closure.id}
                  className={classList({
                    'row interval-wrapper': true,
                    'another-year': isAnotherYear,
                  })}
                />
              )
            }
          })}
        </div>
      </CellWrapper>
    )
  }

  protected renderCellWithOperatingIntervals(): JSX.Element {
    const { operatingIntervals } = this.props.obj
    const { isThisYear } = this.props.projectDateStore
    const { removeOperatingInterval, formatOperatingInterval } = this.store

    return (
      <CellWrapper paddingY={0} className="col px12">
        <div className="col">
          {operatingIntervals.map((interval, i) => {
            const formattedInterval = formatOperatingInterval(interval)
            const isAnotherYear =
              !isThisYear(interval.endDate) || !isThisYear(interval.startDate)
            const renderRemoveIcon = i < operatingIntervals.length

            return (
              <div
                key={i}
                title={formattedInterval}
                className={classList({
                  'row interval-wrapper': true,
                  'another-year': isAnotherYear,
                })}
              >
                <div className="text monospace large w-fit-content">
                  {formattedInterval}
                  {renderRemoveIcon && (
                    <Icon
                      className="pointer"
                      icon={IconNames.SMALL_CROSS}
                      onClick={removeOperatingInterval.bind(null, interval)}
                    />
                  )}
                </div>
              </div>
            )
          })}
        </div>
        {this.operatingIntervalsBtnsRenderer()}
      </CellWrapper>
    )
  }

  protected renderCellWithClosure(
    closures: Closure[],
    areActionButtonsVisible: boolean = true,
  ): JSX.Element {
    const {
      projectDateStore: { isThisYear },
      shouldShowOldClosures,
    } = this.props
    const { formatClosureInterval, removeClosure, isClosureIntervalActive } =
      this.store

    return (
      <CellWrapper paddingY={0} className="col px12">
        <div className="col">
          {shouldShowOldClosures
            ? this.renderOldAdditionalIntervals()
            : this.renderUpToDateAdditionalIntervals()}
          {closures.map((closure, i) => {
            const interval = closure.closureInterval
            const formattedInterval = formatClosureInterval(interval)
            const isAnotherYear =
              !isThisYear(interval.endDate) || !isThisYear(interval.startDate)
            const renderRemoveIcon =
              !closure.associatedAnnouncementId && !closure.associatedPermitId

            return (
              <div
                key={i}
                title={formattedInterval}
                className={classList({
                  'row interval-wrapper': true,
                  active: isClosureIntervalActive(interval),
                  'another-year': isAnotherYear,
                })}
              >
                <div className="text monospace large w-fit-content">
                  {formattedInterval}
                  {renderRemoveIcon && (
                    <Icon
                      className="pointer"
                      icon={IconNames.SMALL_CROSS}
                      onClick={removeClosure.bind(null, closure)}
                    />
                  )}
                </div>
              </div>
            )
          })}
        </div>
        {areActionButtonsVisible && this.renderIntervalActionButtons()}
      </CellWrapper>
    )
  }

  protected renderUpToDateAdditionalIntervals = (): JSX.Element[] => {
    // overwritten
    return null
  }

  protected renderOldAdditionalIntervals = (): JSX.Element[] => {
    // overwritten
    return null
  }

  protected onAddAnnouncement = () => {
    const { obj, onAddAnnouncement } = this.props
    onAddAnnouncement(obj)
  }

  protected renderCellWithNumber(
    value: number,
    buttonTitle: string,
    shouldShowModal: boolean,
    onApply: (propKey: string, value: string) => void,
    onClose: () => void,
    toggleModal: () => void,
    propKey: string,
  ): JSX.Element {
    return (
      <CellWrapper
        paddingY={this.props.paddingY}
        style={{ maxWidth: FIXED_CELL_WIDTH, minWidth: FIXED_CELL_WIDTH }}
      >
        {!!value && (
          <div className="col">
            <EditableLabel
              type="number"
              min={1}
              text={value.toString()}
              update={onApply.bind(this, propKey)}
              remove={onApply.bind(this, propKey, 0)}
            />
          </div>
        )}
        {!value && this.renderAddControl(buttonTitle, toggleModal)}

        {shouldShowModal &&
          this.renderAddNumberModal(onApply, onClose, propKey)}
      </CellWrapper>
    )
  }

  protected renderAddNumberModal(
    onApply: (propKey: string, value: string) => void,
    onClose: () => void,
    propKey: string,
  ): JSX.Element {
    return (
      <div className="relative">
        <MenuCloser closeMenu={onClose}>
          <div className="absolute-block bg-white add-number-menu px10">
            <StruxhubInput
              isMinimalisticMode={true}
              min={1}
              value={this.store.numberValue.toString()}
              onChange={this.setNumberValueFromEvent}
              type="number"
            />
            <div className="tm-dp-btn-container">
              <div className="tm-dp-btn text" onClick={onClose}>
                {Localization.translator.cancel}
              </div>
              <div
                className="tm-dp-btn text bold-font"
                onClick={onApply.bind(this, propKey, this.store.numberValue)}
              >
                {Localization.translator.apply}
              </div>
            </div>
          </div>
        </MenuCloser>
      </div>
    )
  }

  protected renderAddControl(title: string, onClick: () => void) {
    return (
      <div
        className="text bold add-button-text blue-highlight pa10 brada10 w-fit-content pointer"
        onClick={onClick}
      >
        {title}
      </div>
    )
  }

  protected setNumberValueFromEvent = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    this.store.numberValue = Number(event.target.value)
  }

  protected stopPropagation = (event: React.SyntheticEvent) => {
    if (event) {
      event.stopPropagation()
    }
  }

  private renderCompaniesCell({
    hasAnyCompanyRestriction,
    permittedCompanies,
    getAllPermittedCompaniesNames,
  }: LocationAttributeBase): JSX.Element {
    const {
      permitCompanies,
      hidePermittedCompaniesModal,
      togglePermittedCompaniesModal,
      shouldShowPermittedCompaniesModal,
    } = this.store

    const permittedCompaniesNames = getAllPermittedCompaniesNames(
      this.props.companiesStore,
    )

    const { addCompany, restrictAccess } = Localization.translator

    let buttonTitle

    if (hasAnyCompanyRestriction) {
      buttonTitle = addCompany
    } else {
      buttonTitle = restrictAccess
    }

    return (
      <CellWrapper
        paddingY={this.props.paddingY}
        style={{ maxWidth: FIXED_CELL_WIDTH, minWidth: FIXED_CELL_WIDTH }}
      >
        <div className="col">
          {ObjectCellBase.renderPermittedCompanies(
            hasAnyCompanyRestriction,
            permittedCompaniesNames,
          )}
        </div>
        {this.renderAddControl(buttonTitle, togglePermittedCompaniesModal)}

        {shouldShowPermittedCompaniesModal && (
          <PermittedCompaniesPickerModal
            permittedCompanies={permittedCompanies}
            onClose={hidePermittedCompaniesModal}
            onPermit={permitCompanies}
          />
        )}
      </CellWrapper>
    )
  }

  private renderClosedIntervals() {
    const { obj, closuresStore, shouldShowOldClosures, toggleOldClosures } =
      this.props
    const icon = shouldShowOldClosures
      ? IconNames.CARET_DOWN
      : IconNames.CARET_RIGHT

    const inactiveClosures = closuresStore.getLocationInactiveClosures(obj.id)
    const activeClosures = closuresStore.getLocationActiveClosures(obj.id)

    return (
      <div className="full-height">
        <div
          className="row pointer px20 pt10"
          onClick={toggleOldClosures.bind(null, obj.id)}
        >
          {Localization.translator.closureHistory}
          <Icon icon={icon} />
        </div>
        {shouldShowOldClosures &&
          this.renderCellWithClosure(inactiveClosures, false)}
        {this.renderCellWithClosure(activeClosures)}
      </div>
    )
  }

  private operatingIntervalsBtnsRenderer = (): JSX.Element => {
    return this.renderAddControl(
      Localization.translator.addHours,
      this.store.showOperatingIntervalPicker,
    )
  }

  private renderIntervalActionButtons = (): JSX.Element => {
    const { addClosureDate, addSiteAnnouncement, addDailyClosure } =
      Localization.translator
    const { showClosureDatePicker, showDailyClosurePicker } = this.store

    return (
      <>
        {this.renderAddControl(addClosureDate, showClosureDatePicker)}
        {this.renderAddControl(addDailyClosure, showDailyClosurePicker)}
        {this.renderAddControl(addSiteAnnouncement, this.onAddAnnouncement)}
      </>
    )
  }

  private handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.store.setObjectName(e.target.value)
  }
}

export const objectCellInjects = [
  'tagsStore',
  'projectDateStore',
  'state',
  'sitemapItemsStore',
  'syncRestrictionsStore',
  'permitTypesStore',
  'levelsStore',
  'companiesStore',
  'closuresStore',
  'sitePermitsStore',
  'announcementsStore',
]

@inject(...objectCellInjects)
@observer
export default class ObjectCell extends ObjectCellBase {}
