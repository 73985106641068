import { action, computed } from 'mobx'

import { DeliveryFilterType } from '~/client/graph'
import { ICompactRow } from '~/client/src/desktop/components/CompactDeliveriesList/CompactDeliveriesList'
import InspectionStates from '~/client/src/desktop/enums/InspectionStates'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import {
  ILWFCCategory,
  ILWFCRow,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'
import { CancelationReasonNames } from '~/client/src/shared/enums/CancelationReason'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import {
  deliveryFilterTypes,
  extendedDeliveryFilterTypes,
} from '~/client/src/shared/enums/DeliveryFilterType'
import DeliveryGroupingOption from '~/client/src/shared/enums/DeliveryGroupingOption'
import Delivery from '~/client/src/shared/models/Delivery'
import Material from '~/client/src/shared/models/Material'
import User from '~/client/src/shared/models/User'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveryAssignmentsStore from '~/client/src/shared/stores/domain/DeliveryAssignments.store'
import MaterialCategoryStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import {
  CATEGORY_ROW_HEIGHT,
  ITreeNodeObj,
} from '~/client/src/shared/stores/ui/BaseList.store'
import BaseMultiBandListStore from '~/client/src/shared/stores/ui/BaseMultiBandList.store'
import ProjectDateStore, {
  millisecondsToTime,
} from '~/client/src/shared/stores/ui/ProjectDate.store'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import {
  commonAttributeMapper,
  commonById,
  commonByIds,
  commonIdMapper,
  groupingCommonMapper,
} from '~/client/src/shared/utils/mappers'
import { NO_SPECIFIED, NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import DesktopDeliveryViewStore from '../../Deliveries.store'
import DeliveriesMapStore from './DeliveriesMap.store'

// localization: no display text to translate

const HEADER_ROW_INDEX = 1
const deliveryKey = 'delivery'

export default class DeliveriesBySitemapListStore extends BaseMultiBandListStore<Delivery> {
  public get columns() {
    return []
  }

  public constructor(
    private readonly appState: DesktopInitialState,
    private readonly projectDateStore: ProjectDateStore,
    private readonly deliveryAssignmentsStore: DeliveryAssignmentsStore,
    private readonly desktopDeliveryViewStore: DesktopDeliveryViewStore,
    private readonly deliveriesMapStore: DeliveriesMapStore,
    private readonly companiesStore: CompaniesStore,
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly materialCategoryStore: MaterialCategoryStore,
    private readonly userProjectsStore: UserProjectsStore,
  ) {
    super(appState.deliveryFilters, () =>
      deliveriesMapStore.displayedSitemapDeliveries(),
    )
  }

  @action.bound
  public selectDelivery(deliveryId: string) {
    this.deliveriesMapStore.selectDelivery(deliveryId)
  }

  public get scrollToIndex(): number {
    return this.scrollToRow - HEADER_ROW_INDEX
  }

  @computed
  public get rows(): ILWFCRow[] {
    const { selectedDeliveryBandsOption } = this.appState.filters

    return this.toBandTreeNodeRows(
      selectedDeliveryBandsOption.bands,
      null,
      '',
      0,
      false,
    )
  }

  @computed
  public get compactListRows(): ICompactRow[] {
    return this.rows.map(({ data, category }) => {
      if (category) {
        return { category }
      }

      return { delivery: data.delivery }
    })
  }

  @computed
  public get categoryToInstancesMap(): { [categoryKey: string]: Delivery[] } {
    const map = {}

    this.filteredCollection.forEach(delivery => {
      const categoryId = delivery[this.groupingKey]
      const formattedCategoryId = this.formatCategoryId(categoryId, delivery)

      if (map[formattedCategoryId]) {
        map[formattedCategoryId].push(delivery)
      } else {
        map[formattedCategoryId] = [delivery]
      }
    })

    return map
  }

  public getFilteredCollectionExcludeFilter = (
    excludedFilters: Array<DeliveryFilterType | string> = [],
  ) => {
    let filteredCollection = this.isSearchFilterActive
      ? this.sourceCollection().filter(this.searchFiltering)
      : this.sourceCollection()

    this.activeFilterTypes.forEach(filterType => {
      const shouldUseFilter = !excludedFilters.includes(filterType)

      if (!shouldUseFilter) {
        return
      }

      const filter = this.filter.fieldsMap[filterType]
      const { appliedFilterOptions } = filter

      filteredCollection = filteredCollection.filter(inst =>
        appliedFilterOptions.includes(inst[this.idKey]),
      )
    })

    return filteredCollection
  }

  @computed
  public get filteredCollection() {
    const { isMyDeliveriesOnly, isMyDelivery, areCDOrdersHidden } =
      this.desktopDeliveryViewStore

    const excludedFilters = extendedDeliveryFilterTypes.filter(
      ft => !deliveryFilterTypes.includes(ft),
    )

    let filteredDeliveries =
      this.getFilteredCollectionExcludeFilter(excludedFilters)

    if (areCDOrdersHidden) {
      filteredDeliveries = filteredDeliveries.filter(
        d => !d.isFromConcreteDirect,
      )
    }

    if (!isMyDeliveriesOnly) {
      return filteredDeliveries
    }

    return filteredDeliveries.filter(isMyDelivery)
  }

  protected toRows(deliveries: Delivery[]) {
    return deliveries.map(delivery => {
      const data = {
        [deliveryKey]: delivery,
      }

      return { data }
    })
  }

  protected searchFiltering = ({ id, codeToDisplay }: Delivery) => {
    const key = this.filter.searchKey.toLowerCase()

    const deliveryCode = codeToDisplay(this.companiesStore).toLowerCase()
    if (deliveryCode.includes(key)) {
      return true
    }

    const filteredSubscribersNames = Object.keys(
      this.assigneeNameToDeliveryIdsMap,
    ).filter(name => name.toLowerCase().includes(key))

    return filteredSubscribersNames.some(name =>
      this.assigneeNameToDeliveryIdsMap[name].includes(id),
    )
  }

  protected createTreeNodeCategoryRow(
    categoryId: string,
    categoryName: string,
    categoryObject: any,
    level: number,
    objects: Delivery[],
  ): ILWFCRow {
    const total = objects.length
    const completed = objects.filter(d => d.isDone).length

    const category: ILWFCCategory = {
      categoryId,
      shortCategoryLabel: categoryName,
      categoryLabel: categoryName,
      isChecked: objects.every(
        inst =>
          !this.canSelectInstance(inst) || this.selection.get(inst[this.idKey]),
      ),
      idsToSelect: objects.map(d => d[this.idKey]),
      instancesInfo: `${completed}/${total}`,
    }

    return {
      category,
      data: categoryObject,
      height: CATEGORY_ROW_HEIGHT,
      level,
    }
  }

  protected getTreeNodeObjsByBand(currentBand: string): ITreeNodeObj[] {
    const { getMonthDayAndTimeToDisplay } = this.projectDateStore
    const {
      getMaterialsByIds,
      buildingFilterOptions,
      zoneFilterOptions,
      routeFilterOptions,
      gateFilterOptions,
      equipmentFilterOptions,
      levelFilterOptions,
      areaFilterOptions,
      companyFilterOptions,
      getVehicleTypeById,
    } = this.desktopDeliveryViewStore

    switch (currentBand) {
      case DeliveryGroupingOption[FieldIds.COMPANY]:
        return companyFilterOptions.map(commonAttributeMapper)
      case DeliveryGroupingOption[FieldIds.STATUS]:
        return Object.values(DeliveryStatus).map(groupingCommonMapper)

      case DeliveryGroupingOption[FieldIds.DATE]:
        return this.filteredCollection
          .map(d => getMonthDayAndTimeToDisplay(d.startDate))
          .map(groupingCommonMapper)

      case DeliveryGroupingOption[FieldIds.BUILDING]:
        return buildingFilterOptions.map(commonAttributeMapper)
      case DeliveryGroupingOption[FieldIds.ZONE]:
        return zoneFilterOptions.map(commonAttributeMapper)
      case DeliveryGroupingOption[FieldIds.LEVEL]:
        return levelFilterOptions.map(commonAttributeMapper)
      case DeliveryGroupingOption[FieldIds.AREA]:
        return areaFilterOptions.map(commonAttributeMapper)
      case DeliveryGroupingOption[FieldIds.GATE]:
        return gateFilterOptions.map(commonAttributeMapper)
      case DeliveryGroupingOption[FieldIds.ROUTE]:
        return routeFilterOptions.map(commonAttributeMapper)
      case DeliveryGroupingOption[FieldIds.OFFLOADING_EQUIPMENT]:
        return equipmentFilterOptions.map(commonAttributeMapper)

      case DeliveryGroupingOption[FieldIds.ON_SITE_CONTACTS]:
        return Array(
          ...new Set(
            this.filteredCollection.map(
              ({ onSiteContactPersonIds }) => onSiteContactPersonIds,
            ),
          ),
        )
          .flat()
          .map(option => {
            const user = this.projectMembersStore.getById(option)
            const fullName = User.getFullNameToDisplay(
              user,
              this.userProjectsStore,
              true,
            )
            return { id: user?.id || UNASSIGNED, name: fullName || NO_VALUE }
          })

      case DeliveryGroupingOption[FieldIds.VENDOR]:
        return companyFilterOptions.map(commonAttributeMapper)

      case DeliveryGroupingOption[FieldIds.TRUCK_SIZE]:
        return this.filteredCollection
          .map(d => d.truckSize || NO_VALUE)
          .map(truckId => {
            const vehicleTypeName = getVehicleTypeById(truckId)?.name
            return {
              name: vehicleTypeName || NO_VALUE,
              id: vehicleTypeName ? truckId : NO_SPECIFIED,
            } as ITreeNodeObj
          })

      case DeliveryGroupingOption[FieldIds.MATERIAL]:
        return this.filteredCollection
          .map(d => {
            const materials = getMaterialsByIds(d.materialIds)
            return this.getMaterialsCategory(materials)
          })
          .map(groupingCommonMapper)

      case DeliveryGroupingOption[FieldIds.DURATION]:
        return this.filteredCollection
          .map(d => millisecondsToTime(d.durationMs))
          .map(groupingCommonMapper)

      case DeliveryGroupingOption[FieldIds.CANCELATION_REASON]:
        return Object.values(CancelationReasonNames).map(reason =>
          groupingCommonMapper(reason),
        )

      case DeliveryGroupingOption[FieldIds.INSPECTION_SECTION]:
        return Object.values(InspectionStates).map(reason =>
          groupingCommonMapper(reason),
        )
    }
  }

  @computed
  protected get bandObjectMap(): {
    [bandType: string]: { [groupName: string]: Delivery[] }
  } {
    const map = {}

    const groupingOptions = Object.values(DeliveryGroupingOption).filter(
      option => option !== DeliveryGroupingOption.location,
    )

    groupingOptions.forEach(band => {
      const bandMap = (map[band] = {})

      this.filteredCollection.forEach(delivery => {
        const groupNames: string[] = this.getGroupNames(delivery, band)
        if (!groupNames.length) {
          groupNames.push(UNASSIGNED)
        }

        groupNames.forEach(groupName => {
          if (!bandMap[groupName]) {
            bandMap[groupName] = []
          }

          const index = bandMap[groupName].findIndex(
            d => d.startDate > delivery.startDate,
          )

          if (index === -1) {
            bandMap[groupName].push(delivery)
          } else {
            bandMap[groupName].splice(index, 0, delivery)
          }
        })
      })
    })

    return map
  }

  private getMaterialsCategory(materials: Material[]): string {
    return (
      materials
        .map(m => this.materialCategoryStore.getCategoryNameById(m.categoryId))
        .filter(m => m)
        .join(', ') || NO_VALUE
    )
  }

  @computed
  private get assigneeNameToDeliveryIdsMap() {
    const { assignedEntitiesByUserIdMap } = this.deliveryAssignmentsStore

    return Object.keys(assignedEntitiesByUserIdMap).reduce((acc, userId) => {
      const assignee = this.projectMembersStore.getById(userId)
      const assigneeName = User.getFullNameToDisplay(
        assignee,
        this.userProjectsStore,
      )

      acc[assigneeName] = assignedEntitiesByUserIdMap[userId]

      return acc
    }, {})
  }

  private getGroupNames(delivery: Delivery, band: string): string[] {
    const { getMonthDayAndTimeToDisplay } = this.projectDateStore
    const {
      getMaterialsByIds,
      getVehicleTypeById,
      getCompanyById,
      buildingFilterOptions,
      zoneFilterOptions,
      routeFilterOptions,
      gateFilterOptions,
      equipmentFilterOptions,
      levelFilterOptions,
      areaFilterOptions,
    } = this.desktopDeliveryViewStore

    switch (band) {
      case DeliveryGroupingOption[FieldIds.DATE]:
        return [getMonthDayAndTimeToDisplay(delivery.startDate)]
      case DeliveryGroupingOption[FieldIds.COMPANY]:
        const company = getCompanyById(delivery.company)
        return [company?.id || UNASSIGNED]
      case DeliveryGroupingOption[FieldIds.STATUS]:
        return Object.values(DeliveryStatus).filter(
          status => delivery.status === status,
        )

      case DeliveryGroupingOption[FieldIds.DURATION]:
        return [millisecondsToTime(delivery.durationMs)]

      case DeliveryGroupingOption[FieldIds.MATERIAL]:
        const materials = getMaterialsByIds(delivery.materialIds)
        return [this.getMaterialsCategory(materials) || UNASSIGNED]

      case DeliveryGroupingOption[FieldIds.VENDOR]:
        return [delivery.vendor || UNASSIGNED]
      case DeliveryGroupingOption[FieldIds.TRUCK_SIZE]:
        const truckSize = getVehicleTypeById(delivery.truckSize)
        return [truckSize?.id || NO_SPECIFIED]

      case DeliveryGroupingOption[FieldIds.BUILDING]:
        return buildingFilterOptions
          .filter(commonById(delivery.building))
          .map(commonIdMapper)
      case DeliveryGroupingOption[FieldIds.ZONE]:
        return zoneFilterOptions
          .filter(commonById(delivery.zone))
          .map(commonIdMapper)
      case DeliveryGroupingOption[FieldIds.LEVEL]:
        return levelFilterOptions
          .filter(commonById(delivery.level))
          .map(commonIdMapper)
      case DeliveryGroupingOption[FieldIds.AREA]:
        return areaFilterOptions
          .filter(commonById(delivery.area))
          .map(commonIdMapper)
      case DeliveryGroupingOption[FieldIds.GATE]:
        return gateFilterOptions
          .filter(commonById(delivery.gate))
          .map(commonIdMapper)
      case DeliveryGroupingOption[FieldIds.ROUTE]:
        return routeFilterOptions
          .filter(commonById(delivery.route))
          .map(commonIdMapper)
      case DeliveryGroupingOption[FieldIds.OFFLOADING_EQUIPMENT]:
        return equipmentFilterOptions
          .filter(commonByIds(delivery.offloadingEquipmentIds))
          .map(commonIdMapper)

      case DeliveryGroupingOption[FieldIds.ON_SITE_CONTACTS]:
        const members = this.projectMembersStore.getByIds(
          delivery.onSiteContactPersonIds,
        )
        return members.map(member => member.id || UNASSIGNED)

      case DeliveryGroupingOption[FieldIds.INSPECTION_SECTION]:
        return Object.values(InspectionStates).filter(inspectionReason =>
          delivery.status.includes(inspectionReason),
        )
      case DeliveryGroupingOption[FieldIds.CANCELATION_REASON]:
        const groupNames = Object.values(CancelationReasonNames).filter(
          cancellationReason =>
            delivery.cancellationReason === cancellationReason,
        )
        return groupNames.length ? groupNames : [CancelationReasonNames.Other]
    }
  }
}
