import { IconNames } from '@blueprintjs/icons'
import { action, computed, observable } from 'mobx'

import {
  FilterType,
  IGlobeViewSpecificItemData,
  IPosition,
  ISitemapSpecificItemData,
  LocationType,
  SitemapItemShapeType,
  UploadingType,
} from '~/client/graph'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import {
  SCALED_IMAGE_QUALITY,
  SCALED_IMAGE_TYPE,
} from '~/client/src/shared/components/BaseMapView/BaseMapView'
import { ALLOWED_SITEMAP_ITEMS_WITHOUT_DATA_OBJECT } from '~/client/src/shared/components/MapBoxEditor/MapBoxViewer.store'
import CircleShapeCoordinates from '~/client/src/shared/components/SitemapHelpers/models/CircleShapeCoordinates'
import GlobeViewCircleProperties from '~/client/src/shared/components/SitemapHelpers/models/GlobeViewCircleProperties'
import { GlobeViewShapeProperties } from '~/client/src/shared/components/SitemapHelpers/models/GlobeViewItemProperties'
import GlobeViewPolyLineProperties from '~/client/src/shared/components/SitemapHelpers/models/GlobeViewPolyLineProperties'
import GlobeViewRectangleProperties from '~/client/src/shared/components/SitemapHelpers/models/GlobeViewRectangleProperties'
import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import MapViewItemFactory from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemFactory'
import PolyLineShapeCoordinates from '~/client/src/shared/components/SitemapHelpers/models/PolyLineShapeCoordinates'
import RectangleShapeCoordinates from '~/client/src/shared/components/SitemapHelpers/models/RectangleShapeCoordinates'
import SitemapCircleProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapCircleProperties'
import MapViewItemDrawnPart from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemDrawnPart'
import { SitemapShapeProperties } from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemProperties'
import SitemapPolyLineProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapPolyLineProperties'
import SitemapRectangleProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapRectangleProperties'
import MapViewItemType from '~/client/src/shared/enums/MapViewItemType'
import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import GlobeView from '~/client/src/shared/models/GlobeView'
import HierarchyNode from '~/client/src/shared/models/HierarchyNode'
import IAccessibleLevelsAttribute from '~/client/src/shared/models/IAccessibleLevelsAttribute'
import Area from '~/client/src/shared/models/LocationObjects/Area'
import Building from '~/client/src/shared/models/LocationObjects/Building'
import Gate from '~/client/src/shared/models/LocationObjects/Gate'
import InteriorDoor from '~/client/src/shared/models/LocationObjects/InteriorDoor'
import InteriorPath from '~/client/src/shared/models/LocationObjects/InteriorPath'
import Level from '~/client/src/shared/models/LocationObjects/Level'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import LocationIntegration, {
  LocationIntegrationType,
} from '~/client/src/shared/models/LocationObjects/LocationIntegration'
import LogisticsObject from '~/client/src/shared/models/LocationObjects/LogisticsObject'
import OffloadingEquipment from '~/client/src/shared/models/LocationObjects/OffloadingEquipment'
import Route from '~/client/src/shared/models/LocationObjects/Route'
import Staging from '~/client/src/shared/models/LocationObjects/Staging'
import VerticalObject from '~/client/src/shared/models/LocationObjects/VerticalObject'
import Zone from '~/client/src/shared/models/LocationObjects/Zone'
import Sitemap from '~/client/src/shared/models/Sitemap'
import SitemapItem, {
  ShapeCoordinates,
} from '~/client/src/shared/models/SitemapItem'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import GlobeViewSetupStore from '~/client/src/shared/stores/GlobeViewSetup.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import { FileUploadingStore } from '~/client/src/shared/stores/domain/FileUploading.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SyncRestrictionsStore from '~/client/src/shared/stores/domain/SyncRestrictions.store'
import {
  sortAttributes,
  sortLevels,
} from '~/client/src/shared/utils/sortingFunctions'
import { ToastTheme, showToast } from '~/client/src/shared/utils/toaster'
import { areObjectsEqual } from '~/client/src/shared/utils/util'

import MapViewSetUpStore from '../MapViewSetUp.store'
import { ILevelDisplayInfo } from '../components/PropertiesPanel/BuildingProperties'
import SitemapsSetupStore from './SitemapsSetup.store'

const MAX_IMAGE_WIDTH = 1920
const MAX_IMAGE_HEIGHT = 1080

const { Circle, Rectangle, Polyline } = SitemapItemShapeType

export const siteLogistics = 'Site Logistics'
export const maturixStations = 'Maturix Stations'
export const projectOverviewMaps = 'Project Overview Maps'

const ALLOWED_SHAPES_BY_TYPE = {
  [LocationType.OffloadingEquipment]: [Rectangle, Polyline, Circle],
  [LocationType.Route]: [Polyline],
  [LocationType.InteriorPath]: [Polyline],
}

const DEFAULT_ALLOWED_SHAPES = [Rectangle, Polyline]

const ALLOWED_PARENTS_BY_TYPE = {
  [LocationType.Area]: [LocationType.Level, LocationType.Zone],
  [LocationType.VerticalObject]: [LocationType.Building],
  [LocationType.Level]: [LocationType.Building],
  [LocationType.Building]: [LocationType.Building],
  [LocationType.Integration]: [LocationType.Level],
}
const DEFAULT_ALLOWED_PARENTS = [
  LocationType.Building,
  LocationType.Zone,
  LocationType.Level,
  LocationType.Area,
]

const sitemapSaveEvents = [
  e.SAVE_BASEMAP,
  e.SAVE_SITEMAP_ITEM,
  e.SAVE_SITEMAP_IMAGE,
  e.SAVE_GLOBE_IMAGE,
]
const copiedLevelChildren = "Copied level's children"
const copiedSuccessfully = 'Copied Successfully'
const pastedSuccessfully = 'Pasted Successfully'
interface IActionHistory {
  selectedItem: MapViewItemBase
}

export enum ItemsCollapseState {
  collapsed,
  notCollapsed,
  transit,
}

export default class MapViewItemsSetupStore {
  @observable public isSavingAfterChanges: boolean = false
  @observable public searchKey: string = ''
  @observable public isUploading: boolean = false
  @observable public imageFile: File = null
  @observable public imageFileUrl: string = null
  @observable public selectedMapViewItem: MapViewItemBase = null
  @observable public selectedMapViewItemDrawnPart: MapViewItemDrawnPart = null

  @observable public isTextStickerCreationActive: boolean = false
  @observable public creatableAttributeType: LocationType = null
  @observable public creatableAttributeIcon: MapViewLocationIcon = null

  @observable public focusedLevel: ILevelDisplayInfo = null
  @observable public copiedShapeProperties:
    | SitemapShapeProperties
    | GlobeViewShapeProperties = null
  @observable public copiedShapeCoordinates: ShapeCoordinates = null
  @observable public copiedItem: MapViewItemBase = null
  @observable public deletableMapViewItem: MapViewItemBase
  @observable public mapViewItemForSave: MapViewItemBase
  @observable public hoveredNode: HierarchyNode = null

  @observable private hierarchyExpandState = new Map<string, boolean>()
  @observable private isSaving: boolean = false

  private actionsHistory: IActionHistory[] = []
  private historyIndex: number = -1

  public constructor(
    private readonly eventsStore: DesktopEventStore,
    private readonly sitemapItemsStore: SitemapItemsStore,
    private readonly locationAttributesStore: LocationAttributesStore,
    private readonly syncRestrictionsStore: SyncRestrictionsStore,
    private readonly activityFiltersStore: ActivityFiltersStore,
    private readonly mapViewSetUpStore: MapViewSetUpStore,
    private readonly fileUploadingStore: FileUploadingStore,
  ) {}

  @computed
  public get hierarchyList(): HierarchyNode[] {
    const nodes: HierarchyNode[] = []
    const siteLogisticsNodes: HierarchyNode[] = []
    const maturixNodes: HierarchyNode[] = []
    const siteLogisticsNode = new HierarchyNode(
      this.hierarchyExpandState,
      1,
      null,
      null,
      siteLogistics,
    )
    const maturixStationsNode = new HierarchyNode(
      this.hierarchyExpandState,
      1,
      null,
      null,
      maturixStations,
    )

    this.mapViewItems
      .filter(item => !item.hasParent)
      .forEach(item => {
        const node = new HierarchyNode(this.hierarchyExpandState, 1, item)
        if (node.item?.dataObject?.type === LocationType.Building) {
          nodes.push(node)
          node.children = this.getNodeChildren(node, nodes)
        } else if (
          item.dataObject?.is(LocationIntegrationType.MaturixStation)
        ) {
          node.parent = maturixStationsNode
          maturixNodes.push(node)
          node.children = this.getNodeChildren(node, maturixNodes)
          maturixStationsNode.children.push(node)
        } else {
          node.parent = siteLogisticsNode
          siteLogisticsNodes.push(node)
          node.children = this.getNodeChildren(node, siteLogisticsNodes)
          siteLogisticsNode.children.push(node)
        }
      })

    return [
      ...nodes,
      siteLogisticsNode,
      ...siteLogisticsNodes,
      maturixStationsNode,
      ...maturixNodes,
    ]
  }

  public setImage = async (file: File): Promise<void> => {
    this.isUploading = true
    this.imageFile = file
    this.imageFileUrl = await this.getImageUrl(file)
    this.isUploading = false
  }

  public setImageFromBrowse = async event => {
    this.isUploading = true
    const file = event.target.files[0]
    this.imageFile = file
    this.imageFileUrl = await this.getImageUrl(file)
    this.isUploading = false
  }

  @action.bound
  public setSearchKey(key: string): void {
    this.searchKey = key
  }

  public copyLevel = (item?: MapViewItemBase): void => {
    const itemToCopy = item || this.selectedMapViewItem
    if (itemToCopy?.dataObject?.type === LocationType.Level) {
      this.copiedItem = itemToCopy
      showToast(copiedLevelChildren, ToastTheme.SUCCESS, IconNames.DUPLICATE)
    }
  }

  public pasteLevel = async (item?: MapViewItemBase): Promise<void> => {
    const itemToPaste = item || this.selectedMapViewItem
    if (
      itemToPaste?.dataObject?.type === LocationType.Level &&
      !!this.copiedItem
    ) {
      await this.saveItemChildren(this.copiedItem, itemToPaste)

      showToast(pastedSuccessfully, ToastTheme.SUCCESS, IconNames.TICK)
    }
  }

  public copy = (): void => {
    if (this.isGlobeMode) {
      this.copiedShapeProperties = this.setGlobeViewShapeProperties(
        this.selectedMapViewItem.sitemapItemProperties.shapeProperties,
      )
      this.copiedShapeCoordinates = this.setGlobeViewShapeCoordinates(
        this.selectedMapViewItem.sitemapItem.shapeCoordinates,
      )
    } else {
      const { iconProperties } = this.selectedMapViewItem.sitemapItemProperties
      const x = -iconProperties?.position?.x || 0
      const y = -iconProperties?.position?.y || 0
      this.copiedShapeProperties = this.setSitemapShapeProperties(
        this.selectedMapViewItem.sitemapItemProperties.shapeProperties,
        x,
        y,
      )
    }

    showToast(copiedSuccessfully, ToastTheme.SUCCESS, IconNames.TICK)
  }

  public paste = (): void => {
    if (this.isGlobeMode) {
      this.selectedMapViewItem.globeViewItemProperties.shapeProperties =
        this.setGlobeViewShapeProperties(
          this.copiedShapeProperties as GlobeViewShapeProperties,
        )
      this.selectedMapViewItem.sitemapItem.shapeCoordinates =
        this.setGlobeViewShapeCoordinates(this.copiedShapeCoordinates)
    } else {
      const { iconProperties } = this.selectedMapViewItem.sitemapItemProperties
      const x = iconProperties?.position?.x || 0
      const y = iconProperties?.position?.y || 0
      this.selectedMapViewItem.sitemapItemProperties.shapeProperties =
        this.setSitemapShapeProperties(
          this.copiedShapeProperties as SitemapShapeProperties,
          x,
          y,
        )
    }
    showToast(pastedSuccessfully, ToastTheme.SUCCESS, IconNames.TICK)
  }

  @computed
  public get sitemapHierarchyTree(): HierarchyNode[] {
    const hierarchy: HierarchyNode[] = []
    const siteOverviewMap = new HierarchyNode(
      this.hierarchyExpandState,
      1,
      null,
      null,
      projectOverviewMaps,
    )
    const siteLogisticsNode = new HierarchyNode(
      this.hierarchyExpandState,
      1,
      null,
      null,
      siteLogistics,
    )
    const maturixStationsNode = new HierarchyNode(
      this.hierarchyExpandState,
      1,
      null,
      null,
      maturixStations,
    )

    this.mapViewItems
      .filter(item => !item.hasParent)
      .forEach(item => {
        const node = new HierarchyNode(this.hierarchyExpandState, 1, item)
        const dataObject = node.item?.dataObject
        node.children = this.getNodeChildren(node)

        if (dataObject?.type === LocationType.Building) {
          hierarchy.push(node)
        } else if (dataObject?.is(LocationIntegrationType.MaturixStation)) {
          maturixStationsNode.children.push(node)
        } else if (node.item?.dataObject) {
          siteLogisticsNode.children.push(node)
        } else {
          siteOverviewMap.children.push(node)
        }
      })

    return [
      siteOverviewMap,
      ...hierarchy,
      siteLogisticsNode,
      maturixStationsNode,
    ]
  }

  public getItemById = (id: string): SitemapItem => {
    return this.sitemapItemsStore.byId.get(id)
  }

  @computed
  public get displayedSitemapItems(): MapViewItemBase[] {
    return this.mapViewItems.filter(
      i => i.isDisplayed && !this.isMapViewItemSelected(i),
    )
  }

  public toggleItemsCollapsingState = (): void => {
    if (this.hierarchyExpandState.size > 0) {
      this.hierarchyExpandState.clear()
    } else {
      this.mapViewItems.forEach(item => {
        this.hierarchyExpandState.set(item.id, true)
      })
      this.hierarchyExpandState.set(siteLogistics, true)
      this.hierarchyExpandState.set(maturixStations, true)
    }
  }

  public get itemsCollapseState(): ItemsCollapseState {
    switch (true) {
      case this.hierarchyExpandState.size === 0:
        return ItemsCollapseState.notCollapsed
      case this.hierarchyExpandState.size === this.mapViewItems.length + 1:
        return ItemsCollapseState.collapsed
      default:
        return ItemsCollapseState.transit
    }
  }

  public get globeView(): GlobeView {
    return this.globeViewSetupStore.selectedGlobeView
  }

  public getAllowedShapesForType = (
    type: LocationType,
  ): SitemapItemShapeType[] => {
    return ALLOWED_SHAPES_BY_TYPE[type] || DEFAULT_ALLOWED_SHAPES
  }

  @computed
  public get mapViewItems(): MapViewItemBase[] {
    if (this.globeView) {
      const items: MapViewItemBase[] = []

      this.hierarchyAttributes.forEach(dataObject => {
        const assignedItems = this.sitemapItemsStore.list.filter(s =>
          s.isAssignedTo(dataObject),
        )

        if (!assignedItems?.length) {
          const sitemapItem = this.getDefaultSitemapItemForAttribute(dataObject)

          items.push(
            MapViewItemFactory.fromGlobeItem(
              dataObject.copy(),
              sitemapItem.copy(),
              this.globeView,
            ),
          )

          return
        }

        const convertedItems: MapViewItemBase[] = assignedItems.map(item => {
          return MapViewItemFactory.fromGlobeItem(
            dataObject.copy(),
            item.copy(),
            this.globeView,
          )
        })

        items.push(
          convertedItems.reduce((prevVal, current) =>
            current?.isDisplayed ? current : prevVal,
          ),
        )
      })

      this.sitemapItemsStore.list
        .filter(
          item =>
            !item.isAssigned &&
            this.hasAnyDisplayData(item) &&
            ALLOWED_SITEMAP_ITEMS_WITHOUT_DATA_OBJECT.includes(item.type),
        )
        .forEach(item => {
          items.push(
            MapViewItemFactory.fromGlobeItem(null, item.copy(), this.globeView),
          )
        })

      if (this.selectedMapViewItem && !this.selectedMapViewItem.id) {
        items.push(this.selectedMapViewItem)
      }

      return items
    }

    if (!this.whiteboard) {
      return []
    }

    const items: MapViewItemBase[] = []

    this.hierarchyAttributes.forEach(dataObject => {
      const assignedItems = this.sitemapItemsStore.list.filter(s =>
        s.isAssignedTo(dataObject),
      )

      if (!assignedItems?.length) {
        const sitemapItem = this.getDefaultSitemapItemForAttribute(dataObject)

        items.push(
          MapViewItemFactory.fromSitemapItem(
            dataObject.copy(),
            sitemapItem.copy(),
            this.whiteboard,
          ),
        )

        return
      }

      const convertedItems: MapViewItemBase[] = assignedItems.map(item => {
        return MapViewItemFactory.fromSitemapItem(
          dataObject.copy(),
          item.copy(),
          this.whiteboard,
        )
      })

      if (convertedItems.length) {
        items.push(
          convertedItems.reduce((prevVal, current) =>
            current?.isDisplayed ? current : prevVal,
          ),
        )
      }
    })

    this.sitemapItemsStore.list
      .filter(
        item =>
          !item.isAssigned &&
          ALLOWED_SITEMAP_ITEMS_WITHOUT_DATA_OBJECT.includes(item.type) &&
          this.hasAnyDisplayData(item),
      )
      .forEach(item => {
        items.push(
          MapViewItemFactory.fromSitemapItem(
            null,
            item.copy(),
            this.whiteboard,
          ),
        )
      })

    if (
      this.selectedMapViewItem &&
      !this.selectedMapViewItem.id &&
      !this.isSitemapBlocked
    ) {
      items.push(this.selectedMapViewItem)
    }

    return items
  }

  @computed
  public get selectedSitemapItemAllowedParents(): LocationBase[] {
    if (!this.selectedMapViewItem) {
      return
    }

    const { dataObject } = this.selectedMapViewItem

    let allowedAttributes = this.hierarchyAttributes.filter(dto =>
      this.allowedTypes.includes(dto.type),
    )

    if (dataObject && dataObject?.type === LocationType.Building) {
      allowedAttributes = allowedAttributes.filter(
        a => a.id !== dataObject.id && !a.hasParent,
      )
    }
    return allowedAttributes
  }

  @computed
  public get selectedSitemapItemRestrictedParents(): LocationBase[] {
    if (!this.selectedMapViewItem) {
      return
    }

    const { dataObject } = this.selectedMapViewItem
    return this.hierarchyAttributes.filter(
      dto =>
        !this.allowedTypes.includes(dto.type) ||
        dto.id === dataObject?.id ||
        (dataObject?.type === LocationType.Building && dto.hasParent),
    )
  }

  public isMapViewItemSelected = (item: MapViewItemBase): boolean => {
    return this.selectedMapViewItem && this.selectedMapViewItem.id === item.id
  }

  @action.bound
  public async selectMapViewItem(item: MapViewItemBase): Promise<void> {
    if (!item) {
      this.safelyDeselectMapViewItem()
      return
    }
    if (!this.isMapViewItemSelected(item)) {
      if (
        !this.selectedMapViewItem ||
        !this.selectedMapViewItem.isValid(this.isGlobeMode)
      ) {
        this.deselectMapViewItem()
      } else {
        await this.saveSelectedMapViewItem()
      }
    }
    if (this.creatableAttributeType) {
      this.disableCreatingAttribute()
    }

    this.selectedMapViewItem = item.copy()
    if (
      this.selectedMapViewItem.sitemapItem.shapeCoordinates &&
      this.selectedMapViewItem.globeViewItemProperties.shapeProperties?.type ===
        SitemapItemShapeType.Polyline
    ) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;(
        this.selectedMapViewItem.globeViewItemProperties
          .shapeProperties as GlobeViewPolyLineProperties
      ).isClosed =
        this.selectedMapViewItem.sitemapItem.shapeCoordinates?.isClosed
    }
    if (!this.selectedMapViewItem.isDisplayed) {
      this.selectedMapViewItem.fillDataBeforeDisplayingFromSitemaps(
        this.allWhiteboards,
      )
    }
    this.selectSitemapItemDrawnPartIfRequired()
    this.initActionsHistory()
  }

  public selectSitemapItemDrawnPartIfRequired(): void {
    const {
      id,
      sitemapItem: { type: sitemapItemType },
    } = this.selectedMapViewItem
    const {
      iconProperties,
      labelProperties,
      shapeProperties,
      hasMultipleParts,
    } = this.isGlobeMode
      ? this.selectedMapViewItem.globeViewItemProperties
      : this.selectedMapViewItem.sitemapItemProperties

    if (hasMultipleParts || (id && sitemapItemType === MapViewItemType.Line)) {
      return
    }
    if (iconProperties) {
      this.selectSitemapItemDrawnPart(MapViewItemDrawnPart.Icon)
    }
    if (labelProperties) {
      this.selectSitemapItemDrawnPart(MapViewItemDrawnPart.Label)
    }
    if (shapeProperties) {
      this.selectSitemapItemDrawnPart(MapViewItemDrawnPart.Shape)
    }
  }

  @action.bound
  public selectSitemapItemDrawnPart(part: MapViewItemDrawnPart): void {
    if (
      this.selectedMapViewItem &&
      (this.isGlobeMode
        ? this.selectedMapViewItem.globeViewItemProperties.hasDrawnPart(part)
        : this.selectedMapViewItem.sitemapItemProperties.hasDrawnPart(part))
    ) {
      this.selectedMapViewItemDrawnPart = part
    } else {
      this.deselectMapViewItemDrawnPart()
    }
  }

  @action.bound
  public deselectMapViewItemDrawnPart(): void {
    this.selectedMapViewItemDrawnPart = null
  }

  @action.bound
  public safelyDeselectMapViewItem(): void {
    if (this.isGlobeMode) {
      const globeItem =
        this.mapViewSetUpStore.mapBoxViewerStore.allGlobeViewItems.find(
          i => i.id === this.selectedMapViewItem?.id,
        )
      if (!globeItem || !globeItem.isEqual(this.selectedMapViewItem, true)) {
        this.mapViewItemForSave = this.selectedMapViewItem
      } else {
        this.deselectMapViewItem()
      }
    } else {
      this.deselectMapViewItem()
    }
  }

  @computed
  public get isSitemapBlocked(): boolean {
    return this.selectedMapViewItem && (this.isSaving || this.isSitemapUpdating)
  }

  public get isGlobeMode(): boolean {
    return this.mapViewSetUpStore.isGlobeMode
  }

  public saveSelectedMapViewItem = async (
    shouldKeepSelected?: boolean,
    shouldAddToMap?: boolean,
  ): Promise<void> => {
    if (
      !this.selectedMapViewItem ||
      !this.selectedMapViewItem.isValid(this.isGlobeMode)
    ) {
      return this.deselectMapViewItem()
    }
    this.setSaving(true)

    const objToUpdate = this.selectedMapViewItem.dataObject?.copy()
    const isLvlParentChanged = this.isLevelParentChanged(objToUpdate)

    const itemToSave = this.selectedMapViewItem.copy()

    await this.saveMapViewItem(itemToSave, shouldAddToMap)

    if (isLvlParentChanged) {
      await this.updateAccessibleLevelsByLevel(objToUpdate)
    }

    if (!shouldKeepSelected) {
      this.deselectMapViewItem()
    }

    this.setSaving(false)
  }

  @computed
  public get isSitemapUpdating(): boolean {
    return sitemapSaveEvents.some(ev =>
      this.eventsStore.appState.loading.get(ev),
    )
  }

  public saveMapViewItem = async (
    item: MapViewItemBase,
    shouldAddToMap?: boolean,
  ): Promise<boolean> => {
    this.mapViewSetUpStore.showLoader()
    const { viewport } = this.mapViewSetUpStore.mapBoxViewerStore
    if (!item.sitemapItem?.coordinates && this.isGlobeMode) {
      item.sitemapItem.coordinates = {
        latitude: viewport.latitude,
        longitude: viewport.longitude,
      }
    }

    const { item: dataObject, isUpdated: isDataObjectUpdated } =
      await this.saveDataObjectIfChanged(item)

    if (dataObject) {
      item.sitemapItem.assign(dataObject)
    }

    const { id: sitemapItemId, isUpdated: isSitemapItemUpdated } =
      await this.saveMapViewItemRelatedData(item)

    item.sitemapItem.id = sitemapItemId
    const isSitemapSpecificItemUpdated = this.updateMapViewSpecificItem(item)

    if (isSitemapSpecificItemUpdated && shouldAddToMap) {
      if (this.mapViewSetUpStore.isGlobeMode) {
        await this.globeViewSetupStore.addItemToGlobeView(
          item.sitemapItem.id,
          item,
        )
      } else {
        await this.sitemapsSetupStore.addItemToSitemap(
          item.sitemapItem.id,
          item,
        )
      }
    }

    this.mapViewSetUpStore.hideLoader()

    return (
      isDataObjectUpdated ||
      isSitemapItemUpdated ||
      isSitemapSpecificItemUpdated
    )
  }

  public async saveDataObjectIfChanged(item: MapViewItemBase): Promise<any> {
    const { dataObject } = item
    if (!dataObject) {
      return { item: null, isUpdated: false }
    }
    const existingObject = this.hierarchyAttributes.find(i => i.id === item.id)
    const isParentChanged = !areObjectsEqual(
      existingObject?.parent || {},
      dataObject.parent || {},
    )
    let res
    switch (dataObject.type) {
      case LocationType.Building:
        res = await this.locationAttributesStore.buildingsStore.updateIfChanged(
          dataObject as Building,
        )
        await this.activityFiltersStore.setDefaultActivityLocationRelationships(
          FilterType.Building,
          dataObject,
        )
        break
      case LocationType.Zone:
        res = await this.locationAttributesStore.zonesStore.updateIfChanged(
          dataObject as Zone,
        )
        break
      case LocationType.Gate:
        res = await this.locationAttributesStore.gatesStore.updateIfChanged(
          dataObject as Gate,
        )
        break
      case LocationType.Route:
        res = await this.locationAttributesStore.routesStore.updateIfChanged(
          dataObject as Route,
        )
        break
      case LocationType.OffloadingEquipment:
        res =
          await this.locationAttributesStore.offloadingEquipmentsStore.updateIfChanged(
            dataObject as OffloadingEquipment,
          )
        break
      case LocationType.LogisticsObject:
        res =
          await this.locationAttributesStore.logisticsObjectsStore.updateIfChanged(
            dataObject as LogisticsObject,
          )
        break
      case LocationType.Integration:
        res =
          await this.locationAttributesStore.locationIntegrationsStore.updateIfChanged(
            dataObject as LocationIntegration,
          )
        break
      case LocationType.VerticalObject:
        res =
          await this.locationAttributesStore.verticalObjectsStore.updateIfChanged(
            dataObject as VerticalObject,
          )
        break
      case LocationType.Level:
        res = await this.locationAttributesStore.levelsStore.updateIfChanged(
          dataObject as Level,
        )
        await this.activityFiltersStore.setDefaultActivityLocationRelationships(
          FilterType.Level,
          dataObject,
        )
        break
      case LocationType.Area:
        res = await this.locationAttributesStore.areasStore.updateIfChanged(
          dataObject as Area,
        )
        await this.activityFiltersStore.setDefaultActivityLocationRelationships(
          FilterType.Zone,
          dataObject,
        )
        break
      case LocationType.Staging:
        res = await this.locationAttributesStore.stagingsStore.updateIfChanged(
          dataObject as Staging,
        )
        break
      case LocationType.InteriorDoor:
        res =
          await this.locationAttributesStore.interiorDoorsStore.updateIfChanged(
            dataObject as InteriorDoor,
          )
        break
      case LocationType.InteriorPath:
        res =
          await this.locationAttributesStore.interiorPathsStore.updateIfChanged(
            dataObject as InteriorPath,
          )
        break
    }

    if (isParentChanged) {
      this.syncRestrictionsStore.updateRestrictionsForItem(dataObject)
    }
    return res
  }

  public async saveMapViewItemRelatedData(
    item: MapViewItemBase,
  ): Promise<{ id: string; isUpdated: boolean }> {
    const { sitemapItem } = item

    const existingItem = this.sitemapItemsStore.byId.get(sitemapItem.id)

    if (existingItem && existingItem.isEqual(sitemapItem)) {
      return {
        id: sitemapItem.id,
        isUpdated: false,
      }
    }

    const id = await this.saveItem(sitemapItem)

    return { id, isUpdated: true }
  }

  public updateMapViewSpecificItem(item: MapViewItemBase): boolean {
    const { sitemapItem } = item
    const { isGlobeMode } = this.mapViewSetUpStore
    const displayData = isGlobeMode
      ? this.globe?.displayDataMap[sitemapItem.id]?.item ||
        ({} as IGlobeViewSpecificItemData)
      : this.whiteboard.getItemDisplayData(sitemapItem.id)

    if (!item.isDisplayDataEqual(displayData, isGlobeMode)) {
      const updatedData = item.getDisplayData(isGlobeMode)

      if (this.mapViewSetUpStore.isGlobeMode) {
        this.globe.setItemDisplayData(sitemapItem.id, {
          ...updatedData,
          id: displayData.id,
          globeViewId:
            (displayData as IGlobeViewSpecificItemData)?.globeViewId ||
            this.globe.id,
          projectId: displayData?.projectId || this.globe.projectId,
        })
      } else {
        this.whiteboard.setItemDisplayData(sitemapItem.id, {
          ...updatedData,
          id: displayData.id,
          sitemapId:
            (displayData as ISitemapSpecificItemData)?.sitemapId ||
            this.whiteboard.id,
          projectId: displayData?.projectId || this.whiteboard.projectId,
        })
      }
      return true
    }

    return false
  }

  @action.bound
  public createDataLessItem(type: MapViewItemType): void {
    const item = MapViewItemFactory.createDataLessItem(
      type,
      this.state.activeProject.id,
      this.mapViewItems.map(i => i.name),
    )
    this.selectMapViewItem(item)
  }

  @action.bound
  public enableCreatingAttribute(
    type: LocationType,
    iconName?: MapViewLocationIcon,
  ): void {
    this.saveSelectedMapViewItem()
    this.isTextStickerCreationActive = false
    this.creatableAttributeType = type
    this.creatableAttributeIcon = iconName
  }

  @action.bound
  public enableCreatingText(): void {
    this.saveSelectedMapViewItem()
    this.disableCreatingAttribute()
    this.isTextStickerCreationActive = true
  }

  @action.bound
  public disableCreatingAttribute(): void {
    this.creatableAttributeType = null
    this.creatableAttributeIcon = null
  }

  @action.bound
  public createAttributeInPosition(position: IPosition): void {
    if (this.isTextStickerCreationActive) {
      this.createTextBoxEditor(position)
      return
    }

    if (!this.creatableAttributeType) {
      return
    }

    const color =
      this.creatableAttributeType === LocationType.Building &&
      this.locationAttributesStore.buildingsStore.colorForNewItem

    const coordinates =
      this.mapViewSetUpStore.mapBoxViewerStore.getItemCoordinates(
        position.x,
        position.y,
      )

    const item = MapViewItemFactory.createDataItem(
      this.creatableAttributeType,
      this.creatableAttributeIcon,
      position,
      this.state.activeProject.id,
      this.mapViewItems.map(i => i.name),
      color,
      coordinates,
    )
    item.isDisplayed = true

    this.selectMapViewItem(item)
  }

  public get deletableSitemapItemCaption(): string {
    if (!this.deletableMapViewItem) {
      return
    }
    const { dataObject } = this.deletableMapViewItem
    if (dataObject) {
      return dataObject.getFieldName(this.state)
    }
    return this.deletableMapViewItem.typeCaption
  }

  public get mapViewItemForSaveCaption(): string {
    if (!this.mapViewItemForSave) {
      return
    }
    const { dataObject } = this.mapViewItemForSave
    if (dataObject) {
      return dataObject.getFieldName(this.state)
    }
    return this.mapViewItemForSave.typeCaption
  }

  @action.bound
  public showDeleteConfirmationDialog(item: MapViewItemBase): void {
    if (!item) {
      return
    }
    if (!item.id) {
      return this.deselectMapViewItem()
    }
    this.deletableMapViewItem = item
  }

  @action.bound
  public hideMapViewItemDeleteConfirmDialog(): void {
    this.deletableMapViewItem = null
  }

  @action.bound
  public deselectMapViewItem(): void {
    this.selectedMapViewItem = null
    this.mapViewItemForSave = null
    this.deselectMapViewItemDrawnPart()
    this.clearActionsHistory()
  }

  @action.bound
  public hideMapViewItemSaveConfirmDialog(): void {
    this.deselectMapViewItem()
  }

  @action.bound
  public async applyMapViewItemSaveConfirmDialog(): Promise<void> {
    this.isSavingAfterChanges = true
    await this.saveMapViewItem(
      this.mapViewItemForSave,
      this.selectedMapViewItem.isDisplayed,
    )
    this.deselectMapViewItem()
    this.isSavingAfterChanges = false
  }

  @action.bound
  public async applyMapViewItemDeleteConfirmDialog(): Promise<void> {
    if (!this.deletableMapViewItem?.id) {
      return
    }

    const item = this.deletableMapViewItem

    this.hideMapViewItemDeleteConfirmDialog()

    this.mapViewSetUpStore.showLoader()

    if (this.selectedMapViewItem && this.selectedMapViewItem.id === item.id) {
      this.deselectMapViewItem()
    }

    this.setNewParentsForDeletableItem(item)

    if (item.dataObject) {
      await this.deleteDataObject(item.dataObject)
    }

    if (item.sitemapItem.id) {
      await this.removeSitemapItems([item.sitemapItem.id])
    }

    if (
      item?.sitemapItem?.id &&
      this.whiteboard?.isItemDisplayed?.(item.sitemapItem.id)
    ) {
      this.whiteboard.deleteItemDisplayData(item.sitemapItem.id)

      await this.sitemapsSetupStore.saveSitemap(this.whiteboard)
    }

    this.mapViewSetUpStore.hideLoader()
  }

  public async deleteSitemapItemsByIds(ids: string[]): Promise<void> {
    const items = this.mapViewItems.filter(i => ids.includes(i.id))

    if (this.selectedMapViewItem && ids.includes(this.selectedMapViewItem.id)) {
      this.deselectMapViewItem()
    }

    for (const item of items) {
      this.setNewParentsForDeletableItem(item)

      if (item.dataObject) {
        await this.deleteDataObject(item.dataObject)
      }

      if (this.whiteboard?.isItemDisplayed?.(item.sitemapItem.id)) {
        this.whiteboard.deleteItemDisplayData(item.sitemapItem.id)
      }
    }

    const itemsIdsToDelete = items.map(i => i.sitemapItem?.id).filter(i => i)
    await this.removeSitemapItems(itemsIdsToDelete)

    await this.sitemapsSetupStore.saveSitemap(this.whiteboard)
  }

  public async deleteDataObject(dataObject: LocationBase): Promise<void> {
    switch (dataObject.type) {
      case LocationType.Building:
        return await this.locationAttributesStore.buildingsStore.removeItems([
          dataObject.id,
        ])
      case LocationType.Zone:
        return await this.locationAttributesStore.zonesStore.removeItems([
          dataObject.id,
        ])
      case LocationType.Gate:
        return await this.locationAttributesStore.gatesStore.removeItems([
          dataObject.id,
        ])
      case LocationType.Route:
        return await this.locationAttributesStore.routesStore.removeItems([
          dataObject.id,
        ])
      case LocationType.OffloadingEquipment:
        return await this.locationAttributesStore.offloadingEquipmentsStore.removeItems(
          [dataObject.id],
        )
      case LocationType.LogisticsObject:
        return await this.locationAttributesStore.logisticsObjectsStore.removeItems(
          [dataObject.id],
        )
      case LocationType.Integration:
        return await this.locationAttributesStore.locationIntegrationsStore.removeItems(
          [dataObject.id],
        )
      case LocationType.Staging:
        return await this.locationAttributesStore.stagingsStore.removeItems([
          dataObject.id,
        ])
      case LocationType.InteriorDoor:
        return await this.locationAttributesStore.interiorDoorsStore.removeItems(
          [dataObject.id],
        )
      case LocationType.InteriorPath:
        return await this.locationAttributesStore.interiorPathsStore.removeItems(
          [dataObject.id],
        )
      case LocationType.VerticalObject:
        return await this.locationAttributesStore.verticalObjectsStore.removeItems(
          [dataObject.id],
        )
      case LocationType.Level:
        return await this.locationAttributesStore.levelsStore.removeItems([
          dataObject.id,
        ])
      case LocationType.Area:
        return await this.locationAttributesStore.areasStore.removeItems([
          dataObject.id,
        ])
    }
  }

  public async saveDataObject(
    dataObject: LocationBase,
    shouldSkipAdditionalSaveAction?: boolean,
  ): Promise<string> {
    switch (dataObject.type) {
      case LocationType.Building:
        return await this.locationAttributesStore.buildingsStore.saveItem(
          dataObject as Building,
          shouldSkipAdditionalSaveAction,
        )
      case LocationType.Zone:
        return await this.locationAttributesStore.zonesStore.saveItem(
          dataObject as Zone,
        )
      case LocationType.Gate:
        return await this.locationAttributesStore.gatesStore.saveItem(
          dataObject as Gate,
        )
      case LocationType.Route:
        return await this.locationAttributesStore.routesStore.saveItem(
          dataObject as Route,
        )
      case LocationType.OffloadingEquipment:
        return await this.locationAttributesStore.offloadingEquipmentsStore.saveItem(
          dataObject as OffloadingEquipment,
        )
      case LocationType.LogisticsObject:
        return await this.locationAttributesStore.logisticsObjectsStore.saveItem(
          dataObject as LogisticsObject,
        )
      case LocationType.Integration:
        return await this.locationAttributesStore.locationIntegrationsStore.saveItem(
          dataObject as LocationIntegration,
        )
      case LocationType.Staging:
        return await this.locationAttributesStore.stagingsStore.saveItem(
          dataObject as Staging,
        )
      case LocationType.InteriorDoor:
        return await this.locationAttributesStore.interiorDoorsStore.saveItem(
          dataObject as InteriorDoor,
        )
      case LocationType.InteriorPath:
        return await this.locationAttributesStore.interiorPathsStore.saveItem(
          dataObject as InteriorPath,
        )
      case LocationType.VerticalObject:
        return await this.locationAttributesStore.verticalObjectsStore.saveItem(
          dataObject as VerticalObject,
        )
      case LocationType.Level:
        return await this.locationAttributesStore.levelsStore.saveItem(
          dataObject as Level,
        )
      case LocationType.Area:
        return await this.locationAttributesStore.areasStore.saveItem(
          dataObject as Area,
        )
    }
  }

  public async setNewParentsForDeletableItem(
    item: MapViewItemBase,
  ): Promise<boolean[]> {
    const children = this.mapViewItems.filter(i => i.isParent(item))
    if (!children.length) {
      return
    }

    const levelIdsToRemove = []

    const updateRelatedSiteMapItems = children.map(child => {
      if (child.dataObject?.type === LocationType.Level) {
        child.setParent(null)
        this.setNewParentsForDeletableItem(child)
        levelIdsToRemove.push(child.id)
      }
      child.setParent(item.parent)
      return this.saveMapViewItem(child)
    })

    this.locationAttributesStore.levelsStore.removeItems(levelIdsToRemove)

    return Promise.all(updateRelatedSiteMapItems)
  }

  public sitemapAssignedItems(sitemap: Sitemap): LocationBase[] {
    if (!sitemap) {
      return []
    }
    return this.hierarchyAttributes.filter(dto =>
      dto.isSitemapAssigned(sitemap.id),
    )
  }

  public globeAssignedItems(globe: GlobeView): LocationBase[] {
    if (!globe) {
      return []
    }
    return this.hierarchyAttributes.filter(dto => dto.isGlobeAssigned(globe.id))
  }

  public get selectedSitemapAssignedItems(): LocationBase[] {
    if (!this.whiteboard) {
      return []
    }
    return this.hierarchyAttributes.filter(dto =>
      dto.isSitemapAssigned(this.whiteboard.id),
    )
  }

  public updateGlobeAssignedItems = async (
    newAssignedIds: string[],
    globe?: GlobeView,
  ): Promise<void> => {
    const globeToCheck = globe || this.globe
    if (!globeToCheck) {
      return
    }

    await this.hierarchyAttributes.forEach(item => {
      if (
        item.isGlobeAssigned(globeToCheck.id) &&
        !newAssignedIds.includes(item.id)
      ) {
        item.deassignGlobe(globeToCheck.id)
        this.saveDataObject(item)
      }

      if (
        !item.isGlobeAssigned(globeToCheck.id) &&
        newAssignedIds.includes(item.id)
      ) {
        item.assignGlobe(globeToCheck.id)
        this.saveDataObject(item)
      }
    })
  }

  public updateSitemapAssignedItems = async (
    newAssignedIds: string[],
    sitemap?: Sitemap,
  ): Promise<void> => {
    const sitemapToCheck = sitemap || this.whiteboard
    if (!sitemapToCheck) {
      return
    }

    await this.hierarchyAttributes.forEach(item => {
      if (
        item.isSitemapAssigned(sitemapToCheck.id) &&
        !newAssignedIds.includes(item.id)
      ) {
        item.deassignSitemap(sitemapToCheck.id)
        this.saveDataObject(item)
      }

      if (
        !item.isSitemapAssigned(sitemapToCheck.id) &&
        newAssignedIds.includes(item.id)
      ) {
        item.assignSitemap(sitemapToCheck.id)
        this.saveDataObject(item)
      }
    })
  }

  public isAttributeAssignedToGlobe = (item: MapViewItemBase): boolean => {
    if (this.globe?.isProjectOverviewGlobe) {
      return false
    }
    const { firstBuilding } = this.locationAttributesStore.buildingsStore
    const { globe } = this.mapViewSetUpStore.mapViewItemsSetupStore
    if (!item.dataObject || !firstBuilding) {
      return false
    }
    const { type, id } = item.dataObject
    if (type === LocationType.Building && id === firstBuilding.id) {
      return !this.mapViewSetUpStore.globeViewSetupStore.globeToLocation[
        globe.id
      ]
    }
  }

  public isAttributeAssignedToSitemap = (item: MapViewItemBase): boolean => {
    if (this.whiteboard?.isProjectOverviewMap) {
      return false
    }
    const { firstBuilding } = this.locationAttributesStore.buildingsStore
    const { whiteboard } = this.mapViewSetUpStore.mapViewItemsSetupStore
    if (!item.dataObject || !firstBuilding) {
      return false
    }
    const { type, id } = item.dataObject
    if (type === LocationType.Building && id === firstBuilding.id) {
      return !this.mapViewSetUpStore.sitemapViewsSetupStore.sitemapToLocation[
        whiteboard.id
      ]
    }
  }

  public isAttributeAssigned = (item: MapViewItemBase): boolean => {
    if (this.globe) {
      return this.isAttributeAssignedToGlobe(item)
    } else {
      return this.isAttributeAssignedToSitemap(item)
    }
  }

  public isItemAssigned = (item: MapViewItemBase): boolean => {
    if (this.globe) {
      return this.isItemAssignedToGlobe(item)
    } else {
      return this.isItemAssignedToSitemap(item)
    }
  }

  public isItemAssignedToSitemap = (item: MapViewItemBase): boolean => {
    return (
      item.dataObject && this.isDataObjectAssignedToSitemap(item.dataObject)
    )
  }

  public isItemAssignedToGlobe = (item: MapViewItemBase): boolean => {
    return item.dataObject && this.isDataObjectAssignedToGlobe(item.dataObject)
  }

  public isDataObjectAssignedToSitemap = (
    dataObject: LocationBase,
  ): boolean => {
    return dataObject.isSitemapAssigned(this.whiteboard?.id)
  }

  public isDataObjectAssignedToGlobe = (dataObject: LocationBase): boolean => {
    return dataObject.isGlobeAssigned(this.globe?.id)
  }

  @computed
  public get areThereDisplayedItems(): boolean {
    return !!this.globe.items.filter(i => !i.isHidden).length
  }

  @action.bound
  public toggleAllItemsVisibility(): void {
    if (!this.isUpdatingSitemapLoaderShown) {
      this.toggleItemsVisibility(this.mapViewItems, this.areThereDisplayedItems)
    }
  }

  @action.bound
  public toggleSingleItemVisibility(
    item: MapViewItemBase,
    avoidSaving?: boolean,
  ): void {
    this.toggleItemsVisibility([item], item.isDisplayed, avoidSaving)
  }

  @action.bound
  public async toggleItemsVisibility(
    items: MapViewItemBase[],
    areItemsDisplayed: boolean,
    avoidSaving?: boolean,
  ): Promise<void> {
    const { showLoader, hideLoader } = this.mapViewSetUpStore

    if (!avoidSaving) {
      showLoader()
    }

    if (areItemsDisplayed) {
      await this.hideItems(items)
    } else {
      await this.displayItems(items)
    }

    hideLoader()
  }

  @computed
  public get hierarchyAttributes(): LocationBase[] {
    return this.locationAttributesStore.getSortedAllAttributes(sortAttributes)
  }

  public addCurrentStateToHistory = (): void => {
    if (!this.selectedMapViewItem) {
      return
    }
    const newActionHistory: IActionHistory = {
      selectedItem: this.selectedMapViewItem.copy(),
    }

    this.actionsHistory.splice(
      ++this.historyIndex,
      this.actionsHistory.length,
      newActionHistory,
    )
  }

  public undoAction = (): void => {
    if (this.actionsHistory[this.historyIndex - 1]) {
      this.historyIndex--
      this.applyStateFromHistory()
    }
  }

  public redoAction = (): void => {
    if (this.actionsHistory[this.historyIndex + 1]) {
      this.historyIndex++
      this.applyStateFromHistory()
    }
  }

  public updateAccessibleLevelsByLevel = async (
    level: LocationBase<Level>,
  ): Promise<void> => {
    if (
      level?.type !== LocationType.Level ||
      level?.parent?.parentType !== LocationType.Building
    ) {
      return
    }

    const buildingDto =
      this.locationAttributesStore.buildingsStore.getInstanceById(
        level.parent.parentId,
      )
    await this.addLevelsToAccessibles(buildingDto, [level.id])
  }

  @action.bound
  public resetAccessibleLevels(item: MapViewItemBase): void {
    const { dataObject } = item

    let attribute: VerticalObject | OffloadingEquipment = null
    switch (dataObject?.type) {
      case LocationType.VerticalObject:
        attribute = dataObject as VerticalObject
        break
      case LocationType.OffloadingEquipment:
        attribute = dataObject as OffloadingEquipment
        break
    }

    if (!attribute) {
      return
    }

    attribute.accessibleLevels = this.getAccessibleLevelsByParentId(
      attribute.parent?.parentId,
    )
  }

  public addLevelsToAccessibles = async (
    buildingDto: Building,
    levelIds: string[],
  ): Promise<void> => {
    this.mapViewSetUpStore.showLoader()

    const {
      list: verticalObjects,
      saveItemsWhenNestedNumberOfLevelsChanges: saveMultipleVerticalObjs,
    } = this.locationAttributesStore.verticalObjectsStore
    const {
      list: equipments,
      saveItemsWhenNestedNumberOfLevelsChanges: saveMultipleEquipments,
    } = this.locationAttributesStore.offloadingEquipmentsStore

    await this.updateObjectAccessibleLevels(
      verticalObjects,
      saveMultipleVerticalObjs,
      buildingDto,
      levelIds,
    )
    await this.updateObjectAccessibleLevels(
      equipments,
      saveMultipleEquipments,
      buildingDto,
      levelIds,
    )

    this.mapViewSetUpStore.hideLoader()
  }

  private async updateObjectAccessibleLevels<
    T extends IAccessibleLevelsAttribute & LocationBase,
  >(
    list: T[],
    saveMany: (elements: T[]) => Promise<string[]>,
    buildingDto: Building,
    levelIds: string[],
  ): Promise<void> {
    const elements = list.filter(vo => vo.isParent(buildingDto))

    const updatedObjects = this.getUpdatedAccessibleLvlsObjs(
      elements,
      buildingDto,
      levelIds,
    )

    await saveMany(updatedObjects)
  }

  private getUpdatedAccessibleLvlsObjs<T extends IAccessibleLevelsAttribute>(
    list: T[],
    buildingDto: Building,
    levelIds: string[],
  ): T[] {
    return list
      .map(element => {
        const currentAccessibleLevels = this.getAccessibleLevelsByParentId(
          buildingDto.id,
          element,
        )
        const idsToAdd = levelIds.filter(
          lvl => !currentAccessibleLevels.includes(lvl),
        )

        if (!idsToAdd.length) {
          return null
        }

        currentAccessibleLevels.push(...idsToAdd)
        element.accessibleLevels = currentAccessibleLevels

        return element
      })
      .filter(el => el)
  }

  private getAccessibleLevelsByParentId = (
    parentId: string,
    attribute?: IAccessibleLevelsAttribute,
  ): string[] => {
    const { list: allLevels } = this.locationAttributesStore.levelsStore
    const building =
      this.locationAttributesStore.buildingsStore.getInstanceById(parentId)

    let levels = allLevels.filter(l => l.isParent(building))

    if (attribute?.accessibleLevels?.length) {
      const filteredLvls = levels.filter(l =>
        attribute.accessibleLevels.includes(l.id),
      )
      levels = filteredLvls.length ? filteredLvls : levels
    }

    return (
      building?.sortLevelsByCustomOrder(levels) ||
      levels.sort((a, b) => sortLevels(b, a))
    ).map(({ id }) => id)
  }

  private isLevelParentChanged = (attribute: LocationBase): boolean => {
    if (attribute?.type !== LocationType.Level) {
      return false
    }

    const existingLevel =
      this.locationAttributesStore.levelsStore.getInstanceById(attribute?.id)

    return (
      existingLevel &&
      attribute.parent?.parentId !== existingLevel.parent?.parentId
    )
  }

  private initActionsHistory(): void {
    if (this.selectedMapViewItem) {
      this.actionsHistory = [
        {
          selectedItem: this.selectedMapViewItem.copy(),
        },
      ]
      this.historyIndex = 0
    }
  }

  private createTextBoxEditor(position: IPosition): void {
    const textItem = MapViewItemFactory.createDataLessItem(
      MapViewItemType.TextBox,
      this.state.activeProject.id,
      this.mapViewItems.map(i => i.name),
    )
    if (this.isGlobeMode) {
      textItem.sitemapItem.coordinates =
        this.mapViewSetUpStore.mapBoxViewerStore.getItemCoordinates(
          position.x,
          position.y,
        )
    } else {
      textItem.sitemapItemProperties.labelProperties.setPosition(position)
    }
    this.selectMapViewItem(textItem)
    this.isTextStickerCreationActive = false
  }

  private clearActionsHistory = (): void => {
    this.actionsHistory = []
    this.historyIndex = -1
  }

  private applyStateFromHistory = (): void => {
    const state = this.actionsHistory[this.historyIndex]
    const hasDrawnPart = this.isGlobeMode
      ? state.selectedItem.globeViewItemProperties.hasDrawnPart(
          this.selectedMapViewItemDrawnPart,
        )
      : state.selectedItem.sitemapItemProperties.hasDrawnPart(
          this.selectedMapViewItemDrawnPart,
        )

    if (this.selectedMapViewItemDrawnPart && !hasDrawnPart) {
      this.deselectMapViewItemDrawnPart()
    }

    this.selectedMapViewItem = state.selectedItem.copy()
  }

  private getDefaultSitemapItemForAttribute(dto: LocationBase): SitemapItem {
    return new SitemapItem(
      null,
      dto.name,
      dto.color,
      null,
      null,
      this.state.activeProject.id,
      dto.type,
      dto.id,
      null,
      null,
      null,
      null,
      null,
    )
  }

  private getNodeChildren(
    node: HierarchyNode,
    nodes?: HierarchyNode[],
  ): HierarchyNode[] {
    return this.mapViewItems
      .filter(item => item.isParent(node.item))
      .map(item => {
        const childNode = new HierarchyNode(
          this.hierarchyExpandState,
          node.level + 1,
          item,
          node,
        )
        if (nodes) {
          nodes.push(childNode)
        }
        childNode.children = this.getNodeChildren(childNode, nodes)
        return childNode
      })
  }

  private saveItemChildren = async (
    item: MapViewItemBase,
    newParent: MapViewItemBase,
  ): Promise<void[]> => {
    const children = this.mapViewItems
      .filter(i => i.isParent(item))
      .map(i => i.copy())
    if (!children.length) {
      return
    }
    return await Promise.all(
      children.map(async child => {
        const initialChild = child.copy()
        child.sitemapItem.setId(null)
        if (child.dataObject) {
          child.dataObject.id = null
        }

        child.setParent({
          parentId: newParent.id,
          parentType: newParent.dataObject.type,
        })
        await this.saveMapViewItem(child)

        this.saveItemChildren(initialChild, child)
      }),
    )
  }

  private get sitemapsSetupStore(): SitemapsSetupStore {
    return this.mapViewSetUpStore.sitemapsSetupStore
  }

  private get globeViewSetupStore(): GlobeViewSetupStore {
    return this.mapViewSetUpStore.globeViewSetupStore
  }

  private get state(): DesktopInitialState {
    return this.eventsStore.appState
  }

  public get whiteboard(): Sitemap {
    return this.sitemapsSetupStore.selectedSitemap || ({} as Sitemap)
  }

  public get globe(): GlobeView {
    return this.globeViewSetupStore.selectedGlobeView
  }

  private hasAnyDisplayData(item: SitemapItem): boolean {
    return (
      this.allWhiteboards.some(s => s.hasDisplayData(item.id)) ||
      this.allGlobeViews.some(s => s.hasDisplayData(item.id))
    )
  }

  private get allWhiteboards(): Sitemap[] {
    // Sorting:
    // selected sitemap first
    // then sitemaps with same basemaps
    // then rest
    return this.sitemapsSetupStore.whiteboards.sort((a, b) => {
      let aVal = 0
      let bVal = 0

      if (a === this.whiteboard) {
        aVal = 2
      } else if (this.whiteboard?.basemapId === a?.basemapId) {
        aVal = 1
      }

      if (b === this.whiteboard) {
        bVal = 2
      } else if (this.whiteboard?.basemapId === b?.basemapId) {
        bVal = 1
      }

      return bVal - aVal
    })
  }

  private get allGlobeViews(): GlobeView[] {
    return this.globeViewSetupStore.allGlobeViews
  }

  @action.bound
  private async hideItems(items: MapViewItemBase[]): Promise<void> {
    if (items.some(i => this.selectedMapViewItem.id === i.id)) {
      this.deselectMapViewItem()
    }
    if (this.isGlobeMode) {
      await this.changeGlobeItemDataVisibility(items, false)
    } else {
      await this.changeSitemapItemDataVisibility(items, false)
    }
  }

  @action.bound
  private async displayItems(items: MapViewItemBase[]): Promise<void> {
    if (this.isGlobeMode) {
      await this.changeGlobeItemDataVisibility(items, true)
    } else {
      await this.changeSitemapItemDataVisibility(items, true)
    }

    const oldSelectedItem = items.find(i => this.isMapViewItemSelected(i))
    if (oldSelectedItem) {
      const updatedItem = this.mapViewItems.find(
        i => i.id === oldSelectedItem.id,
      )
      this.selectMapViewItem(updatedItem)
    }
  }

  @action.bound
  private async changeGlobeItemDataVisibility(
    items: MapViewItemBase[],
    shouldDisplay: boolean,
  ): Promise<void> {
    const { viewport } = this.mapViewSetUpStore.mapBoxViewerStore
    const { id: globeViewId, projectId } =
      this.globeViewSetupStore.selectedGlobeView

    const itemsForSave = await Promise.all(
      items
        .filter(i => i.isDisplayed !== shouldDisplay)
        .map(async item => {
          const exists =
            this.globeViewSetupStore.selectedGlobeView.itemsMap[
              item.sitemapItem.id
            ]
          let shouldSaveSitemapItem = false

          if (!item.sitemapItem?.coordinates) {
            item.sitemapItem.coordinates = {
              latitude: viewport.latitude,
              longitude: viewport.longitude,
            }
            shouldSaveSitemapItem = true
          }
          if (!item.sitemapItem?.id) {
            shouldSaveSitemapItem = true
          }
          if (
            item.sitemapItem.type === MapViewItemType.Line &&
            !item.sitemapItem.shapeCoordinates
          ) {
            item.sitemapItem.shapeCoordinates = new PolyLineShapeCoordinates(
              [
                {
                  latitude: viewport.latitude,
                  longitude: viewport.longitude,
                },
                {
                  latitude: viewport.latitude,
                  longitude: viewport.longitude + 0.01,
                },
              ],
              false,
            )
            shouldSaveSitemapItem = true
          }

          if (shouldSaveSitemapItem) {
            item.sitemapItem.id = (
              await this.saveMapViewItemRelatedData(item)
            ).id
          }

          if (exists) {
            const itemToUpdate =
              this.globeViewSetupStore.selectedGlobeView.getItemDisplayData(
                item.sitemapItem.id,
              )
            return {
              ...itemToUpdate,
              isHidden: !shouldDisplay,
            }
          } else {
            if (!shouldDisplay) {
              return null
            }
            item.fillDataBeforeDisplayingFromGlobes(
              this.globeViewSetupStore.globeViewsList,
            )
            if (
              item.sitemapItem.shapeCoordinates?.type &&
              item.globeViewItemProperties?.shapeProperties?.type !==
                item.sitemapItem.shapeCoordinates.type
            ) {
              item.updateGlobeViewShape(item.sitemapItem.shapeCoordinates.type)
            }

            const itemToAdd = {
              ...item.getDisplayData(true),
              projectId,
              globeViewId,
              isHidden: !shouldDisplay,
            } as IGlobeViewSpecificItemData

            return itemToAdd
          }
        })
        .filter(i => !!i),
    )

    this.globeViewSetupStore.saveGlobeViewItems(itemsForSave)
  }

  @action.bound
  private async changeSitemapItemDataVisibility(
    items: MapViewItemBase[],
    shouldDisplay: boolean,
  ): Promise<void> {
    const { id: sitemapId, projectId } = this.sitemapsSetupStore.selectedSitemap

    const itemsForSave = await Promise.all(
      items
        .filter(i => i.isDisplayed !== shouldDisplay)
        .map(async item => {
          const exists =
            this.sitemapsSetupStore.selectedSitemap.itemsMap[
              item.sitemapItem.id
            ]

          if (exists) {
            const itemToUpdate =
              this.sitemapsSetupStore.selectedSitemap.getItemDisplayData(
                item.sitemapItem.id,
              )
            return {
              ...itemToUpdate,
              isHidden: !shouldDisplay,
            }
          } else {
            item.fillDataBeforeDisplayingFromSitemaps(
              this.sitemapsSetupStore.whiteboards,
            )
            if (!item.sitemapItem?.id) {
              item.sitemapItem.id = (
                await this.saveMapViewItemRelatedData(item)
              ).id
            }
            const itemToAdd = {
              ...item.getDisplayData(false),
              sitemapId,
              projectId,
              isHidden: !shouldDisplay,
            } as ISitemapSpecificItemData

            return itemToAdd
          }
        }),
    )

    this.sitemapsSetupStore.saveSitemapItemData(itemsForSave)
  }

  private saveItem(sitemapItem: SitemapItem): Promise<string> {
    return new Promise<string>(resolve => {
      this.sitemapItemsStore.save(sitemapItem, id => resolve(id))
    })
  }

  private removeSitemapItems(sitemapItemIds: string[]): Promise<void> {
    return new Promise<void>(resolve => {
      if (!sitemapItemIds?.length) {
        return resolve()
      }

      this.sitemapItemsStore.removeMany(sitemapItemIds, resolve)
    })
  }

  private async getImageUrl(sourceFile: File | Blob): Promise<string> {
    const file = await this.getScaledImage(sourceFile)

    const isImage = file.type.startsWith('image/')

    const fileType = isImage ? UploadingType.Image : UploadingType.Pdf
    const [result] = await this.fileUploadingStore.uploadFile(
      file,
      fileType,
      (file as File).name,
    )
    return result.fileURL
  }

  private async getScaledImage(file: File | Blob): Promise<File | Blob> {
    const isImage = file.type.startsWith('image/')

    if (!isImage) {
      return file
    }

    const img = new Image()
    img.src = URL.createObjectURL(file)

    await new Promise(resolve => {
      img.onload = resolve
    })

    if (img.width <= MAX_IMAGE_WIDTH && img.height <= MAX_IMAGE_HEIGHT) {
      return file
    }
    return this.scaleImage(img)
  }

  private scaleImage(img: HTMLImageElement): Promise<Blob> {
    let { width, height } = img

    if (width > MAX_IMAGE_WIDTH) {
      height *= MAX_IMAGE_WIDTH / width
      width = MAX_IMAGE_WIDTH
    }

    if (height > MAX_IMAGE_HEIGHT) {
      width *= MAX_IMAGE_HEIGHT / height
      height = MAX_IMAGE_HEIGHT
    }

    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext('2d')
    ctx.drawImage(img, 0, 0, width, height)

    return new Promise<Blob>(resolve => {
      canvas.toBlob(resolve, SCALED_IMAGE_TYPE, SCALED_IMAGE_QUALITY)
    })
  }

  private get allowedTypes(): LocationType[] {
    const { dataObject } = this.selectedMapViewItem
    if (dataObject && ALLOWED_PARENTS_BY_TYPE[dataObject.type]) {
      return ALLOWED_PARENTS_BY_TYPE[dataObject.type]
    }
    return DEFAULT_ALLOWED_PARENTS
  }

  public get isUpdatingSitemapLoaderShown(): boolean {
    return this.mapViewSetUpStore.isUpdatingSitemapLoaderShown
  }

  private setGlobeViewShapeCoordinates = (
    shapeCoordinates: ShapeCoordinates,
  ):
    | PolyLineShapeCoordinates
    | CircleShapeCoordinates
    | RectangleShapeCoordinates => {
    return shapeCoordinates.copy()
  }

  private setGlobeViewShapeProperties = (
    shape: GlobeViewShapeProperties,
  ):
    | GlobeViewPolyLineProperties
    | GlobeViewCircleProperties
    | GlobeViewRectangleProperties => {
    return shape.copy()
  }

  private setSitemapShapeProperties = (
    shape: SitemapShapeProperties,
    x: number,
    y: number,
  ):
    | SitemapPolyLineProperties
    | SitemapCircleProperties
    | SitemapRectangleProperties => {
    switch (shape.type) {
      case SitemapItemShapeType.Circle:
        const circle = shape.copy() as SitemapCircleProperties
        circle.position = {
          x: circle.position.x + x,
          y: circle.position.y + y,
        }
        return circle
      case SitemapItemShapeType.Rectangle:
        const rectangle = shape.copy() as SitemapRectangleProperties
        rectangle.position = {
          x: rectangle.position.x + x,
          y: rectangle.position.y + y,
        }
        return rectangle
      case SitemapItemShapeType.Polyline:
        const polyline = shape.copy() as SitemapPolyLineProperties
        polyline.points = polyline.points.map(point => {
          return {
            x: point.x + x,
            y: point.y + y,
          }
        })
        return polyline
    }
  }

  private setSaving(isSaving: boolean): void {
    this.isSaving = isSaving
  }
}
