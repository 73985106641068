import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { IDeliveryConfigurations } from '~/client/graph'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import {
  CancelationReason,
  cancelationReasonsList,
} from '~/client/src/shared/enums/CancelationReason'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import getCancelationReasonTitle from '~/client/src/shared/utils/getCancelationReasonTitle'
import { customDebounce } from '~/client/src/shared/utils/util'

const DEBOUNCE_DELAY = 800

interface IProps {
  eventsStore?: DesktopEventStore
}

@inject('eventsStore')
@observer
export default class CancelationReasonsListConfig extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props)

    this.dispatchSaveConfig = customDebounce(
      this.dispatchSaveConfig,
      DEBOUNCE_DELAY,
    )
  }

  public render() {
    return (
      <div className="ml40 px12 col">
        {cancelationReasonsList.map(reason => (
          <Checkbox
            key={reason}
            className="w-fit-content py5"
            label={getCancelationReasonTitle(reason)}
            isChecked={!this.isReasonHidden(reason)}
            onClick={this.onReasonClick.bind(this, reason)}
          />
        ))}
      </div>
    )
  }

  private isReasonHidden = (reason: CancelationReason): boolean => {
    return this.deliveryConfig.hiddenCancelationReasons.includes(reason)
  }

  private onReasonClick(reason: CancelationReason) {
    if (!this.deliveryConfig.hiddenCancelationReasons) {
      this.deliveryConfig.hiddenCancelationReasons = []
    }

    const index = this.deliveryConfig.hiddenCancelationReasons.findIndex(
      r => r === reason,
    )

    if (index === -1) {
      this.deliveryConfig.hiddenCancelationReasons.push(reason)
    } else {
      this.deliveryConfig.hiddenCancelationReasons.splice(index, 1)
    }

    this.dispatchSaveConfig()
  }

  private dispatchSaveConfig = () => {
    if (this.areAllOptionsHidden) {
      this.initialState.delivery.hiddenFields[FieldIds.CANCELATION_REASON] =
        true

      this.eventsStore.dispatch(e.SAVE_DELIVERY_FIELDS_CONFIGURATIONS)
    }

    this.eventsStore.dispatch(e.SAVE_DELIVERY_CONFIGURATIONS)
  }

  private get areAllOptionsHidden(): boolean {
    return cancelationReasonsList.every(r =>
      this.deliveryConfig.hiddenCancelationReasons.includes(r),
    )
  }

  private get deliveryConfig(): IDeliveryConfigurations {
    return this.initialState.delivery.configurations
  }

  private get initialState(): DesktopInitialState {
    return this.eventsStore.appState
  }

  private get eventsStore(): DesktopEventStore {
    return this.props.eventsStore
  }
}
