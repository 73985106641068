import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, computed } from 'mobx'
import { observer } from 'mobx-react'

import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import TableSeparators from '~/client/src/shared/components/TableSeparators'
import Activity from '~/client/src/shared/models/Activity'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import {
  getBackgroundColor,
  getTextColor,
} from '~/client/src/shared/utils/tableColoring'

const WBS_LEVEL_OFFSET = 10

interface IProps {
  name: string
  caption: string
  level: number
  isExpanded: boolean
  collapseWbs: (name: string) => void
  toggleEntitySelection: (id: string) => void
  isEntitySelected: (id: string) => boolean
  groupActivities: Activity[]
  className?: string
  location?: LocationBase
}

@observer
export default class WBStextCell extends React.PureComponent<IProps> {
  public render() {
    const { name, level, isExpanded, className, caption, location } = this.props
    const icon = isExpanded ? IconNames.CARET_DOWN : IconNames.CARET_RIGHT

    const backgroundColor = getBackgroundColor(name, location)
    const textColor = getTextColor(name)

    return (
      <div
        style={{ backgroundColor, color: textColor }}
        className="full-width gant-left-coll-cell bb-light-grey text-bold pointer"
        onClick={this.toggleChildren}
      >
        <div className={'wbs-text row ' + (className || '')}>
          <div className="row w-max-content pl10 no-grow checkbox-container">
            <Checkbox
              isChecked={this.isGroupSelected}
              onClick={this.onToggle}
            />
          </div>
          <TableSeparators
            level={level}
            className="no-grow full-height br-palette-grey"
            marginLeft={WBS_LEVEL_OFFSET}
          />
          <div className="row x-start">
            <Icon className="pointer ml3 mr5" icon={icon} />
            <SitemapAttributeTag
              shouldShowAsTag={false}
              contentContainerClassName="text-ellipsis py2 row name-cell"
              dataObject={location}
            >
              {caption || name}
            </SitemapAttributeTag>
          </div>
        </div>
      </div>
    )
  }

  @action.bound
  private toggleChildren(event: React.MouseEvent<HTMLElement>) {
    const { collapseWbs, name } = this.props
    event.preventDefault()
    event.stopPropagation()

    collapseWbs(name)
  }

  @computed
  private get isGroupSelected(): boolean {
    const { isEntitySelected, groupActivities } = this.props

    return groupActivities?.every(({ code }) => isEntitySelected(code))
  }

  @action.bound
  private onToggle(event: React.MouseEvent<HTMLElement>) {
    const { toggleEntitySelection, groupActivities } = this.props
    event.preventDefault()
    event.stopPropagation()

    groupActivities.forEach(({ code }) => {
      toggleEntitySelection(code)
    })
  }
}
