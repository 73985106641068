import { action, computed, observable } from 'mobx'

import { TwoMonthsDatePickerMode } from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import CalendarPlaceholderStore from '~/client/src/shared/components/CalendarDayView/components/CalendarPlaceholder.store'
import IEditableCalendarEvent from '~/client/src/shared/components/CalendarDayView/components/IEditableCalendarEvent'
import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import { UrlParamKey } from '~/client/src/shared/constants/commonRoutes'
import CalendarEventLabelType from '~/client/src/shared/enums/CalendarEventLabelType'
import { defaultLogisticsPreferences } from '~/client/src/shared/interfaces/ILogisticsViewPreferences'
import IUserPreferences from '~/client/src/shared/interfaces/IUserPreferences'
import Announcement from '~/client/src/shared/models/Announcement'
import CalendarEvent, {
  CalendarEventEntityType,
} from '~/client/src/shared/models/CalendarEvent'
import GlobeView from '~/client/src/shared/models/GlobeView'
import SitePermit from '~/client/src/shared/models/Permit'
import Sitemap from '~/client/src/shared/models/Sitemap'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import { IFilters } from '~/client/src/shared/stores/InitialState'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ActivityFollowingsStore from '~/client/src/shared/stores/domain/ActivityFollowings.store'
import AnnouncementFollowingsStore from '~/client/src/shared/stores/domain/AnnouncementFollowings.store'
import AnnouncementsStore from '~/client/src/shared/stores/domain/Announcements.store'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import CastFollowingsStore from '~/client/src/shared/stores/domain/CastFollowings.store'
import ClosureFollowingsStore from '~/client/src/shared/stores/domain/ClosureFollowings.store'
import ClosuresStore from '~/client/src/shared/stores/domain/Closures.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import DeliveryFollowingsStore from '~/client/src/shared/stores/domain/DeliveryFollowings.store'
import GlobeViewsStore from '~/client/src/shared/stores/domain/GlobeViews.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import MaturixCastsStore from '~/client/src/shared/stores/domain/MaturixStores/MaturixCasts.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import SitePermitFollowingsStore from '~/client/src/shared/stores/domain/SitePermitFollowings.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import BaseLogisticsStore from '~/client/src/shared/stores/ui/BaseLogistics.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { NOOP } from '~/client/src/shared/utils/noop'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import { IDateFilters } from '../../components/Filters/DateSelector/DateSelector.store'
import LogisticsListTabs from '../../enums/LogisticsListTabs'
import ViewModes from '../../enums/ViewModes'
import DesktopInitialState from '../../stores/DesktopInitialState'
import DesktopEventStore from '../../stores/EventStore/DesktopEvents.store'

export default class LogisticsStore extends BaseLogisticsStore {
  @observable public isPermitCreationActive: boolean = false
  @observable public isAnnouncementCreationActive: boolean = false
  @observable public isAnnouncementDialogDisplayed: boolean = false
  @observable public displayedAnnouncement: Announcement = null
  @observable public selectedAnnouncements: Announcement[] = []
  @observable public selectedSitemap: Sitemap
  @observable public selectedGlobe: GlobeView

  @observable public isPermitDialogDisplayed: boolean = false
  @observable public shouldShowConfirmDeleteDialog: boolean = false
  @observable public displayedPermitId: string = null
  @observable public areDeliveriesExpanded: boolean = true
  @observable public areActivitiesExpanded: boolean = true
  @observable public arePermitsExpanded: boolean = true
  @observable public areAnnouncementsExpanded: boolean = true

  @observable public areAnnouncementsHidden: boolean =
    defaultLogisticsPreferences.areAnnouncementsHidden
  @observable public arePermitsHidden: boolean =
    defaultLogisticsPreferences.arePermitsHidden
  @observable public areDeliveriesHidden: boolean =
    defaultLogisticsPreferences.areDeliveriesHidden
  @observable public areActivitiesHidden: boolean =
    defaultLogisticsPreferences.areActivitiesHidden
  @observable public areMonitoringsHidden: boolean =
    defaultLogisticsPreferences.areMonitoringsHidden
  @observable public selectedTabId: LogisticsListTabs =
    LogisticsListTabs.CLOSURES
  @observable public selectedDatePickerMode: TwoMonthsDatePickerMode =
    TwoMonthsDatePickerMode.ONE_DAY

  @observable public editableCalendarEvent: IEditableCalendarEvent = null
  @observable public isDateMoveConfirmModalOpen: boolean = false

  public dateFilters = observable<IDateFilters>({
    startDate: null,
    daysToAdd: 0,
    isDateFilterActive: true,
  })

  public clearPostEventCallback: () => void = NOOP

  @computed
  public get isPermitUpdating(): boolean {
    return this.state.loading.get(e.SAVE_SITE_PERMITS)
  }

  @computed
  public get displayedPermit(): SitePermit {
    return (
      this.displayedPermitId &&
      this.sitePermitsStore.getFormById(this.displayedPermitId)?.copy()
    )
  }

  public constructor(
    protected readonly announcementsStore: AnnouncementsStore,
    protected readonly sitePermitsStore: SitePermitsStore,
    protected readonly projectDateStore: ProjectDateStore,
    protected readonly deliveriesStore: DeliveriesStore,
    protected readonly activitiesStore: ActivitiesStore,
    protected readonly statusUpdatesStore: StatusUpdatesStore,
    protected readonly eventsStore: DesktopEventStore,
    protected readonly sitemapsStore: SitemapsStore,
    protected readonly globeViewsStore: GlobeViewsStore,
    closuresStore: ClosuresStore,
    maturixCastsStore: MaturixCastsStore,
    private readonly deliveryDetailsStore: DeliveryDetailsStore,
    permitTypesStore: PermitTypesStore,
    locationAttributesStore: LocationAttributesStore,
    protected readonly deliveryFollowingsStore: DeliveryFollowingsStore,
    protected readonly sitePermitFollowingsStore: SitePermitFollowingsStore,
    protected readonly activityFollowingsStore: ActivityFollowingsStore,
    closureFollowingsStore: ClosureFollowingsStore,
    protected readonly announcementFollowingsStore: AnnouncementFollowingsStore,
    private readonly basemapsStore: BasemapsStore,
    protected readonly calendarPlaceholderStore?: CalendarPlaceholderStore,
    castFollowingsStore?: CastFollowingsStore,
    companiesStore?: CompaniesStore,
    protected readonly isPermitOnly?: boolean,
  ) {
    super(
      announcementsStore,
      sitePermitsStore,
      projectDateStore,
      deliveriesStore,
      activitiesStore,
      statusUpdatesStore,
      eventsStore,
      sitemapsStore,
      globeViewsStore,
      maturixCastsStore,
      deliveryFollowingsStore,
      sitePermitFollowingsStore,
      activityFollowingsStore,
      closureFollowingsStore,
      announcementFollowingsStore,
      castFollowingsStore,
      permitTypesStore,
      locationAttributesStore,
      companiesStore,
      closuresStore,
      isPermitOnly,
    )

    this.setInitialDateValue()
  }

  public get isOneDayCalendarModeActive() {
    return this.selectedDatePickerMode === TwoMonthsDatePickerMode.ONE_DAY
  }

  public get isProjectCalendarModeActive(): boolean {
    return this.selectedDatePickerMode === TwoMonthsDatePickerMode.PROJECT
  }

  public initPostEventCallback = (): void => {
    this.clearPostEventCallback = this.eventsStore.addPostEventCallback(
      this.onEvent,
    )
  }

  @action.bound
  public setEditableEvent(event: IEditableCalendarEvent) {
    this.editableCalendarEvent = event
  }

  @action.bound
  public openDateMoveConfirmModal() {
    this.isDateMoveConfirmModalOpen = true
  }

  @action.bound
  public hideDateMoveConfirmModal() {
    this.isDateMoveConfirmModalOpen = false
    this.calendarPlaceholderStore.resetEventPlaceholder()
  }

  @action.bound
  public onEventClicked(event: CalendarEvent) {
    const isNewType = event.labelType === CalendarEventLabelType.New

    if (isNewType) {
      return
    }

    switch (event.entityType) {
      case CalendarEventEntityType.Form:
        this.showPermitViewOrApprovalDialog(event.data)
        break
    }

    if (this.calendarPlaceholderStore.eventPlaceholder) {
      this.calendarPlaceholderStore.resetEventPlaceholder()
    }
  }

  @action.bound
  public loadLogisticsViewPreferences(): void {
    const { user } = this.state

    if (!user) {
      return
    }

    const userPrefs: IUserPreferences = {
      logisticsViewPreferences: defaultLogisticsPreferences,
    }

    try {
      Object.assign(userPrefs, JSON.parse(localStorage.getItem(user.id)))
    } finally {
      const { logisticsViewPreferences } = userPrefs

      this.areAnnouncementsHidden =
        logisticsViewPreferences?.areAnnouncementsHidden
      this.arePermitsHidden = logisticsViewPreferences?.arePermitsHidden
      this.areDeliveriesHidden = logisticsViewPreferences?.areDeliveriesHidden
      this.areActivitiesHidden = logisticsViewPreferences?.areActivitiesHidden
      this.areMonitoringsHidden = logisticsViewPreferences?.areMonitoringsHidden
    }
  }

  @action.bound
  public onEvent(eventContext: EventContext): void {
    const [eventType] = eventContext.event

    switch (eventType) {
      case e.ACTIVATE_PROJECT:
        return this.hideAllDialogs()
      case e.SITEMAPS_RECEIVED:
      case e.SITEMAP_UPDATED:
      case e.GLOBE_VIEWS_RECEIVED:
      case e.GLOBE_VIEW_UPDATED:
      case e.GLOBE_VIEW_ITEM_DATA_UPDATED:
      case e.SITEMAP_ITEM_DATA_UPDATED:
      case e.LOGISTICS_CONFIGURATIONS_RECEIVED:
      case e.LOGISTICS_CONFIGURATIONS_UPDATED:
      case e.FORMS_CONFIGURATIONS_RECEIVED:
      case e.FORMS_CONFIGURATIONS_UPDATED:
        this.ensureCorrectViewMode()
    }
  }

  @action.bound
  public applyPageFromQueryParams(): void {
    const queryBaseStoreMap = {
      [UrlParamKey.Announcement]: {
        store: this.announcementsStore,
        action: item => {
          this.showAnnouncementContent(item)
        },
      },
      [UrlParamKey.Form]: {
        store: this.sitePermitsStore,
        action: item => {
          this.showPermitViewOrApprovalDialog(item)
        },
      },
    }

    const urlParams = new URLSearchParams(location.search)

    const queryParams = Array.from(urlParams.keys())
    const lastQueryParam = queryParams.at(-1) || EMPTY_STRING

    const storeActionPair = queryBaseStoreMap[lastQueryParam]

    if (!lastQueryParam || !storeActionPair) {
      return
    }

    const itemId = urlParams.get(lastQueryParam)

    if (!itemId || !storeActionPair.store.isDataReceived) return

    const item = storeActionPair.store.byId.get(itemId)

    if (item) {
      storeActionPair.action(item)
      this.dateFilters.startDate = this.projectDateStore.startOfDay(
        item.startDate,
      )
    }
  }

  @action.bound
  public toggleActivitiesExpandState = (): void => {
    this.areActivitiesExpanded = !this.areActivitiesExpanded
  }

  @action.bound
  public toggleDeliveriesExpandState = (): void => {
    this.areDeliveriesExpanded = !this.areDeliveriesExpanded
  }

  @action.bound
  public togglePermitsExpandState = (): void => {
    this.arePermitsExpanded = !this.arePermitsExpanded
  }

  @action.bound
  public toggleAnnouncementsExpandState = (): void => {
    this.areAnnouncementsExpanded = !this.areAnnouncementsExpanded
  }

  @action.bound
  public toggleAnnouncementsHiddenState(): void {
    this.areAnnouncementsHidden = !this.areAnnouncementsHidden
    this.saveLogisticsViewPreferences()
  }

  @action.bound
  public togglePermitsHiddenState(): void {
    this.arePermitsHidden = !this.arePermitsHidden
    this.saveLogisticsViewPreferences()
  }

  @action.bound
  public toggleDeliveriesHiddenState(): void {
    this.areDeliveriesHidden = !this.areDeliveriesHidden
    this.saveLogisticsViewPreferences()
  }

  @action.bound
  public toggleActivitiesHiddenState(): void {
    this.areActivitiesHidden = !this.areActivitiesHidden
    this.saveLogisticsViewPreferences()
  }

  @action.bound
  public toggleMonitoringsHiddenState(): void {
    this.areMonitoringsHidden = !this.areMonitoringsHidden
    this.saveLogisticsViewPreferences()
  }

  @action.bound
  public setInitialDateValue(): void {
    this.dateFilters.startDate = this.projectDateStore.startOfDay(new Date())
  }

  @action.bound
  public changeDatePickerMode(mode: TwoMonthsDatePickerMode): void {
    this.selectedDatePickerMode = mode
  }

  @action.bound
  public initializeCalendarView(): void {
    this.changeDatePickerMode(TwoMonthsDatePickerMode.FIXED_WEEK)
    this.dateFilters.isDateFilterActive = true
  }

  public get state(): DesktopInitialState {
    return this.eventsStore.appState
  }

  public get filters(): IFilters {
    return this.isPermitOnly
      ? this.state.formsFilters
      : this.state.logisticsFilters
  }

  public get viewMode(): ViewModes {
    return this.isPermitOnly
      ? this.state.formsList.viewMode
      : this.state.logisticsList.viewMode
  }

  public get isCalendarViewMode(): boolean {
    return this.viewMode === ViewModes.Calendar
  }

  @action
  private setViewMode(viewMode: ViewModes) {
    if (this.isPermitOnly) {
      this.state.formsList.viewMode = viewMode
    } else {
      this.state.logisticsList.viewMode = viewMode
    }
  }

  @action.bound
  public ensureCorrectViewMode(): void {
    if (this.viewMode === ViewModes.Map && this.isMapViewDisabled) {
      this.setViewMode(ViewModes.List)
    }
  }

  @action.bound
  public openPermitCreationForm(): void {
    this.hideAnnouncementDialog()
    this.hidePermitDialog()
    this.hideDeliverySideView()
    this.hideActivitySideView()
    this.isPermitCreationActive = true
  }

  @action.bound
  public hidePermitCreationForm(): void {
    this.isPermitCreationActive = false
    this.editableCalendarEvent = null
    this.calendarPlaceholderStore.resetEventPlaceholder()
  }

  @action.bound
  public hideAnnouncementCreationForm(): void {
    this.isAnnouncementCreationActive = false
    this.displayedAnnouncement = null
  }

  @action.bound
  public showCreationAndEditionAnnouncementForm(
    announcement?: Announcement,
  ): void {
    this.hideAllDialogs()
    this.displayedAnnouncement = announcement?.copy()
    this.isAnnouncementCreationActive = true
  }

  @action.bound
  public showAnnouncementContent(announcement: Announcement): void {
    this.hideAllDialogs()
    this.displayedAnnouncement = announcement?.copy()
    this.isAnnouncementDialogDisplayed = true
  }

  @action.bound
  public removeAnnouncements(announcements: Announcement[]): void {
    this.hideAnnouncementDialog()
    this.eventsStore.dispatch(
      e.DELETE_ANNOUNCEMENTS,
      announcements.map(({ id }) => id),
      this.hideRemoveDialog,
    )
  }

  @action.bound
  public showRemoveDialog(announcement: Announcement): void {
    this.selectedAnnouncements = [announcement]
    this.shouldShowConfirmDeleteDialog = true
  }

  @action.bound
  public hideRemoveDialog(): void {
    this.shouldShowConfirmDeleteDialog = false
    this.selectedAnnouncements = []
  }

  @action.bound
  public hideAnnouncementDialog(): void {
    this.isAnnouncementDialogDisplayed = false
    this.displayedAnnouncement = null
  }

  @action.bound
  public showPermitViewOrApprovalDialog(permit: SitePermit): void {
    this.hideAllDialogs()
    this.displayedPermitId = permit.id
    this.isPermitDialogDisplayed = true
  }

  @action.bound
  public hidePermitDialog(): void {
    this.displayedPermitId = null
    this.isPermitDialogDisplayed = false
    this.isDateMoveConfirmModalOpen = false
    this.editableCalendarEvent = null
  }

  @action.bound
  public displayDeliverySideView(deliveryId: string): void {
    const delivery = this.deliveriesStore.byId.get(deliveryId)

    if (delivery) {
      this.hideAllDialogs()
      this.deliveryDetailsStore.setViewMode(delivery)
    }
  }

  @action.bound
  public hideDeliverySideView(): void {
    this.deliveryDetailsStore.displayedDelivery = null
  }

  @action.bound
  public displayActivitySideView(activityId: string): void {
    this.hideAllDialogs()
    this.activitiesStore.select(activityId)
  }

  @action.bound
  public hideActivitySideView(): void {
    this.activitiesStore.select(EMPTY_STRING)
  }

  @action.bound
  public hideAllDialogs(): void {
    this.hideAnnouncementDialog()
    this.hidePermitCreationForm()
    this.hidePermitDialog()
    this.hideDeliverySideView()
    this.hideActivitySideView()
  }

  public get isLoaderShown(): boolean {
    const { isDeliveriesDisabled, isFormsDisabled, isTrackerDisabled, forms } =
      this.state
    const isBaseLoaderShown =
      !this.sitemapsStore.isDataReceived ||
      !this.closuresStore.isDataReceived ||
      !this.companiesStore.isDataReceived
    const areFormsLoading =
      !this.permitTypesStore.isDataReceived ||
      !this.sitePermitsStore.isDataReceived ||
      !this.globeViewsStore.isDataReceived ||
      !this.basemapsStore.isDataReceived

    if (this.isPermitOnly) {
      return isBaseLoaderShown || areFormsLoading || !forms.isDataReceived
    }

    return (
      isBaseLoaderShown ||
      !this.announcementsStore.isDataReceived ||
      (!isFormsDisabled && areFormsLoading) ||
      (!isDeliveriesDisabled && !this.deliveriesStore.isDataReceived) ||
      (!isTrackerDisabled && !this.activitiesStore.isDataReceived)
    )
  }

  public get isDateFilterActive(): boolean {
    const { isDateFilterActive } = this.dateFilters
    return isDateFilterActive
  }

  public get startDate(): Date {
    const { startDate } = this.dateFilters
    return this.isDateFilterActive
      ? startDate
      : this.projectDateStore.getMinAvailableDate()
  }

  public get endDate(): Date {
    if (!this.isDateFilterActive) {
      return this.projectDateStore.getMaxAvailableDate()
    }

    return this.projectDateStore.getEndDate(this.dateFilters)
  }

  @action.bound
  private saveLogisticsViewPreferences(): void {
    const { user } = this.state

    if (!user) {
      return
    }

    const userPrefs: IUserPreferences = {
      logisticsViewPreferences: defaultLogisticsPreferences,
    }

    try {
      Object.assign(userPrefs, JSON.parse(localStorage.getItem(user.id)))
    } finally {
      const { logisticsViewPreferences } = userPrefs

      logisticsViewPreferences.areAnnouncementsHidden =
        this.areAnnouncementsHidden
      logisticsViewPreferences.arePermitsHidden = this.arePermitsHidden
      logisticsViewPreferences.areDeliveriesHidden = this.areDeliveriesHidden
      logisticsViewPreferences.areActivitiesHidden = this.areActivitiesHidden
    }

    localStorage.setItem(user.id, JSON.stringify(userPrefs))
  }
}
