import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { ContentObjectStatus, StatusUpdateType } from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import Activity from '~/client/src/shared/models/Activity'
import CategoriesOfVarianceStore from '~/client/src/shared/stores/domain/CategoriesOfVariance.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import FlagsStore from '~/client/src/shared/stores/domain/Flags.store'
import RfisStore from '~/client/src/shared/stores/domain/Rfis.store'
import SafetyHazardsStore from '~/client/src/shared/stores/domain/SafetyHazards.store'
import ScheduleCommentsStore from '~/client/src/shared/stores/domain/ScheduleComments.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

interface INotificationsIndicatorsProps {
  activity: Activity
  rfisStore?: RfisStore
  flagsStore?: FlagsStore
  deliveriesStore?: DeliveriesStore
  scheduleCommentsStore?: ScheduleCommentsStore
  categoriesOfVarianceStore?: CategoriesOfVarianceStore
  safetyHazardsStore?: SafetyHazardsStore
  hideCriticalPath?: boolean
  currentViewDate?: Date
  projectDateStore?: ProjectDateStore
  statusUpdatesStore?: StatusUpdatesStore
}

@inject(
  'rfisStore',
  'flagsStore',
  'deliveriesStore',
  'scheduleCommentsStore',
  'projectDateStore',
  'categoriesOfVarianceStore',
  'safetyHazardsStore',
  'statusUpdatesStore',
)
@observer
export default class NotificationsIndicators extends React.Component<INotificationsIndicatorsProps> {
  @computed
  private get rfiCount(): number {
    const { rfisStore, activity } = this.props
    return rfisStore.list.filter(
      rfi => rfi.isOpen && rfi.activityObjectId === activity.code,
    ).length
  }

  @computed
  private get scCount(): number {
    const { scheduleCommentsStore, activity } = this.props
    return scheduleCommentsStore.list.filter(
      sc => sc.isOpen && sc.activityObjectId === activity.code,
    ).length
  }

  @computed
  private get categoryOfVarianceCount(): number {
    const { categoriesOfVarianceStore, activity } = this.props
    return categoriesOfVarianceStore.list.filter(
      categoryOfVariance =>
        categoryOfVariance.status === ContentObjectStatus.Open &&
        categoryOfVariance.activityObjectId === activity.code,
    ).length
  }

  @computed
  private get safetyHazardCount(): number {
    const { safetyHazardsStore, activity } = this.props
    return safetyHazardsStore.list.filter(
      safetyHazard =>
        safetyHazard.status === ContentObjectStatus.Open &&
        safetyHazard.activityObjectId === activity.code,
    ).length
  }

  @computed
  private get flagCount(): number {
    const { flagsStore, activity } = this.props
    return flagsStore.list.filter(
      flag =>
        flag.status === ContentObjectStatus.Open &&
        flag.activityObjectId === activity.code,
    ).length
  }

  @computed
  private get deliveryOfCurrentDateCount(): number {
    const { deliveriesStore, activity, currentViewDate } = this.props

    if (!currentViewDate) {
      return 0
    }

    const { isSameDay } = this.props.projectDateStore
    return deliveriesStore.list.filter(
      delivery =>
        delivery.activityId === activity.code &&
        isSameDay(delivery.startDate, currentViewDate),
    ).length
  }

  @computed
  private get changesCount(): number {
    const { statusUpdatesStore, activity } = this.props

    const statusUpdates = statusUpdatesStore.getAllStatusesUpdatesByActivity(
      activity,
      true,
    )

    return statusUpdates.filter(su => su.type === StatusUpdateType.Change)
      .length
  }

  public render() {
    const { activity } = this.props
    const { isOnCriticalPath } = activity

    return (
      <>
        <Icons.Critical
          className={classList({
            'px5-right': true,
            hidden: !isOnCriticalPath,
          })}
        />
        <Icons.Rfi
          className={classList({
            'px5-right': true,
            hidden: this.rfiCount === 0,
          })}
        />
        <Icons.Variance
          className={classList({
            'px5-right': true,
            hidden: this.categoryOfVarianceCount === 0,
          })}
        />
        <Icons.Safety
          className={classList({
            'px5-right': true,
            hidden: this.safetyHazardCount === 0,
          })}
        />
        <Icons.Flag
          className={classList({
            'icon-red px5-right': true,
            hidden: this.flagCount === 0,
          })}
        />
        <Icons.DateChangeBlue
          className={classList({
            'px5-right': true,
            hidden: this.scCount === 0,
          })}
        />
        <Icons.Delivery
          className={classList({
            'px5-right': true,
            hidden: this.deliveryOfCurrentDateCount === 0,
          })}
        />
        {/* // TODO: find appropriate icon to mark activity as changed */}
        {/* <Icons.DateChangeBlue
          className={classList({
            'px5-right': true,
            hidden: this.changesCount === 0,
          })}
        /> */}
      </>
    )
  }
}
