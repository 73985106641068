import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import {
  BasicDataKeys,
  ILWFCCategory,
  ILWFCColumn,
  ILWFCRow,
  LWFCRowData,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import TableSeparators from '~/client/src/shared/components/TableSeparators'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import {
  getBackgroundColor,
  getTextColor,
} from '~/client/src/shared/utils/tableColoring'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import { DataKeys } from '../../../DeliveriesList.store'

// localization: no display text to translate

interface IProps {
  style: any
  columnIndex: number
  rowIndex: number
  rows: ILWFCRow[]
  columns: ILWFCColumn[]
  columnKeyToCellRenderer: {
    [columnKey: string]: (
      value: any,
      companiesStore?: CompaniesStore,
    ) => JSX.Element
  }
  columnKeyToDefaultRenderer: {
    [columnKey: string]: (value: any) => JSX.Element
  }
  collapsedCategories?: Map<string, boolean>
  getCellClassNames?: (
    columnKey: string,
    data?: LWFCRowData,
  ) => { [className: string]: boolean }
  onCategoryCollapsingToggle?: (categoryId: string) => void
  onCellClick?: (rowData: LWFCRowData, columnKey: string) => void
  onCategoryCheckboxToggle?: (category: ILWFCCategory) => void

  companiesStore?: CompaniesStore
}

const { CARET_DOWN, CARET_RIGHT } = IconNames
const CATEGORY_LABEL_COLUMN_INDEX = 1

@inject('companiesStore')
@observer
export default class DeliveriesTableDataCell extends React.Component<IProps> {
  public render() {
    const { style, columnIndex, rowIndex, rows, columns } = this.props
    const column = columns[columnIndex]
    const { category, data, level } = rows[rowIndex]
    const cellProps = { style }

    return category
      ? this.renderCategoryCell(
          data,
          category,
          column,
          columnIndex,
          style,
          level,
        )
      : this.renderDataCell(data, column, cellProps, level)
  }

  private renderCategoryCell = (
    rowData: LWFCRowData,
    category: ILWFCCategory,
    { dataKey }: ILWFCColumn,
    columnIndex: number,
    style: any,
    level?: number,
  ): JSX.Element => {
    const { collapsedCategories, getCellClassNames } = this.props
    const { categoryId, categoryLabel, isChecked } = category

    const deliveryAttribute = rowData as LocationAttributeBase

    const shouldShowCategoryLabel = columnIndex === CATEGORY_LABEL_COLUMN_INDEX

    const backgroundColor = getBackgroundColor(categoryId, deliveryAttribute)
    const textColor = getTextColor(categoryId)

    let cellContent = null

    if (shouldShowCategoryLabel) {
      const chevronIcon = collapsedCategories.get(categoryId)
        ? CARET_RIGHT
        : CARET_DOWN

      cellContent = (
        <>
          <TableSeparators
            level={level}
            className="no-grow full-height br-palette-grey ml10"
          />
          <div className="row no-grow">
            <Icon className="pointer ml3 mr5" icon={chevronIcon} />
            <SitemapAttributeTag
              shouldShowAsTag={false}
              contentContainerClassName="text-ellipsis py2 row name-cell"
              dataObject={deliveryAttribute}
            >
              {categoryLabel}
            </SitemapAttributeTag>
          </div>
        </>
      )
    } else if (dataKey === BasicDataKeys.CHECKBOX) {
      cellContent = (
        <Checkbox
          className="checkbox-selector text large no-bold"
          isCentered={true}
          isChecked={isChecked}
          onClick={this.handleCategoryCheckboxToggle.bind(null, category)}
        />
      )
    }

    return (
      <div
        style={{ ...style, backgroundColor, color: textColor }}
        onClick={this.toggleChildren.bind(this, categoryId)}
        className={classList({
          'full-height full-width row bb-palette-grey text large bold': true,
          'cell category-cell no-select pointer': true,
          'category-cell-name': shouldShowCategoryLabel,
          ...getCellClassNames(dataKey),
        })}
      >
        {cellContent}
      </div>
    )
  }

  private renderDataCell = (
    data: LWFCRowData,
    column: ILWFCColumn,
    cellProps: any,
    level: number,
  ): JSX.Element => {
    const {
      onCellClick,
      getCellClassNames,
      columnKeyToCellRenderer,
      columnKeyToDefaultRenderer,
      companiesStore,
    } = this.props
    const { dataKey: columnKey, isMonospace } = column
    const value = data[columnKey]

    const renderer =
      columnKeyToCellRenderer[columnKey] ||
      columnKeyToDefaultRenderer[columnKey] ||
      (() => value?.toString() || NO_VALUE)

    const element = renderer(value, companiesStore)

    return (
      <div
        {...cellProps}
        onClick={onCellClick.bind(null, data, columnKey)}
        className={classList({
          'full-height full-width row bb-light-input-border cell text large':
            true,
          'gant-selection': data[BasicDataKeys.CHECKBOX],
          monospace: isMonospace,
          ...getCellClassNames(columnKey, data),
        })}
      >
        {columnKey === DataKeys.BOOKING_ID && (
          <TableSeparators
            level={level}
            className="no-grow full-height br-palette-grey ml10"
          />
        )}
        <div
          className={classList({
            'text-ellipsis': true,
            'row ml10': columnKey === DataKeys.BOOKING_ID,
          })}
        >
          {element}
        </div>
      </div>
    )
  }

  @action.bound
  private handleCategoryCheckboxToggle(
    category: ILWFCCategory,
    e: React.MouseEvent<HTMLElement>,
  ) {
    e.stopPropagation()
    this.props.onCategoryCheckboxToggle(category)
  }

  @action.bound
  private toggleChildren(
    categoryId: string,
    event: React.MouseEvent<HTMLElement>,
  ) {
    const { onCategoryCollapsingToggle } = this.props
    event.preventDefault()
    event.stopPropagation()

    onCategoryCollapsingToggle(categoryId)
  }
}
