import { FormTypeGroupingOption } from '~/client/src/shared/enums/FormTypeGroupingOption'
import FilterInfo from '~/client/src/shared/stores/substates/FilterInfo'

export default class FormTypesFiltersSettingState {
  public desktopFilterMap: { [key: string]: FilterInfo } = {
    [FormTypeGroupingOption.NONE]: new FilterInfo(
      FormTypeGroupingOption.NONE,
      false,
    ),
    [FormTypeGroupingOption.APP]: new FilterInfo(
      FormTypeGroupingOption.APP,
      false,
    ),
    [FormTypeGroupingOption.CATEGORY]: new FilterInfo(
      FormTypeGroupingOption.CATEGORY,
      false,
    ),
  }
}
