import MaterialsGroupingOption from '~/client/src/shared/enums/MaterialsGroupingOption'
import FilterInfo from '~/client/src/shared/stores/substates/FilterInfo'

export default class MaterialsFiltersSettingState {
  public desktopFilterMap: { [key: string]: FilterInfo } = {
    [MaterialsGroupingOption.MATERIAL_CATEGORY]: new FilterInfo(
      MaterialsGroupingOption.MATERIAL_CATEGORY,
      false,
    ),
    [MaterialsGroupingOption.MATERIAL]: new FilterInfo(
      MaterialsGroupingOption.MATERIAL,
      false,
    ),
    [MaterialsGroupingOption.MATERIAL_DESCRIPTION]: new FilterInfo(
      MaterialsGroupingOption.MATERIAL_DESCRIPTION,
      false,
    ),

    [MaterialsGroupingOption.COMPANY]: new FilterInfo(
      MaterialsGroupingOption.COMPANY,
      false,
    ),
    [MaterialsGroupingOption.DATE]: new FilterInfo(
      MaterialsGroupingOption.DATE,
      false,
    ),
    [MaterialsGroupingOption.STATUS]: new FilterInfo(
      MaterialsGroupingOption.STATUS,
      false,
    ),

    [MaterialsGroupingOption.BUILDING]: new FilterInfo(
      MaterialsGroupingOption.BUILDING,
      false,
    ),
    [MaterialsGroupingOption.LEVEL]: new FilterInfo(
      MaterialsGroupingOption.LEVEL,
      false,
    ),
    [MaterialsGroupingOption.ZONE]: new FilterInfo(
      MaterialsGroupingOption.ZONE,
      false,
    ),
    [MaterialsGroupingOption.AREA]: new FilterInfo(
      MaterialsGroupingOption.AREA,
      false,
    ),
    [MaterialsGroupingOption.GATE]: new FilterInfo(
      MaterialsGroupingOption.GATE,
      false,
    ),
    [MaterialsGroupingOption.ROUTE]: new FilterInfo(
      MaterialsGroupingOption.ROUTE,
      false,
    ),
    [MaterialsGroupingOption.EQUIPMENT]: new FilterInfo(
      MaterialsGroupingOption.EQUIPMENT,
      false,
    ),
    [MaterialsGroupingOption.LOGISTICS_OBJECT]: new FilterInfo(
      MaterialsGroupingOption.LOGISTICS_OBJECT,
      false,
    ),
    [MaterialsGroupingOption.VERTICAL_OBJECT]: new FilterInfo(
      MaterialsGroupingOption.VERTICAL_OBJECT,
      false,
    ),
    [MaterialsGroupingOption.INTERIOR_PATH]: new FilterInfo(
      MaterialsGroupingOption.INTERIOR_PATH,
      false,
    ),
    [MaterialsGroupingOption.INTERIOR_DOOR]: new FilterInfo(
      MaterialsGroupingOption.INTERIOR_DOOR,
      false,
    ),
    [MaterialsGroupingOption.STAGING]: new FilterInfo(
      MaterialsGroupingOption.STAGING,
      false,
    ),

    [MaterialsGroupingOption.CURRENT_LOCATION]: new FilterInfo(
      MaterialsGroupingOption.CURRENT_LOCATION,
      false,
    ),

    [MaterialsGroupingOption.VENDOR]: new FilterInfo(
      MaterialsGroupingOption.VENDOR,
      false,
    ),
  }
}
