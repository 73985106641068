import { action, computed, observable } from 'mobx'

import { RoundBracket } from '~/client/src/shared/enums/Brackets'
import { TagType } from '~/client/src/shared/enums/TagType'
import {
  AndOrOperator,
  IsNotOperator,
} from '~/client/src/shared/models/LogicOperation'
import OperationRule from '~/client/src/shared/models/OperationRule'
import Tag, { ITag } from '~/client/src/shared/models/Tag'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import ExpressionElement from '~/client/src/shared/utils/ExpressionParser/ExpressionElement'
import ExpressionParser, {
  companyTypeList,
  locationTypesList,
} from '~/client/src/shared/utils/ExpressionParser/ExpressionParser'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'
import { copyArray } from '~/client/src/shared/utils/util'

// localization: no display text to translate

const NOT = '!'

export default class BallInCourtRulesTableRowStore {
  @observable public shouldShowUsersDirectory = false
  @observable public shouldShowLocationPicker = false
  @observable public shouldShowCompaniesPicker = false

  @observable public hoveredRuleId: string = null
  @observable public selectedBicRule: OperationRule = null

  @observable public allExpressionElements: ExpressionElement[] = []

  private readonly expParser: ExpressionParser = null

  public constructor(
    private readonly tagsStore: TagsStore,
    bicRule: OperationRule,
    private readonly saveRuleExpression: (
      rule: OperationRule,
      locationAndCompanyExpression: string,
    ) => void,
  ) {
    this.expParser = new ExpressionParser(tagsStore)

    this.init(bicRule)
  }

  @action.bound
  public init(bicRule: OperationRule) {
    this.selectedBicRule = bicRule

    this.allExpressionElements = this.expParser.fromStrToElements(
      this.selectedBicRule.expression,
      true,
    )
  }

  @action.bound
  public changeHoveredRuleId(ruleId: string) {
    this.hoveredRuleId = ruleId
  }

  @action.bound
  public resetHoveredRuleId() {
    this.changeHoveredRuleId(null)
  }

  @action.bound
  public toggleUsersDirectory() {
    this.shouldShowUsersDirectory = !this.shouldShowUsersDirectory
  }

  @action.bound
  public hideUsersDirectory() {
    this.shouldShowUsersDirectory = false
  }

  @action.bound
  public toggleLocationPicker() {
    this.shouldShowLocationPicker = !this.shouldShowLocationPicker
  }

  @action.bound
  public hideLocationPicker() {
    this.shouldShowLocationPicker = false
  }

  @action.bound
  public toggleCompaniesPicker() {
    this.shouldShowCompaniesPicker = !this.shouldShowCompaniesPicker
  }

  @action.bound
  public hideCompaniesPicker() {
    this.shouldShowCompaniesPicker = false
  }

  @action.bound
  public addNewTagToElements(tagId: string) {
    const tagDto = this.tagsStore.getTagById(tagId)

    if (!tagDto) {
      return
    }

    const newCompanyElements = copyArray(this.companyElements)
    const newLocationElements = copyArray(this.locationElements)

    if (companyTypeList.includes(tagDto.type)) {
      this.addNewElement(newCompanyElements, tagDto)
    } else if (locationTypesList.includes(tagDto.type)) {
      this.addNewElement(newLocationElements, tagDto)
    } else {
      return
    }

    this.changeRuleExpression(
      newLocationElements,
      newCompanyElements,
      this.locationNotOperatorValue,
      this.companyNotOperatorValue,
    )
  }

  @action.bound
  public removeTagFromElements(tagId: string) {
    const newCompanyElements = copyArray(this.companyElements)
    const newLocationElements = copyArray(this.locationElements)

    this.removeExistingElement(newCompanyElements, tagId)
    this.removeExistingElement(newLocationElements, tagId)

    this.changeRuleExpression(
      newLocationElements,
      newCompanyElements,
      this.locationNotOperatorValue,
      this.companyNotOperatorValue,
    )
  }

  @action.bound
  public clearAllElements(isCompaniesElements?: boolean) {
    if (!this.locationElements.length && !this.companyElements.length) {
      return
    }

    if (isCompaniesElements) {
      return this.changeRuleExpression(
        this.locationElements,
        [],
        this.locationNotOperatorValue,
        this.companyNotOperatorValue,
      )
    }

    this.changeRuleExpression(
      [],
      this.companyElements,
      this.locationNotOperatorValue,
      this.companyNotOperatorValue,
    )
  }

  @action.bound
  public changeNotOperator(newOperator: IsNotOperator, isCompany?: boolean) {
    if (!this.locationElements.length && !this.companyElements.length) {
      return
    }

    if (isCompany) {
      return this.changeRuleExpression(
        this.locationElements,
        this.companyElements,
        this.locationNotOperatorValue,
        newOperator,
      )
    }

    this.changeRuleExpression(
      this.locationElements,
      this.companyElements,
      newOperator,
      this.companyNotOperatorValue,
    )
  }

  @action.bound
  private changeRuleExpression(
    locationsElements: ExpressionElement[],
    companiesElements: ExpressionElement[],
    locationNotOperatorValue: IsNotOperator,
    companyNotOperatorValue: IsNotOperator,
  ) {
    const locationsExpression =
      ExpressionParser.fromElementsToStr(locationsElements)

    const companiesExpression =
      ExpressionParser.fromElementsToStr(companiesElements)

    let newExpression = EMPTY_STRING

    const convertedLocationNotOperator =
      locationNotOperatorValue === IsNotOperator.IS_NOT ? NOT : EMPTY_STRING
    const convertedCompanyNotOperator =
      companyNotOperatorValue === IsNotOperator.IS_NOT ? NOT : EMPTY_STRING

    if (locationsExpression) {
      newExpression = newExpression.concat(
        `${convertedLocationNotOperator} ( ${locationsExpression} )`,
      )
    }

    if (companiesExpression) {
      const companiesExpressionWithNot = `${convertedCompanyNotOperator} ( ${companiesExpression} )`

      newExpression = locationsExpression
        ? newExpression.concat(
            ` ${AndOrOperator.AND} ${companiesExpressionWithNot}`,
          )
        : newExpression.concat(companiesExpressionWithNot)
    }

    newExpression = newExpression.trim()

    this.hideLocationPicker()
    this.hideCompaniesPicker()

    this.saveRuleExpression(this.selectedBicRule, newExpression)
  }

  private getElementsByType(
    elements: ExpressionElement[],
    tagTypes: TagType[],
  ): ExpressionElement[] {
    const elementsWithTags: ExpressionElement[] =
      ExpressionParser.getElementsInBrackets(elements)

    if (!elementsWithTags?.length) {
      return []
    }

    const onlyTagsArray = elementsWithTags.filter(el =>
      ExpressionParser.isTagElement(el),
    )

    const areTagsIncludesInTypes = onlyTagsArray.every(el =>
      tagTypes.includes((el as Tag)?.type),
    )

    return areTagsIncludesInTypes ? elementsWithTags : []
  }

  private addNewElement(elements: ExpressionElement[], tagDto: ITag) {
    if (elements.length) {
      elements.push(AndOrOperator.OR)
    }

    elements.push(
      new Tag(
        tagDto.id,
        tagDto.name,
        tagDto.color,
        tagDto.iconName,
        tagDto.type,
      ),
    )
  }

  private removeExistingElement(elements: ExpressionElement[], tagId: string) {
    const tagIndex = elements.findIndex(el => (el as Tag)?.id === tagId)

    if (tagIndex !== -1) {
      const removeIndex = tagIndex === 0 ? tagIndex : tagIndex - 1

      elements.splice(removeIndex, 2)
    }
  }

  @computed
  public get locationElements(): ExpressionElement[] {
    return this.getElementsByType(this.allExpressionElements, locationTypesList)
  }

  @computed
  public get companyElements(): ExpressionElement[] {
    const compList = this.getElementsByType(
      this.allExpressionElements,
      companyTypeList,
    )

    if (compList?.length) {
      return compList
    }

    const locationEndIndex = this.allExpressionElements.findIndex(
      el => el === RoundBracket.RIGHT,
    )

    const lastElementIndex = this.allExpressionElements.length - 1
    const nextElementIndex = locationEndIndex + 1

    if (
      locationEndIndex === -1 ||
      lastElementIndex === locationEndIndex ||
      lastElementIndex === nextElementIndex
    ) {
      return []
    }

    const slicedArray = this.allExpressionElements.slice(nextElementIndex)

    return this.getElementsByType(slicedArray, companyTypeList)
  }

  @computed
  public get selectedLocationTagIds(): string[] {
    return this.locationElements.map(el => (el as Tag)?.id).filter(t => !!t)
  }

  @computed
  public get selectedCompanyTagIds(): string[] {
    return this.companyElements.map(el => (el as Tag)?.id).filter(t => !!t)
  }

  @computed
  public get locationNotOperatorValue(): IsNotOperator {
    if (!this.locationElements.length) {
      return IsNotOperator.IS
    }

    const locationStartBracketIndex = this.allExpressionElements.findIndex(
      el => el === RoundBracket.LEFT,
    )
    const locationNotOperatorIndex = locationStartBracketIndex - 1
    const locationNotOperator = this.allExpressionElements[
      locationNotOperatorIndex
    ] as string

    return locationNotOperator === NOT ? IsNotOperator.IS_NOT : IsNotOperator.IS
  }

  @computed
  public get companyNotOperatorValue(): IsNotOperator {
    if (!this.companyElements.length) {
      return IsNotOperator.IS
    }

    const companyStartBracketIndex = this.allExpressionElements.lastIndexOf(
      RoundBracket.LEFT,
    )
    const companyNotOperatorIndex = companyStartBracketIndex - 1
    const companyNotOperator = this.allExpressionElements[
      companyNotOperatorIndex
    ] as string

    return companyNotOperator === NOT ? IsNotOperator.IS_NOT : IsNotOperator.IS
  }
}
