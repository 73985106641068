import * as React from 'react'

import { inject, observer } from 'mobx-react'

import ActivityDatesType from '~/client/src/desktop/enums/ActivityDatesType'
import Activity from '~/client/src/shared/models/Activity'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

interface IProps {
  activity: Activity
  dateType: ActivityDatesType

  projectDateStore?: ProjectDateStore
}

@inject('projectDateStore')
@observer
export default class ActivityDatesCell extends React.Component<IProps> {
  public render() {
    const { activity, dateType, projectDateStore } = this.props

    const plannedDay =
      dateType === ActivityDatesType.Start
        ? activity.plannedStartDayFormatted(projectDateStore)
        : activity.plannedFinishDayFormatted(projectDateStore)

    const actualDay =
      dateType === ActivityDatesType.Start
        ? activity.actualStartDayFormatted(projectDateStore)
        : activity.actualFinishDayFormatted(projectDateStore)

    return (
      <div className="text medium left x-center y-center col mw140">
        <div className="full-width">{plannedDay}</div>
        {actualDay && <div className="full-width">{actualDay + ' A'}</div>}
      </div>
    )
  }
}
