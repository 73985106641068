import { action, computed, observable } from 'mobx'

import BaseTagSelectionStore from '~/client/src/desktop/stores/ui/BaseTagSelection.store'
import CheckState from '~/client/src/shared/components/Checkbox/CheckState'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Assignment from '~/client/src/shared/models/Assignment/Assignment'
import { ITag } from '~/client/src/shared/models/Tag'
import { IAssignmentsStore } from '~/client/src/shared/stores/BaseAssignments.store'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import { ACTIVATE_PROJECT } from '~/client/src/shared/stores/EventStore/eventConstants'
import InitialState from '~/client/src/shared/stores/InitialState'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import { handleSuccessChangeAssociation } from '~/client/src/shared/utils/entityAssociationHelper'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

// localization: translated

export default class ResponsibilitiesPanelStore extends BaseTagSelectionStore {
  @observable public inputValue: string = EMPTY_STRING
  @observable public selectedEntitiesIds: string[] = []

  public constructor(
    private readonly tagsStore: TagsStore,
    private readonly domainStore: IAssignmentsStore,
    private readonly state: InitialState,
    private onClose: () => void,
    selectedEntitiesIds: string[] = [],
  ) {
    super()
    this.init(selectedEntitiesIds)
  }

  public init(selectedEntitiesIds: string[] = []) {
    this.selectedEntitiesIds = selectedEntitiesIds
  }

  @computed
  public get entitiesAssignments(): Assignment[] {
    const { getAssignmentByEntityId } = this.domainStore

    const entitiesAssignments = this.selectedEntitiesIds
      .map(getAssignmentByEntityId)
      .filter(a => !!a)

    if (entitiesAssignments.length !== this.selectedEntitiesIds.length) {
      entitiesAssignments.push(Assignment.getDraft())
    }

    return entitiesAssignments
  }

  @computed
  public get srcAsTags(): ITag[] {
    const commonRecipientsByType = this.entitiesAssignments.reduce(
      (acc, { recipients }) => {
        recipients.forEach(({ type, ids }) => {
          if (acc[type]) {
            acc[type] = [...new Set([...acc[type], ...ids])]
          } else {
            acc[type] = ids
          }
        })

        return acc
      },
      {},
    )

    const tags = []

    Object.keys(commonRecipientsByType).forEach(type => {
      const ids = commonRecipientsByType[type]
      tags.push(...this.tagsStore.getTagsByIds(type, ids))
    })

    return tags
  }

  public getInstanceCheckState = ({
    type: tagType,
    id: tagId,
  }: ITag): CheckState => {
    const doEveryInstancesHaveTag = this.entitiesAssignments.every(
      assignment => {
        const assignmentRecipient = assignment.recipients.find(
          ({ type }) => type === tagType,
        )

        return assignmentRecipient && assignmentRecipient.ids.includes(tagId)
      },
    )

    return doEveryInstancesHaveTag
      ? CheckState.CHECKED
      : CheckState.PARTIALLY_CHECKED
  }

  @action.bound
  public changeInput(newInputValue: string) {
    this.inputValue = newInputValue
  }

  @action.bound
  public clearInput() {
    this.inputValue = EMPTY_STRING
  }

  @action.bound
  public handleApply() {
    this.entitiesAssignments.forEach(assignment => {
      this.tagsForAdding.forEach(({ type, id: recipientId }) => {
        const recipientsByType = assignment.recipients.find(
          r => r.type === type,
        )

        if (!recipientsByType) {
          assignment.recipients.push({ type, ids: [recipientId] })
        } else if (!recipientsByType.ids.includes(recipientId)) {
          recipientsByType.ids.push(recipientId)
        }
      })

      this.tagsForDeletion.forEach(({ type: type, id: tagId }) => {
        const recipientsByType = assignment.recipients.find(
          r => r.type === type,
        )
        if (!recipientsByType) {
          return
        }

        const index = recipientsByType.ids.findIndex(id => id === tagId)
        if (index !== -1) {
          recipientsByType.ids.splice(index, 1)
        }
      })

      assignment.recipients = assignment.recipients.filter(r => r.ids.length)
    })

    const deleteAssignments = this.entitiesAssignments.filter(
      a => !a.isDraft && !a.recipients.length,
    )

    if (deleteAssignments.length) {
      this.domainStore.delete(
        deleteAssignments.map(a => a.id),
        this.handleAssignmentsDeletionSuccess,
      )
    }

    const updateAssignments = this.entitiesAssignments
      .filter(a => !a.isDraft && a.recipients.length)
      .map(a => a.getDto())

    const draft = this.entitiesAssignments.find(
      a => a.isDraft && a.recipients.length,
    )
    let drafts = []
    if (draft) {
      const eWithAssignmentIds = [
        ...updateAssignments,
        ...deleteAssignments,
      ].map(({ entityId }) => entityId)

      const eWithoutAssignmentIds = this.selectedEntitiesIds.filter(
        id => !eWithAssignmentIds.includes(id),
      )

      draft.projectId = this.state.activeProject.id
      drafts = eWithoutAssignmentIds.map(id => draft.getDto(id))
    }

    if (drafts.length || updateAssignments.length) {
      this.domainStore.save(
        [...drafts, ...updateAssignments],
        this.handleAssignmentsUpdateSuccess,
      )
    }

    super.handleApply()
  }

  @action.bound
  public handlePostEventCallback(eventContext: EventContext) {
    const [eventType] = eventContext.event

    if (eventType === ACTIVATE_PROJECT) {
      this.onClose()
    }
  }

  private handleAssignmentsDeletionSuccess = () => {
    handleSuccessChangeAssociation(
      Localization.translator.successfullyDeletedAssignments,
    )
    this.onClose()
  }

  private handleAssignmentsUpdateSuccess = () => {
    handleSuccessChangeAssociation(
      Localization.translator.successfullyUpdatedExistingAssignments,
    )
    this.onClose()
  }
}
