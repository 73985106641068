import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import Draggable, {
  ControlPosition,
  DraggableData,
  DraggableEvent,
} from 'react-draggable'

import { LocationType } from '~/client/graph'
import TooltipWrapper from '~/client/src/desktop/components/TooltipWrapper/TooltipWrapper'
import * as Icons from '~/client/src/shared/components/Icons'
import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import HierarchyNode from '~/client/src/shared/models/HierarchyNode'
import GlobeViewSetupStore from '~/client/src/shared/stores/GlobeViewSetup.store'

import MapBoxEditorStore from '../../stores/MapBoxEditor.store'
import MapViewItemsSetupStore, {
  maturixStations,
  siteLogistics,
} from '../../stores/MapViewItemsSetup.store'
import SitemapViewsSetupStore from '../../stores/SitemapViewsSetup.store'
import SitemapItemTag from '../SitemapItemTag'
import HierarchyTreeNodeStore from './HierarchyTreeNode.store'

import './HierarchyTreeNode.scss'

export interface IProps {
  node: HierarchyNode
  mapViewItemsSetupStore: MapViewItemsSetupStore
  sitemapViewsSetupStore: SitemapViewsSetupStore
  level: number

  globeViewSetupStore: GlobeViewSetupStore
  setViewportFromItem?(item: MapViewItemBase): void
  mapBoxEditorStore?: MapBoxEditorStore
  withinHighlightedGroup?: boolean
  lastNodeOfHighlightedGroupId?: string
  getUpdatedItem?: (item: MapViewItemBase) => MapViewItemBase
  hideLabel?: boolean
  recomputeGridSize?: () => void
}

const LEVEL_INDENT = 12
const INITIAL_LINE_INDENT = 3

@observer
export default class HierarchyTreeNode extends React.Component<IProps> {
  private readonly store: HierarchyTreeNodeStore = null
  private position: ControlPosition = {
    x: 0,
    y: 0,
  }

  public constructor(props: IProps) {
    super(props)

    this.store = new HierarchyTreeNodeStore(
      props.mapViewItemsSetupStore,
      props.node,
    )
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps.node !== this.props.node) {
      this.store.setNode(this.props.node)
    }
  }

  public render(): JSX.Element {
    const { node } = this.props

    const isDraggable = node?.item?.dataObject?.type === LocationType.Level

    return (
      <>
        <div className="relative">
          {isDraggable && (
            <Draggable
              axis="y"
              defaultClassName="level-drag-handle absolute full-width"
              defaultClassNameDragging="level-drag-handle-active absolute full-width bg-white unclickable-element"
              onStart={this.onDragStart.bind(this, node.item)}
              onStop={this.onDragStop}
              onDrag={this.setPosition}
              position={this.position}
              handle=".dragging-trigger"
            >
              {this.renderCurrentItem()}
            </Draggable>
          )}
          {this.renderCurrentItem()}
        </div>
      </>
    )
  }

  private onDragStart = (item: MapViewItemBase): void => {
    this.store.resetNodeHovering()
    this.props.mapViewItemsSetupStore.copyLevel(item)
  }

  private onDragStop = (): void => {
    this.position.y = 0
    this.props.mapViewItemsSetupStore.pasteLevel(
      this.props.mapViewItemsSetupStore.hoveredNode.item,
    )
  }

  private setPosition = (e: DraggableEvent, data: DraggableData): void => {
    this.position.y = data.y
  }

  private renderSectionHeader = (
    nodeId: string,
    childrenCount: number,
  ): JSX.Element => {
    switch (nodeId) {
      case siteLogistics:
        return (
          <div className="text large ellipsis">
            {Localization.translator.siteLogistics}
            {!!childrenCount && (
              <span className="text large light ml5">({childrenCount})</span>
            )}
          </div>
        )
      case maturixStations:
        return (
          <div className="row align-start">
            <div className="text large ellipsis">
              {Localization.translator.maturixStations}
              <span className="text large light ml5">({childrenCount})</span>
            </div>
          </div>
        )
      default:
        return (
          <div className="text large ellipsis">
            {Localization.translator.undefinedSection}
          </div>
        )
    }
  }

  @action.bound
  private onCollapseToggle(): void {
    this.props.node.changeCollapsedState()
    this.props.recomputeGridSize?.()
  }

  private renderCurrentItem(): JSX.Element {
    const { node, level, mapViewItemsSetupStore, mapBoxEditorStore } =
      this.props

    const {
      item,
      isSite,
      childrenCount,
      isCollapsed,
      isHidden,
      hasChildren,
      nodeId,
      isGroupToggleHovering,
      isItemToggleHovering,
      lastNodeOfHighlightedGroupId,
      name,
    } = node
    const { searchKey } = mapViewItemsSetupStore
    const {
      isNodeSelected,
      setNodeHovering,
      resetNodeHovering,
      isObjectToggleHovering,
    } = this.store

    const isLastNodeOfHighlightedGroup =
      isGroupToggleHovering && lastNodeOfHighlightedGroupId === nodeId
    const shouldHighlightAllBorders =
      isObjectToggleHovering || (isItemToggleHovering && isCollapsed)
    const isDraggable =
      node?.item?.dataObject?.type === LocationType.Level && !mapBoxEditorStore
    const isInActive = mapBoxEditorStore && !item?.sitemapItem?.coordinates
    const isFiltered =
      searchKey &&
      name &&
      !name.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())
    const isHiddenNode =
      (!hasChildren && isInActive && !mapBoxEditorStore) ||
      isHidden ||
      isFiltered

    if (isSite && !childrenCount && nodeId === maturixStations) {
      return null
    }

    return (
      <div
        className={classList({
          'hierarchy-tree-node-wrapper row pr12 relative': true,
          'selected-node': isNodeSelected,
          'ba2-group-highlight brada4': shouldHighlightAllBorders,

          'top-highlight': !shouldHighlightAllBorders && isItemToggleHovering,
          'x-highlight':
            !shouldHighlightAllBorders &&
            !isLastNodeOfHighlightedGroup &&
            isGroupToggleHovering &&
            !isItemToggleHovering,
          'bottom-highlight':
            !shouldHighlightAllBorders && isLastNodeOfHighlightedGroup,
          'inactive-element': isInActive,
          hidden: isHiddenNode,
        })}
        style={{ paddingLeft: LEVEL_INDENT * level }}
        onMouseEnter={setNodeHovering.bind(this, node)}
        onMouseLeave={resetNodeHovering}
      >
        {this.renderLines()}
        <div className="no-grow pr4">
          {hasChildren ? (
            <div
              className={classList({
                'caret-down': !isCollapsed,
                'caret-right': isCollapsed,
              })}
              onClick={this.onCollapseToggle}
            />
          ) : (
            <div className="dot" />
          )}
        </div>
        <div className="node-container" onClick={this.selectItem}>
          {isSite ? (
            this.renderSectionHeader(nodeId, childrenCount)
          ) : (
            <SitemapItemTag
              isItemAssigned={mapViewItemsSetupStore.isItemAssigned}
              item={item}
              childrenCount={childrenCount}
              isAttributeAssigned={mapViewItemsSetupStore.isAttributeAssigned}
            />
          )}
          {isDraggable && (
            <Icon
              className="dragging-trigger"
              icon={IconNames.DRAG_HANDLE_VERTICAL}
            />
          )}
        </div>
        {!mapBoxEditorStore && this.renderGroupVisibilityToggle()}
        {this.renderObjectVisibilityToggle()}
      </div>
    )
  }

  private renderLines(): JSX.Element[] {
    const linesCount = this.props.level - 1

    return [...new Array(linesCount).keys()].map(i => (
      <div
        key={i}
        className="level-line"
        style={{ left: (i + 1) * LEVEL_INDENT + INITIAL_LINE_INDENT }}
      />
    ))
  }

  private selectItem = (event: React.SyntheticEvent<HTMLDivElement>): void => {
    const { node, getUpdatedItem, setViewportFromItem, mapBoxEditorStore } =
      this.props
    if (!node?.item) {
      return
    }
    const updatedItem = getUpdatedItem ? getUpdatedItem(node.item) : node.item
    if (!mapBoxEditorStore) {
      this.store.selectItem(event, updatedItem)
    }
    setViewportFromItem?.(updatedItem || node.item)
  }

  private renderObjectVisibilityToggle(): JSX.Element {
    const { item, isSite } = this.props.node
    if (isSite) {
      return null
    }

    const { mapBoxEditorStore, hideLabel } = this.props
    const {
      toggleItem,
      setObjectToggleHovering,
      resetObjectToggleHovering,
      isUpdatingSitemapLoaderShown,
    } = this.store

    const isDisplayed = mapBoxEditorStore
      ? mapBoxEditorStore.leftPanelVisibility.items[item.id]
      : item.isDisplayed
    const { hideObject, showObject } = Localization.translator

    if (hideLabel) {
      return (
        <div
          className={classList({
            'icon-wrapper no-grow': true,
            pointer: !isUpdatingSitemapLoaderShown,
            'wait-cursor': isUpdatingSitemapLoaderShown,
          })}
          onClick={
            mapBoxEditorStore
              ? mapBoxEditorStore.toggleItemVisibility.bind(null, item.id)
              : toggleItem
          }
          onMouseEnter={setObjectToggleHovering}
          onMouseLeave={resetObjectToggleHovering}
        >
          {isDisplayed ? <Icons.EyeView /> : <Icons.EyeHide />}
        </div>
      )
    }

    return (
      <TooltipWrapper
        disabled={isUpdatingSitemapLoaderShown}
        content={isDisplayed ? hideObject : showObject}
        className="row no-grow"
      >
        <div
          className={classList({
            'icon-wrapper no-grow': true,
            pointer: !isUpdatingSitemapLoaderShown,
            'wait-cursor': isUpdatingSitemapLoaderShown,
          })}
          onClick={
            mapBoxEditorStore
              ? mapBoxEditorStore.toggleItemVisibility.bind(null, item.id)
              : toggleItem
          }
          onMouseEnter={setObjectToggleHovering}
          onMouseLeave={resetObjectToggleHovering}
        >
          {isDisplayed ? <Icons.EyeView /> : <Icons.EyeHide />}
        </div>
      </TooltipWrapper>
    )
  }

  private renderGroupVisibilityToggle(): JSX.Element {
    const { hasChildren, resetGroupToggleHovering, setGroupToggleHovering } =
      this.props.node
    if (!hasChildren || !this.store.isNodeHovering) {
      return null
    }

    const { isGroupDisplayed, toggleItemsGroup, isUpdatingSitemapLoaderShown } =
      this.store

    const { hideGroup, showGroup } = Localization.translator

    return (
      <TooltipWrapper
        disabled={isUpdatingSitemapLoaderShown}
        content={isGroupDisplayed ? hideGroup : showGroup}
        className="group-tooltip-handler row absolute no-grow mr12"
      >
        <span
          className={classList({
            'group-toggle icon-wrapper row no-grow pa1 brada2': true,
            pointer: !isUpdatingSitemapLoaderShown,
            'wait-cursor': isUpdatingSitemapLoaderShown,
          })}
          onMouseEnter={setGroupToggleHovering}
          onMouseLeave={resetGroupToggleHovering}
          onClick={toggleItemsGroup}
        >
          {isGroupDisplayed ? <Icons.EyeHide /> : <Icons.EyeView />}
        </span>
      </TooltipWrapper>
    )
  }
}
