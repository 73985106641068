enum ChartColors {
  GREEN = 'green',
  BLUE = 'blue',
  RED = 'red',
  YELLOW = 'yellow',
  GRAY = 'gray',
  DEFAULT = GRAY,
}

export default ChartColors
