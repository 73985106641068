import { enumToList } from '~/client/src/shared/utils/converters'

import Localization from '../../shared/localization/LocalizationManager'

enum MaterialsFilterType {
  MATERIAL = 'material',
  COMPANY = 'company',
  STATUS = 'status',
  PLANNED_INSTALL_LOCATION = 'plannedInstallLocation',
  PLANNED_DELIVERY_LOCATION = 'plannedDeliveryLocation',
  CURRENT_LOCATION = 'currentLocation',
}

export const getMaterialsFilterTypeCaption = (type: MaterialsFilterType) => {
  switch (type) {
    case MaterialsFilterType.STATUS:
      return Localization.translator.status
    case MaterialsFilterType.PLANNED_INSTALL_LOCATION:
      return Localization.translator.plannedInstallLocation
    case MaterialsFilterType.PLANNED_DELIVERY_LOCATION:
      return Localization.translator.plannedDeliveryLocation
    case MaterialsFilterType.CURRENT_LOCATION:
      return Localization.translator.currentLocation
    case MaterialsFilterType.COMPANY:
      return Localization.translator.company
    case MaterialsFilterType.MATERIAL:
      return Localization.translator.material
    default:
      throw new Error(type + ' is unhandled')
  }
}

export const materialsFilterTypes = enumToList(MaterialsFilterType)

export default MaterialsFilterType
