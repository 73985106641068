import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'

import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import ProjectSetUpPageStore from '~/client/src/desktop/views/ProjectSetUp/ProjectSetUpPage.store'
import QrCodesStore, {
  QRCodesMode,
} from '~/client/src/shared/components/QRCodes/QRCodes.store'
import QRCodesContent from '~/client/src/shared/components/QRCodes/components/QRCodesContent'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ScanHistoriesStore from '~/client/src/shared/stores/domain/ScanHistories.store'
import ScannersStore from '~/client/src/shared/stores/domain/Scanners.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import { Header, Wrapper } from '../SetupPageLayout/SetupPageLayout'

interface IProps {
  projectSetUpPageStore: ProjectSetUpPageStore

  eventsStore?: DesktopEventStore
  userProjectsStore?: UserProjectsStore
  projectMembersStore?: ProjectMembersStore
  scannersStore?: ScannersStore
  scanHistoriesStore?: ScanHistoriesStore
}

@inject(
  'eventsStore',
  'userProjectsStore',
  'projectMembersStore',
  'scannersStore',
  'scanHistoriesStore',
)
@observer
export default class QRCodes extends React.Component<IProps> {
  private qrCodesStore: QrCodesStore = null

  public constructor(props: IProps) {
    super(props)

    this.qrCodesStore = new QrCodesStore(
      props.eventsStore,
      props.scannersStore,
      props.scanHistoriesStore,
      props.projectMembersStore,
      props.userProjectsStore,
    )
  }

  public render() {
    const { mode } = this.qrCodesStore
    const containerClassName = `col scrollable qr-codes ${this.qrCodesStore.className}`

    return (
      <Wrapper>
        <Header className="row" title={Localization.translator.scanStations} />
        {mode !== QRCodesMode.VIEW && this.renderHeaderBar()}

        <div className={containerClassName}>
          <QRCodesContent
            store={this.qrCodesStore}
            isFullscreenAllowed={false}
          />
        </div>
      </Wrapper>
    )
  }

  private renderHeaderBar(): JSX.Element {
    const headerParams = this.getHeaderParameters()

    return (
      <div className="row py12">
        {headerParams.navClickHandler && (
          <Icon
            className="row no-grow navigation-icon px8 text grey-30 pointer"
            icon={IconNames.ARROW_LEFT}
            onClick={headerParams.navClickHandler}
          />
        )}
        <div className="text small-header px12 no-white-space-wrap">
          {headerParams.headerText}
        </div>
      </div>
    )
  }

  private getHeaderParameters() {
    const {
      mode,
      scanner,
      newScanner,
      setScannerSelectionMode,
      setScannerViewMode,
    } = this.qrCodesStore

    switch (mode) {
      case QRCodesMode.SELECT:
        return {
          navClickHandler: scanner ? setScannerViewMode : undefined,
          headerText: Localization.translator.selectScanner,
        }
      case QRCodesMode.EDIT:
        const isNewScanner = !newScanner?.id
        return {
          navClickHandler: setScannerSelectionMode,
          headerText: isNewScanner
            ? Localization.translator.createNewScanner
            : Localization.translator.editScannerText,
        }
    }
  }
}
