import { action, computed, observable } from 'mobx'

import CheckState from '~/client/src/shared/components/Checkbox/CheckState'
import { ITag } from '~/client/src/shared/models/Tag'

// localization: no display text to translate

export type SelectionMap = Map<ITag, CheckState>

export default abstract class BaseTagSelectionStore {
  @observable public currentSelection: SelectionMap = null

  @computed
  public get defaultSelection(): SelectionMap {
    const map = new Map()

    this.srcAsTags.forEach(tag => {
      const checkState = this.getInstanceCheckState(tag)
      map.set(tag, checkState)
    })

    return map
  }

  @computed
  public get tagsForAdding(): ITag[] {
    return [...this.currentSelection.entries()]
      .filter(([, checkState]) => checkState === CheckState.CHECKED)
      .map(([tag]) => tag)
  }

  @computed
  public get tagsForDeletion(): ITag[] {
    return [...this.defaultSelection.entries()]
      .filter(([tag]) => !this.currentSelection.get(tag))
      .map(([tag]) => tag)
  }

  public abstract getInstanceCheckState(tag: ITag)

  public get srcAsTags(): ITag[] {
    return []
  }

  @computed
  public get canApply(): boolean {
    if (!this.currentSelection) {
      return false
    }

    if (this.defaultSelection.size !== this.currentSelection.size) {
      return true
    }

    return [...this.currentSelection.entries()].some(
      ([tag, checkState]) => this.defaultSelection.get(tag) !== checkState,
    )
  }

  @action.bound
  public changeSelection(selectionMap: SelectionMap) {
    this.currentSelection = selectionMap
  }

  public handleApply() {
    this.resetCurrentSelection()
  }

  private resetCurrentSelection() {
    this.currentSelection = null
  }
}
