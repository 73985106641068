import { action, computed, observable } from 'mobx'

import { LocationType } from '~/client/graph'
import CalendarPlaceholderStore from '~/client/src/shared/components/CalendarDayView/components/CalendarPlaceholder.store'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import CalendarEvent, {
  CalendarEventEntityType,
  NEW_EVENT_DATA_ID,
} from '~/client/src/shared/models/CalendarEvent'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import { ICollectionItem } from '~/client/src/shared/stores/domain/Calendars.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import CalendarViewStore, {
  MINUTES_IN_HOUR,
} from '~/client/src/shared/stores/ui/CalendarView.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import DesktopCalendarColumn from './DesktopCalendarColumn'
import DesktopCalendarTotalBarItem from './DesktopCalendarTotalBarItem'

// localization: no display text to translate

const MS_IN_MINUTE = 1000 * 60

const TOTAL_BAR_ITEMS_PER_PAGE = 4

export default abstract class DesktopCalendarStore extends CalendarViewStore {
  public abstract readonly eventEntityType: CalendarEventEntityType

  @observable public collection: ICollectionItem[] = []
  @observable public selectedType?: LocationType = LocationType.Zone
  @observable public editableEventColumn: DesktopCalendarColumn
  @observable public initialEventColumn: DesktopCalendarColumn
  @observable public initialEventDate: Date
  @observable public currentTotalBarPage: number = 0
  @observable public shouldShowFullOffloadingEquipmentBar: boolean = false
  @observable public isMultiDayRowExpanded: boolean = false

  public constructor(
    protected calendarPlaceholderStore: CalendarPlaceholderStore,
    projectDateStore: ProjectDateStore,
    companiesStore: CompaniesStore,
  ) {
    super(projectDateStore, companiesStore)
  }

  public abstract get startDate(): Date

  public abstract get events(): CalendarEvent[]

  public abstract get calendarColumns(): DesktopCalendarColumn[]

  public abstract get timeInterval(): number

  public abstract get filteredAttributes(): LocationAttributeBase[]

  protected abstract get filteredCollection(): ICollectionItem[]

  protected abstract getTotalBarItems(
    limit?: number,
    skip?: number,
  ): DesktopCalendarTotalBarItem[]

  public abstract setShowingOfAllZoneColumns(
    shouldShowAllAttributesColumns: boolean,
  )

  @action.bound
  public toggleOffloadingEquipmentBar() {
    this.shouldShowFullOffloadingEquipmentBar =
      !this.shouldShowFullOffloadingEquipmentBar
  }

  public setSelectedType = (type: LocationType) => {
    this.selectedType = type
    this.setCollection()
  }

  public updateSortedCollection = (collection: ICollectionItem[]) => {
    this.collection = collection
  }

  public getEventsByColumn(column: DesktopCalendarColumn) {
    const events: CalendarEvent[] = column.getEventsDisplayedOnColumn(
      this.events,
    )

    if (this.isNewEventDraggingInRangeOfColumn(column)) {
      events.push(this.getNewEvent(this.eventEntityType))
      return events
    }

    const { eventPlaceholder } = this.calendarPlaceholderStore
    if (this.didEventPlaceholderSetInColumn(eventPlaceholder, column)) {
      events.push(eventPlaceholder)
    }

    return events.sort((a, b) => {
      const dataA = a.data
      const dataB = b.data

      if (a.startDate.getTime() !== b.startDate.getTime()) {
        return a.startDate.getTime() - b.startDate.getTime()
      }

      const durationA = a.endDate.getTime() - a.startDate.getTime()
      const durationB = b.endDate.getTime() - b.startDate.getTime()

      if (durationA !== durationB) {
        return durationB - durationA
      }

      const nameA = dataA?.codeToDisplay(this.companiesStore)
      const nameB = dataB?.codeToDisplay(this.companiesStore)

      if (nameA && !nameB) {
        return 1
      } else if (!nameA && nameB) {
        return -1
      } else if (!nameA && !nameB) {
        return 1
      } else {
        return nameA.localeCompare(nameB)
      }
    })
  }

  @computed
  public get multiDayEvents() {
    const { isSameDay } = this.projectDateStore

    const events = [...this.events].filter(
      e => !isSameDay(e.startDate, e.endDate) || e.isAllDay,
    )

    const { eventPlaceholder } = this.calendarPlaceholderStore
    if (eventPlaceholder?.isAllDay) {
      events.push(eventPlaceholder)
    }

    if (isSameDay(this.editableEventStartDate, this.editableEventEndDate)) {
      return events
    }

    if (
      this.calendarColumns.some(column =>
        this.isNewEventDraggingInRangeOfColumn(column),
      )
    ) {
      events.push(this.getNewEvent(this.eventEntityType))
      return events
    }

    if (
      this.calendarColumns.some(column =>
        this.didEventPlaceholderSetInColumn(eventPlaceholder, column),
      ) &&
      !isSameDay(eventPlaceholder?.startDate, eventPlaceholder?.endDate) &&
      !eventPlaceholder?.isAllDay
    ) {
      events.push(eventPlaceholder)
    }

    return events
  }

  @action.bound
  public toggleMultiDayRowExpansion() {
    this.isMultiDayRowExpanded = !this.isMultiDayRowExpanded
  }

  public setEventPlaceholder(
    zoneId?: string,
    areaId?: string,
    buildingId?: string,
    gateId?: string,
    levelId?: string,
    routeId?: string,
  ) {
    const newEvent = this.getNewEvent(this.eventEntityType)

    newEvent.zoneId = zoneId
    newEvent.areaId = areaId
    newEvent.buildingId = buildingId
    newEvent.gateId = gateId
    newEvent.levelId = levelId
    newEvent.routeId = routeId

    this.calendarPlaceholderStore.setEventPlaceholder(newEvent)
  }

  @computed
  public get visibleTotalBarItems(): DesktopCalendarTotalBarItem[] {
    const startIdx = TOTAL_BAR_ITEMS_PER_PAGE * this.currentTotalBarPage
    return this.getTotalBarItems(TOTAL_BAR_ITEMS_PER_PAGE, startIdx)
  }

  @computed
  public get maxTotalPage() {
    const totalBarItems = this.getTotalBarItems()
    return Math.floor(totalBarItems.length / TOTAL_BAR_ITEMS_PER_PAGE)
  }

  @action.bound
  public setPrevTotalPage() {
    if (this.currentTotalBarPage > 0) {
      this.currentTotalBarPage--
    }
  }

  @action.bound
  public setNextTotalPage() {
    if (this.currentTotalBarPage < this.maxTotalPage) {
      this.currentTotalBarPage++
    }
  }

  @action.bound
  public activateNewEventMode(
    initialColumn: DesktopCalendarColumn,
    initialDate: Date,
  ) {
    this.editableEventColumn = initialColumn
    this.initialEventColumn = initialColumn
    this.editableEventStartDate = initialDate
    this.editableEventEndDate = this.getEndDate(initialDate)
    this.initialEventDate = initialDate
    this.editableEventDataId = NEW_EVENT_DATA_ID
    this.setNewEventMode()
  }

  @action.bound
  public updateEditableEventValues(
    timeIntervalsOffset: number,
    newColumnIndex: number,
  ) {
    const minutesOffsetY =
      this.getValidatedEditableEventMinutes(timeIntervalsOffset)
    const eventDate = this.projectDateStore.fromIsoString(
      this.editableEventColumn.newEventData[FieldIds.DATE],
    )

    const newDate = this.projectDateStore.setHours(
      eventDate,
      Math.floor(minutesOffsetY / MINUTES_IN_HOUR),
      minutesOffsetY % MINUTES_IN_HOUR,
    )

    if (newDate <= this.initialEventDate) {
      this.editableEventStartDate = newDate
      this.editableEventEndDate = this.getEndDate(this.initialEventDate)
    } else {
      this.editableEventStartDate = this.initialEventDate
      this.editableEventEndDate = newDate
    }

    const validatedColumnIndex =
      this.getValidatedEditableEventColumnIndex(newColumnIndex)
    this.editableEventColumn = this.calendarColumns[validatedColumnIndex]
  }

  public isWorkingDay = (date: Date): boolean => {
    return this.projectDateStore.isWorkingDay(date)
  }

  public setCollection() {
    this.collection = [
      ...this.filteredAttributes.map(item => ({ item, isHidden: false })),
      {
        item: null,
        isHidden: false,
      },
    ]
  }

  protected getValidatedEditableEventMinutes(timeIntervalsOffset: number) {
    const minutesOffset = timeIntervalsOffset * this.timeInterval

    return this.validateNumberRange(
      minutesOffset,
      this.minStartTimeMs / MS_IN_MINUTE,
      Math.floor(this.maxEndTimeMs / MS_IN_MINUTE),
    )
  }

  protected getValidatedEditableEventColumnIndex(index: number) {
    const maxColumnIndex = this.calendarColumns.length - 1
    return this.validateNumberRange(index, 0, maxColumnIndex)
  }

  protected validateNumberRange(
    item: number,
    minValue: number,
    maxValue: number,
  ) {
    switch (true) {
      case item < minValue:
        return 0
      case item > maxValue:
        return maxValue
      default:
        return item
    }
  }

  protected isNewEventDraggingInRangeOfColumn(column: DesktopCalendarColumn) {
    return this.isNewEventMode && column.isEqual(this.editableEventColumn)
  }

  protected didEventPlaceholderSetInColumn(
    eventPlaceholder: CalendarEvent,
    column: DesktopCalendarColumn,
  ) {
    return (
      !this.isNewEventMode &&
      eventPlaceholder &&
      column.isEventDisplayedOnColumn(eventPlaceholder)
    )
  }
}
