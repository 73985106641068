import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { ScrollSync } from 'react-scroll-sync'
import {
  AutoSizer,
  Column,
  Table,
  defaultTableHeaderRenderer,
} from 'react-virtualized'

import IWbsRow from '~/client/src/desktop/interfaces/IWbsRow'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopActivityListStore, {
  tagTypes,
} from '~/client/src/desktop/views/SimpleGanttView/components/DesktopActivityList.store'
import SortOrder from '~/client/src/shared/enums/SortOrder'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'

import ActivityNameHeader from '../../../ActivityNameHeader'
import ActivityStatusHeader from '../../../ActivityStatusHeader'
import CustomRowRender from '../../../CustomRowRender/CustomRowRender'
import ActivityGanttOrListViewStore from '../../ActivityGanttOrListView.store'
import TableOffsetsColumnCollapseStore from '../../TableOffsetsColumnCollapse.store'
import ActivityNameCell from '../ActivityNameCell/ActivityNameCell'
import BulkActionBar from '../BulkActionBar/BulkActionBar'
import ActivityCommentCell from './components/ActivityComment'
import ActivityDateCell from './components/ActivityDateCell'
import ActivityPercentCompleteCell from './components/ActivityPercentCompleteCell'
import ActivityValue from './components/ActivityValue'
import ExtendedSectionsBar from './components/ExtendedSectionsBar'
import ResponsibilityCell from './components/ResponsibilityCell'
import SortingCell from './components/SortingCell'
import TagCell from './components/TagCell'
import WBStextCell from './components/WBStext'

import './ActivityListView.scss'

interface IProps {
  rows: IWbsRow[]
  activityListStore: DesktopActivityListStore
  store: ActivityGanttOrListViewStore
  tableOffsetsColumnCollapseStore: TableOffsetsColumnCollapseStore

  state?: DesktopInitialState
  activityFiltersStore?: ActivityFiltersStore
}

const COMMON_THINNER_COLUMN_WIDTH = 80
const COMMON_COLUMN_WIDTH = 90
const WIDE_COLUMN_WIDTH = 170
const DATE_COLUMN_WIDTH = 140
const COMMENTS_COLUMN_WIDTH = 350
const ROW_HEIGHT = 30
const HEADER_ROW_HEIGHT = 53
const TABLE_HEIGHT = 900

const tagsKeys = [
  tagTypes.building,
  tagTypes.level,
  tagTypes.zone,
  tagTypes.assignedMembers,
]

const activityDatesKeys = [
  'activity.plannedStartDayFormatted',
  'activity.plannedFinishDayFormatted',
  'activity.actualStartDayFormatted',
  'activity.actualFinishDayFormatted',
]

const durationKeys = [
  'activity.plannedDuration',
  'activity.remainingDuration',
  'complete',
]

const plannedScheduleKeys = [
  'activity.plannedStartDayFormatted',
  'activity.plannedFinishDayFormatted',
]

const actualScheduleKeys = [
  'activity.actualStartDayFormatted',
  'activity.actualFinishDayFormatted',
]

const activityCommentsKeys = ['comment']

@inject('state', 'activityFiltersStore')
@observer
export default class ActivityListView extends React.Component<IProps> {
  public UNSAFE_componentWillMount() {
    const { activityListStore, store } = this.props
    activityListStore.setDatesIfHasSelectedActivity()
    store.updateScrollValue()
  }

  public render() {
    return (
      <div className="activity-list-container">
        <div className="row tables-holder">{this.renderRightTable()}</div>
      </div>
    )
  }

  private renderRightTable(): JSX.Element {
    const { rows, store, tableOffsetsColumnCollapseStore, activityListStore } =
      this.props
    const { handleRowClick, tableScrollToIndex } = store
    const {
      shouldUseDefaultCollapseStateForProgress,
      shouldCollapseAssignee,
      shouldCollapseDuration,
      shouldCollapsePlannedSchedule,
      shouldCollapseActualSchedule,
      shouldCollapseComments,
    } = tableOffsetsColumnCollapseStore

    const tagsColumnsCountClass = `tags-count-${this.availableTagsColumnsCount}`

    return (
      <div
        className={classList({
          'table right custom-tree-table': true,
          [tagsColumnsCountClass]: true,
          'collapsed-progress': !shouldUseDefaultCollapseStateForProgress,
          'collapsed-assignee': shouldCollapseAssignee,
          'collapsed-duration': shouldCollapseDuration,
          'collapsed-planned-schedule': shouldCollapsePlannedSchedule,
          'collapsed-actual-schedule': shouldCollapseActualSchedule,
          'collapsed-comments': shouldCollapseComments,
        })}
      >
        <div className="bulk-bar-holder pl10">
          <BulkActionBar
            activityListStore={activityListStore}
            store={store}
            tableOffsetsColumnCollapseStore={tableOffsetsColumnCollapseStore}
          />
        </div>
        <ScrollSync>
          <AutoSizer
            disableWidth={true}
            className="full-height"
            defaultHeight={TABLE_HEIGHT}
          >
            {({ height }) => (
              <Table
                width={this.tableWidth}
                height={height - ROW_HEIGHT}
                headerHeight={HEADER_ROW_HEIGHT}
                rowHeight={ROW_HEIGHT}
                rowCount={rows.length}
                rowStyle={{ alignItems: 'stretch' }}
                scrollToIndex={tableScrollToIndex}
                scrollToAlignment="center"
                rowGetter={this.rowGetter}
                onRowClick={handleRowClick}
                rowRenderer={this.rowRenderer}
              >
                {this.activityListSecondaryColumns.map((column, index) => {
                  if (this.disabledColumnKeys.includes(column.dataKey)) {
                    return (
                      <Column
                        width={0}
                        minWidth={0}
                        maxWidth={0}
                        key={index}
                        dataKey={column.dataKey}
                        cellRenderer={this.emptyRenderer}
                      />
                    )
                  }

                  const width = column.width || COMMON_COLUMN_WIDTH
                  return (
                    <Column
                      width={width}
                      minWidth={width}
                      maxWidth={width}
                      key={index}
                      {...column}
                      cellRenderer={this.getCell}
                      headerRenderer={this.activityNameHeaderRenderer}
                    />
                  )
                })}
              </Table>
            )}
          </AutoSizer>
        </ScrollSync>
      </div>
    )
  }

  @computed
  private get activityListSecondaryColumns() {
    const { activityNameSectionWidth } =
      this.props.tableOffsetsColumnCollapseStore

    return [
      {
        label: 'Activity Name',
        dataKey: 'name',
        width: activityNameSectionWidth,
      },
      {
        label: 'Assignee',
        dataKey: tagTypes.assignedMembers,
        width: WIDE_COLUMN_WIDTH,
      },
      {
        label: 'Original Dur.',
        dataKey: 'activity.plannedDuration',
        width: COMMON_THINNER_COLUMN_WIDTH,
      },
      {
        label: 'Remaining Dur.',
        dataKey: 'activity.remainingDuration',
        width: COMMON_THINNER_COLUMN_WIDTH,
      },
      {
        label: 'Complete %',
        dataKey: 'complete',
        width: COMMON_COLUMN_WIDTH,
      },
      {
        label: 'Start',
        dataKey: 'activity.plannedStartDayFormatted',
        width: DATE_COLUMN_WIDTH,
      },
      {
        label: 'Finish',
        dataKey: 'activity.plannedFinishDayFormatted',
        width: DATE_COLUMN_WIDTH,
      },
      {
        label: 'Actual Start',
        dataKey: 'activity.actualStartDayFormatted',
        width: DATE_COLUMN_WIDTH,
      },
      {
        label: 'Actual Finish',
        dataKey: 'activity.actualFinishDayFormatted',
        width: DATE_COLUMN_WIDTH,
      },
      {
        label: 'Comments',
        dataKey: 'comment',
        width: COMMENTS_COLUMN_WIDTH,
      },
    ]
  }

  private getFilterCaption = (filterKey: string): string => {
    const filterInfo = this.filterInfoMap[filterKey]
    return filterInfo?.getCaption()
  }

  private get filterInfoMap() {
    const { settings } = this.props.activityFiltersStore
    return settings.filterInfoMap
  }

  @computed
  private get disabledColumnKeys(): string[] {
    const {
      shouldCollapseAssignee,
      shouldCollapseDuration,
      shouldCollapsePlannedSchedule,
      shouldCollapseActualSchedule,
      shouldCollapseComments,
    } = this.props.tableOffsetsColumnCollapseStore

    const disabledKeys = []

    if (shouldCollapseAssignee) {
      tagsKeys.forEach(tagType => disabledKeys.push(tagType))
    }
    if (shouldCollapseDuration) {
      durationKeys.forEach(key => disabledKeys.push(key))
    }
    if (shouldCollapsePlannedSchedule) {
      plannedScheduleKeys.forEach(key => disabledKeys.push(key))
    }
    if (shouldCollapseActualSchedule) {
      actualScheduleKeys.forEach(key => disabledKeys.push(key))
    }
    if (shouldCollapseComments) {
      activityCommentsKeys.forEach(type => disabledKeys.push(type))
    }
    return disabledKeys
  }

  @computed
  private get tableWidth(): number {
    const { collapsedSectionsWidth } =
      this.props.tableOffsetsColumnCollapseStore

    const columnsWidth = this.activityListSecondaryColumns.reduce(
      (sum, column) => {
        if (!this.disabledColumnKeys.includes(column.dataKey)) {
          sum += column.width || COMMON_COLUMN_WIDTH
        }

        return sum
      },
      0,
    )

    return columnsWidth + collapsedSectionsWidth
  }

  @computed
  private get availableTagsColumnsCount(): number {
    return this.activityListSecondaryColumns.filter(t =>
      tagsKeys.some(tagType => tagType === t.dataKey),
    ).length
  }

  private emptyRenderer = (): JSX.Element => {
    return null
  }

  private activityNameHeaderRenderer = ({ dataKey, label }): JSX.Element => {
    const {
      store,
      state: { isStatusUpdateManpowerDisabled },
    } = this.props

    const {
      sortColumn,
      isSortingOrderDesc,
      currentSortingOrder,
      sortingDataKey,
    } = store

    const isActive = currentSortingOrder !== SortOrder.DEFAULT
    if (dataKey === 'name') {
      const { areCompaniesEnabled } = this.props.activityFiltersStore
      return (
        <>
          <ActivityNameHeader
            sortKey={sortingDataKey}
            currentSortingOrder={currentSortingOrder}
            isSortingDesc={isSortingOrderDesc}
            onSort={sortColumn}
            hideCompany={!areCompaniesEnabled}
          />
          <ActivityStatusHeader
            isManpowerEnabled={!isStatusUpdateManpowerDisabled}
            sortKey={sortingDataKey}
            currentSortingOrder={currentSortingOrder}
            isSortingDesc={isSortingOrderDesc}
            onSort={sortColumn}
          />
          <ExtendedSectionsBar
            tableOffsetsColumnCollapseStore={
              this.props.tableOffsetsColumnCollapseStore
            }
          />
        </>
      )
    }

    return (
      <div className="row text uppercase no-outline-container cell cell-header pointer lp05 no-select full-height full-width">
        <SortingCell
          isActive={isActive && dataKey === sortingDataKey}
          isDesc={isSortingOrderDesc}
          sortKey={dataKey as string}
          onSort={sortColumn}
        >
          {defaultTableHeaderRenderer({ dataKey, label })}
        </SortingCell>
      </div>
    )
  }

  private getCell = ({
    dataKey,
    rowData,
  }: {
    dataKey: string
    rowData: IWbsRow
  }): JSX.Element => {
    const {
      toggleWbsGroup,
      toggleActivitySelection,
      isActivitySelected,
      getActivitiesFromGroup,
    } = this.props.store
    const {
      isWbs,
      activity,
      isExpanded,
      level,
      name,
      caption,
      company,
      wbsId,
      location,
    } = rowData

    if (isWbs && dataKey === 'name') {
      return (
        <WBStextCell
          name={name}
          location={location}
          caption={caption}
          level={level}
          isExpanded={isExpanded}
          collapseWbs={toggleWbsGroup}
          toggleEntitySelection={toggleActivitySelection}
          isEntitySelected={isActivitySelected}
          groupActivities={getActivitiesFromGroup(wbsId, level)}
        />
      )
    }

    if (isWbs && dataKey !== 'name') {
      return <div className="text cell-wrapper px10" />
    }

    if (dataKey === 'name') {
      return (
        <ActivityNameCell
          level={level}
          activity={activity}
          company={company}
          store={this.props.store}
        />
      )
    }

    if (tagsKeys.includes(dataKey as tagTypes)) {
      return (
        <TagCell
          tagType={dataKey as tagTypes}
          tagCaption={this.getFilterCaption(dataKey)}
          activity={activity}
          store={this.props.store}
        />
      )
    }

    if (dataKey === 'activity.plannedDuration') {
      return <ActivityValue activity={activity} dataKey={dataKey} />
    }

    if (activityDatesKeys.includes(dataKey)) {
      return <ActivityDateCell activity={activity} dataKey={dataKey} />
    }

    if (dataKey === 'activity.remainingDuration') {
      return (
        <div className="text cell-wrapper px10 bb-cool-grey">
          {activity.getRemainingDuration(company)}
        </div>
      )
    }

    if (dataKey === 'complete') {
      return (
        <ActivityPercentCompleteCell activity={activity} company={company} />
      )
    }

    if (dataKey === 'respName' || dataKey === 'respCode') {
      return (
        <ResponsibilityCell
          responsibilityCode={rowData.responsibilityCode}
          isCode={dataKey === 'respCode'}
        />
      )
    }

    if (dataKey === 'comment') {
      return (
        <ActivityCommentCell activity={activity} store={this.props.store} />
      )
    }

    return <div className="text cell-wrapper px10 bb-cool-grey">-</div>
  }

  private rowGetter = ({ index }): IWbsRow => {
    return this.props.rows[index]
  }

  private getRowClassName(index: number) {
    if (index === -1) {
      return 'no-outline'
    }

    const { isWbs } = this.rowGetter({ index })
    return classList({
      'no-outline': true,
      'activity-info-cell row-wrapper activity-info-cell': !isWbs,
    })
  }

  private rowRenderer = props => {
    const { index, key } = props

    props.rowKey = key
    return (
      <CustomRowRender
        store={this.props.store}
        customClassName={this.getRowClassName(index)}
        {...props}
      />
    )
  }
}
