import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import ColorPickerWrapper from '~/client/src/shared/components/ColorPicker/ColorPickerWrapper'
import * as Icons from '~/client/src/shared/components/Icons'
import InitialState from '~/client/src/shared/stores/InitialState'
import { SITEMAP_ITEMS_COLORS_VALUES } from '~/client/src/shared/utils/SitemapItemsColors'

import './SitemapItemsColorPicker.scss'

interface IProps {
  value: string

  className?: string
  opacity?: number
  state?: InitialState

  onChange(color: string): void
}

@inject('state')
@observer
export default class SitemapItemsColorPicker extends React.Component<IProps> {
  public render() {
    const { value, className, onChange } = this.props

    return (
      <div
        className={classList({
          'sitemap-items-color-picker': true,
          [className]: !!className,
        })}
      >
        <ColorPickerWrapper
          value={value}
          colors={this.colors}
          onChange={onChange}
          customColorPickerButton={this.renderColorPickerButton()}
        />
      </div>
    )
  }

  private get colors() {
    return [
      ...SITEMAP_ITEMS_COLORS_VALUES,
      ...(this.props.state.projectColoringOptions.colors || []),
    ]
  }

  private renderColorPickerButton = (): JSX.Element => {
    const { value, opacity } = this.props

    return (
      <div className="inline-block relative">
        <div className="row value-container relative">
          <div
            className="no-grow color-option value"
            style={{ backgroundColor: value, opacity }}
          />
          <div className="opacity-background color-option value" />
          <div className="row y-center x-center arrow-container">
            <Icons.DownArrow className="no-grow select-arrow-down" />
          </div>
        </div>
      </div>
    )
  }
}
