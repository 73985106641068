import React from 'react'

import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { NotificationType } from '~/client/graph'
import { IFullscreenPreviewComment } from '~/client/src/shared/components/FileFullscreenPreview/FileFullscreenPreview.store'
import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'
import FileType from '~/client/src/shared/enums/FileType'
import IFilePreviewProperties from '~/client/src/shared/interfaces/IFilePreviewProperties'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Flag from '~/client/src/shared/models/Flag'
import BaseNotification from '~/client/src/shared/models/Notification'
import Photo from '~/client/src/shared/models/Photo'
import ScheduleComment from '~/client/src/shared/models/ScheduleComment'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import { SHOW_FULLSCREEN_PREVIEW } from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import DeliveryStatusChangesStore from '~/client/src/shared/stores/domain/DeliveryStatusChanges.store'
import FlagsStore from '~/client/src/shared/stores/domain/Flags.store'
import GatesStore from '~/client/src/shared/stores/domain/Gates.store'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'
import PhotosStore from '~/client/src/shared/stores/domain/Photos.store'
import RfisStore from '~/client/src/shared/stores/domain/Rfis.store'
import ScheduleCommentsStore from '~/client/src/shared/stores/domain/ScheduleComments.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import ZonesStore from '~/client/src/shared/stores/domain/Zones.store'
import {
  isDeleteType,
  isDeliveryType,
  isFlagType,
  isRFIType,
  isScheduleCommentType,
  isStatusUpdateType,
} from '~/client/src/shared/types/NotificationTypes'
import { NO_IMAGE_PATH } from '~/client/src/shared/utils/usefulStrings'

const MAX_PHOTOS_COUNT = 5

// translated

interface IProps {
  notification: BaseNotification
  deliveriesStore?: DeliveriesStore
  flagsStore?: FlagsStore
  rfisStore?: RfisStore
  gatesStore?: GatesStore
  zonesStore?: ZonesStore
  messagesStore?: MessagesStore
  statusUpdatesStore?: StatusUpdatesStore
  scheduleCommentsStore?: ScheduleCommentsStore
  photosStore?: PhotosStore
  deliveryStatusChangesStore?: DeliveryStatusChangesStore
  eventsStore?: EventsStore
  activitiesStore?: ActivitiesStore
  companiesStore?: CompaniesStore
}

@inject(
  'flagsStore',
  'rfisStore',
  'gatesStore',
  'zonesStore',
  'eventsStore',
  'deliveriesStore',
  'statusUpdatesStore',
  'messagesStore',
  'photosStore',
  'deliveryStatusChangesStore',
  'scheduleCommentsStore',
  'activitiesStore',
  'companiesStore',
)
@observer
export default class NotificationPhotos extends React.Component<IProps> {
  @observable public areImagesLoaded: boolean = false

  public render() {
    const {
      notification,
      flagsStore,
      rfisStore,
      messagesStore,
      photosStore,
      statusUpdatesStore,
      scheduleCommentsStore,
      deliveriesStore,
      activitiesStore,
      zonesStore,
      gatesStore,
      companiesStore,
    } = this.props
    const { type, entityId, activityObjectId } = notification
    let photosIds: string[] = []
    let photos: Photo[] = []
    let activityEntityType: NotificationType = null
    let deliveryStatus: DeliveryStatus = null
    const activity = activitiesStore.list.find(a => a.code === activityObjectId)
    let companyName: string = activity?.companies.join(',') || ''
    const photoDetails: string[] = []

    switch (true) {
      case isDeleteType(type):
        return <div />
      case isDeliveryType(type):
        const delivery = deliveriesStore.list.find(d => d.id === entityId)
        if (!delivery) {
          break
        }
        photos = this.getDeliveryPhotos()
        photosIds = photos && photos.map(p => p.id)
        const { company, status, zone, gate } = delivery
        companyName = companiesStore.getCompanyNameById(company)
        deliveryStatus = status
        photoDetails.push(zonesStore.getString(zone, false))
        photoDetails.push(gatesStore.getString(gate, false))
        break
      case isStatusUpdateType(type):
        const statusUpdate =
          statusUpdatesStore.byId.get(entityId) || StatusUpdate.none
        const statusUpdateMessages = messagesStore.list.filter(
          m => m.threadId === statusUpdate.threadId,
        )
        photosIds =
          statusUpdateMessages &&
          statusUpdateMessages.filter(m => !!m.photoId).map(m => m.photoId)
        photos =
          photosIds && photosStore.list.filter(p => photosIds.includes(p.id))
        activityEntityType = NotificationType.StatusUpdateCreated
        photoDetails.push(`Activity Id: ${statusUpdate.activityP6Code}`)
        break
      case isFlagType(type):
        const flag = flagsStore.byId.get(entityId) || Flag.none
        const flagMessages = messagesStore.list.filter(
          m => m.threadId === flag.threadId,
        )
        photosIds =
          flagMessages &&
          flagMessages.filter(m => !!m.photoId).map(m => m.photoId)
        photos =
          photosIds && photosStore.list.filter(p => photosIds.includes(p.id))
        activityEntityType = NotificationType.FlagCreated
        photoDetails.push(`Activity Id: ${flag.activityObjectId}`)
        break
      case isRFIType(type):
        const rfi = rfisStore.byId.get(entityId) || Flag.none
        const rfiMessages = messagesStore.list.filter(
          m => m.threadId === rfi.threadId,
        )
        photosIds =
          rfiMessages &&
          rfiMessages.filter(m => !!m.photoId).map(m => m.photoId)
        photos =
          photosIds && photosStore.list.filter(p => photosIds.includes(p.id))
        activityEntityType = NotificationType.RequestForInformationCreated
        photoDetails.push(`Activity Id: ${rfi.activityObjectId}`)
        break
      case isScheduleCommentType(type):
        const scheduleComment =
          scheduleCommentsStore.byId.get(entityId) || ScheduleComment.none
        const scheduleCommentMessages = messagesStore.list.filter(
          m => m.threadId === scheduleComment.threadId,
        )
        photosIds =
          scheduleCommentMessages &&
          scheduleCommentMessages.filter(m => !!m.photoId).map(m => m.photoId)
        photos =
          photosIds && photosStore.list.filter(p => photosIds.includes(p.id))
        activityEntityType = NotificationType.ScheduleCommentCreated
        photoDetails.push(`Activity Id: ${scheduleComment.activityObjectId}`)
        break
      default:
        return null
    }

    return this.renderPhotosRow(
      photos,
      activityEntityType,
      deliveryStatus,
      companyName,
      photoDetails,
    )
  }

  private renderPhotosRow(
    allPhotos: Photo[],
    activityEntityType: NotificationType,
    deliveryStatus: DeliveryStatus,
    companyName: string,
    photoDetails: string[],
  ) {
    const photos = allPhotos.slice(allPhotos.length - MAX_PHOTOS_COUNT)
    return (
      <div className="row">
        <div className="row reverse">
          {photos.map((photo, index) => {
            return (
              <img
                className={classList({
                  'photo-container': index !== photos.length - 1,
                  'photo-container-first': index === photos.length - 1,
                })}
                style={!this.areImagesLoaded ? { visibility: 'hidden' } : {}}
                onClick={this.openPhoto.bind(
                  this,
                  allPhotos,
                  photo.url,
                  companyName,
                  photoDetails,
                  activityEntityType,
                  deliveryStatus,
                )}
                key={index}
                src={photo.url || NO_IMAGE_PATH}
                onLoad={this.setLoaded}
              />
            )
          })}
        </div>
        {allPhotos.length > MAX_PHOTOS_COUNT && (
          <div className="no-grow text vertical large">
            {Localization.translator.plusXMore(
              allPhotos.length - MAX_PHOTOS_COUNT,
            )}
          </div>
        )}
      </div>
    )
  }

  private getDeliveryPhotos() {
    const {
      notification,
      deliveriesStore,
      deliveryStatusChangesStore,
      messagesStore,
      photosStore,
    } = this.props
    const { id, threadId } = deliveriesStore.list.find(
      d => d.id === notification.entityId,
    )
    const deliveryThreadsIds = deliveryStatusChangesStore
      .getStatusChangesForDelivery(id)
      .map(status => status.threadId)
      .concat(threadId)
    const messagesIds = messagesStore.list
      .filter(m => deliveryThreadsIds.includes(m.threadId))
      .map(m => m.id)

    return photosStore.list.filter(p => messagesIds.includes(p.messageId))
  }

  @action.bound
  private openPhoto(
    photos: Photo[],
    selectedPhotoUrl: string,
    companyName: string,
    photoDetails: string[],
    activityEntityType: NotificationType,
    deliveryStatus: DeliveryStatus,

    event: React.SyntheticEvent<HTMLDivElement>,
  ) {
    const { messagesStore } = this.props
    const reversedPhotos = photos.reverse()
    const messagesIds = photos.map(p => p.messageId)
    const messages = messagesStore.list.filter(m => messagesIds.includes(m.id))
    const comments: IFullscreenPreviewComment[] = []
    const files: IFilePreviewProperties[] = []

    messages.forEach(message => {
      const { photoId, userId, text, createdAt } = message
      const photo = reversedPhotos.find(p => p.id === photoId)
      files.push({ fileUrl: photo.url, fileType: FileType.Image })
      comments.push({
        authorId: userId,
        text,
        deliveryStatus,
        companyName,
        timestamp: createdAt,
        photoDetails,
        activityEntityType,
      })
    })

    const currentFileIndex = files.findIndex(
      photo => photo.fileUrl === selectedPhotoUrl,
    )
    event.stopPropagation()
    this.props.eventsStore.dispatch(
      SHOW_FULLSCREEN_PREVIEW,
      files,
      currentFileIndex,
      comments,
    )
  }

  private setLoaded = () => {
    this.areImagesLoaded = true
  }
}
