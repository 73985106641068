import * as React from 'react'

import { Classes, Dialog } from '@blueprintjs/core'
import { observer } from 'mobx-react'

import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import Checkbox from '~/client/src/shared/components/Checkbox/Checkbox'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import MaterialsUploadStore from '../MaterialsUpload.store'

// translated

interface IProps {
  store: MaterialsUploadStore
}

@observer
export default class ChooseMaterialsSheetDialog extends React.Component<IProps> {
  public render() {
    const {
      isChooseSheetDialogShown,
      resetInitialValues,
      uploadedSheets,
      chosenSheetName,
      selectedSheet,
      applyChooseSheetDialog,
      chooseSheetName,
    } = this.props.store

    const bodyClassName = `${Classes.DIALOG_BODY} relative px16`
    const footerClassName = `${Classes.DIALOG_FOOTER} ma0 py10 px30 bt-light-input-border row x-center`

    return (
      <Dialog
        isOpen={isChooseSheetDialogShown}
        title={Localization.translator.chooseSheet}
        isCloseButtonShown={false}
        canOutsideClickClose={false}
        className="bg-white ba-light-input-border pb5"
      >
        <div className={bodyClassName}>
          <div className="text large light mb40">
            {Localization.translator.materialsUploading.pleaseSelectSheetToUpload(
              uploadedSheets.length > 1,
            )}
          </div>
          {uploadedSheets.map((sheet, index) => (
            <div
              key={sheet.name + index}
              className="row mb5 pointer"
              onClick={chooseSheetName.bind(null, sheet.name)}
            >
              <div className="row y-center no-flex-children" title={sheet.name}>
                <Checkbox isChecked={chosenSheetName === sheet.name} />
                {sheet.name}
              </div>
              <div className="text large light no-grow nowrap mx10">
                {Localization.translator.xColumns(sheet.columns.length)}
              </div>
            </div>
          ))}
          <div className="text large light mb20" />
        </div>

        <div className={footerClassName}>
          <BaseActionButton
            title={Localization.translator.cancel}
            className="gray-theme mx10"
            isEnabled
            isGrow
            isAnimated
            onClick={resetInitialValues}
          />
          <BaseActionButton
            title={Localization.translator.next}
            className="primary-theme-inverted mx10"
            isEnabled={!!selectedSheet}
            isGrow
            isAnimated
            onClick={applyChooseSheetDialog}
          />
        </div>
      </Dialog>
    )
  }
}
