import * as React from 'react'

import { inject, observer } from 'mobx-react'

import FilterResetter from '~/client/src/desktop/components/FilterResetter/FilterResetter'
import ConcreteDirectFilterButton from '~/client/src/desktop/components/Filters/ConcreteDirectFilterButton'
import SuperFilter from '~/client/src/desktop/components/SuperFilter/SuperFilter'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import SuperFilterHandleButton from '~/client/src/shared/components/Filters/SuperFilter/SuperFilterHandleButton'
import { NOOP } from '~/client/src/shared/utils/noop'

import DeliveryMapFilterStore from './DeliveryMapFilter.store'

// localization: no display text to translate

interface IProps {
  store: DeliveryMapFilterStore
  forceCloseMap: { [filterType: string]: boolean }
  hasCDIntegration: boolean
  areCDOrdersDisabled: boolean

  toggleShowingOfCDOrders(): void

  eventsStore?: DesktopEventStore
}

@inject('eventsStore')
@observer
export default class DeliveryMapFilter extends React.Component<IProps> {
  private readonly clearPostEventCallback: () => void = NOOP

  public constructor(props: IProps) {
    super(props)

    props.store.syncFilters()

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      props.store.onDeliveriesReceived,
    )
  }

  public componentDidUpdate() {
    const { forceCloseMap } = this.props

    if (forceCloseMap) {
      const { fieldsMap } = this.props.eventsStore.appState.deliveryFilters
      Object.keys(fieldsMap).forEach(filterType => {
        if (forceCloseMap[filterType]) {
          fieldsMap[filterType].isFilterShown = false
        }
      })
    }
  }

  public render() {
    const {
      areCDOrdersDisabled,
      hasCDIntegration,
      toggleShowingOfCDOrders,
      store,
    } = this.props

    return (
      <div className="row full-height location-filter x-start scrollable-section">
        {this.renderHandleButtons()}
        <ConcreteDirectFilterButton
          isActive={!areCDOrdersDisabled}
          isHidden={!hasCDIntegration}
          toggleShowingOfCDOrders={toggleShowingOfCDOrders}
        />
        {this.renderPopups()}
        <FilterResetter onReset={store.resetAllFilters} />
      </div>
    )
  }

  public componentWillUnmount() {
    this.clearPostEventCallback()
  }

  private renderPopups(): JSX.Element[] {
    return Object.keys(this.storesMap).map(filterType => {
      const store = this.storesMap[filterType]
      return <SuperFilter key={filterType} filterStore={store} />
    })
  }

  private renderHandleButtons(): JSX.Element[] {
    return Object.keys(this.storesMap).map(filterType => {
      const store = this.storesMap[filterType]
      return (
        <SuperFilterHandleButton
          className="no-grow"
          key={filterType}
          filterStore={store}
        />
      )
    })
  }

  private get storesMap() {
    return this.props.store.filterStoresByTypeMap
  }
}
