import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import mapboxgl, { MapMouseEvent } from 'mapbox-gl'
import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { ViewState } from 'react-map-gl'

import { IProjectAddressInput } from '~/client/graph'
import StruxhubMapboxAddressInput from '~/client/src/desktop/components/StruxhubDesktopInputs/StruxhubMapboxAddressInput/StruxhubMapboxAddressInput'
import TooltipWrapper from '~/client/src/desktop/components/TooltipWrapper/TooltipWrapper'
import desktopRoutes from '~/client/src/desktop/constants/desktopRoutes'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DesktopCommonStore from '~/client/src/desktop/stores/ui/DesktopCommon.store'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import { Loader } from '~/client/src/shared/components/Loader'
import { MapBoxViewer } from '~/client/src/shared/components/MapBoxEditor/MapBoxViewer'
import MapBoxViewerStore, {
  getAddressFromCoords,
  mapboxFeatureToAddress,
} from '~/client/src/shared/components/MapBoxEditor/MapBoxViewer.store'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import { IMapBoxFeature } from '~/client/src/shared/interfaces/IMapBoxFeature'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import GlobeViewSetupStore from '~/client/src/shared/stores/GlobeViewSetup.store'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import GlobeViewsStore from '~/client/src/shared/stores/domain/GlobeViews.store'
import GraphExecutorStore from '~/client/src/shared/stores/domain/GraphExecutor.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import ProjectsStore from '~/client/src/shared/stores/domain/Projects.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { NORTH_HEADING } from '~/client/src/shared/utils/Address'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import ProjectSetUpPageStore from '../../ProjectSetUpPage.store'
import ProjectSetUpPage from '../../ProjectSetUpPages'
import ProjectSettingsStore from '../ProjectWorkspaceSetUp/ProjectSettings.store'
import { getMapBoxPlaces } from '../ProjectWorkspaceSetUp/components/MapBoxView'
import ProjectCreationStore from './ProjectCreation.store'

import './ProjectCreation.scss'

interface IProps {
  projectSetUpPageStore: ProjectSetUpPageStore
  eventsStore?: DesktopEventStore
  sitemapsStore?: SitemapsStore
  basemapsStore?: BasemapsStore
  locationAttributesStore?: LocationAttributesStore
  sitemapItemsStore?: SitemapItemsStore
  common?: DesktopCommonStore
  userProjectsStore?: UserProjectsStore
  projectsStore?: ProjectsStore
  graphExecutorStore?: GraphExecutorStore
  projectDateStore?: ProjectDateStore
  globeViewsStore?: GlobeViewsStore
  tagsStore?: TagsStore
}

const regExp = /^[+-]?([0-9]*[.])?[0-9]+$/

const formatTooltip = 'Follow the next format: longitude,latitude'
const createNewProject = 'Create new project'
const projectAddress = 'Project address'
const longitudeLatitude = 'Longitude-Latitude'
const savingProject = 'Saving Project'

const notUniqueCodeMessage = (code: string) =>
  `The project with the code [${code}] already exists. Try another value`

const DEFAULT_ZOOM = 6
const ZOOM_ON_ADDRESS = 16
const defaultCoordinates = '0, 0'

@inject(
  'projectsStore',
  'eventsStore',
  'sitemapsStore',
  'basemapsStore',
  'locationAttributesStore',
  'sitemapItemsStore',
  'common',
  'userProjectsStore',
  'graphExecutorStore',
  'projectDateStore',
  'globeViewsStore',
  'tagsStore',
)
@observer
export default class ProjectCreation extends React.Component<IProps> {
  @observable private coordinates: string = defaultCoordinates
  public readonly globeViewSetupStore: GlobeViewSetupStore
  private readonly store: ProjectCreationStore
  private readonly mapBoxViewerStore: MapBoxViewerStore

  @observable private viewport: ViewState = {
    latitude: 0,
    longitude: 0,
    zoom: 0,
    bearing: NORTH_HEADING,
    pitch: 0,
    padding: null,
  }
  @observable private latitude: number = 0
  @observable private longitude: number = 0
  private readonly projectSettingsStore: ProjectSettingsStore

  public constructor(props: IProps) {
    super(props)

    this.store = new ProjectCreationStore(props.graphExecutorStore)

    this.globeViewSetupStore = new GlobeViewSetupStore(
      props.eventsStore,
      props.globeViewsStore,
      props.userProjectsStore,
      props.sitemapItemsStore,
      props.locationAttributesStore,
      props.tagsStore,
    )

    this.mapBoxViewerStore = new MapBoxViewerStore(
      props.sitemapsStore,
      props.locationAttributesStore,
      props.eventsStore.appState,
      this.globeViewSetupStore,
      props.basemapsStore,
      props.eventsStore,
      true,
    )

    this.projectSettingsStore = new ProjectSettingsStore(
      props.projectsStore,
      props.eventsStore,
      props.projectSetUpPageStore,
      props.userProjectsStore,
      props.graphExecutorStore,
      props.projectDateStore,
    )

    this.projectSettingsStore.setDefaultProjectWorkingHours()
    this.projectSettingsStore.projectAddress =
      this.props.eventsStore.appState.getDefaultProjectAddress()
    const { bearing, zoom, pitch, center } =
      props.eventsStore.appState.projectAddress
    const { latitude, longitude } = props.eventsStore.appState

    const lat = center?.lat || latitude
    const lng = center?.lng || longitude
    this.viewport.latitude = lat
    this.viewport.longitude = lng
    this.viewport.zoom = zoom || DEFAULT_ZOOM
    this.viewport.pitch = pitch || 0
    this.viewport.bearing = bearing

    this.latitude = lat
    this.longitude = lng
    this.coordinates = `${this.longitude},${this.latitude}`
  }

  public UNSAFE_componentWillMount(): void {
    this.projectSettingsStore.enableNewProjectSelection()
  }

  public async componentDidMount() {
    const item = await getAddressFromCoords(this.latitude, this.longitude)
    this.mapBoxOnAddressValueChanged(item)
  }

  public render(): JSX.Element {
    if (this.isLoading) {
      return <Loader hint={Localization.translator.loading} />
    }

    return (
      <div className="row full-width full-height project-creation">
        {this.isSavingProject && (
          <>
            <Loader
              className="loader-container absolute full-width full-height unclickable-element col x-center y-center text large white"
              hint={savingProject}
            />
            <div className="absolute full-width full-height unclickable-element text white opacity-background" />
          </>
        )}
        {this.renderContent()}
        {this.renderRightTable()}
      </div>
    )
  }

  private renderContent(): JSX.Element {
    return (
      <div
        className={classList({
          'full-height': true,
          'unclickable-element': this.isSavingProject,
        })}
      >
        <div className="map-content relative">
          <MapBoxViewer
            store={this.mapBoxViewerStore}
            viewport={this.viewport}
            setViewport={this.setViewport}
            onAddressChanged={this.onAddressChanged}
            shouldShowProjectMarker={true}
            latitude={this.latitude}
            longitude={this.longitude}
            updateLngLat={this.updateLngLat}
            language={Localization.currentLanguage}
            onMapDblClick={this.onDblClick}
          />
        </div>
      </div>
    )
  }

  private renderRightTable(): JSX.Element {
    const { isProjectCodeBeingChecked } = this.store

    return (
      <div className="full-height col side-table bl-palette-grey pa10">
        <div className="row pb20 pt10">
          <Icon
            className="no-grow pointer"
            icon={IconNames.CROSS}
            size={22}
            onClick={this.closeProjectCreation}
          />
          <div className="text size22 center bold">{createNewProject}</div>
        </div>
        {this.renderLocationRightTable()}
        <div className="col y-end">
          <BaseActionButton
            className="primary-theme-inverted"
            title={Localization.translator.submit_verb}
            isEnabled={!this.isButtonDisabled && !isProjectCodeBeingChecked}
            onClick={this.onButtonClick}
            isLoading={isProjectCodeBeingChecked}
          />
        </div>
      </div>
    )
  }

  private renderLocationRightTable(): JSX.Element {
    const {
      projectDetailsDescriptions: { projectCodeError, projectNameError },
      projectName,
      projectCode,
    } = Localization.translator
    const {
      projectAddressSuggestions,
      projectName: activeProjectName,
      projectCode: activeProjectCode,
      isProjectErrorMessageVisible,
      isProjectCodeErrorMessageVisible,
    } = this.projectSettingsStore
    const { address } = this.activeProjectAddress

    return (
      <div className="col y-start no-grow mb20">
        <StruxhubInput
          id={projectName}
          label={projectName}
          isRequired={true}
          isValid={!isProjectErrorMessageVisible}
          value={activeProjectName}
          onChange={this.onProjectNameChange}
          onValueReset={this.onProjectNameReset}
          validationMessage={isProjectErrorMessageVisible && projectNameError}
        />
        <StruxhubInput
          id={projectCode}
          label={projectCode}
          isRequired={true}
          isValid={!isProjectCodeErrorMessageVisible}
          value={activeProjectCode}
          onChange={this.onProjectCodeChange}
          onValueReset={this.onProjectCodeReset}
          validationMessage={
            isProjectCodeErrorMessageVisible && projectCodeError
          }
        />
        <div className="col">
          <StruxhubMapboxAddressInput
            label={projectAddress}
            isRequired={true}
            fullAddressLabel={this.projectAddressString}
            items={projectAddressSuggestions}
            onItemSelect={this.mapBoxOnAddressValueChanged}
            onQueryChange={this.mapBoxOnQueryChange}
            value={address}
          />
          <div className="row">
            <TooltipWrapper
              className="coordinates-wrapper"
              content={formatTooltip}
            >
              <StruxhubInput
                id="latLng"
                label={longitudeLatitude}
                isRequired={true}
                value={this.coordinates}
                onChange={this.updateCoordinates}
                onBlur={this.setCoordinates}
              />
            </TooltipWrapper>
          </div>
        </div>
      </div>
    )
  }

  private closeProjectCreation = (): void => {
    this.props.projectSetUpPageStore.navigateTo(
      ProjectSetUpPage.PROJECT_DETAILS,
    )
  }

  private onCreateProjectClick = async (): Promise<void> => {
    this.props.eventsStore.appState.loading.set(e.SAVE_PROJECT, true)
    this.props.globeViewsStore.clearList()
    await this.projectSettingsStore.submitForm(this.saveGlobe)
  }

  private onButtonClick = async (): Promise<void> => {
    const { projectCode } = this.projectSettingsStore

    try {
      // eslint-disable-next-line no-var
      var isUnique = await this.store.checkProjectCodeUniqueness(
        this.projectSettingsStore.projectCode,
      )
    } catch (e) {
      return alert(
        Localization.translator.somethingWentWrongDuringAPIInteraction,
      )
    }

    if (isUnique) {
      this.onCreateProjectClick()
    } else {
      alert(notUniqueCodeMessage(projectCode))
      this.projectSettingsStore.resetProjectCode()
    }
  }

  private redirectToMapSetup = async (): Promise<void> => {
    this.props.eventsStore.appState.loading.set(e.SAVE_PROJECT, false)
    this.props.common._displayView(desktopRoutes.MAP_SETUP)
  }

  private saveGlobe = async (projectId: string): Promise<void> => {
    const { globeCorners } = this.mapBoxViewerStore

    const center = {
      lat: this.viewport.latitude,
      lng: this.viewport.longitude,
    }

    const bounds = {
      ne: { lat: globeCorners[0].latitude, lng: globeCorners[0].longitude },
      sw: { lat: globeCorners[3].latitude, lng: globeCorners[3].longitude },
    }

    const globeId = await this.globeViewSetupStore.createNewGlobeView(
      null,
      bounds,
      center,
      this.viewport.zoom,
      0,
      this.viewport.bearing,
      this.viewport.pitch,
      globeCorners,
      projectId,
    )
    await this.globeViewSetupStore.assignGlobeToAllApps(globeId, projectId)
    this.props.eventsStore.appState.loading.set(e.SAVE_PROJECT, false)

    this.redirectToMapSetup()
  }

  private updateCoordinates = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.coordinates = event.target.value
  }

  private setCoordinates = async () => {
    const [lng, lat] = this.coordinates.trim().replace(/\s/g, '').split(',')

    if (!lng.match(regExp) || !lat.match(regExp)) {
      this.coordinates = `${this.longitude},${this.latitude}`
      return
    }

    this.longitude = Number(lng)
    this.latitude = Number(lat)

    const item = await getAddressFromCoords(this.latitude, this.longitude)
    this.mapBoxOnAddressValueChanged(item)
  }

  private mapBoxOnQueryChange = async (query: string): Promise<void> => {
    const data: GeoJSON.FeatureCollection = await getMapBoxPlaces(
      query,
      mapboxgl.accessToken,
    )

    this.projectSettingsStore.setProjectAddressSuggestions(
      data.features ? (data.features as IMapBoxFeature[]) : [],
    )
  }

  private mapBoxOnAddressValueChanged = (item: IMapBoxFeature) => {
    if (!item) {
      return
    }

    const addr = mapboxFeatureToAddress(item)
    this.latitude = addr.center.lat
    this.longitude = addr.center.lng

    this.viewport.zoom = ZOOM_ON_ADDRESS
    this.viewport.latitude = addr.center.lat
    this.viewport.longitude = addr.center.lng
    this.viewport.bearing = addr.bearing

    if (!addr.zoom) {
      addr.zoom = ZOOM_ON_ADDRESS
      addr.pitch = this.viewport.pitch
    }

    this.projectSettingsStore.setProjectAddress(addr)
    this.coordinates = `${this.longitude}, ${this.latitude}`
  }

  private setViewport = (viewport: ViewState) => {
    this.viewport = viewport
  }

  @action.bound
  private onDblClick(e: MapMouseEvent): void {
    this.updateLngLat(e.lngLat.lat, e.lngLat.lng)
  }

  private updateLngLat = (latitude: number, longitude: number) => {
    this.longitude = longitude
    this.latitude = latitude
    this.coordinates = `${this.longitude}, ${this.latitude}`
  }

  private onAddressChanged = ({
    address,
    country,
    city,
    state,
    zipcode,
  }: IProjectAddressInput) => {
    const { globeCorners } = this.mapBoxViewerStore

    const bounds = {
      ne: { lat: globeCorners[0].latitude, lng: globeCorners[0].longitude },
      sw: { lat: globeCorners[3].latitude, lng: globeCorners[3].longitude },
    }

    this.projectSettingsStore.projectAddress.bounds = bounds
    this.projectSettingsStore.projectAddress.center.lat = this.latitude
    this.projectSettingsStore.projectAddress.center.lng = this.longitude
    this.projectSettingsStore.projectAddress.address = address
    this.projectSettingsStore.projectAddress.country = country
    this.projectSettingsStore.projectAddress.city = city
    this.projectSettingsStore.projectAddress.state = state
    this.projectSettingsStore.projectAddress.zipcode = zipcode
  }

  private onProjectNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { value: projectName = EMPTY_STRING } = event.target

    this.projectSettingsStore.setProjectName(projectName)
    this.projectSettingsStore.setProjectCode(projectName)
  }

  private onProjectNameReset = (): void => {
    this.projectSettingsStore.setProjectName(EMPTY_STRING)
  }

  private onProjectCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    this.projectSettingsStore.setProjectCode(event.target.value)
  }

  private onProjectCodeReset = (): void => {
    this.projectSettingsStore.setProjectCode(EMPTY_STRING)
  }

  private get projectAddressString(): string {
    const { address, city, state, zipcode, country } = this.activeProjectAddress

    return `${address} ${city} ${state} ${zipcode} ${country}`
  }

  private get activeProjectAddress(): IProjectAddressInput {
    const { projectAddress: activeProjectAddress } = this.projectSettingsStore

    return activeProjectAddress || ({ projectId: null } as IProjectAddressInput)
  }

  @computed
  private get isLoading(): boolean {
    const { loading } = this.props.eventsStore.appState
    return loading.get(e.ACTIVATE_PROJECT)
  }

  @computed
  private get isSavingProject(): boolean {
    const { loading } = this.props.eventsStore.appState

    return (
      loading.get(e.SAVE_PROJECT) ||
      loading.get(e.SAVE_SITEMAP) ||
      loading.get(e.SAVE_GLOBE_VIEW) ||
      loading.get(e.SAVE_BASEMAP)
    )
  }

  private get isButtonDisabled(): boolean {
    const {
      projectName: activeProjectName,
      projectCode,
      isProjectErrorMessageVisible,
      isProjectCodeErrorMessageVisible,
    } = this.projectSettingsStore

    return (
      isProjectErrorMessageVisible ||
      !activeProjectName ||
      isProjectCodeErrorMessageVisible ||
      !projectCode ||
      !this.projectAddressString.trim()
    )
  }
}
