import * as React from 'react'

import { action } from 'mobx'
import { observer } from 'mobx-react'

import EditableTextColumn from '~/client/src/desktop/components/EditableColumns/EditableTextColumn/EditableTextColumn'
import MaterialStatusIndicator from '~/client/src/desktop/components/MaterialStatusIndicator/MaterialStatusIndicator'
import CompanyProfilePreview from '~/client/src/shared/components/CompanyProfilePreview/CompanyProfilePreview'
import GroupedListWithFixedColumns, {
  LWFCRowData,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import Location from '~/client/src/shared/components/SitemapAttributeSelector/Location'
import { MaterialStatus } from '~/client/src/shared/enums/MaterialStatus'
import Company from '~/client/src/shared/models/Company'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import MaterialsListStore, {
  DataKeys,
  IEditableCellModel,
  IFormCellData,
  ILocationCellData,
} from './MaterialsList.store'
import CurrentLocationCell from './components/CurrentLocationCell'
import DeliveryDataCell from './components/DeliveryDataCell'
import MaterialTransferCell from './components/MaterialTransferCell'

interface IMaterialsList {
  store: MaterialsListStore
}

const LOCATION_ICON_SIZE = 14

@observer
export default class MaterialsList extends React.Component<IMaterialsList> {
  public render() {
    const {
      rows,
      columns,
      columnsWidthState,
      groupingKey,
      collapsedCategories,
      toggleCategoryCollapsing,
      fixedColumnsCount,
      sortState,
      handleColumnSort,
      onSelectAll,
      onResetAllSelections,
      toggleCategory,
    } = this.props.store

    return (
      <GroupedListWithFixedColumns
        onColumnSort={handleColumnSort}
        rows={rows}
        columnsCount={columns.length}
        columns={columns}
        columnsWidthState={columnsWidthState}
        sortedColumnKey={sortState.columnKey}
        sortingOrder={sortState.order}
        groupingKey={groupingKey}
        fixedColumnsCount={fixedColumnsCount}
        collapsedCategories={collapsedCategories}
        onCellClick={this.handleCellClick}
        onCategoryCheckboxToggle={toggleCategory}
        columnKeyToCellRenderer={this.columnKeyToCellRenderer}
        onCategoryCollapsingToggle={toggleCategoryCollapsing}
        selectAll={onSelectAll}
        resetAll={onResetAllSelections}
      />
    )
  }

  @action.bound
  private handleCellClick(rowData: LWFCRowData, columnKey: string) {
    const { onCheckboxClick } = this.props.store
    if (columnKey === DataKeys.CHECKBOX) {
      onCheckboxClick(rowData[DataKeys.ID])
    }
  }

  private get columnKeyToCellRenderer() {
    return {
      [DataKeys.STATUS]: (value: MaterialStatus) => (
        <MaterialStatusIndicator status={value} />
      ),
      [DataKeys.DESCRIPTION]: this.renderEditableTextColumn,
      [DataKeys.PLANNED_QUANTITY]: this.renderEditableTextColumn,
      [DataKeys.PLANNED_INSTALL_LOCATION]: this.renderLocation,
      [DataKeys.PLANNED_DELIVERY_LOCATION]: this.renderLocation,
      [DataKeys.NEXT_DELIVERY]: this.renderDeliveryDataCell(
        DataKeys.NEXT_DELIVERY,
      ),
      [DataKeys.NEXT_DELIVERY_BOOKING_DATE]: this.renderDeliveryDataCell(
        DataKeys.NEXT_DELIVERY_BOOKING_DATE,
      ),
      [DataKeys.CURRENT_MATERIAL_LOCATION]: this.renderCurrentLocationCell,
      [DataKeys.VENDOR]: this.renderDeliveryDataCell(DataKeys.VENDOR),
      [DataKeys.RESPONSIBLE_COMPANY]: this.renderDeliveryDataCell(
        DataKeys.RESPONSIBLE_COMPANY,
      ),
      [DataKeys.MATERIAL_TRANSFER_ID]: this.renderMaterialTransferCell(
        DataKeys.MATERIAL_TRANSFER_ID,
      ),
      [DataKeys.MATERIAL_TRANSFER_DATE]: this.renderMaterialTransferCell(
        DataKeys.MATERIAL_TRANSFER_DATE,
      ),
    }
  }

  private renderEditableTextColumn = ({
    viewModelId,
    value,
    isNumber,
    onChange,
  }: IEditableCellModel): JSX.Element => {
    const inputType = isNumber ? 'number' : null
    return (
      <EditableTextColumn
        value={value}
        onSave={onChange}
        isEditable
        type={inputType}
        rowId={viewModelId}
      />
    )
  }

  private renderLocation(location: LocationBase): JSX.Element {
    if (!location) {
      return <div>{NO_VALUE}</div>
    }

    return (
      <div className="col y-center full-height text-ellipsis">
        <Location
          dto={location}
          iconSize={LOCATION_ICON_SIZE}
          contentClassName="text large"
          chainClassName="text small grey-light ellipsis pl24"
          contentContainerClassName="text-ellipsis py2"
        />
      </div>
    )
  }

  private renderDeliveryDataCell =
    (dataKey: string) =>
    (cellData: IFormCellData): JSX.Element =>
      (
        <DeliveryDataCell
          dataKey={dataKey}
          deliveryCellData={cellData}
          onNewDeliveryClick={this.props.store.openNewFormFromCell}
          onIdClick={this.props.store.openExistingDelivery}
          isNewBtnAvailable={!this.props.store.areMaterialsHiddenInDelivery}
          locationRenderer={this.renderLocation}
          companyRenderer={this.renderCompanyProfile}
        />
      )

  private renderCurrentLocationCell = (
    cellData: ILocationCellData,
  ): JSX.Element => (
    <CurrentLocationCell
      locationCellData={cellData}
      isTransferEnabled={!!this.props.store.transferFormType}
      onNewTransferClick={this.props.store.openNewTransferForm}
      onTransferIdClick={this.props.store.openExistingTransfer}
      locationRenderer={this.renderLocation}
    />
  )

  private renderMaterialTransferCell =
    (dataKey: string) =>
    (cellData: IFormCellData): JSX.Element =>
      (
        <MaterialTransferCell
          dataKey={dataKey}
          formCellData={cellData}
          isTransferEnabled={!!this.props.store.transferFormType}
          onNewTransferClick={this.props.store.openNewTransferForm}
          onTransferIdClick={this.props.store.openExistingTransfer}
          locationRenderer={this.renderLocation}
        />
      )

  private renderCompanyProfile(company: Company): JSX.Element {
    if (!company) {
      return <div>{NO_VALUE}</div>
    }

    return (
      <CompanyProfilePreview
        company={company}
        isMenuHidden={true}
        isPhoneHidden={true}
      />
    )
  }
}
